import * as yup from 'yup';

const phoneFormatRegex = /^(?:0|\+32|\+33)(?:\d{2})(?:\d{6}|\d{7})$/;

export const clientScheme = (t) => yup.object({
    genderId: yup.string().required(),
    originId: yup.string().nullable(),
    statusId: yup.string().nullable(),
    priorityId: yup.string().nullable(),
    name: yup.string().required().default('').min(2).max(100),
    email1: yup.string().email().nullable().max(100),
    email2: yup.string().email().nullable().max(100),
    phone1: yup.string()
        .nullable()
        .max(100)
        .test('isValidPhone1', t('validations.phone'), function(value) {
            return !value || (value && phoneFormatRegex.test(value));
        }),
    phone2: yup.string()
        .nullable()
        .max(100)
        .test('isValidPhone2', t('validations.phone'), function(value) {
            return !value || (value && phoneFormatRegex.test(value));
        }),
    address: yup.string().required().default('').max(100),
    nb: yup.string().required().default('').max(100),
    postalCode: yup.string().required().default('').max(100),
    city: yup.string().required().default('').max(100),
    countryId: yup.string().required().max(100),
    invoiceAddress: yup.string().when('$useInvoiceAddress', {
        is: true,
        then: () => yup.string().required().max(100),
        otherwise: () => yup.string().nullable()
    }),
    invoiceNb: yup.string().when('$useInvoiceAddress', {
        is: true,
        then: () => yup.string().required().max(100),
        otherwise: () => yup.string().nullable()
    }),
    invoicePostalCode: yup.string().when('$useInvoiceAddress', {
        is: true,
        then: () => yup.string().required().max(100),
        otherwise: () => yup.string().nullable()
    }),
    invoiceCity: yup.string().when('$useInvoiceAddress', {
        is: true,
        then: () => yup.string().required().max(100),
        otherwise: () => yup.string().nullable()
    }),
    invoiceCountryId: yup.string().when('$useInvoiceAddress', {
        is: true,
        then: () => yup.string().required().max(100),
        otherwise: () => yup.string().nullable()
    }),
    tva: yup.string().nullable().max(100),
    note: yup.string().nullable().max(1000)
});
