import quotesService from "./quotes.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export function useQuotes() {
    const {data: quotes, isFetched, isLoading, isError} = useQuery(['quotes'], quotesService.all, {
        initialData: []
    });

    return {
        quotes,
        isLoading,
        isFetched,
        isError
    };
}

export function useQuotesByClient(id) {
    const {data: quotes, isLoading, isError} = useQuery(['quotes', id], ({queryKey}) => quotesService.getByClient(queryKey[1]));

    return {
        quotes, isLoading, isError
    };
}

export function useQuote(id, options = null) {
    const {data: quote, isLoading, isError} = useQuery(['quotes', id], ({queryKey}) => quotesService.get(queryKey[1]), options);

    return {
        quote, isLoading, isError
    };
}

export function useUpdateQuoteMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => quotesService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['quotes']);
        }
    });

    return mutation;
}


export function useCreateQuoteMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(quotesService.create, {
        onSuccess: (data) => {
            const quotes = queryClient.getQueryData(['quotes']) ?? [];
            queryClient.setQueryData(['quotes'], [...quotes, data]);
        }
    });

    return mutation;
}

export function useCreateQuoteZipMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation((params) => quotesService.createZip(params), {
        onSuccess: (data) => {
            const quotes = queryClient.getQueryData(['quotes']) ?? [];
            queryClient.setQueryData(['quotes'], [...quotes, data]);
        }
    });

    return mutation;
}

export function useDeleteQuoteMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(quotesService.delete, {
        onSuccess: (data, variables) => {
            const quotes = queryClient.getQueryData(['quotes']) ?? [];
            queryClient.setQueryData(['quotes'], quotes.filter(x => x.id !== variables))
        }
    });

    return mutation;
}

export function useQuoteTemplates() {
    const {data: quoteTemplates, isLoading, isError} = useQuery(['quote-templates'], quotesService.allTemplates, {
        initialData: []
    });

    return {
        quoteTemplates,
        isLoading,
        isError
    };
}

export function useCreateQuoteTemplateMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(quotesService.createTemplate, {
        onSuccess: (data) => {
            const quotes = queryClient.getQueryData(['quote-templates']) ?? [];
            queryClient.setQueryData(['quote-templates'], [...quotes, data]);
        }
    });

    return mutation;
}

export function useDeleteQuoteTemplateMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(quotesService.deleteTemplate, {
        onSuccess: (data, variables) => {
            const quotes = queryClient.getQueryData(['quote-templates']) ?? [];
            queryClient.setQueryData(['quote-templates'], quotes.filter(x => x.id !== variables))
        }
    });

    return mutation;
}

export function useQuoteStatusMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => quotesService.setStatus(params.id, params.data), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['quotes']);
        }
    });

    return mutation;
}

export function useQuoteAddDwgMutation() {
    const mutation = useMutation((params) => quotesService.addDwg(params.id));

    return mutation;
}

export function useQuoteAddCommentMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => quotesService.addComment(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['quotes', params.id]);
        }
    });

    return mutation;
}
