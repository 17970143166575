import {Route, Routes} from "react-router-dom";
import {CreateProviderPage} from "./providers-create.page";
import {EditProviderPage} from "./providers-edit.page";
import {ProviderList} from "./provider-list";

export function Providers() {
    return <Routes>

        <Route path=":id" element={<EditProviderPage />} />
        <Route path="create" element={<CreateProviderPage />} />
        <Route path="*" element={<ProviderList />} />

    </Routes>

}