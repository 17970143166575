import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {useState} from "react";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {CustomLink} from "../core/CustomLink";
import {Controller, useForm} from "react-hook-form";
import {classNames} from 'primereact/utils';
import {useAuth} from "./auth.context";
import {FormErrorMessage} from "../core/FormErrorMessage";
import {useTranslation} from "react-i18next";

export function LoginPage() {
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const { t } = useTranslation();

    const defaultValues = {
        email: '',
        password: ''
    };

    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            await auth.logIn(data.email, data.password);
        }
        catch (err) {
            setError(err?.response?.data?.message || t('auth.loginError'));
        }
        finally {
            setLoading(false);
        }
    };



    return (
        <div className="h-screen flex" style={{background: '#DF8009'}}>
            <Card className="   lg:px-6 lg:shadow-3 lg:col-4 lg:col-offset-4
                                md:px-4 md:shadow-3 md:col-6 md:col-offset-3
                                my-auto"
            >
                <div>
                    <img alt="logo" src="https://www.deyemon.be/wp-content/uploads/2020/10/logo_alonge_B.png" height="90" className="mb-4 mt-2 w-full"></img>
                </div>
                {error && (<p className="p-error">{error}</p>) }
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid mt-5">
                    <div className="field mt-5">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller name="email" control={control}
                                            rules={{ required: t('auth.emailRequired'), pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: t('auth.emailInvalid') }}}
                                            render={({ field, fieldState }) => (
                                                <InputText autoFocus id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>Email</label>
                            </span>
                        <FormErrorMessage error={errors['email']} />
                    </div>
                    <div className="field mt-5">
                            <span className="p-float-label">
                                <Controller name="password" control={control} rules={{ required: t('auth.passwordRequired') }} render={({ field, fieldState }) => (
                                    <Password id={field.name} {...field} toggleMask feedback={false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Password</label>
                            </span>
                        <FormErrorMessage error={errors['password']} />
                    </div>
                    <Button type="submit" label={t('auth.login')} loading={loading} />
                </form>
                <div className="text-right">
                    <CustomLink to="/forgotpassword" label={t('auth.forgotPassword')} />
                </div>
            </Card>
        </div>
    );
}