import {Route, Routes} from "react-router-dom";
import {CreatePriorityPage} from "./priorities-create.page";
import {EditPriorityPage} from "./priorities-edit.page";
import {PrioritiesList} from "./priorities-list";


export function Priorities() {
    return <Routes>
        <Route path=":id" element={<EditPriorityPage />} />
        <Route path="/create" element={<CreatePriorityPage />} />
        <Route path="*" element={<PrioritiesList />} />
    </Routes>
}