import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {Divider} from "primereact/divider";
import {RequireRole} from "../auth/RequireRole";
import {Genders} from "../clients/genders/genders";
import {useTranslation} from "react-i18next";
import {Origins} from "../clients/origins/origin";
import {Priorities} from "../clients/priorities/priorities";
import {Units} from "../units/units";
import {Providers} from "../providers/providers";
import {Categories} from "../categories/categories";
import {BaseFile} from "../organisations/baseFiles/base-file";
import {Logo} from "../organisations/logos/logo";
import {SaleCondition} from "../organisations/sale-condition/sale-condition";
import {QuoteEdit} from "./quote/quote.edit";
import {Emails} from "../email/emails";
import {Signature} from "../organisations/signature/signature";
import {Downloads} from "../organisations/download/downloads";
import {QuoteTemplates} from "./quote/templates/quote.templates";
import {InvoiceTemplates} from "./invoice/templates/invoice.templates";
import { Theme } from "../organisations/theme/theme";
import { Countries } from "../clients/countries/countries";

export function Settings() {

    let navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <div className="relative surface-200">
            <div className="fixed bottom-10 pr-3 md:col-2 sm:col-1 h-screen">

                <div className="p-buttonset">
                    <Button className="p-button-text p-button-rounded bg-white w-12 m-0 text-left" label={t('gender.title')} onClick={() => navigate("/settings/genders")}/>
                    <Divider className="m-1"/>
                </div>
                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('origin.title')} onClick={() => navigate("/settings/origins")}/>
                    <Divider className="m-1" />
                </div>
                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('priority.title')} onClick={() => navigate("/settings/priorities")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('unit.title')} onClick={() => navigate("/settings/units")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('provider.title')} onClick={() => navigate("/settings/providers")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('category.title')} onClick={() => navigate("/settings/categories")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('common.country')} onClick={() => navigate("/settings/countries")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('baseFiles.title')} onClick={() => navigate("/settings/basefiles")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('logos.title')} onClick={() => navigate("/settings/logos")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('salecondition.title')} onClick={() => navigate("/settings/salecondition")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('signature.title')} onClick={() => navigate("/settings/signature")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('quote.optionPdf.option')} onClick={() => navigate("/settings/quotes")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('quote.template.title')} onClick={() => navigate("/settings/quote-templates")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('invoice.template.title')} onClick={() => navigate("/settings/invoice-templates")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('email.title')} onClick={() => navigate("/settings/emails")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t('downloads.title')} onClick={() => navigate("/settings/downloads")}/>
                    <Divider className="m-1" />
                </div>

                <div className="p-buttonset">
                    <Button className="p-button-text bg-white w-12 m-0 text-left" label={t("theme.title")} onClick={() => navigate("/settings/theme")}/>
                    <Divider className="m-1" />
                </div>

            </div>

            <div className="md:col-offset-2 sm:col-offset-1 col">
                <Routes>
                    <Route path="*" element={<Navigate to="genders" />} />

                    <Route path="/genders/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Genders />
                        </RequireRole>
                    } />

                    <Route path="/origins/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Origins />
                        </RequireRole>
                    } />

                    <Route path="/priorities/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Priorities />
                        </RequireRole>
                    } />

                    <Route path="/units/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Units />
                        </RequireRole>
                    } />

                    <Route path="/providers/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Providers />
                        </RequireRole>
                    } />

                    <Route path="/categories/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Categories />
                        </RequireRole>
                    } />

                    <Route path="/countries/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Countries />
                        </RequireRole>
                    } />

                    <Route path="/basefiles/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <BaseFile />
                        </RequireRole>
                    } />

                    <Route path="/logos/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Logo />
                        </RequireRole>
                    } />

                    <Route path="/salecondition/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <SaleCondition />
                        </RequireRole>
                    } />

                    <Route path="/signature/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Signature />
                        </RequireRole>
                    } />

                    <Route path="/quotes/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <QuoteEdit />
                        </RequireRole>
                    } />

                    <Route path="/quote-templates/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <QuoteTemplates />
                        </RequireRole>
                    } />

                    <Route path="/invoice-templates/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <InvoiceTemplates />
                        </RequireRole>
                    } />

                    <Route path="/emails/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Emails />
                        </RequireRole>
                    } />

                    <Route path="/downloads/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Downloads />
                        </RequireRole>
                    } />

                    <Route path="/theme/*" element={
                        <RequireRole role="ROLE_ADMIN">
                            <Theme />
                        </RequireRole>
                    } />

                </Routes>
            </div>
        </div>
    )
}