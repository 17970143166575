import {action, computed, makeObservable, observable} from "mobx";
import Decimal from "decimal.js";
import {MaterialLine} from "./MaterialLine";
import {v4 as uuidv4} from "uuid";
import {PostLine} from "./PostLine";
import { MultipleMaterialLine } from "./MultipleMaterialLine";

export class SectionLine
{
    id;
    type = 'section';
    description = "";
    lines = [];
    shouldFocus = false

    note = null;
    coefficient;

    totalExclVAT6 = new Decimal("0");
    totalExclVAT21 = new Decimal("0");
    totalVAT6 = new Decimal("0");
    totalVAT21 = new Decimal("0");

    static create() {
        return new SectionLine(uuidv4(), "",[], true, null, 1);
    }

    constructor(id, description, lines, shouldFocus = false, note = null, coefficient) {
        this.id = id;
        this.description = description;
        this.lines = lines;
        this.shouldFocus = shouldFocus
        this.note = note;
        this.coefficient = coefficient;

        makeObservable(this, {
            lines: observable,
            description: observable,
            coefficient: observable,
            total: computed,
            subTotal: computed,
            vatTotal: computed,
            changeSubTotal: action,
            updateFromForm: action,
            moveLine: action,
            pasteLine: action,
            removeLine: action,
            addMaterial: action,
            addPost: action,
            valid: computed,
            updateDescription: action,
            updateSubTotal: action,
            note: observable,
            updateNote: action,
            updateCoefficient: action,
            option: computed,
            updateOption: action,
            optionSubTotal: computed,
            optionTotal: computed,
            discount: computed,
            updateDiscount: action,
            discountSubTotal: computed,
            discountTotal: computed,
            addMultipleMaterial: action,
            removeMultipleMaterialLine: action,
            variant: computed,
            updateVariant: action,
            variantSubTotal: computed,
            variantTotal: computed,
        })
    }


    updateNote(value) {
        this.note = value;
    }
    get subTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.subTotal), new Decimal(0))
    }

    get total() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.total), new Decimal(0))
    }

    populateMultipleVAT() {
        // first reset values
        this.totalExclVAT6 = new Decimal("0");
        this.totalExclVAT21 = new Decimal("0");
        this.totalVAT6 = new Decimal("0");
        this.totalVAT21 = new Decimal("0"); 
        // secondly compute all vat values
        this.lines.filter(line => line.type !== 'multipleMaterial').forEach(line => { 
            if (line.type !== 'material') {
                line.populateMultipleVAT();
                this.totalExclVAT6 = this.totalExclVAT6.add(line.totalExclVAT6, new Decimal(0));
                this.totalVAT6 = this.totalVAT6.add(line.totalVAT6, new Decimal(0));
                this.totalExclVAT21 = this.totalExclVAT21.add(line.totalExclVAT21, new Decimal(0));
                this.totalVAT21 = this.totalVAT21.add(line.totalVAT21, new Decimal(0));
            } else {
                if (line.vat.d[0] === 6 ) {
                    if (!line.variant && !line.option && !line.discount) {
                        this.totalExclVAT6 = this.totalExclVAT6.add(line.subTotal, new Decimal(0));
                        this.totalVAT6 = this.totalVAT6.add(line.total, new Decimal(0));
                    } else if (line.discount) {
                        this.totalExclVAT6 = this.totalExclVAT6.minus(line.subTotal, new Decimal(0));
                        this.totalVAT6 = this.totalVAT6.minus(line.total, new Decimal(0));
                    }
                } else if (line.vat.d[0] === 21) {
                    if (!line.variant && !line.option && !line.discount) {
                        this.totalExclVAT21 = this.totalExclVAT21.add(line.subTotal, new Decimal(0));
                        this.totalVAT21 = this.totalVAT21.add(line.total, new Decimal(0));
                    } else if (line.discount) {
                        this.totalExclVAT21 = this.totalExclVAT21.minus(line.subTotal, new Decimal(0));
                        this.totalVAT21 = this.totalVAT21.minus(line.total, new Decimal(0));
                    }
                }
            }
        });
    }

    get vatTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.vatTotal), new Decimal(0))
    }

    get optionSubTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.optionSubTotal), new Decimal(0))
    }
    get optionTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.optionTotal), new Decimal(0))
    }

    get discountSubTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.discountSubTotal), new Decimal(0))
    }
    get discountTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.discountTotal), new Decimal(0))
    }

    get variantSubTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.variantSubTotal), new Decimal(0))
    }
    get variantTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.variantTotal), new Decimal(0))
    }

    changeSubTotal(newSubTotal) {
        const priceShare = newSubTotal.dividedBy(this.subTotal.minus(this.variantSubTotal))

        this.lines.forEach(x => {
            x.changeSubTotal(x.subTotal.times(priceShare))
        })
    }

    updateFromForm(data) {
        // console.log("Updating from section form");
        const subTotal = new Decimal(data.subTotal);

        if(!this.subTotal.equals(subTotal)) {
            this.changeSubTotal(subTotal)
            return;
        }

        if(this.description !== data.description) {
            this.description = data.description
        }

        if(this.note !== data.note) {
            this.note = data.note;
        }
    }

    updateDescription(description) {
        this.description = description
    }

    updateCoefficient(coefficient) {
        const oldCoef = Number(this.coefficient) === 1 ? 1 : (this.coefficient/100)+1;
        this.coefficient = coefficient;
        const coefValue = Number(this.coefficient) === 1 ? 1 : (this.coefficient/100)+1;
    
        this.lines.forEach(x => {
            x.changeSubTotal(x.subTotal.div(oldCoef).times(coefValue))
        })
    }

    updateSubTotal(value) {
        const subTotal = new Decimal(value);

        if(!this.subTotal.equals(subTotal)) {
            this.changeSubTotal(subTotal)
        }
    }

    moveLine(lineItem, dragItem, dragIndex, hoverIndex) {
        // dragIndex & hoverIndex doesn't match the real actual position index
        const draggedItemIndex = this.lines.findIndex(line => line.id === dragItem.id);
        const hoverItemIndex = this.lines.findIndex(line => line.id === lineItem.id);

        this.lines.splice(hoverItemIndex, 0, this.lines.splice(draggedItemIndex, 1)[0]);
    }

    pasteLine(line, addAfterLine) {
        const insertLine = (newLine, indexToAddAfter) => {
            this.lines.splice(indexToAddAfter + 1, 0, newLine);
    
            if (indexToAddAfter < this.lines.length - 1) {
                const itemsToMove = this.lines.splice(indexToAddAfter + 2);
                this.lines.push(...itemsToMove);
            }
        };
    
        const createMaterialLine = (inputLine) => {
            const materialLine = MaterialLine.create();
            materialLine.description = inputLine.description;
            materialLine.unit = inputLine.unit;
            materialLine.amount = new Decimal(inputLine.amount);
            materialLine.pricePerUnit = new Decimal(inputLine.pricePerUnit);
            materialLine.vat = new Decimal(inputLine.vat);
            materialLine.note = inputLine.note;
            materialLine.option = inputLine.option;
            materialLine.discount = inputLine.discount;
            return materialLine;
        };
    
        const createPostLine = (inputLine) => {
            const postLine = PostLine.create();
            postLine.description = inputLine.description;
            postLine.unit = inputLine.unit;
            postLine.amount = new Decimal(inputLine.amount);
            postLine.note = inputLine.note;
            const linesToInsert = inputLine.lines.map(createMaterialLine);
            postLine.lines = linesToInsert;
            return postLine;
        };
    
        let lineToInsert = null;
    
        if (line.type === 'material') {
            lineToInsert = createMaterialLine(line);
        } else if (line.type === 'post') {
            lineToInsert = createPostLine(line);
        } else {
            throw new Error('Type not implemented');
        }
    
        const indexToAddAfter = this.lines.findIndex((line) => line.id === addAfterLine.id);
        insertLine(lineToInsert, indexToAddAfter);
    }

    removeLine(lineItem) {
        const index = this.lines.indexOf(lineItem);
        if(index !== -1) {
            this.lines.splice(index, 1)
        }
    }

    addMaterial(description) {
        const material = MaterialLine.create(description);
        this.lines.push(material)
        return material
    }

    addMultipleMaterial() {
        this.lines.push(MultipleMaterialLine.create(this));
    }

    addPost() {
        this.lines.push(PostLine.create())
    }

    removeMultipleMaterialLine(multipleMaterialLine) {
        const lineFound = this.lines.findIndex(line => line.id === multipleMaterialLine.id);
        this.lines.splice(lineFound, 1);
    }

    get valid() {

        for (let i = 0; i < this.lines.length; i++) {
            if(!this.lines[i].valid) {
                return false;
            }
        }

        return this.lines.length > 0;
    }

    get dto() {
        return {
            id: this.id,
            description: this.description,
            type: this.type,
            subTotal: this.subTotal.toFixed(2),
            vatTotal: this.vatTotal.toFixed(2),
            total: this.total.toFixed(2),
            lines: this.lines.map(x => x.dto),
            note: this.note,
            option: this.option,
            optionSubTotal: this.optionSubTotal.toFixed(2),
            optionTotal: this.optionTotal.toFixed(2),
            discount: this.discount,
            discountSubTotal: this.discountSubTotal.toFixed(2),
            discountTotal: this.discountTotal.toFixed(2),
            coefficient: this.coefficient.toString(),
        }
    }

    get option() {
        for (let i = 0; i < this.lines.length; i++) {
            if(!this.lines[i].option) {
                return false;
            }
        }

        return this.lines.length === 0 ? false : true;
    }

    updateOption(value) {
        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            if(line instanceof PostLine || line instanceof MaterialLine) {
                line.updateOption(value);
            }
        }
    }

    get discount() {
        for (let i = 0; i < this.lines.length; i++) {
            if(!this.lines[i].discount) {
                return false;
            }
        }

        return this.lines.length === 0 ? false : true;
    }

    updateDiscount(value) {
        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            if(line instanceof PostLine || line instanceof MaterialLine) {
                line.updateDiscount(value);
            }
        }
    }

    get variant() {
        for (let i = 0; i < this.lines.length; i++) {
            if(!this.lines[i].variant) {
                return false;
            }
        }

        return this.lines.length === 0 ? false : true;
    }

    updateVariant(value) {
        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            if(line instanceof PostLine || line instanceof MaterialLine) {
                line.updateVariant(value);
            }
        }
    }
}
