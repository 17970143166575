import {Trans, useTranslation} from "react-i18next";
import {Dialog} from "primereact/dialog";
import {Fragment} from "react";
import {Button} from "primereact/button";


export function ConfirmDelete({visible, name, onHide, loading, onConfirmed}) {

    const {t} = useTranslation();

    const deleteDialogFooter = (
        <Fragment>
            <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={onHide} loading={loading} />
            <Button label={t('common.yes')} icon="pi pi-trash" className="p-button-text p-button-danger" onClick={onConfirmed} loading={loading} />
        </Fragment>
    );

    return (
        <Dialog visible={visible} header={t('common.confirm')} modal footer={deleteDialogFooter} onHide={onHide} closeOnEscape={false} closable={false}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                <Trans i18nKey="common.confirmDelete" values={{name}}>
                    Are you sure you want to delete <strong>{{name}}</strong> from the list?
                </Trans>
            </div>
        </Dialog>
    );
}