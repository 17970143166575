import api from "./api";
import TokenService from "./token.service";
import jwtDecode from "jwt-decode";

class AuthService {
    login(username, password) {
        return api
            .post("/api/login", {
                "username": username,
                "password": password
            })
            .then(res => {
                const accessToken = res.data.token;
                const refreshToken = res.data.refreshToken;

                const decoded = jwtDecode(accessToken);

                localStorage.setItem('user', JSON.stringify({
                    accessToken,
                    refreshToken
                }));

                const user = {
                    email: decoded.email,
                    name: decoded.name,
                    roles: decoded.roles,
                    organisation: decoded.organisation,
                    accessToken: accessToken,
                    refreshToken: refreshToken
                };

                TokenService.setUser(user);

                return user;
            }).catch((error) => {
				throw error;
			});
    }
    logout() {
        TokenService.removeUser();
    }
    getCurrentUser() {
        return TokenService.getUser();
    }
    getCurrentExpiry() {
        return TokenService.getExpiry();
    }

    forgotPassword(email) {
        return api
            .post('api/forgotpassword', {
                email
            })
    }

    setPassword(token, password) {
        return api
            .post( '/api/password', {
                token,
                password
            })
    }

}
export default new AuthService();