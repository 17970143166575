import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Card} from "primereact/card";
import {FilterMatchMode} from "primereact/api";
import {Dialog} from "primereact/dialog";
import {HasRole} from "../auth/has-role";
import {useToast} from "../core/toast.context";
import {useClients, useDeleteClientMutation} from "./clients.hooks";
import {Trans, useTranslation} from "react-i18next";
import Moment from "moment";
import {TopMenu} from "../core/TopMenu";
import {ClientStatus} from "../core/ClientStatus";
import {Dropdown} from "primereact/dropdown";
import {useClientStatuses} from "./client.status.hooks";
import {Skeleton} from "primereact/skeleton";
import {DateTime} from "../core/DateTime";
import {ScreenSize} from "../core/ScreenSize";

export function ClientsListPage(clientExt = null) {


    const {clients, isLoading, isFetched} = useClients();

    const deleteMutation = useDeleteClientMutation();

    const [client, setClient] = useState(null);

    let navigate = useNavigate();
    let toast = useToast();

    const {t} = useTranslation();

    const [filters] = useState({
        //'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'formattedId': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'gender.name': { value: null, matchMode: FilterMatchMode.EQUALS },
        'name': {value: null, matchMode: FilterMatchMode.CONTAINS },
        'email1': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'phone1': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'city': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'origin.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'status': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'priority.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const actionTemplate = (client) => {
        return (
            <Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text m-0" onClick={() => navigate("/clients/"+client.id)} />
                <Button icon="pi pi-folder-open" className="p-button-rounded p-button-warning p-button-text m-0" onClick={() => {window.location.href = 'deyemon://G:/Mon Drive/Deyemon Gestion/'+Moment(client.createdAt).format('YYYY')+"/"+client.formattedId+" - "+client.name;}} />
                <HasRole role="ROLE_ADMIN">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text m-0" onClick={() => confirmDeleteClient(client)}/>
                </HasRole>
            </Fragment>
        )
    };

    const confirmDeleteClient = (client) => {
        setClient(client);
    };

    const hideDeleteDialog = () => {
        setClient(null);
    };

    const deleteClient = () => {
        deleteMutation.mutateAsync(client.id)
            .then(() => {
                toast.current.show({severity: 'success', summary: t('client.deleted.title'), detail: t('client.deleted.text'), life: 3000});
            })
            .catch((err) => {
                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('client.deleted.problem') + " " + err , life: 3000});
            })
            .finally(() => {
                hideDeleteDialog();
            });
    };

    const deleteDialogFooter = (
        <Fragment>
            <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} loading={deleteMutation.isLoading} />
            <Button label={t('common.yes')} icon="pi pi-trash" className="p-button-text p-button-danger" onClick={deleteClient} loading={deleteMutation.isLoading} />
        </Fragment>
    );

    const onRowClicked = (event) => {

        if (event.field === "email1") {
            if (event.value) {
                window.location = 'mailto:' + event.value
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: t('common.oops'),
                    detail: t('email.open.problem'),
                    life: 3000
                });
            }

        }else if (event.field === "phone1"){
            if (event.value) {
                window.location = 'tel:' + event.value
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: t('common.oops'),
                    detail: t('client.phone.problem'),
                    life: 3000
                });
            }
        }else{
            navigate('/clients/'+event.rowData.id)
        }
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option.value}`}>{option.name}</span>;
    }

    const clientStatuses = useClientStatuses();

    const clientStatusFilterElement = (options) => {
        return <Dropdown value={options.value} options={clientStatuses} optionLabel={"name"} optionValue={"value"} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder={t('common.status')} className="p-column-filter" showClear />;
    }

    const items = Array.from({length: 10}, (v, i) => i);

    const skeletonTemplate = () => {
        return <Skeleton/>;
    };

    return (
        <div>
            {!clientExt.client && <TopMenu moduleName={t('client.title')} create={'/clients/create/'} />}
            <Card className='shadow-3 mt-3' title={t('client.list')}>
                <DataTable
                    value={isLoading || !isFetched ? items : clientExt.client ? clients.filter((x) => x.client.id === clientExt.client) : clients}
                    paginator
                    sortMode='multiple'
                    filterDisplay='row'
                    filters={filters}
                    dataKey='id'
                    stripedRows
                    selectionMode='single'
                    emptyMessage={t('client.emptyList')}
                    currentPageReportTemplate={t('datatable.reportTemplate')}
                    rows={25}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    rowHover
                    responsiveLayout='scroll'
                    columnResizeMode='expand'
                    size={'small'}
                    cellSelection
                    onCellSelect={(e) => onRowClicked(e)}>
                    <Column
                        field='formattedId'
                        header={t('client.number')}
                        sortable
                        filter
                        filterPlaceholder={t('client.number')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.formattedId)}
                    />
                    <Column
                        field='gender.name'
                        header={t('common.gender')}
                        sortable
                        filter
                        filterPlaceholder={t('common.gender')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.gender?.name)}
                    />
                    <Column
                        field='name'
                        header={t('common.name')}
                        sortable
                        filter
                        filterPlaceholder={t('common.name')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.name)}
                    />
                    <Column
                        field='email1'
                        header={t('common.email')}
                        sortable
                        filter
                        filterPlaceholder={t('common.email')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton /> : row.email1)}
                    />
                    <Column
                        field='phone1'
                        header={t('common.phoneNumber')}
                        sortable
                        filter
                        filterPlaceholder={t('common.phoneNumber')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.phone1)}
                    />
                    <Column
                        field='city'
                        header={t('common.city')}
                        sortable
                        filter
                        filterPlaceholder={t('common.city')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.city)}
                    />
                    <Column
                        field='origin.name'
                        header={t('common.origin')}
                        sortable
                        filter
                        filterPlaceholder={t('common.origin')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.origin?.name)}
                    />
                    <Column
                        field='status'
                        header={t('common.status')}
                        sortable
                        filter
                        filterPlaceholder={t('common.status')}
                        filterElement={clientStatusFilterElement}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : <ClientStatus id={row.id} status={row.status} sendAt={row.sentToClient} />)}
                    />
                    <Column
                        field='priority.name'
                        header={t('common.priority')}
                        sortable
                        filter
                        filterPlaceholder={t('common.priority')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.priority?.name)}
                    />
                    <Column body={isLoading || !isFetched ? skeletonTemplate : actionTemplate} className='p-0' style={{minWidth: '200px'}} />
                </DataTable>
            </Card>

            <Dialog visible={!!client} header={t('common.confirm')} modal footer={deleteDialogFooter} onHide={hideDeleteDialog} closeOnEscape={false} closable={false}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {client &&
                        (
                            <Trans i18nKey="common.confirmDelete" values={{name: client.name}}>
                                Are you sure you want to delete <strong>{{name: client.name}}</strong> from the list?
                            </Trans>
                        )
                    }
                </div>
            </Dialog>

        </div>
    );

}

