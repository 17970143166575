import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import unitService from "./units.service";


export function useUnits() {
    const {data: units, isLoading, isFetched, isError} = useQuery(['units'], unitService.all, {
        initialData: []
    });

    return {
        units,
        isLoading,
        isFetched,
        isError
    };
}

export function useUnit(id, options = null) {
    const {data: unit, isLoading, isError} = useQuery(['units', id], ({queryKey}) => unitService.get(queryKey[1]), options);

    return {
        unit, isLoading, isError
    };
}

export function useUpdateUnitMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => unitService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['units']);
        }
    });

    return mutation;
}


export function useCreateUnitMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(unitService.create, {
        onSuccess: (data) => {
            const units = queryClient.getQueryData(['units']) ?? [];
            queryClient.setQueryData(['units'], [...units, data]);
        }
    });

    return mutation;
}

export function useDeleteUnitMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(unitService.delete, {
        onSuccess: (data, variables) => {
            const units = queryClient.getQueryData(['units']) ?? [];
            queryClient.setQueryData(['units'], units.filter(x => x.id !== variables))
        }
    });

    return mutation;
}