import {Route, Routes} from "react-router-dom";
import {CreateEmailPage} from "./email-create.page";
import {EmailListPage} from "./email-list.page";
import {EditEmailPage} from "./emails-edit.page";


export function Emails(){
    return <Routes>
        <Route path="/create" element={<CreateEmailPage />} />
        <Route path=":id/*" element={<EditEmailPage />} />
        <Route path="*" element={<EmailListPage />} />
    </Routes>
}