import {useQuery} from "@tanstack/react-query";
import licenseService from "./license.service";


export function useLicense() {
    const {data: licenseInformation, isFetched, isLoading, isError} = useQuery(['licenseInformation'], licenseService.getInformation, {
        initialData: null,
        refetchInterval: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        licenseInformation,
        isLoading,
        isFetched,
        isError
    };
}
