import {useForm} from "react-hook-form";
import {InvoiceContext, useInvoiceContext} from "../invoice.context";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {SectionDescriptionInput} from "./description.input";
import {CurrencyInput} from "./currency.input";
import {sectionLineSchema, toolBarWidth} from "../constants";
import {Button} from "primereact/button";
import {InvoiceLine} from "./invoice.line";
import {useLineDnd} from "./use-line.dnd";
import {observer} from "mobx-react";
import {Currency} from "./currency";
import {yupResolver} from "@hookform/resolvers/yup";
import {Menu} from "primereact/menu";
import {classNames} from "primereact/utils";
import {NoteInput} from "./note.input";
import { CoefficientInput } from "../../core/coefficient.input";

export const InvoiceSectionLine = observer(({section, index, parent, isCococontractor}) => {

    const defaultValues = {
        description: section.description,
        subTotal: section.subTotal.toFixed(2),
        note: section.note,
        option: section.option,
        coefficient: section.coefficient.toString(), // Needs to be a string to be the selectedValue in dropdown
    };

    const {control, handleSubmit, watch, setValue, formState} = useForm({defaultValues: defaultValues, mode: 'all', resolver: yupResolver(sectionLineSchema())});

    const invoiceModel = useInvoiceContext();

    useEffect(() => {
        invoiceModel.setFormState(section.id, formState.isValid);

        return () => invoiceModel.unRegisterForm(section.id);
    }, [formState.isValid, section.id, invoiceModel]);

    const onSubmit = useCallback((data) => {
        section.updateFromForm(data);
    }, [section]);

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if(type === 'change') {
                //handleSubmit(onSubmit)()
                switch (name) {
                    case 'description':
                        section.updateDescription(value.description);
                        break;
                    case 'subTotal':
                        section.updateSubTotal(value.subTotal);
                        break;
                    case 'note':
                        section.updateNote(value.note);
                        break;
                    case 'option':
                        section.updateOption(value.option);
                        break;
                    case 'coefficient':
                        section.updateCoefficient(value.coefficient);
                        break;    
                    default:
                        throw new Error("Unimplemented type "+name)
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [section, watch]);

    useEffect(() => {
        setValue('description', defaultValues.description, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.description, setValue]);

    useEffect(() => {
        setValue('option', defaultValues.option, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.option, setValue]);

    useEffect(() => {
        setValue('note', defaultValues.note, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.note, setValue]);

    useEffect(() => {
        setValue('subTotal', defaultValues.subTotal, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.subTotal, setValue]);

    useEffect(() => {
        setValue('coefficient', defaultValues.coefficient, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.coefficient, setValue]);

    const {t} = useTranslation();

    const {ref, drag, isDragging, handlerId} = useLineDnd(section, index, parent);

    const addMenuRef = useRef(null)

    const addMenuItems = useMemo(() => {
        const items = [
            {
                label: t('common.post'),
                command: () => {
                    section.addPost();
                }
            },
            {
                label: t('common.material'),
                command: () => {
                    section.addMaterial();
                }
            }
        ];

        return items;

    }, [t, section]);


    const { copiedLine, setCopiedLine } = useContext(InvoiceContext);

    const optionMenuRef = useRef(null);

    const optionMenuItems = useMemo(() => {
        const items = [
            {
                label: t('invoice.lineOptionType'),
                items: [
                    {
                        label: t('invoice.lineOptionTypeNormal'),
                        icon: !section.extraCharge && !section.credit && !section.variant ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            section.updateExtraCharge(false);
                            section.updateCredit(false);
                            section.updateVariant(false);
                        },
                    },
                    {
                        label: t('invoice.lineOptionTypeExtraCharge'),
                        icon: section.extraCharge ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            section.updateExtraCharge(true);
                            section.updateCredit(false);
                            section.updateVariant(false);
                        },
                    },
                    {
                        label: t('invoice.lineOptionTypeCredit'),
                        icon: section.credit ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            section.updateCredit(true);
                            section.updateExtraCharge(false);
                            section.updateVariant(false);
                        },
                    },
                    {
                        label: t('common.variant'),
                        icon: section.variant ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            section.updateCredit(false);
                            section.updateExtraCharge(false);
                            section.updateVariant(true);
                        },
                    },
                ]
            },
            {
                label: t('invoice.actions'),
                items: [
                    {
                        label: t('common.addNote'),
                        icon: 'pi pi-comment text-green-500',
                        command: () => {
                            if(section.note === null) {
                                setAutoFocusNote(true);
                                section.updateNote("");
                            }
                        }
                    },
                    {
                        label: t('common.post'),
                        icon: 'pi pi-plus text-blue-500',
                        command: () => {
                            section.addPost();
                        }
                    },
                    {
                        label: t('common.material'),
                        icon: 'pi pi-plus text-blue-500',
                        command: () => {
                            section.addMaterial();
                        }
                    },
                    {
                        label: t('invoice.line.delete'),
                        icon: 'pi pi-trash text-red-500',
                        command: () => {
                            parent.removeLine(section)
                        }
                    },
                    {
                        label: t('common.copy'),
                        icon: 'pi pi-copy text-grey-500',
                        command: () => {
                            setCopiedLine(section)
                        }
                    },
                    copiedLine && {
                        label: t('common.paste'),
                        icon: 'pi pi-file text-grey-500',
                        command: () => {
                            const item = copiedLine;
                            setCopiedLine(null)
                            parent.pasteLine(item, section);
                        }
                    },
                ].filter(Boolean) // Remove falsy values (in case "copiedItem" doesn't exist)
            }
        ];

        return items;
    }, [t, section.extraCharge, section.credit, section.variant,  parent, section, copiedLine]);

    const [autoFocusNote, setAutoFocusNote] = useState(false);

    return (
        <>
            <div className="mb-3 mt-3" ref={ref} style={{opacity: isDragging ? 0 : 1}} data-handler-id={handlerId}>
                <form onSubmit={handleSubmit(onSubmit)} className={classNames("grid grid-nogutter pt-1 pb-1", {
            "bg-red-200": section.extraCharge,
            "bg-green-200": section.credit,
            "bg-purple-200": section.variant,
            "surface-200": !section.extraCharge && !section.credit
        })}>


                    <div className="col flex align-items-start flex-wrap">
                        <div className="grid grid-nogutter w-full">
                            <div className="col flex flex-wrap align-items-start">
                                <SectionDescriptionInput inputClassName="p-inputtext text-primary text-lg font-bold w-full" control={control}
                                            fieldName="description" autoFocus={section.shouldFocus} displayTemplate={(value, error) => {
                                    if (error || !value) {
                                        return (t('common.clickToEdit'))
                                    }

                                    return (
                                        <strong className="text-lg" style={{whiteSpace: 'pre-wrap'}}>{value}</strong>);
                                }}/>
                            </div>
                        </div>
                    </div>
                    {section.lines.length === 0 && (
                        <div className="col-6 flex align-items-start justify-content-center">
                            <span className={"text-red-500"}>{t('invoice.emptySectionLines')}</span>
                        </div>
                    )}
                    {section.lines.length > 0 && (
                        <>
                            <div className="col-1 flex align-items-start">

                            </div>
                            <div className="col-1 flex align-items-start">

                            </div>
                            <div className="col-1 flex align-items-start">
                            </div>
                            <div className="col-1 flex align-items-start">
                                <CoefficientInput control={control} fieldName="coefficient" label={t('common.coefficient')} setValue={(newValue) => section.updateCoefficient(newValue)} />
                            </div>
                            <div className="col-1 flex align-items-start flex-wrap">
                                <CurrencyInput control={control} fieldName="subTotal" label={t('common.subTotal')} showLabel addStyle={"font-bold  text-primary"}/>
                            </div>
                        </>
                    )}
                    <div className="col-fixed flex align-items-start justify-content-end  pt-1"
                         style={{width: toolBarWidth}}
                    >
                        <Button type="button" icon={"pi pi-plus"} className={"p-button-text p-button-sm p-button-info"} onClick={(e) => addMenuRef.current.toggle(e)}/>
                        <Menu model={addMenuItems} popup ref={addMenuRef} id="popup_menu" />
                        <Button type="button" icon={"pi pi-trash"} className={"p-button-text p-button-sm p-button-danger"}
                                onClick={() => {
                                    parent.removeLine(section)
                                }}/>

                        <Button type="button" ref={drag} icon={"pi pi-bars"} className={"p-button-text p-button-sm p-button-secondary cursor-move"}></Button>
                        <Button type="button" icon={"pi pi-ellipsis-v"} className={"p-button-text p-button-sm p-button-info"} onClick={(e) => optionMenuRef.current.toggle(e)}/>
                        <Menu model={optionMenuItems} popup ref={optionMenuRef} id="popup_menu" />
                    </div>

                    {section.note !== null && (
                        <>
                            <div className="grid grid-nogutter col-12">
                                <div className={classNames('col-fixed text-right', {'w-2rem': true})}><i className={"pi pi-comment text-sm mt-3"}></i></div>
                                <div className="col flex align-items-start">
                                    <NoteInput control={control} fieldName="note" autoFocus={autoFocusNote}></NoteInput>
                                </div>
                                <div className="col-1">
                                    <Button type={"button"} icon={"pi pi-times"} className={"p-button-text p-button-sm mt-1"} onClick={() => {
                                        section.updateNote(null)
                                    }}/>
                                </div>
                                <div className="col-1"></div>
                                <div className="col-1"></div>
                                <div className="col-1"></div>
                                <div className="col-1"></div>
                                <div className="col-1"></div>
                                <div className="col-fixed flex align-items-start"
                                     style={{width: toolBarWidth}}
                                ></div>
                            </div>
                        </>
                    )}
                </form>
                
                {
                    section.lines.length > 0 && (
                        <div style={{border: `solid #EEEEEE 1px`, paddingLeft: "0.375rem" }}>
                            {section.lines.map((line, i) => { return <InvoiceLine index={index+i+1} key={line.id} line={line} parent={section} isCococontractor={isCococontractor} /> } )}
                        </div>
                    )
                }
            </div>
        </>

    );
})
