import {Route, Routes} from "react-router-dom";
import {DashboardPage} from "./dashboard.page";

export function Dashboard() {
    return <Routes>

        <Route path="*" element={<DashboardPage />} />

    </Routes>

}