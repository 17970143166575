import {useClient} from "../clients.hooks";
import {ProgressSpinner} from "primereact/progressspinner";


export const ClientCard = ({clientId}) => {

    const {client, isLoading} = useClient(clientId);

    if(isLoading) {
        return <>
            <ProgressSpinner />
        </>
    }

    const address = client.invoiceAddress ?? client.address;
    const nb = client.invoiceNb ?? client.nb;
    const city = client.invoiceCity ?? client.city;
    const postalCode = client.invoicePostalCode ?? client.postalCode;
    const country = client.invoiceCountry?.name ?? client.country.name;

    return (
        <>
            <div className="pt-5">

                <p>
                    <strong>
                        {client.gender.name} {client.name}<br/>
                        {address} {nb},<br />{postalCode} {city}, {country}<br/>
                        {client.tva}
                    </strong>
                </p>
            </div>
        </>
    )
};