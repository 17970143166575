import {useTranslation} from "react-i18next";
import {useUnits} from "../../units/units.hooks";
import {Controller} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import {FormErrorMessage} from "../../core/FormErrorMessage";

export function UnitInput({fieldName, control, addStyle}) {

    const {t} = useTranslation();

    const {units, isLoading} = useUnits();

    return (
        <>
            <Controller name={fieldName} control={control}
                        render={({field, fieldState}) => (
                            <>
                                <Dropdown
                                    optionLabel="name"
                                    options={units}
                                    optionValue={"name"}
                                    disabled={isLoading}
                                    placeholder={t('common.unit')}
                                    dropdownIcon={""}
                                    {...field}
                                    // onBlur={(e) => {
                                    //     setActive(false);
                                    //     field.onBlur();
                                    // }}
                                    className={classNames({'p-invalid': fieldState.error}, 'p-inputtext-sm', 'w-full m-1', 'hover:surface-hover background-color-none border-color-none ' + addStyle)}
                                />

                                {fieldState.error && <><div className={"break"}></div><div className="ml-auto"><FormErrorMessage error={fieldState.error} /></div></>}
                            </>
                        )}/>
        </>
    )
}
