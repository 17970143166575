import * as Yup from "yup";

export const postLineScheme = () => Yup.object({
    type: Yup.string().required().default('material'),
    material: Yup.object().required(),
    amount: Yup.number().positive().required(),
    state: Yup.object().default({name: 'normal'}),
});

export const postScheme = () => Yup.object().shape({
    name: Yup.string().required().default('').min(2).max(500),
    unitId: Yup.string().required().max(100),
    note: Yup.string().nullable().max(1000),
    lines: Yup.array().of(postLineScheme())
});