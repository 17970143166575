import {Route, Routes} from "react-router-dom";
import { EditCountryPage } from "./countries-edit.page";
import { CreateCountryPage } from "./countries-create.page";
import { CountryList } from "./country-list";


export function Countries() {
    return <Routes>
        <Route path=":id" element={<EditCountryPage />} />
        <Route path="/create" element={<CreateCountryPage />} />
        <Route path="*" element={<CountryList />} />
    </Routes>
}