import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {sendInvoiceToClientSchema} from "../constants";
import {TextControl} from "../../core/ControlledInputs/TextControl";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {useToast} from "../../core/toast.context";
import {useCallback, useRef, useState} from "react";
import {useQueryClient} from "@tanstack/react-query";
import invoicesService from "../invoices.service";
import {Divider} from "primereact/divider";
import {useEmails} from "../../email/emails.hooks";
import {Dropdown} from "primereact/dropdown";
import {ChipsControl} from "../../core/ControlledInputs/ChipsControl";
import {useClient} from "../../clients/clients.hooks";
import {EditorControl} from "../../core/ControlledInputs/EditorControl";
import {PlaceholderInvoice} from "../../core/PlaceholderInvoice";
import {Card} from "primereact/card";
import {FileUpload} from "primereact/fileupload";
import {ProgressBar} from "primereact/progressbar";


export function SendInvoiceToClientForm({invoice,onCompleted}) {
    const [totalSize,setTotalSize]=useState(0);
    const [useAttachments, setUseAttachments] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState()
    const fileUploadRef = useRef(null);

    const schema = sendInvoiceToClientSchema();

    const {control, handleSubmit, setValue, setFocus} = useForm({
        defaultValues: {
            subject: '',
            to: [],
            cc: [],
            bcc: [],
            body: ''
        },
        resolver: yupResolver(schema)
    });

    const toast = useToast();
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();

    const onSubmit=useCallback(async (data) => {
        if(totalSize>20000000) {
            toast.current.show({severity: 'error',summary: t('common.oops'),detail: t('invoice.sendToClient.attachment'),life: 3000});
            return
        } 
        const files = fileUploadRef?.current?.getFiles()
        setLoading(true);

        try {
            await invoicesService.sendToClient(invoice.id, data, files);
            toast.current.show({severity: 'success', summary: t('invoice.sendToClient.success'), detail: t('invoice.sendToClient.successDetail'), life: 3000});
            await queryClient.invalidateQueries(['invoices']);
            onCompleted();
        } catch (error) {
            toast.current.show({severity: 'error', summary: t('common.oops'), detail: error.response?.data?.detail || t('invoice.sendToClient.problem'), life: 3000});
        }
        finally {
            setLoading(false);
        }
    }, [invoice.id, toast, t, queryClient, totalSize]);

    const {emails, isLoading: isLoadingEmails} = useEmails();

    const {client, isLoading: isLoadingClient} = useClient(invoice.client.id, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        staleTime: 0,
        onSuccess: (data) => {

            const emails = [];
            if(data.email1) {
                emails.push(data.email1);
            }

            if(data.email2) {
                emails.push(data.email2);
            }

            setValue('to', emails);
        }
    });
    const chooseOptions = {label: t('common.choose'), icon: 'pi pi-fw pi-plus'};
	const uploadOptions = {label: t('common.upload'), icon: 'pi pi-upload', className: 'p-button-success'};

		const emptyTemplate = () => {
			return (
				<div className='flex align-items-center flex-column'>
					<i
						className='pi pi-file mt-3 p-5'
						style={{
							fontSize: '2em',
							borderRadius: '50%',
							backgroundColor: 'var(--surface-b)',
							color: 'var(--surface-d)',
						}}></i>
					<span style={{fontSize: '1.2em', color: 'var(--text-color-secondary)'}} className='my-5'>
						{t('email.upload')}
					</span>
				</div>
			);
        };
    
    const onTemplateSelect = (e) => {
        let _totalSize = 0;
        let files=[...fileUploadRef?.current?.getFiles(), ...e.files];
        const uniqueIds = [];
        const unique = files.filter((element) => {
					const isDuplicate = uniqueIds.includes(element.name);

					if (!isDuplicate) {
                        uniqueIds.push(element.name);
                        
						return true;
                    }
            return false;
        });
        Object.keys(unique).forEach((key) => {
            _totalSize += unique[key].size || 0;
        });

        setTotalSize(_totalSize);
    };
    const headerTemplate = (options) => {
        const {className,chooseButton,uploadButton}=options;
       const value = totalSize / 200000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 20 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    }

    const onTemplateRemove=(file,callback) => {
        setTotalSize(totalSize - file.file.size);
    };

    return (
        <>
            <Divider align="left">
                <div className="inline-flex align-items-center">
                    <i className="pi pi-database mr-2"></i>
                    <b>Template</b>
                </div>
            </Divider>
            <div className="grid pt-4 p-fluid">
                <div className="col field">
                    <Dropdown placeholder={t('email.template')}
                              options={emails}
                              optionLabel={'name'}
                              value={selectedTemplate}
                              disabled={isLoadingEmails}
                              dropdownIcon={isLoadingEmails ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'}
                              onChange={(e) => {
                                setSelectedTemplate(e.value)
                                setValue('subject', e.value.subject);
                                setValue('body', e.value.body);
                                setFocus('subject');
                              }
                              }/>
                </div>
            </div>
            <Divider align="left">
                <div className="inline-flex align-items-center">
                    <i className="pi pi-envelope mr-2"></i>
                    <b>Email</b>
                </div>
            </Divider>
            <form className="grid pt-4 p-fluid" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12 field pt-2">
                <TextControl control={control} fieldName={'subject'} autoFocus={true} label={t('invoice.sendToClient.subject')} />
            </div>
            <div className="col-12 field">
                <ChipsControl control={control} fieldName={'to'} label={t('invoice.sendToClient.to')} />
            </div>
            <div className="col-12 field">
                <ChipsControl control={control} fieldName={'cc'} label={t('invoice.sendToClient.cc')} />
            </div>
            <div className="col-12 field">
                <ChipsControl control={control} fieldName={'bcc'} label={t('invoice.sendToClient.bcc')} />
            </div>
                <div className="field col-12">
                    <PlaceholderInvoice/>
                </div>
            <div className="col-12 field">
                <EditorControl control={control} fieldName={'body'} label={t('invoice.sendToClient.subject')} />
                {/*<TextAreaControl control={control} fieldName={'body'} rows={2} autoResize={true} label={t('invoice.sendToClient.subject')} />*/}
            </div>
            <Divider align="left">
                    <div className="inline-flex align-items-center" onClick={() => {
                        setUseAttachments(!useAttachments)
                    }}>
                    <i className="pi pi-envelope mr-2"></i>
                    <b>Attachments</b>
                    <Button type={'button'} className=" ml-4 p-button-outlined" >
                           {useAttachments ? t('common.close') : t('common.open')}
                    </Button>
                </div>
            </Divider>
            {useAttachments &&  <FileUpload
                name="logo"
                ref={fileUploadRef}
                className="p-0 col-12 mb-3"
                emptyTemplate={emptyTemplate}
                accept=".png, .jpeg, .pdf, .dwg, .docx, .xlsx"
                chooseOptions={chooseOptions}
                uploadOptions={uploadOptions}
                onRemove={onTemplateRemove}
                customUpload
                headerTemplate={headerTemplate}
                onSelect={onTemplateSelect}
                maxFileSize={20000000}
                multiple
            />}
            <div className="col-12">
                <Button type={"submit"} loading={loading} label={t('common.send')} />
            </div>
        </form>
        </>
    )
}
