import {useTranslation} from "react-i18next";
import {Controller} from "react-hook-form";
import {classNames} from "primereact/utils";
import {FormErrorMessage} from "../../core/FormErrorMessage";
import {InputTextarea} from "primereact/inputtextarea";

export function NoteInput({fieldName, control, autoFocus}) {

    const {t} = useTranslation();

    return (
        <>
            <Controller name={fieldName} control={control}
                        render={({field, fieldState}) => (
                            <>
                                <InputTextarea
                                    {...field}
                                    autoFocus={autoFocus}
                                    rows={1}
                                    autoResize
                                    className={classNames('w-full text-sm m-1', {'p-invalid': fieldState.error}, 'w-full hover:surface-hover background-color-none border-color-none')}
                                    placeholder={t('common.note')}
                                    onBlur={(e) => {
                                        field.onBlur();
                                    }}
                                />

                                {fieldState.error && <><div className={"break"}></div><div className="ml-auto"><FormErrorMessage error={fieldState.error} /></div></>}
                            </>
                        )}/>
        </>
    )
}
