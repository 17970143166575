import {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {LineItemTypes} from "../constants";

export function useLineDnd(lineItem, index, parent) {

    //const quoteModel = useQuoteContext();

    const ref = useRef(null);

    const [{isDragging}, drag, preview] = useDrag(() => ({
        type: LineItemTypes.MATERIAL,
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        item: () => {
            return {id: lineItem.id, index, parentId: parent.id};
        }
    }), [lineItem, index, parent.id]);

    const [{handlerId}, drop] = useDrop({
        accept: [LineItemTypes.SECTION, LineItemTypes.MATERIAL, LineItemTypes.POST],
        //canDrop: item => post.lines.map(x => x.id).indexOf(item.id) !== -1,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }

            if (item.parentId !== parent.id) {
                return;
            }

            const dragIndex = item.index
            const hoverIndex = index;
            //console.log(dragIndex, hoverIndex);
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            //This check is needed, as the indexes sometimes get out of sync right after swapping 2 items
            if (lineItem.id === item.id) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            //move(dragIndex, hoverIndex)

            parent.moveLine(lineItem, monitor.getItem(), dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    }, [lineItem, index, parent]);

    preview(drop(ref))


    return {
        preview,
        drag,
        drop,
        ref,
        isDragging,
        handlerId
    };
}
