import api from '../../../services/api';

class InvoiceTemplateService {
	all() {
		return api.get('api/invoice-templates').then(function (x) {
			return x.data;
		});
	}

	delete(id) {
		return api.delete('api/invoice-templates/' + id);
	}

	get(id) {
		return api.get('api/invoice-templates/' + id).then((x) => x.data);
	}

	create(data) {
		return api.post('api/invoice-templates', JSON.stringify(data)).then((x) => x.data);
	}

	update(id, data) {
		return api.post('api/invoice-templates/' + id, JSON.stringify(data)).then((x) => x.data);
	}
}

export default new InvoiceTemplateService();
