import organisationsService from "./organisations.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export function useOrganisations() {
    const {data: organisations, isFetched, isLoading, isError} = useQuery(['organisations'], organisationsService.all, {
        initialData: []
    });

    return {
        organisations: organisations?.map(item => {
            return {
                ...item,
                deletedAt: item.deletedAt ?? 'NA'
            }
        }),
        isLoading,
        isFetched,
        isError
    };
}

export function useOrganisation(id, options = null) {
    const {data: organisation, isLoading, isError, isFetching} = useQuery(['organisations', id], ({queryKey}) => organisationsService.get(queryKey[1]), options);

    return {
        organisation, isLoading, isError, isFetching
    };
}

export function useCurrentOrganisation(options = null) {
    const {data: organisation, isLoading, isError, isFetching} = useQuery(['organisations', 'current'], ({queryKey}) => organisationsService.getCurrent(), {
        refetchInterval: 5 * 60 * 1000,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        ...options
    });

    return {
        organisation, isLoading, isError, isFetching
    };
}

export function useUpdateOrganisationMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => organisationsService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['organisations']);
        }
    });

    return mutation;
}


export function useCreateOrganisationMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(organisationsService.create, {
        onSuccess: (data) => {
            const organisations = queryClient.getQueryData(['organisations']) ?? [];
            queryClient.setQueryData(['organisations'], [...organisations, data]);
        }
    });

    return mutation;
}

export function useDeleteOrganisationMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(organisationsService.delete, {
        onSuccess: (data, variables) => {
            const organisations = queryClient.getQueryData(['organisations']) ?? [];
            queryClient.setQueryData(['organisations'], organisations.filter(x => x.id !== variables))
        }
    });

    return mutation;
}

export function useOrganisationGoogleDriveConfiguration(id, options = null) {
        const {data: googleDriveConfiguration, isLoading, isError} = useQuery(['organisations-google-drive', id], ({queryKey}) => organisationsService.getGoogleDriveConfiguration(queryKey[1]), options);

        return {
            googleDriveConfiguration, isLoading, isError
        };
}

export function useUpdateOrganisationGoogleDriveConfigurationMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => organisationsService.setGoogleDriveConfiguration(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['organisations-google-drive']);
        }
    });

    return mutation;
}

export function useOrganisationLogoBase64() {
    const {data, isLoading, isError} = useQuery(['organisation-logo'], organisationsService.getLogoBase64, {
        initialData: null
    });

    return {
        base64: data,
        isLoading,
        isError
    };
}
