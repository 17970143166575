import {QuoteComment} from "./quote-comment";
import {Divider} from "primereact/divider";
import {AddQuoteCommentForm} from "./quote-comment.form";
import {useTranslation} from "react-i18next";


export function QuoteComments({quote}) {

    const {t} = useTranslation();

    return (
        <>
            {quote.comments.length === 0 && (<>
                <p className={"text-gray-500"}>{t('common.noComments')}</p>
                <Divider />
            </>)}
            {quote.comments.length > 0 && quote.comments.map(comment => (<QuoteComment key={comment.id} comment={comment} />))}
            <AddQuoteCommentForm quote={quote} />
        </>
    );

}
