import Decimal from "decimal.js";
import {action, computed, makeObservable, observable} from "mobx";
import {v4 as uuidv4} from "uuid";

export class MaterialLine {
    id;
    description = "";
    type = 'material';
    unit = "";
    amount = new Decimal("0");
    pricePerUnit = new Decimal("0");
    vat = new Decimal("0");
    shouldFocus = false;
    note = null;
    extraCharge = false;
    credit = false;
    variant = false;

    static create() {
        return new MaterialLine(uuidv4(), "", "m²", new Decimal(1), new Decimal(1), new Decimal(21), true, null, false, false);
    }

    static fromPostLine(postLine) {
        return new MaterialLine(
            uuidv4(),
            postLine.material.name,
            postLine.material.unit.name,
            new Decimal(postLine.amount),
            new Decimal(postLine.material.price),
            new Decimal(postLine.material.tva).times(100),
            false,
            postLine.material.note ? postLine.material.note : null,
            postLine.option ? postLine.option : false,
            postLine.discount ? postLine.discount : false,
            postLine.variant ? postLine.variant : false,
        )
    }

    constructor(id, description, unit, amount, pricePerUnit, vat, shouldFocus = false, note = null, extraCharge = false, credit = false, variant = false) {
        this.id = id;
        this.description = description
        this.unit = unit
        this.amount = amount
        this.pricePerUnit = pricePerUnit
        this.vat = vat
        this.shouldFocus = shouldFocus
        this.note = note;
        this.extraCharge = extraCharge;
        this.credit = credit;
        this.variant = variant;

        makeObservable(this, {
            description: observable,
            unit: observable,
            amount: observable,
            pricePerUnit: observable,
            vat: observable,
            total: computed,
            subTotal: computed,
            vatTotal: computed,
            changeSubTotal: action,
            updateFromForm: action,
            valid: computed,
            updateFromMaterial: action,
            updateDescription: action,
            updatePricePerUnit: action,
            updateAmount: action,
            updateSubTotal: action,
            updateUnit: action,
            updateVat: action,
            note: observable,
            updateNote: action,
            extraCharge: observable,
            updateExtraCharge: action,
            extraChargeSubTotal: computed,
            extraChargeTotal: computed,
            credit: observable,
            updateCredit: action,
            creditSubTotal: computed,
            creditTotal: computed,
            variant: observable,
            variantTotal: computed,
            variantSubTotal: computed,
            updateVariant: action,
        })
    }

    get total() {
        return this.calculateSubTotal().add(this.calculateVatTotal());
    }

    calculateSubTotal() {
        const subTotal = this.amount.times(this.pricePerUnit);

        // if(this.credit) {
        //     return subTotal.times(-1);
        // }

        return subTotal;
    }

    calculateVatTotal() {
        return this.calculateSubTotal().times(this.vat).dividedBy(100)
    }

    get subTotal() {
        return this.calculateSubTotal()
    }

    get vatTotal() {
        return this.calculateVatTotal()
    }

    get extraChargeSubTotal() {
        return this.extraCharge ? this.calculateSubTotal() : new Decimal(0);
    }

    get extraChargeTotal() {
        return this.extraCharge ? this.total : new Decimal(0);
    }

    get creditTotal() {
        return this.credit ? this.total.times(-1) : new Decimal(0);
    }

    get creditSubTotal() {
        return this.credit ? this.subTotal.times(-1) : new Decimal(0);
    }

    get variantTotal() {
        return this.variant ? this.total : new Decimal(0);
    }

    get variantSubTotal() {
        return this.variant ? this.subTotal : new Decimal(0);
    }

    updateDescription(description) {
        this.description = description
    }

    updateSubTotal(newSubTotal) {
        const subTotal = new Decimal(newSubTotal);

        if (!this.subTotal.equals(subTotal)) {
            this.changeSubTotal(subTotal);
        }
    }

    updateUnit(unit) {
        this.unit = unit;
    }

    updateAmount(value) {
        const amount = new Decimal(value);

        if (!this.amount.equals(amount)) {
            this.amount = amount;
        }
    }

    updatePricePerUnit(value) {
        const pricePerUnit = new Decimal(value);

        if (!this.pricePerUnit.equals(pricePerUnit)) {
            this.pricePerUnit = pricePerUnit;
        }
    }

    updateVat(value) {
        const vat = new Decimal(value);
        if (!this.vat.equals(vat)) {
            this.vat = vat;
        }
    }

    updateNote(value) {
        this.note = value;
    }

    updateExtraCharge(value) {
        this.extraCharge = value;
    }

    updateCredit(value) {
        this.credit = value;
    }

    updateVariant(value) {
        this.variant = value;
    }

    updateFromForm(data) {

        const subTotal = new Decimal(data.subTotal);

        if (!this.subTotal.equals(subTotal)) {
            this.changeSubTotal(subTotal);
            return;
        }

        if (this.description !== data.description) {
            this.description = data.description
        }

        if (this.unit !== data.unit) {
            this.unit = data.unit;
        }

        const amount = new Decimal(data.amount);
        if (!this.amount.equals(amount)) {
            this.amount = amount;
        }

        const pricePerUnit = new Decimal(data.pricePerUnit);

        if (!this.pricePerUnit.equals(pricePerUnit)) {
            this.pricePerUnit = pricePerUnit;
        }

        const vat = new Decimal(data.vat);
        if (!this.vat.equals(vat)) {
            this.vat = vat;
        }

        if (this.note !== data.note) {
            this.note = data.note;
        }
    }

    changeSubTotal(newSubTotal) {
        if (!this.variant) {
            const currentTotal = this.subTotal

            const priceShare = newSubTotal.dividedBy(currentTotal)

            const newPricePerUnit = this.pricePerUnit.times(priceShare)

            this.pricePerUnit = newPricePerUnit
        }
    }

    get valid() {
        return true
    }

    updateFromMaterial(material) {

        this.description = material.name;
        this.unit = material.unit.name;
        this.vat = new Decimal(material.tva).times(100);
        this.pricePerUnit = new Decimal(material.price);
        this.amount = new Decimal(1);
        this.note = material.note || null;

        // if(parent instanceof PostLine) {
        //     this.amount = new Decimal(1).times(parent.amount);
        // } else {
        //
        // }
    }

    get dto() {
        return {
            id: this.id,
            description: this.description,
            type: this.type,
            unit: this.unit,
            amount: this.amount.toFixed(2),
            pricePerUnit: this.pricePerUnit.toFixed(2),
            vat: this.vat.toFixed(2),
            subTotal: this.subTotal.toFixed(2),
            vatTotal: this.vatTotal.toFixed(2),
            total: this.total.toFixed(2),
            note: this.note,
            extraCharge: this.extraCharge,
            extraChargeSubTotal: this.extraChargeSubTotal.toFixed(2),
            extraChargeTotal: this.extraChargeTotal.toFixed(2),
            credit: this.credit,
            creditSubTotal: this.creditSubTotal.toFixed(2),
            creditTotal: this.creditTotal.toFixed(2),
            variant: this.variant,
            variantSubTotal: this.variantSubTotal.toFixed(2),
            variantTotal: this.variantTotal.toFixed(2),
        }
    }

}
