import {Card} from "primereact/card";
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {useState} from "react";
import {InputText} from "primereact/inputtext";
import {CustomLink} from "../core/CustomLink";
import {Controller, useForm} from "react-hook-form";
import {classNames} from "primereact/utils";
import authService from "../services/auth.service";
import {Dialog} from "primereact/dialog";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function ForgotPasswordPage() {

    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const defaultValues = {
        email: ''
    };

    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });

    const {t} = useTranslation();

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            await authService.forgotPassword(data.email);
            setMessage(t('auth.resetPasswordEmailSent'));

            setTimeout(() => {
                navigate("/login");
            }, 2000);
        }
        catch(err) {
            setMessage(t('auth.resetPasswordFailed'));
        }
        finally {
            setLoading(false);
            setShowDialog(true);
        }
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <div className="h-screen flex" style={{background: '#DF8009'}}>
            <Dialog header="Reset password" visible={showDialog} style={{ width: '25vw' }} onHide={() => setShowDialog(false)}>
                <p>{message}</p>
            </Dialog>

            <Card title={t('auth.resetPassword')} className="px-6 shadow-3 col-4 col-offset-4 my-auto">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                    <div className="field mt-5">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller name="email" control={control}
                                            rules={{ required: t('auth.emailRequired'), pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: t('auth.emailInvalid')}}}
                                            render={({ field, fieldState }) => (
                                                <InputText autoFocus id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>Email</label>
                            </span>
                        {getFormErrorMessage('email')}
                    </div>
                    <Button type="submit" label={t('auth.sendMeALink')} loading={loading} />
                </form>
                <Divider />
                <div className="text-right">
                    <CustomLink to="/login" label={t('auth.backToLogin')} />
                </div>
            </Card>
        </div>
    );
}