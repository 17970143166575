import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {useForm} from "react-hook-form";
import {useToast} from "../core/toast.context";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";
import {TopMenu} from "../core/TopMenu";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {DropdownControl} from "../core/ControlledInputs/DropDownControl";
import {TextAreaControl} from "../core/ControlledInputs/TextAreaControl";
import {useAllowedRoles} from "../auth/roles.hooks";
import {useUpdateUserMutation, useUser} from "./users.hooks";
import {userScheme} from "./user.scheme";
import {useAuth} from "../auth/auth.context";
import {OrganisationSelector} from "../organisations/organisation.selector";
import {HasRole} from "../auth/has-role";
import {useOrganisationContext} from "../organisations/organisation.context";
import { CalenderControl } from "../core/ControlledInputs/CalendarControl";
import moment from "moment";
import Loader from "../core/Loader";

export function EditUserPage() {

    const scheme = userScheme();
    const {control, handleSubmit, formState: {isDirty}, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const params = useParams();

    const {t} = useTranslation();

    const auth = useAuth();
    const {selectedOrganisationId} = useOrganisationContext();
    const {user, isLoading, isFetching} = useUser(params.id, {
        onSuccess: (data) => {
            if (!isDirty) {
                reset({
                    name: data.name,
                    email: data.email,
                    role: data.role,
                    note: data.note ?? '',
                    deletedAt: data.deletedAt ?? '',
                });
            }
        }
    }, selectedOrganisationId);

    const updateMutation = useUpdateUserMutation();

    const navigate = useNavigate();
    let toast = useToast();

    const roles = useAllowedRoles();

    const onSubmit = async (data) => {

        try {
            const dto = {
                ...data,
                deletedAt: data.deletedAt ? moment(data.deletedAt).set({"hour": 23, "minute": 59}).zone("+00:00").format() : null
            };

            await updateMutation.mutateAsync({
                id: user.id,
                dto: dto,
                organisationId: selectedOrganisationId
            });

            reset(data);

            toast.current.show({
                severity: 'success',
                summary: t('user.updated.title'),
                detail: t('user.updated.text'),
                life: 3000
            });
        } catch (err) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: t('user.updated.problem') + " " + err,
                life: 3000
            });
        }
    };

    return (
        <div>
            <TopMenu moduleName={t('user.title')} list={'/users'} create={'/users/create/'}/>

            <HasRole role={'ROLE_SUPER_ADMIN'}>
                <OrganisationSelector />
            </HasRole>

            <Card title={t('user.edit')} className="shadow-3 mt-3">

                {isFetching || isLoading ? <Loader/> :

                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                        <div className="field col-12">
                            <TextControl control={control} fieldName={'name'} label={t('common.name')}/>
                        </div>
                        <div className="field col-12">
                            <TextControl control={control} fieldName={'email'} label={t('common.email')} disabled/>
                        </div>

                        <div className="field col-6">
                            <DropdownControl control={control} fieldName={'role'} label={t('common.role')}
                                             optionValue={'value'} optionLabel={'name'} options={roles}
                                             disabled={auth.user.email === user?.email}/>
                        </div>

                        <div className="field col-6">
                            <CalenderControl disabled={auth?.user?.email === user?.email} control={control}
                                             fieldName={'deletedAt'} label={t('common.deletedAt')}/>
                        </div>

                        <div className="field col-12">
                            <TextAreaControl control={control} fieldName={'note'} label={t('common.note')} maxLength={1000} />
                        </div>

                        <Button type="submit" label={t('common.edit')}
                                loading={updateMutation.isLoading}/>
                    </form>
                }
            </Card>
        </div>
    )
}