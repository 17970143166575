import {Controller} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import {FormErrorMessage} from "../FormErrorMessage";


export function DropdownControl({fieldName, control, label, optionLabel, optionValue, options, loading=false, filter = false, disabled=false}) {

    return (
        <Controller name={fieldName} control={control} render={({field, fieldState}) => (
            <>
                <span className="p-float-label">
                    <Dropdown id={field.name}
                              disabled={disabled || loading}
                              dropdownIcon={loading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'}
                              className={classNames({'p-invalid': fieldState.error})}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              options={options}
                              optionLabel={optionLabel}
                              optionValue={optionValue}
                              filter={filter}
                              showFilterClear
                              showClear
                    />
                    <label htmlFor={field.name} className={classNames({'p-error': fieldState.error})}>{label}</label>
                </span>
                <FormErrorMessage error={fieldState.error}/>
            </>
        )}/>
    );
}