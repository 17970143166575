import {Route, Routes} from "react-router-dom";
import {CreateUnitPage} from "./units-create.page";
import {EditUnitPage} from "./units-edit.page";
import {UnitList} from "./unit-list";

export function Units() {
    return <Routes>

        <Route path=":id" element={<EditUnitPage />} />
        <Route path="create" element={<CreateUnitPage />} />
        <Route path="*" element={<UnitList />} />

    </Routes>

}