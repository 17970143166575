import React, {createContext, useContext} from "react";


const toastContext = createContext(null);

export function ProvideToast({ toastRef, children }) {
    return <toastContext.Provider value={toastRef}>{children}</toastContext.Provider>;
}

export const useToast = () => {
    return useContext(toastContext);
};