import {classNames} from "primereact/utils";
import {useMemo, useRef} from "react";
import {Button} from "primereact/button";
import {useClientStatusMutation} from "../clients/clients.hooks";
import {useToast} from "./toast.context";
import {useTranslation} from "react-i18next";
import {Menu} from "primereact/menu";
import Moment from "moment";
import {useClientStatus} from "../clients/client.status.hooks";

export function ClientStatus({id, status, sendAt}) {

    const toast = useToast();
    const {t} = useTranslation();

    const clientStatus = useClientStatus(status);


    const color = classNames({
        'bg-blue-600': status === 1,
        'bg-yellow-500': status === 2,
        'bg-orange-500': status === 3,
        'bg-purple-500': status === 4
    });

    const editMutation = useClientStatusMutation();

    const menu = useRef(null);

    const menuItems = useMemo(() => {
        const setStatus = async (status, id) => {
            menu.current.toggle(false);
            try {

                await editMutation.mutateAsync({
                    id: id,
                    data: {
                        status: status,
                        id: id
                    },
                });

                toast.current.show({
                    severity: 'success',
                    summary: t('status.change.title'),
                    detail: t('status.change.text'),
                    life: 3000
                });

            } catch (err) {
                toast.current.show({
                    severity: 'error',
                    summary: t('common.oops'),
                    detail: t('status.change.problem') + " " + err,
                    life: 3000
                });
            }
        };
    }, [editMutation, id, status, t, toast]);

    return (
        <div style={{textAlign:"center"}} >
            <Menu model={menuItems} popup ref={menu} id="popup_menu_quote_status" />
            <Button className={color}
                    style={{border: "0px", height: 25}}
                    type="button"
                    label={sendAt && (status === 2 || status === 3) ? <i className="pi pi-send">&nbsp;{Moment(sendAt).format('DD-MM-YY')}</i> : clientStatus.name} onClick={(event) => menuItems.length === 0 || menu.current.toggle(event)}/>
        </div>
    );
}