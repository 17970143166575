import {action, computed, makeObservable, observable} from "mobx";
import Decimal from "decimal.js";
import {MaterialLine} from "./MaterialLine";
import {PostLine} from "./PostLine";
import {SectionLine} from "./SectionLine";
import { MultipleMaterialLine } from "./MultipleMaterialLine";

function mapDtoLinesToModel(dtoLines) {
    const lines = [];
    for (let i = 0; i < dtoLines.length; i++) {
        const dto = dtoLines[i];

        if(dto.type === 'material') {
            const material = new MaterialLine(dto.id, dto.description, dto.unit, new Decimal(dto.amount), new Decimal(dto.pricePerUnit), new Decimal(dto.vat), false, dto.note || null, !!dto.option, !!dto.discount, !!dto.variant);
            lines.push(material);
        } else if(dto.type === 'post') {
            const postLines = mapDtoLinesToModel(dto.lines);
            const post = new PostLine(dto.id, dto.description, dto.unit, new Decimal(dto.amount), postLines, false, dto.note || null, dto.coefficient || 1);
            lines.push(post);
        } else if(dto.type === 'section') {
            const sectionLines = mapDtoLinesToModel(dto.lines);
            const section = new SectionLine(dto.id, dto.description, sectionLines, false, dto.note || null, dto.coefficient || 1);
            lines.push(section)
        }
    }

    return lines;
}

export class Quote
{
    id;
    lines = []
    client;
    number;
    createdAt;
    validUntil;
    sentToClient;
    status;

    comments = [];

    totalExclVAT6 = new Decimal("0");
    totalExclVAT21 = new Decimal("0");
    totalVAT6 = new Decimal("0");
    totalVAT21 = new Decimal("0");

    static fromDto(id, dtoLines, client, number, createdAt, validUntil, sentToClient, status) {
        const lines = mapDtoLinesToModel(dtoLines);

        return new Quote(id, lines, client, number, createdAt, validUntil, sentToClient, status);
    }

    constructor(id, lines, client, number, createdAt, validUntil, sentToClient, status) {
        this.id = id;
        this.lines = lines;
        this.client = client;
        this.number = number;
        this.createdAt = createdAt;
        this.validUntil = validUntil;
        this.sentToClient = sentToClient;
        this.status = status;

        makeObservable(this, {
            lines: observable,
            client: observable,
            total: computed,
            subTotal: computed,
            vatTotal: computed,
            changeSubTotal: action,
            moveLine: action,
            pasteLine: action,
            removeLine: action,
            addMaterial: action,
            addPost: action,
            addSection: action,
            valid: computed,
            updateSubTotal: action,
            setClient: action,
            optionSubTotal: computed,
            optionTotal: computed,
            discountSubTotal: computed,
            discountTotal: computed,
            variantSubTotal: computed,
            variantTotal: computed,
            subTotalAfterDiscountWithOptions: computed,
            totalAfterDiscountWithOptions: computed,
            subTotalAfterDiscountWithoutOptions: computed,
            totalAfterDiscountWithoutOptions: computed,
            addMultipleMaterial: action,
            removeMultipleMaterialLine: action,

            comments: observable
        })
    }

    get subTotal() {
        return this.lines
            .filter(line => line.type !== 'multipleMaterial')
            .reduce((prev, current) => prev.add(current.subTotal), new Decimal(0))
            .minus(this.variantSubTotal)
            .minus(this.optionSubTotal)
            .minus(2*this.discountSubTotal) // Remove it twice to actually make a discount, because the discount amount has been added as a price to count
    }

    get total() {
        this.populateMultipleVAT();

        return this.lines
            .filter(line => line.type !== 'multipleMaterial')
            .reduce((prev, current) => prev.add(current.total), new Decimal(0))
            .minus(this.variantTotal)
            .minus(this.optionTotal)
            .minus(2*this.discountTotal) // Remove it twice to actually make a discount, because the discount amount has been added as a price to count
    }

    populateMultipleVAT() {
        this.totalExclVAT6 = new Decimal("0");
        this.totalExclVAT21 = new Decimal("0");
        this.totalVAT6 = new Decimal("0");
        this.totalVAT21 = new Decimal("0");

        this.lines.filter(line => line.type !== 'multipleMaterial').forEach(line => {
            if (line.type !== 'material') {
                line.populateMultipleVAT();
                this.totalExclVAT6 = this.totalExclVAT6.add(line.totalExclVAT6, new Decimal(0));
                this.totalVAT6 = this.totalVAT6.add(line.totalVAT6, new Decimal(0));
                this.totalExclVAT21 = this.totalExclVAT21.add(line.totalExclVAT21, new Decimal(0));
                this.totalVAT21 = this.totalVAT21.add(line.totalVAT21, new Decimal(0));
            } else {
                if (line.vat.d[0] === 6 ) {
                    if (!line.variant && !line.option && !line.discount) {
                        this.totalExclVAT6 = this.totalExclVAT6.add(line.subTotal, new Decimal(0));
                        this.totalVAT6 = this.totalVAT6.add(line.total, new Decimal(0));
                    } else if (line.discount) {
                        this.totalExclVAT6 = this.totalExclVAT6.minus(line.subTotal, new Decimal(0));
                        this.totalVAT6 = this.totalVAT6.minus(line.total, new Decimal(0));
                    }
                } else if (line.vat.d[0] === 21) {
                    if (!line.variant && !line.option && !line.discount) {
                        this.totalExclVAT21 = this.totalExclVAT21.add(line.subTotal, new Decimal(0));
                        this.totalVAT21 = this.totalVAT21.add(line.total, new Decimal(0));
                    } else if (line.discount) {
                        this.totalExclVAT21 = this.totalExclVAT21.minus(line.subTotal, new Decimal(0));
                        this.totalVAT21 = this.totalVAT21.minus(line.total, new Decimal(0));
                    }
                }
            }
        });
    }

    get vatTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.vatTotal), new Decimal(0))
    }

    get optionSubTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.optionSubTotal), new Decimal(0))
    }

    get optionTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.optionTotal), new Decimal(0))
    }

    get discountSubTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.discountSubTotal), new Decimal(0))
    }

    get discountTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.discountTotal), new Decimal(0))
    }

    get variantSubTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.variantSubTotal), new Decimal(0))
    }

    get variantTotal() {
        return this.lines.filter(line => line.type !== 'multipleMaterial').reduce((prev, current) => prev.add(current.variantTotal), new Decimal(0))
    }

    changeSubTotal(newSubTotal) {
        if(this.subTotal.equals(newSubTotal)) {
            return;
        }
        // console.log("Changing quote subtotal to "+newSubTotal.toFixed(2));
        const priceShare = newSubTotal.dividedBy(this.subTotal)

        this.lines.forEach(x => {
            x.changeSubTotal(x.subTotal.times(priceShare))
        })
    }

    updateSubTotal(value) {
        const subTotal = new Decimal(value);
        this.changeSubTotal(subTotal);
    }

    moveLine(lineItem, dragItem, dragIndex, hoverIndex) {
        // dragIndex & hoverIndex doesn't match the real actual position index
        const draggedItemIndex = this.lines.findIndex(line => line.id === dragItem.id);
        const hoverItemIndex = this.lines.findIndex(line => line.id === lineItem.id);

        this.lines.splice(hoverItemIndex, 0, this.lines.splice(draggedItemIndex, 1)[0]);
    }

    pasteLine(line, addAfterLine) {
        const createMaterialLine = (inputLine) => {
            const materialLine = MaterialLine.create();
            materialLine.description = inputLine.description;
            materialLine.unit = inputLine.unit;
            materialLine.amount = new Decimal(inputLine.amount);
            materialLine.pricePerUnit = new Decimal(inputLine.pricePerUnit);
            materialLine.vat = new Decimal(inputLine.vat);
            materialLine.note = inputLine.note;
            materialLine.option = inputLine.option;
            materialLine.discount = inputLine.discount;
            return materialLine;
        };
    
        const createPostLine = (inputLine) => {
            const postLine = PostLine.create();
            postLine.description = inputLine.description;
            postLine.unit = inputLine.unit;
            postLine.amount = new Decimal(inputLine.amount);
            postLine.note = inputLine.note;
            const postLineLines = inputLine.lines.map(createMaterialLine);
            postLine.lines = postLineLines;
            return postLine;
        };
    
        const createSectionLine = (inputLine) => {
            const sectionLine = SectionLine.create();
            sectionLine.description = inputLine.description;
            sectionLine.note = inputLine.note;
            const sectionLineLines = inputLine.lines.map((l) => {
                if (l.type === 'material') {
                    return createMaterialLine(l);
                } else if (l.type === 'post') {
                    return createPostLine(l);
                } else {
                    throw Error('Type not implemented');
                }
            });
            sectionLine.lines = sectionLineLines;
            return sectionLine;
        };
    
        let lineToInsert = null;
    
        if (line.type === 'material') {
            lineToInsert = createMaterialLine(line);
        } else if (line.type === 'post') {
            lineToInsert = createPostLine(line);
        } else if (line.type === 'section') {
            lineToInsert = createSectionLine(line);
        } else {
            throw Error('Type not implemented');
        }
    
        const indexToAddAfter = this.lines.findIndex((line) => line.id === addAfterLine.id);
        this.lines.splice(indexToAddAfter + 1, 0, lineToInsert);
    
        if (indexToAddAfter < this.lines.length - 1) {
            const itemsToMove = this.lines.splice(indexToAddAfter + 2);
            this.lines.push(...itemsToMove);
        }
    }
    

    removeLine(lineItem) {
        const index = this.lines.indexOf(lineItem);
        if(index !== -1) {
            this.lines.splice(index, 1)
        }
    }

    addMaterial(description) {
        const mat = MaterialLine.create(description)
        this.lines.push(mat)
        return mat
    }

    addMultipleMaterial() {
        this.lines.push(MultipleMaterialLine.create(this));
    }

    removeMultipleMaterialLine(multipleMaterialLine) {
        const lineFound = this.lines.findIndex(line => line.id === multipleMaterialLine.id);
        this.lines.splice(lineFound, 1);
    }

    addPost() {
        this.lines.push(PostLine.create())
    }

    addSection() {
        this.lines.push(SectionLine.create());
    }

    get valid() {

        if(!this.client) {
            return false;
        }

        for (let i = 0; i < this.lines.length; i++) {
            if(!this.lines[i].valid) {
                return false;
            }
        }

        return this.lines.length > 0;
    }

    setClient(client) {
        this.client = client;
    }

    get dto() {
        return {
            id: this.id,
            clientId: this.client?.id,
            subTotal: this.subTotal.toFixed(2),
            vatTotal: this.vatTotal.toFixed(2),
            total: this.total.toFixed(2),
            number: this.number,
            validUntil: this.validUntil,
            lines: this.lines.map(x => x.dto),
            status: this.status,
            optionSubTotal: this.optionSubTotal.toFixed(2),
            optionTotal: this.optionTotal.toFixed(2),
            discountSubTotal: this.discountSubTotal.toFixed(2),
            discountTotal: this.discountTotal.toFixed(2),
            variantSubTotal: this.variantSubTotal.toFixed(2),
            variantTotal: this.variantTotal.toFixed(2),
            subTotalAfterDiscountWithOptions: this.subTotalAfterDiscountWithOptions.toFixed(2),
            totalAfterDiscountWithOptions: this.totalAfterDiscountWithOptions.toFixed(2),
            subTotalAfterDiscountWithoutOptions: this.subTotalAfterDiscountWithoutOptions.toFixed(2),
            totalAfterDiscountWithoutOptions: this.totalAfterDiscountWithoutOptions.toFixed(2)
        };
    }


    get subTotalAfterDiscountWithOptions() {
        return this.subTotal.sub(this.discountSubTotal);
    }
    get totalAfterDiscountWithOptions() {
        return this.total.sub(this.discountTotal);
    }
    get subTotalAfterDiscountWithoutOptions() {
        return this.subTotal.sub(this.discountSubTotal).sub(this.optionSubTotal);
    }
    get totalAfterDiscountWithoutOptions() {
        return this.total.sub(this.discountTotal).sub(this.optionTotal);
    }
}
