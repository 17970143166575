import {Card} from "primereact/card";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {googleDriveConfigurationScheme, organisationScheme} from "./organisation.scheme";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {
    useCurrentOrganisation,
    useOrganisation, useOrganisationGoogleDriveConfiguration,
    useUpdateOrganisationGoogleDriveConfigurationMutation,
    useUpdateOrganisationMutation
} from "./organisations.hooks";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {OrganisationForm} from "./organisation.form";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {TextAreaControl} from "../core/ControlledInputs/TextAreaControl";
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";
import moment from "moment";


export function EditOrganisationsPage(){

    const {t} = useTranslation();

    return (
        <div>
            <TopMenu moduleName={t('organisation.title')} list={'/organisations/'} create={'/organisations/create/'}/>
            <EditOrganisation />
            <GoogleDriveConfiguration />
        </div>
    )
}

function EditOrganisation() {

    const params = useParams();

    const scheme = organisationScheme();
    const {control, handleSubmit, formState: {isDirty}, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const { organisation: userCurrentOrganisation ,isLoading , isFetching} = useCurrentOrganisation();

    const {t} = useTranslation();

    const {organisation} = useOrganisation(params.id, {
        onSuccess: (data) => {
            if(!isDirty) {
                reset({
                    name: data.name,
                    email: data.email,
                    address: data.address,
                    nb: data.nb,
                    postalCode: data.postalCode,
                    city: data.city,
                    country: data.country,
                    phone: data.phone,
                    tva: data.tva,
                    bank: data.bank,
                    note: data.note,
                    clientFormat: data.clientFormat,
                    quoteFormat: data.quoteFormat,
                    invoiceFormat: data.invoiceFormat,
                    deletedAt: data.deletedAt ?? ''
                });
            }
        }
    });

    const updateMutation = useUpdateOrganisationMutation();

    let toast = useToast();

    const onSubmit = async (data) => {

        try {
            const dto = {
                ...data,
                deletedAt: data.deletedAt ? moment(data.deletedAt).set({"hour": 23, "minute": 59}).zone("+00:00").format() : null
            };

            await updateMutation.mutateAsync({
                id: organisation.id,
                data: dto
            });

            reset(data);

            toast.current.show({severity: 'success', summary: t('organisation.updated.title'), detail: t('organisation.updated.text'), life: 3000});
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('organisation.updated.problem') + " " + err, life: 3000});
        }
    };

    return (
        <Card title={t('organisation.edit')} className="shadow-3 mt-3">

            <OrganisationForm onSubmit={handleSubmit(onSubmit)}
                              isLoading={updateMutation.isLoading}
                              control={control}
                              submitLabel={t('common.edit')} disableCalender={organisation?.id === userCurrentOrganisation?.id} />
        </Card>
    )
}

function GoogleDriveConfiguration() {

    const params = useParams();

    const scheme = googleDriveConfigurationScheme();
    const {control, handleSubmit, formState: {isDirty}, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const {t} = useTranslation();

    const {googleDriveConfiguration, isLoading} = useOrganisationGoogleDriveConfiguration(params.id, {
        onSuccess: (data) => {
            if(!isDirty) {
                reset({
                    invoiceDirectory: data.invoiceDirectory ?? t('organisation.googleDriveConfiguration.invoiceDirectoryDefault'),
                    quoteDirectory: data.quoteDirectory ?? t('organisation.googleDriveConfiguration.quoteDirectoryDefault'),
                    documentsDirectory: data.documentsDirectory ?? t('organisation.googleDriveConfiguration.documentsDirectoryDefault')
                });
            }
        }
    });

    const updateMutation = useUpdateOrganisationGoogleDriveConfigurationMutation();

    let toast = useToast();

    const onSubmit = async (data) => {

        if(!googleDriveConfiguration.active && !data.serviceAccount) {
            toast.current.show({severity: 'error', summary: t('organisation.googleDriveConfiguration.serviceAccountRequired.summary'), detail: t('organisation.googleDriveConfiguration.serviceAccountRequired.detail'), life: 3000});
            return;
        }

        try {
            const dto = {
                ...data
            };

            await updateMutation.mutateAsync({
                id: params.id,
                data: dto
            });

            reset(data);

            toast.current.show({severity: 'success', summary: t('organisation.googleDriveConfiguration.updated.title'), detail: t('organisation.googleDriveConfiguration.updated.text'), life: 3000});
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('organisation.googleDriveConfiguration.updated.problem') + " " + err, life: 3000});
        }
    };

    if(isLoading) {
        return (
            <Card title={t('organisation.googleDriveConfiguration.title')} className="shadow-3 mt-3">
                <ProgressSpinner />
            </Card>
        )
    }

    return (
        <Card title={t('organisation.googleDriveConfiguration.title')} className="shadow-3 mt-3">
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid grid-no-gutter">
                <div className="field col-12">
                    {!googleDriveConfiguration.active && (<p className={"text-red-500 mb-4"}><i  className={'pi pi-exclamation-triangle'}/> {t('organisation.googleDriveConfiguration.serviceAccountIsRequired')}</p>)}
                    {googleDriveConfiguration.active && (<p className={"text-primary mb-4"}><i className={'pi pi-check'}/> {t('organisation.googleDriveConfiguration.serviceAccountIsActive')}</p>)}
                    <TextAreaControl control={control} fieldName={'serviceAccount'} label={t('organisation.googleDriveConfiguration.serviceAccount')} />
                </div>

                <div className="field col-12">
                    <TextControl control={control} fieldName={'invoiceDirectory'} label={t('organisation.googleDriveConfiguration.invoiceDirectory')} />
                </div>

                <div className="field col-12">
                    <TextControl control={control} fieldName={'quoteDirectory'} label={t('organisation.googleDriveConfiguration.quoteDirectory')} />
                </div>

                <div className="field col-12">
                    <TextControl control={control} fieldName={'documentsDirectory'} label={t('organisation.googleDriveConfiguration.documentsDirectory')} />
                </div>

                <Button type="submit" label={t('common.edit')} loading={updateMutation.isLoading}/>
            </form>
        </Card>
    )
}