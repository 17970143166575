import invoicesService from "./invoices.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import quotesService from "../quotes/quotes.service";


export function useInvoices() {
    const {data: invoices, isFetched, isLoading, isError} = useQuery(['invoices'], invoicesService.all, {
        initialData: []
    });

    return {
        invoices,
        isLoading,
        isFetched,
        isError
    };
}

export function useInvoicesByClient(id) {
    const {data: invoices, isLoading, isError} = useQuery(['invoices', id], ({queryKey}) => invoicesService.getByClient(queryKey[1]));

    return {
        invoices, isLoading, isError
    };
}

export function useInvoice(id, options = null) {
    const {data: invoice, isLoading, isError} = useQuery(['invoices', id], ({queryKey}) => invoicesService.get(queryKey[1]), options);

    return {
        invoice, isLoading, isError
    };
}

export function useUpdateInvoiceMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => invoicesService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['invoices']);
        }
    });

    return mutation;
}


export function useCreateInvoiceMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(invoicesService.create, {
        onSuccess: (data) => {
            const invoices = queryClient.getQueryData(['invoices']) ?? [];
            queryClient.setQueryData(['invoices'], [...invoices, data]);
        }
    });

    return mutation;
}

export function useDeleteInvoiceMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(invoicesService.delete, {
        onSuccess: (data, variables) => {
            const invoices = queryClient.getQueryData(['invoices']) ?? [];
            queryClient.setQueryData(['invoices'], invoices.filter(x => x.id !== variables))
        }
    });

    return mutation;
}

export function useInvoiceTemplates() {
    const {data: invoiceTemplates, isLoading, isError} = useQuery(['invoice-templates'], invoicesService.allTemplates, {
        initialData: []
    });

    return {
        invoiceTemplates,
        isLoading,
        isError
    };
}

export function useCreateInvoiceTemplateMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(invoicesService.createTemplate, {
        onSuccess: (data) => {
            const invoices = queryClient.getQueryData(['invoice-templates']) ?? [];
            queryClient.setQueryData(['invoice-templates'], [...invoices, data]);
        }
    });

    return mutation;
}

export function useDeleteInvoiceTemplateMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(invoicesService.deleteTemplate, {
        onSuccess: (data, variables) => {
            const invoices = queryClient.getQueryData(['invoice-templates']) ?? [];
            queryClient.setQueryData(['invoice-templates'], invoices.filter(x => x.id !== variables))
        }
    });

    return mutation;
}

export function useInvoiceStatusMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => invoicesService.setStatus(params.id, params.data), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['invoices']);
        }
    });

    return mutation;
}

export function useInvoiceAddCommentMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => invoicesService.addComment(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['invoices', params.id]);
        }
    });

    return mutation;
}

export function useCreateInvoiceZipMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation((params) => invoicesService.createZip(params), {
        onSuccess: (data) => {
            const invoices = queryClient.getQueryData(['invoices']) ?? [];
            queryClient.setQueryData(['invoices'], [...invoices, data]);
        }
    });

    return mutation;
}
