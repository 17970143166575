import {Controller} from "react-hook-form";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {FormErrorMessage} from "../FormErrorMessage";

export function TextControl({fieldName, control, label, autoFocus=false, disabled=false, type, maxLength}) {
    const isLetter = (value) => /^[a-zA-ZÀ-ÿ -]*$/.test(value);

    return (
        <Controller name={fieldName} control={control}
          render={({field, fieldState, formState}) => {
              const handleChange = (e) => {
                const newValue = e.target.value;
      
                if (maxLength && newValue.length > maxLength) {
                  return;
                }
      
                if (type === 'text') {
                  isLetter(newValue) && field.onChange(newValue);
                } else {
                  field.onChange(newValue);
                }
              };

              return <>
                  <span className="p-float-label">
                      <InputText id={field.name}
                                  {...field}
                                  autoFocus={autoFocus}
                                  className={classNames({'p-invalid': !!fieldState.error})} disabled={disabled}
                                  type={type}

                                  onChange={handleChange}
                                  />
                      <label htmlFor={field.name}
                              className={classNames({'p-error': !!fieldState.error})}>{label}</label>
                  </span>
                  <FormErrorMessage error={fieldState.error}/>
              </>
          }}/>
    );
}
