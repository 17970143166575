import genderService from "./gender.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export function useGenders() {
    const {data: genders,isFetched, isLoading, isError} = useQuery(['genders'], genderService.all, {
        initialData: []
    });

    return {
        genders,
        isLoading,
        isFetched,
        isError
    };
}

export function useGender(id, options = null) {
    const {data: gender, isLoading, isError} = useQuery(['genders', id], ({queryKey}) => genderService.get(queryKey[1]), options);

    return {
        gender, isLoading, isError
    };
}

export function useUpdateGenderMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => genderService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['genders']);
        }
    });

    return mutation;
}


export function useCreateGenderMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(genderService.create, {
        onSuccess: (data) => {
            const genders = queryClient.getQueryData(['genders']) ?? [];
            queryClient.setQueryData(['genders'], [...genders, data]);
        }
    });

    return mutation;
}

export function useDeleteGenderMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(genderService.delete, {
        onSuccess: (data, variables) => {
            const genders = queryClient.getQueryData(['genders']) ?? [];
            queryClient.setQueryData(['genders'], genders.filter(x => x.id !== variables))
        }
    });

    return mutation;
}