import {classNames} from "primereact/utils";
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import {Toolbar} from "primereact/toolbar";
import {useAuth} from "../auth/auth.context";
import {useCurrentOrganisation} from "../organisations/organisations.hooks";
import {ScreenSize} from "./ScreenSize";

export function TopMenu({moduleName,menu,list,className,create}) {
    const [isUserExpiry,setIsUserExpiry]=useState(false)
    const [isOrgExpiry,setIsOrgExpiry]=useState(false)
    const { userDeletedAt, organisationDeletedAt } = useAuth();
    const [topValue, setTopValue] = useState('0px')
    useMemo(() => {
			if (userDeletedAt == 0 || !!userDeletedAt) {
				setIsUserExpiry(true);
			} else {
				setIsUserExpiry(false);
			}
			if (organisationDeletedAt == 0 || !!organisationDeletedAt) {
				setIsOrgExpiry(true);
			} else {
				setIsOrgExpiry(false);
			}
		}, [userDeletedAt, organisationDeletedAt]);

    const getTop = () => {
            if(isUserExpiry&&isOrgExpiry) {
                setTopValue( '101px' )
                return
            } else if(isUserExpiry||isOrgExpiry) {
                setTopValue( '50px' )
                return
            }
            setTopValue('0px')
    };
    
    useEffect( () => {
        const timer = setTimeout( getTop, 1000 )
        return () => clearTimeout(timer)
    })

	return (
		<div className={classNames('card', className)} style={{position: 'sticky', top: topValue, zIndex: '1'}}>
			<Toolbar left={<LeftMenu moduleName={moduleName} menu={menu} list={list} create={create} />} right={<RightMenu />} />
		</div>
	);
}

export function LeftMenu({moduleName, menu, list, create}){

    const navigate = useNavigate();
    return(
        <>
            <span className={'text-5xl'}>{moduleName}</span>
            <Divider layout="vertical"/>

            {list &&
                <Button label={t('common.list')} icon="pi pi-list" className="p-button-info mr-2" onClick={() => navigate(list)} />
            }

            {create &&
                <Button label={t('common.create')} icon="pi pi-plus" className="p-button mr-2" onClick={() => navigate(create)} />
            }

            {menu}
        </>
    );
}

export function RightMenu(){

    const auth = useAuth();

    const {organisation} = useCurrentOrganisation();

    return(
            <ScreenSize>

                <div style={{textAlign:"right"}}>
                    <span className={'text-5xl'}>{organisation?.name}</span>
                    <br/>
                    <span className={'text-1xl'}>{auth.user.name}</span>
                </div>
            </ScreenSize>
    );
}