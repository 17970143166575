import countryService from "./country.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export function useCountries() {
    const {data: countries,isFetched, isLoading, isError} = useQuery(['countries'], countryService.all, {
        initialData: [],
    });

    return {
        countries,
        isLoading,
        isFetched,
        isError
    };
}

export function useCountry(id, options = null) {
    const {data: country, isLoading, isError} = useQuery(['countries', id], ({queryKey}) => countryService.get(queryKey[1]), options);

    return {
        country, isLoading, isError
    };
}

export function useUpdateCountryMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => countryService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['countries']);
        }
    });

    return mutation;
}


export function useCreateCountryMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(countryService.create, {
        onSuccess: (data) => {
            const countries = queryClient.getQueryData(['countries']) ?? [];
            queryClient.setQueryData(['countries'], [...countries, data]);
        }
    });

    return mutation;
}

export function useDeleteCountryMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(countryService.delete, {
        onSuccess: (data, variables) => {
            const countries = queryClient.getQueryData(['countries']) ?? [];
            queryClient.setQueryData(['countries'], countries.filter(x => x.id !== variables))
        }
    });

    return mutation;
}