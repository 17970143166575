import {ProvideInvoice, useInvoiceContext} from "../invoice.context";
import {InvoiceForm} from "../components/invoice.form";
import {InvoiceInfoForm} from "../components/invoice-info.form";
import {Divider} from "primereact/divider";
import {Card} from "primereact/card";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Invoice} from "../models/Invoice";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    useCreateInvoiceTemplateMutation,
    useInvoice,
    useUpdateInvoiceMutation
} from "../invoice.hooks";
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {observer} from "mobx-react";
import {useToast} from "../../core/toast.context";
import {TopMenu} from "../../core/TopMenu";
import {useAuth} from "../../auth/auth.context";
import {Dialog} from "primereact/dialog";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {TextControl} from "../../core/ControlledInputs/TextControl";
import Moment from "moment";
import {InvoiceStatus} from "../../core/InvoiceStatus";
import {InvoiceComments} from "../components/invoice-comments";
import {TimelinePerso} from "../../core/Timeline";
import invoicesService from "../invoices.service";
import {ConfirmYesNo} from "../../core/ConfirmYesNo";
import {ColorLegend} from "../../core/color-legend";
import { Checkbox } from "primereact/checkbox";

export function InvoicesEditPage() {

    let params = useParams();

    const [invoice, setInvoice] = useState(null);
    const [isInvoiceCococontractor, setIsInvoiceCococontractor] = useState(false);

    const toast = useToast();

    const {pathname} = useLocation();
    const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener('beforeunload', alertUser);
		if (pathname == `/invoices/${params.id}`) {
			window.history.pushState(null, document.title, window.location.href);
			window.addEventListener('popstate', popFunction);
		}
		return () => {
			window.removeEventListener('beforeunload', alertUser);
			window.removeEventListener('popstate', popFunction);
		};
	}, []);
	const alertUser = (e) => {
		e.preventDefault();
		e.returnValue = '';
	};

	const popFunction = (event) => {
		event.preventDefault();
		const answer = window.confirm(t(`common.leavePage`));
		if (!answer) {
			window.history.pushState(null, document.title, window.location.href);
		} else {
			navigate('/invoices/');
		}
	};

    useInvoice(params.id, {
        onSuccess: (data) => {
            if(invoice === null) {
                const q = Invoice.fromDto(data.id, data.lines, data.client, data.number, data.createdAt, data.validUntil, data.sentToClient, data.status)
                q.comments=data.comments;
                q.logs=data?.logs
                setInvoice(q);
                setIsInvoiceCococontractor(data.isCocontractor);
            } else {
                invoice.comments = data.comments;
            }
        }
    });

    const {t} = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const createPdf = useCallback(async (generatePdf) => {

        const pdfWindow = window.open();

        try {
            const blob = await invoicesService.getPdf(generatePdf.id);
            //Build a URL from the file
            const fileURL = URL.createObjectURL(blob);
            pdfWindow.location.href = fileURL;

        } catch (error) {
            pdfWindow.close();
            toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('quote.pdf.problem'), life: 3000});
        }

    }, [navigate, t, toast]);

    const colors = [
        { code: '#FFADA7', label: t('invoice.lineOptionTypeExtraCharge') },
        { code: '#B2DDB4', label: t('invoice.lineOptionTypeCredit') },
        { code: '#E7CBEC', label: t('quote.lineOptionTypeVariant') },
    ];

    const menu = useMemo((status) => {

        if(invoice !== null) {
            return <>
                    <Button label={t('common.saveAsTemplate')} icon="pi pi-save" className="p-button-plain p-button-text" onClick={() => setShowModal(true)} />
                    <Button label={t('common.openFolder')} icon="pi pi-folder-open" className="p-button-plain p-button-text" onClick={() => {
                        window.location.href = 'deyemon://G:/Mon Drive/Deyemon Gestion/'+Moment(invoice.client.createdAt).format('Y')+"/"+invoice.client.formattedId+" - "+invoice.client.name+"/Factures";
                    }} />
                    <Button label={t('common.printToPdf')} icon="pi pi-print" className="p-button-plain p-button-text" onClick={() => createPdf(invoice)} />
                    <InvoiceStatus id={invoice.id} status={invoice.dto.status}/>
                    <div className="col"> <ColorLegend colors={colors} /></div>
                    {/* place cocontractant select here */}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0.5rem' }}>
                        <Checkbox inputId="invoiceCocontractor" checked={isInvoiceCococontractor}
                            onChange={e => {
                                setIsInvoiceCococontractor(e.checked);
                            }}/>
                        <label htmlFor="invoiceCocontractor" style={{ color: '#6C757D'}}> {t("invoice.cocontractant")} </label> 
                    </div>
                </>
        }
    }, [invoice, t, isInvoiceCococontractor])

    if(invoice === null) {
        return (
            <div>
                <TopMenu moduleName={t('invoice.title')} list={'/invoices/'} create={'/invoices/create/'}/>
                <Card className="ml-auto mr-auto text-center mt-2" style={{maxWidth: '1400px'}}>
                    <ProgressSpinner />
                </Card>
            </div>
        )
    }

    return (

        <div>
            <TopMenu moduleName={t('invoice.title')} list={'/invoices/'} create={'/invoices/create/'} menu={menu}/>
            <SaveAsTemplateModal invoice={invoice} showModal={showModal} setShowModal={setShowModal} />
            <ProvideInvoice devi={invoice}>
                <EditPage invoice={invoice} isInvoiceCococontractor={isInvoiceCococontractor} />
            </ProvideInvoice>

        </div>

    )
}

const saveAsTemplateScheme = () => yup.object({
    name: yup.string().required().default('').min(2).max(100)
});

const SaveAsTemplateModal = ({showModal, setShowModal, invoice}) => {

    const {t} = useTranslation();

    const scheme = saveAsTemplateScheme();
    const {control, handleSubmit, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const mutation = useCreateInvoiceTemplateMutation();

    const toast = useToast();

    const onSubmit = useCallback(async (data) => {
        const dto = {
            name: data.name,
            lines: invoice.dto.lines,
            vatTotal: invoice.dto.vatTotal,
            total: invoice.dto.total,
            subTotal: invoice.dto.subTotal
        };

        try {
            await mutation.mutateAsync(dto);
            toast.current.show({
                severity: 'success',
                summary: t('invoice.template.created.title'),
                detail: t('invoice.template.created.text'),
                life: 3000
            });
            //navigate('/invoice-templates/' + newTemplate.id);
        } catch (err) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: t('invoice.template.created.problem') + ' ' + err,
                life: 3000
            });
        }

        setShowModal(false);

    }, [mutation]);

    return (
        <Dialog visible={showModal} header={t('common.saveAsTemplate')} onHide={() => setShowModal(false)}>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-3 p-fluid grid grid-no-gutter">
                <div className="field col-12">
                    <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                </div>
                <div className="col">
                    <Button type={'submit'} label={t('common.saveAsTemplate')} disabled={mutation.isLoading} loading={mutation.isLoading} />
                </div>
            </form>
        </Dialog>
    )
};

const EditPage = observer(({invoice, isInvoiceCococontractor}) => {

    const {t} = useTranslation();
    const [editInvoice, setEditInvoice] = useState(null);

    const invoiceModel = useInvoiceContext();

    const updateMutation = useUpdateInvoiceMutation();

    const toast = useToast();

    const submitForm = async (data) => {

        try
        {
            const dto = {...invoice.dto, IsCocontractor: isInvoiceCococontractor};

            await updateMutation.mutateAsync({
                id: invoice.id,
                data: dto
            });

            toast.current.show({severity: 'success', summary: t('invoice.updated.title'), detail: t('invoice.updated.text'), life: 3000});
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('invoice.updated.problem') + " " + err, life: 3000});
        }

        setEditInvoice(null);
    };

    const auth = useAuth();

    const canEdit = !invoice.sentToClient || auth.hasRole("ROLE_ADMIN");

    const handleKeyDown = useCallback((event) => {
        // Check for ALT + s
        if (event.altKey && event.key === "s") {
            event.preventDefault(); // Prevent the default browser behavior
            invoice.addSection();
        }

        // Check for ALT + p
        if (event.altKey && event.key === "p") {
            event.preventDefault();
            invoice.addPost();
        }

        // Check for ALT + m
        if (event.altKey && event.key === "m") {
            event.preventDefault();
            invoice.addMaterial();
        }

    }, [invoice]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <>
            <Card className="ml-auto mr-auto mt-2 xl:p-8 sm:p-3" style={{maxWidth: '1400px'}}>

                {invoice.sentToClient && <InvoiceWasSentToClient />}

                <InvoiceInfoForm invoice={invoice} editable={false} />

                <Divider className={'mt-0'} />

                <InvoiceForm invoice={invoice} isInvoiceCococontractor={isInvoiceCococontractor} />

                {invoice.sentToClient && <InvoiceWasSentToClient />}

                <div>
                    {(!invoice.sentToClient || auth.hasRole("ROLE_ADMIN")) &&
                        <Button type={"button"} label={t('common.edit')} disabled={!invoice.valid || !invoiceModel.isValid || updateMutation.isLoading || !canEdit} loading={updateMutation.isLoading}
                                onClick={() => {
                                    if(invoice.sentToClient){
                                        setEditInvoice(true);
                                    }else{
                                        submitForm();
                                    }}}/>
                    }

                    <ConfirmYesNo description='invoice.pdf.editSentInvoice' visible={editInvoice} onHide={() => setEditInvoice(null)} onConfirmed={submitForm} />
                </div>
            </Card>
            <Card className="ml-auto mr-auto mt-2 xl:p-8 sm:p-3" style={{maxWidth: '1400px'}} title={t('common.comments')}>
                <InvoiceComments invoice={invoice} />
            </Card>
            <TimelinePerso logs={invoice?.logs}/>
        </>
    );
});

function InvoiceWasSentToClient() {
    const {t} = useTranslation();

    return (
        <Card title={t('common.warning')} className={"mt-4 mb-4 bg-orange-300"}>
            {t('invoice.wasSentToClient')}
        </Card>
    )
}
