import {useClients, useDeleteClientMutation} from "../clients/clients.hooks";
import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useToast} from "../core/toast.context";
import {Trans, useTranslation} from "react-i18next";
import {FilterMatchMode} from "primereact/api";
import {Button} from "primereact/button";
import Moment from "moment";
import {HasRole} from "../auth/has-role";
import {TopMenu} from "../core/TopMenu";
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {useDeleteEmailMutation, useEmails} from "./emails.hooks";
import {ConfirmDelete} from "../core/ConfirmDelete";
import {Skeleton} from "primereact/skeleton";


export function EmailListPage(client = null){
    const {emails, isLoading, isFetched} = useEmails();

    const deleteMutation = useDeleteEmailMutation();

    const [email, setEmail] = useState(null);

    let navigate = useNavigate();
    let toast = useToast();

    const {t} = useTranslation();

    const [filters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'subject': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const actionTemplate = (email) => {

        return (
            <>
                {!email.common && <HasRole role="ROLE_ADMIN">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-text m-0" onClick={() => navigate(email.id)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text m-0" onClick={() => confirmDeleteEmail(email)}/>
                </HasRole>}
            </>
        )
    };

    const confirmDeleteEmail = (email) => {
        setEmail(email);
    };

    const hideDeleteDialog = () => {
        setEmail(null);
    };

    const deleteEmail = () => {
        deleteMutation.mutateAsync(email.id)
            .then(() => {
                toast.current.show({severity: 'success', summary: t('email.deleted.title'), detail: t('email.deleted.text'), life: 3000});
            })
            .catch((err) => {
                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('email.deleted.problem') + " " + err , life: 3000});
            })
            .finally(() => {
                hideDeleteDialog();
            });
    };
    const items = Array.from({length: 10}, (v, i) => i);

    const skeletonTemplate = () => {
        return <Skeleton width={"80%"}/>;
    };

    return (
        <>
            {!client.client && <TopMenu moduleName={t('email.title')} create={'/settings/emails/create/'} />}
            <Card className='shadow-3 mt-3' title={t('email.list')}>
                <DataTable
                    value={isLoading || !isFetched ? items : client.client ? emails.filter((x) => x.client.id === client.client) : emails}
                    paginator
                    sortMode='multiple'
                    filterDisplay='row'
                    filters={filters}
                    dataKey='id'
                    stripedRows
                    selectionMode='single'
                    emptyMessage={t('email.emptyList')}
                    currentPageReportTemplate={t('datatable.reportTemplate')}
                    rows={25}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    rowHover
                    responsiveLayout='scroll'
                    columnResizeMode='expand'
                    size={'small'}
                    cellSelection
                    onCellSelect={(e) => !e.rowData.common && navigate(e.rowData.id)}>
                    <Column
                        field='name'
                        header={t('common.name')}
                        sortable
                        filter
                        filterPlaceholder={t('common.name')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.name)}
                    />
                    <Column body={isLoading || !isFetched ? skeletonTemplate : actionTemplate} className='p-0' style={{minWidth: '200px'}} />
                </DataTable>
            </Card>

            <ConfirmDelete name={email?.name} loading={deleteMutation.isLoading} visible={email} onHide={() => setEmail(null)} onConfirmed={deleteEmail} />
        </>
    );
}
