import sectionService from "./sections.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export function useSections() {
    const {data: sections, isFetched, isLoading, isError} = useQuery(['sections'], sectionService.all, {
        initialData: []
    });

    return {
        sections,
        isLoading,
        isFetched,
        isError
    };
}

export function useSection(id, options = null) {
    const {data: section, isLoading, isError, isFetching} = useQuery(['sections', id], ({queryKey}) => sectionService.get(queryKey[1]), options);

    return {
        section, isLoading, isError, isFetching
    };
}

export function useUpdateSectionMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => sectionService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['sections']);
        }
    });

    return mutation;
}


export function useCreateSectionMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(sectionService.create, {
        onSuccess: (data) => {
            const sections = queryClient.getQueryData(['sections']) ?? [];
            queryClient.setQueryData(['sections'], [...sections, data]);
        }
    });

    return mutation;
}

export function useDeleteSectionMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(sectionService.delete, {
        onSuccess: (data, variables) => {
            const sections = queryClient.getQueryData(['sections']) ?? [];
            queryClient.setQueryData(['sections'], sections.filter(x => x.id !== variables))
        }
    });

    return mutation;
}