import {Route, Routes} from "react-router-dom";
import {BaseFilesEdit} from "./base-files.edit";

export function BaseFile() {
    return <Routes>

        <Route path="*" element={<BaseFilesEdit />} />

    </Routes>

}