import {classNames} from "primereact/utils";
import {useMemo, useRef} from "react";
import {Button} from "primereact/button";
import {useInvoiceStatusMutation} from "../invoices/invoice.hooks";
import {useToast} from "./toast.context";
import {useTranslation} from "react-i18next";
import {Menu} from "primereact/menu";
import Moment from "moment";
import {useInvoiceStatus} from "../invoices/invoice.status.hooks";

export function InvoiceStatus({id, status, sendAt}) {

    const toast = useToast();
    const {t} = useTranslation();

    const InvoiceStatus = useInvoiceStatus(status);


    const color = classNames({
        'bg-blue-600': status === 1,
        'bg-yellow-500': status === 2,
        'bg-orange-500': status === 3,
        'bg-purple-500': status === 4,
        'bg-green-500': status === 5
    });

    const editMutation = useInvoiceStatusMutation();

    const menu = useRef(null);

    const menuItems = useMemo(() => {
        const setStatus = async (status, id) => {
            menu.current.toggle(false);
            try {

                await editMutation.mutateAsync({
                    id: id,
                    data: {
                        status: status,
                        id: id
                    },
                });

                toast.current.show({
                    severity: 'success',
                    summary: t('status.change.title'),
                    detail: t('status.change.text'),
                    life: 3000
                });

            } catch (err) {
                toast.current.show({
                    severity: 'error',
                    summary: t('common.oops'),
                    detail: t('status.change.problem') + " " + err,
                    life: 3000
                });
            }
        };

        const items = [
            // {
            //     label: t('common.other'),
            //     items: [
            //         {
            //             label: t('common.duplicate'),
            //             icon: 'pi pi-clone',
            //             command: () => {
            //                 navigate({
            //                     pathname: '/Invoices/create',
            //                     search: `?${createSearchParams({invoiceId: invoice.id})}`
            //                 })
            //             }
            //         }
            //     ]
            // }
        ];

        if(status === 2 || status === 3) {
            items.push(
                {
                    label: t('status.credited'),
                    icon: 'pi pi-times text-red-500',
                    command: async () => {
                        await setStatus(4, id);
                    }

                },{
                    label: t('status.billed'),
                    icon: 'pi pi-check text-primary',
                    command: async () => {
                        await setStatus(5, id);
                    }
                }
            )
        }

        return items;
    }, [editMutation, id, status, t, toast]);

    return (
        <div style={{textAlign:"center"}} >
            <Menu model={menuItems} popup ref={menu} id="popup_menu_invoice_status" />
            <Button className={color}
                    style={{border: "0px", height: 25}}
                    type="button"
                    label={sendAt && (status === 2 || status === 3) ? <i className="pi pi-send">&nbsp;{Moment(sendAt).format('DD-MM-YY')}</i> : InvoiceStatus.name} onClick={(event) => menuItems.length === 0 || menu.current.toggle(event)}/>
        </div>
    );
}