import {useClient, useUpdateClientMutation} from "../clients.hooks";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Card} from "primereact/card";
import {TextControl} from "../../core/ControlledInputs/TextControl";
import {DropdownControl} from "../../core/ControlledInputs/DropDownControl";
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {TextAreaControl} from "../../core/ControlledInputs/TextAreaControl";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useOrigins} from "../origins/origin.hooks";
import {useGenders} from "../genders/gender.hooks";
import {usePriorities} from "../priorities/priority.hooks";
import {clientScheme} from "../clients.scheme";
import {useState} from "react";
import {useToast} from "../../core/toast.context";
import {TimelinePerso} from "../../core/Timeline";
import Loader from "../../core/Loader";
import { useCountries } from "../countries/country.hooks";


export const ClientInfo = () => {

    const {t} = useTranslation();

    const scheme = clientScheme(t);

    let toast = useToast();
    let params = useParams();

    const [useInvoiceAddress, setUseInvoiceAddress] = useState(false);
    const [logs, setLogs] = useState();

    const {control, handleSubmit, formState: {isDirty}, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme),
        context: {
            useInvoiceAddress
        }
    });

    const {origins, isLoading: isLoadingOrigins} = useOrigins();
    const {genders, isLoading: isLoadingGenders} = useGenders();
    const {priorities, isLoading: isLoadingPriorities} = usePriorities();
    const {countries, isLoading: isLoadingCountries} = useCountries();

    const {client, isFetching, isLoading} = useClient(params.id, {
        onSuccess: (data) => {

            setLogs(data.logs);

            if(!isDirty) {
                if(data.invoiceAddress !== null) {
                    setUseInvoiceAddress(true);
                } else {
                    setUseInvoiceAddress(false);
                }

                reset({
                    name: data.name,
                    genderId: data.gender?.id,
                    originId: data.origin?.id,
                    priorityId: data.priority?.id,
                    statusId: data.status?.id,
                    email1: data.email1,
                    email2: data.email2,
                    phone1: data.phone1,
                    phone2: data.phone2,
                    address: data.address,
                    nb: data.nb,
                    postalCode: data.postalCode,
                    city: data.city,
                    countryId: data.country?.id,
                    invoiceAddress: data.invoiceAddress,
                    invoiceNb: data.invoiceNb,
                    invoicePostalCode: data.invoicePostalCode,
                    invoiceCity: data.invoiceCity,
                    invoiceCountryId: data.invoiceCountry?.id,
                    tva: data.tva,
                    note: data.note
                })
            }

    }});



    const updateMutation = useUpdateClientMutation();

    const onSubmit = async (data) => {

        try
        {
            const dto = {
                ...data
            };

            if(dto.email1 === ""){
                dto.email1 = null;
            }

            if(dto.email2 === ""){
                dto.email2 = null;
            }

            if(!useInvoiceAddress) {
                delete dto.invoiceAddress;
                delete dto.invoiceNb;
                delete dto.invoicePostalCode;
                delete dto.invoiceCity;
                delete dto.invoiceCountryId;
            }

            await updateMutation.mutateAsync({
                id: client.id,
                data: dto
            });

            reset(data);

            toast.current.show({severity: 'success', summary: t('client.updated.title'), detail: t('client.updated.text'), life: 3000});
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('client.updated.problem') + " " + err, life: 3000});
        }
    };

    return (
        <div>

                <Card title={t('client.edit')} className="shadow-3 mt-3">

                    {isFetching || isLoading ? <Loader/> :

                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                            <div className="field col-12">
                                <TextControl label={t('common.name')} control={control} fieldName={'name'} autoFocus/>
                            </div>

                            <div className="field col-12">
                                <TextControl control={control} fieldName={'tva'} label={t('common.vat')}/>
                            </div>

                            <div className="field col-6">
                                <DropdownControl
                                    control={control}
                                    label={t('common.gender')}
                                    loading={isLoadingGenders}
                                    options={genders}
                                    optionLabel={'name'}
                                    optionValue={'id'}
                                    fieldName={'genderId'}/>
                            </div>

                            <div className="field col-6">
                                <DropdownControl
                                    control={control}
                                    label={t('common.origin')}
                                    loading={isLoadingOrigins}
                                    options={origins}
                                    optionLabel={'name'}
                                    optionValue={'id'}
                                    fieldName={'originId'}/>
                            </div>

                            <div className="field col-6">
                                <DropdownControl
                                    control={control}
                                    label={t('common.priority')}
                                    loading={isLoadingPriorities}
                                    options={priorities}
                                    optionLabel={'name'}
                                    optionValue={'id'}
                                    fieldName={'priorityId'}/>
                            </div>

                            {/*<div className="field col-6">*/}
                            {/*    <DropdownControl*/}
                            {/*        control={control}*/}
                            {/*        label={t('common.status')}*/}
                            {/*        loading={isLoadingStatuses}*/}
                            {/*        options={statuses}*/}
                            {/*        optionLabel={'name'}*/}
                            {/*        optionValue={'id'}*/}
                            {/*        fieldName={'statusId'}/>*/}
                            {/*</div>*/}

                            <div className="field col-6">
                                <TextControl label={t('common.email')} control={control} fieldName={'email1'}/>
                            </div>

                            <div className="field col-6">
                                <TextControl label={t('common.email') + " 2"} control={control} fieldName={'email2'}/>
                            </div>

                            <div className="field col-6">
                                <TextControl label={t('common.phoneNumber')} control={control} fieldName={'phone1'} regex={'(\\+32|0)\\d{9}'}/>
                            </div>

                            <div className="field col-6">
                                <TextControl label={t('common.phoneNumber') + " 2"} control={control}
                                             fieldName={'phone2'} regex={'(\\+32|0)\\d{9}'}/>
                            </div>

                            <div className="field col-6">
                                <i className="pi pi-phone text-primary m-3" onClick={() => window.open ('tel:' + client.phone1,'_blank')}></i>
                                <i className="pi pi-envelope text-primary m-3" onClick={() => window.open ('mailto:' + client.email1 + "?cc=" + client.email2, '_blank')}></i>
                                <i className="pi pi-map text-primary m-3" onClick={() => window.open ('https://www.google.com/maps/place/' + client.address + "+" + client.nb + "+" + client.city, '_blank')}></i>
                            </div>

                            <Divider><strong>{t('client.worksiteAddress')}</strong></Divider>

                            <div className="field col-12">
                                <TextControl control={control} fieldName={'address'} label={t('common.address')}/>
                            </div>

                            <div className="field col-6">
                                <TextControl control={control} fieldName={'nb'} label={t('common.houseNumber')}/>
                            </div>

                            <div className="field col-6">
                                <TextControl control={control} fieldName={'postalCode'} label={t('common.postalCode')} type="number" maxLength={5}/>
                            </div>

                            <div className="field col-6">
                                <TextControl control={control} fieldName={'city'} label={t('common.city')} type="text"/>
                            </div>

                            <div className="field col-6">
                                <DropdownControl
                                    control={control}
                                    label={t('common.country')}
                                    loading={isLoadingCountries}
                                    options={countries}
                                    optionLabel={'name'}
                                    optionValue={'id'}
                                    fieldName={'countryId'}/>
                            </div>

                            <Divider>
                                <Button type={'button'} className="p-button-outlined" onClick={() => {

                                    setUseInvoiceAddress(!useInvoiceAddress);

                                }}
                                        label={!useInvoiceAddress ? t('client.worksiteAddressIsNotTheInvoiceAddress') : t('client.worksiteAddressIsInvoiceAddress')}/>
                            </Divider>


                            {useInvoiceAddress &&
                                <>
                                    <div className="field col-12">
                                        <TextControl control={control} fieldName={'invoiceAddress'}
                                                     label={t('common.address')}/>
                                    </div>

                                    <div className="field col-6">
                                        <TextControl control={control} fieldName={'invoiceNb'}
                                                     label={t('common.houseNumber')}/>
                                    </div>

                                    <div className="field col-6">
                                        <TextControl control={control} fieldName={'invoicePostalCode'}
                                                     label={t('common.postalCode')}/>
                                    </div>

                                    <div className="field col-6">
                                        <TextControl control={control} fieldName={'invoiceCity'}
                                                     label={t('common.city')}/>
                                    </div>

                                    <div className="field col-6">
                                        <DropdownControl
                                            control={control}
                                            label={t('common.country')}
                                            loading={isLoadingCountries}
                                            options={countries}
                                            optionLabel={'name'}
                                            optionValue={'id'}
                                            fieldName={'invoiceCountryId'}/>
                                    </div>

                                </>}

                            <div className="field col-12">
                                <TextAreaControl control={control} fieldName={'note'} label={t('common.note')} maxLength={1000} />
                            </div>

                            <Button type="submit" label={t('common.edit')}
                                    loading={updateMutation.isLoading}/>
                        </form>
                    }
                    </Card>
            {logs &&
                <TimelinePerso logs={logs}/>
            }
        </div>
    )
};