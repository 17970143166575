import api from "../services/api";

function config(organisationId) {
    if(!organisationId || organisationId === 'self') {
        return api.defaults;
    }

    return {
        ...api.defaults,
        headers: {
            'X-ORG-ID': organisationId
        }
    };
}

class UsersService {
    all(organisationId) {
        return api.get('api/users', config(organisationId))
            .then(function(x) {
                return x.data;
            });
    }

    delete(id, organisationId) {
        return api.delete('api/users/'+id, config(organisationId));
    }

    get(id, organisationId) {
        return api.get('api/users/'+id, config(organisationId))
            .then(x => x.data);
    }

    create(data, organisationId) {
        return api.post('api/users', JSON.stringify(data), config(organisationId))
            .then(x => x.data);
    }

    update(id, data, organisationId) {
        return api.post('api/users/' + id, JSON.stringify(data), config(organisationId))
            .then(x => x.data);
    }

    updateUserTheme(userMail, data, organisationId) {
        return api.post('api/users/' + userMail + '/theme', JSON.stringify(data), config(organisationId))
            .then(x => x.data);
    }
}

export default new UsersService();