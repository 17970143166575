import api from "../services/api";

class licenseService {
    getInformation() {
        return api.get('api/license')
            .then(x => x.data);
    }
}

export default new licenseService();
