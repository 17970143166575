import api from "../services/api";

class materialsService {

    all() {
        return api.get('api/materials')
            .then(function (x) {
                return x.data;
            });
    }

    delete(id) {
        return api.delete('api/materials/'+id);
    }

    get(id) {
        return api.get('api/materials/'+id)
            .then(x => x.data);
    }

    update(id, data) {
        return api.post('api/materials/'+id, JSON.stringify(data))
            .then(x => x.data);
    }

    create(data) {
        return api.post('api/materials', JSON.stringify(data))
            .then(x => x.data);
    }
}

export default new materialsService();