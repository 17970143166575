import {InvoiceMaterialLine} from "./invoice-material.line";
import {InvoicePostLine} from "./invoice-post.line";
import {InvoiceSectionLine} from "./invoice-section.line";
import {observer} from "mobx-react";

export const InvoiceLine = observer(({line, index, parent, isCococontractor}) => {
    if (line.type === 'material') {
        return <InvoiceMaterialLine index={index} material={line} parent={parent} isCococontractor={isCococontractor} />
    }
    else if (line.type === 'post') {
        return <InvoicePostLine index={index} post={line} parent={parent} isCococontractor={isCococontractor} ></InvoicePostLine>
    }
    else if (line.type === 'section') {
        return <InvoiceSectionLine index={index} section={line} parent={parent} isCococontractor={isCococontractor} ></InvoiceSectionLine>
    }

    throw new Error("Expected a material, post, section or credit");
})
