import {useTranslation} from "react-i18next";

export function FormErrorMessage({error}) {

    const { t } = useTranslation();

    if (error === undefined) {
        return null;
    } else if (typeof error.message === 'string') {
        return <small className="p-error">{error.message}</small>;
    }
    else if (error.type === 'server') { //error.parameters might be an array here, TODO
        return <small className="p-error">{t('serverValidation.'+error.message, error.parameters)}</small>
    } else if(Array.isArray(error)) {
        return error.map((x) => <FormErrorMessage error={x} />);
    } else {
        const { key, values } = error.message;
        return <small className="p-error">{t(key, values)}</small>;
    }
}
