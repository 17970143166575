import api from "../../services/api";

class PriorityService {
    all() {
        return api.get('api/priorities')
            .then(function(x) {
                return x.data;
            });
    }

    delete(id) {
        return api.delete('api/priorities/'+id);
    }

    get(id) {
        return api.get('api/priorities/'+id)
            .then(x => x.data);
    }

    create(data) {
        return api.post('api/priorities', JSON.stringify(data))
            .then(x => x.data);
    }

    update(id, data) {
        return api.post('api/priorities/' + id, JSON.stringify(data))
            .then(x => x.data);
    }
}

export default new PriorityService();