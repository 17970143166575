import * as yup from "yup";

export const organisationScheme = () => yup.object({
    name: yup.string().required().min(2).max(100).default(''),
    email: yup.string().email().nullable(),
    address: yup.string().nullable().max(100),
    nb: yup.string().nullable().max(10),
    postalCode: yup.string().nullable().max(10),
    city: yup.string().nullable().max(100),
    country: yup.string().nullable().max(100),
    phone: yup.string().nullable().max(100),
    tva: yup.string().nullable().max(100),
    bank: yup.string().nullable().max(100),
    note: yup.string().nullable().max(1000),
    clientFormat: yup.string().required().default('[Y][m][d]-%s').max(100),
    quoteFormat: yup.string().required().default('[Y][m][d]-%s').max(100),
    invoiceFormat: yup.string().required().default('[Y][m][d]-%s').max(100),
    deletedAt: yup.string().nullable(),
});

export const googleDriveConfigurationScheme = () => yup.object({
    serviceAccount: yup.string().nullable().default(''),
    quoteDirectory: yup.string().required().min(1).max(100),
    invoiceDirectory: yup.string().required().min(1).max(100),
    documentsDirectory: yup.string().required().min(1).max(100),
});