import api from "../services/api";

class ProvidersService {
    all() {
        return api.get('api/providers')
            .then(function(x) {
                return x.data;
            });
    }

    delete(id) {
        return api.delete('api/providers/'+id);
    }

    get(id) {
        return api.get('api/providers/'+id)
            .then(x => x.data);
    }

    create(data) {
        return api.post('api/providers', JSON.stringify(data))
            .then(x => x.data);
    }

    update(id, data) {
        return api.post('api/providers/' + id, JSON.stringify(data))
            .then(x => x.data);
    }
}

export default new ProvidersService();