import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {useAuth} from "./auth.context";


export const roles = [
    {
        value: 'superAdmin',
        name: 'user.role.superAdmin'
    },
    {
        value: 'admin',
        name: 'user.role.admin'
    },
    {
        value: 'user',
        name: 'user.role.user'
    }
];

export function useMapRole(roleValue) {

    const {t} = useTranslation();

    return useMemo(() => {
        const index = roles.map(x => x.value).indexOf(roleValue);

        if(index === -1) {
            return null;
        }

        return {
            ...roles[index],
            name: t(roles[index].name)
        };
    }, [t, roleValue]);
}


export function useRoles() {
    const {t} = useTranslation();


    return useMemo(() => {
        return roles.map(role => {
            return {
                ...role,
                name: t(role.name)
            }
        })
    }, [t]);
}

export function useAllowedRoles() {

    const allRoles = useRoles();

    const auth = useAuth();

    if(auth.hasRole('ROLE_SUPER_ADMIN')) {
        return allRoles;
    }
    else if(auth.hasRole('ROLE_ADMIN')) {
        return allRoles.filter(x => x.value !== 'superAdmin');
    }

    return [];
}