import clientsService from "./clients.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import quotesService from "../quotes/quotes.service";


export function useClients() {
    const {data: clients, isFetched, isLoading, isError} = useQuery(['clients'], clientsService.all, {
        initialData: []
    });

    return {
        clients,
        isLoading,
        isFetched,
        isError
    };
}

export function useClient(id, options = null) {
    const {data: client, isLoading, isError, isFetching} = useQuery(['clients', id], ({queryKey}) => clientsService.get(queryKey[1]), options);

    return {
        client, isLoading, isError, isFetching
    };
}

export function useUpdateClientMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => clientsService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['clients']);
        }
    });

    return mutation;
}


export function useCreateClientMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(clientsService.create, {
        onSuccess: (data) => {
            const clients = queryClient.getQueryData(['clients']) ?? [];
            queryClient.setQueryData(['clients'], [...clients, data]);
        }
    });

    return mutation;
}

export function useDeleteClientMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(clientsService.delete, {
        onSuccess: (data, variables) => {
            const clients = queryClient.getQueryData(['clients']) ?? [];
            queryClient.setQueryData(['clients'], clients.filter(x => x.id !== variables))
        }
    });

    return mutation;
}

export function useClientStatusMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => clientsService.setStatus(params.id, params.data), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['clients']);
        }
    });

    return mutation;
}