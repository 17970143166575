import {Card} from "primereact/card";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCreateGenderMutation} from "./gender.hooks";
import {TextControl} from "../../core/ControlledInputs/TextControl";
import {genderScheme} from "./genders.scheme";
import {useToast} from "../../core/toast.context";
import {TopMenu} from "../../core/TopMenu";
import {useTranslation} from "react-i18next";

export function CreateGenderPage() {

    const navigate = useNavigate();
    let toast = useToast();

    const scheme = genderScheme();

    const {control, handleSubmit} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const createMutation = useCreateGenderMutation();

    const {t} = useTranslation();

    const onSubmit = async (data) => {

        const dto = {
            ...data
        };

        try {
            await createMutation.mutateAsync(dto);
            toast.current.show({severity: 'success', summary: t('gender.created.title'), detail: t('gender.created.text'), life: 3000});
            navigate('/settings/genders');
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('gender.created.problem')+' '+err, life: 3000});
        }
    };
    
    return (

        <div>
            <TopMenu moduleName={t('gender.title')} list={'/settings/genders'}/>

            <Card title={t('gender.create')} className="shadow-3 mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                    </div>
                    <Button type="submit" label={t('common.create')} loading={createMutation.isLoading}/>
                </form>
            </Card>
        </div>
    )
}