import {useMaterials} from "../materials/materials.hooks";
import {useForm} from "react-hook-form";
import {postLineScheme} from "./post.scheme";
import {yupResolver} from "@hookform/resolvers/yup";
import {DropdownControl} from "../core/ControlledInputs/DropDownControl";
import {NumericControl} from "../core/ControlledInputs/NumericControl";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";

export function AddMaterialForm({onAdd}) {

    const {materials, isLoading} = useMaterials();
    const scheme = postLineScheme();
    const {control, handleSubmit, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const onSubmit = (data) => {
        onAdd(data);
        reset();
    };

    const {t} = useTranslation();

    const materialState = [
        {name: "normal"},
        {name: "option"},
        {name: "discount"},
    ];

    return (<>
        <div className="p-fluid grid">
            <div className="field col-12 md:col-3">
                <DropdownControl control={control} fieldName={'material'} label={t('common.material')} optionLabel={'name'} options={materials} loading={isLoading} filter />
            </div>

            <div className="field col-12 md:col-3">
                <NumericControl control={control} fieldName="amount" label={t('common.amount')} />
            </div>

            <div className="field col-12 md:col-3">
                <DropdownControl control={control} fieldName={'state'} label={t('common.state')} optionLabel={'name'} options={materialState} filter />
            </div>

            <div className="col-12 md:col-3">
                <Button type="submit" label={t('common.add')} className="p-button-info" onClick={handleSubmit(onSubmit)} />
            </div>
        </div>
    </>);
}
