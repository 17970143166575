import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FilterMatchMode} from "primereact/api";
import {Button} from "primereact/button";
import {HasRole} from "../auth/has-role";
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {useDeletePostMutation, usePosts} from "./posts.hooks";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {Trans, useTranslation} from "react-i18next";
import {Divider} from "primereact/divider";
import TokenService from "../services/token.service";
import {Toolbar} from "primereact/toolbar";
import {Skeleton} from "primereact/skeleton";

export function PostsListPage(client = null) {
    const [post, setPost] = useState(null);
    const {posts, isLoading, isFetched} = usePosts();

    const deleteMutation = useDeletePostMutation();

    let navigate = useNavigate();
    let toast = useToast();

    const {t} = useTranslation();

    const [filters] = useState({
        //'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS,  },
        'unit.name': { value: null, matchMode: FilterMatchMode.CONTAINS,  },
        'note': { value: null, matchMode: FilterMatchMode.CONTAINS,  },
        'section.name': { value: null, matchMode: FilterMatchMode.CONTAINS,  }
    });

    const actionTemplate = (post) => {
        return (
            <Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text m-0" onClick={() => navigate("/posts/"+post.id)} />
                <HasRole role="ROLE_ADMIN">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text m-0" onClick={() => confirmDeletePost(post)}/>
                </HasRole>
            </Fragment>
        )
    };

    const confirmDeletePost = (post) => {
        setPost(post);
    };

    const hideDeleteDialog = () => {
        setPost(null);
    };

    const deletePost = () => {

        deleteMutation.mutateAsync(post.id)
            .then(() => {
                toast.current.show({severity: 'success', summary: t('post.deleted.title'), detail: t('post.deleted.text'), life: 3000});
            })
            .catch(() => {
                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('post.deleted.problem'), life: 3000});
            })
            .finally(() => {
                hideDeleteDialog();
            })
    };

    const deleteDialogFooter = (
        <Fragment>
            <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} loading={deleteMutation.isLoading} />
            <Button label={t('common.yes')} icon="pi pi-trash" className="p-button-text p-button-danger" onClick={deletePost} loading={deleteMutation.isLoading} />
        </Fragment>
    );

    const items = Array.from({length: 10}, (v, i) => i);

    const skeletonTemplate = () => {
        return <Skeleton width={"80%"}/>;
    };

    return (
        <div>
            {!client.client && <TopMenu moduleName={t('post.title')} create={'/posts/create/'} />}
            <Card className='shadow-3 mt-3' title={t('post.list')}>
                <DataTable
                    value={isLoading || !isFetched ? items : client.client ? posts.filter((x) => x.client.id === client.client) : posts}
                    paginator
                    sortMode='multiple'
                    filterDisplay='row'
                    filters={filters}
                    dataKey='id'
                    stripedRows
                    selectionMode='single'
                    emptyMessage={t('post.emptyList')}
                    currentPageReportTemplate={t('datatable.reportTemplate')}
                    rows={25}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    rowHover
                    responsiveLayout='scroll'
                    columnResizeMode='expand'
                    size={'small'}
                    cellSelection
                    onCellSelect={(e) => navigate('/posts/'+e.rowData.id)}>
                    <Column
                        field='name'
                        header={t('common.name')}
                        sortable
                        filter
                        filterPlaceholder={t('common.name')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.name)}
                    />
                    <Column
                        field='unit.name'
                        header={t('common.unit')}
                        sortable
                        filter
                        filterPlaceholder={t('common.unit')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"60%"}/> : row.unit?.name)}
                    />
                    <Column
                        field='note'
                        header={t('common.note')}
                        sortable
                        filter
                        filterPlaceholder={t('common.note')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"90%"}/> : row.note)}
                    />
                    <Column
                        field='section'
                        header={t('common.section')}
                        sortable
                        filter
                        filterPlaceholder={t('common.section')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.section?.name)}
                    />
                    <Column body={isLoading || !isFetched ? skeletonTemplate : actionTemplate} className='p-0' style={{minWidth: '200px'}} />
                </DataTable>
            </Card>

            <Dialog visible={!!post} header={t('common.confirm')} modal footer={deleteDialogFooter} onHide={hideDeleteDialog} closeOnEscape={false} closable={false}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {post &&
                        <Trans i18nKey="common.confirmDelete" values={{name: post.name}}>
                            Are you sure you want to delete <strong>{{name: post.name}}</strong> from the list?
                        </Trans>
                    }
                </div>
            </Dialog>


        </div>
    );
}