import api from "../services/api";

class ClientsService {
    all() {
        return api.get('api/clients')
            .then(function(x) {
                return x.data;
            });
    }

    delete(id) {
        return api.delete('api/clients/'+id);
    }

    get(id) {
        return api.get('api/clients/'+id)
            .then(x => x.data);
    }

    create(data) {
        return api.post('api/clients', JSON.stringify(data))
            .then(x => x.data);
    }

    update(id, data) {
        return api.post('api/clients/' + id, JSON.stringify(data))
            .then(x => x.data);
    }

    getLogs(id) {
        return api.get('api/clients/logs/'+id)
            .then(x => x.data);
    }

    setStatus(id, data) {
        return api.post('api/clients/'+id+'/status', JSON.stringify(data))
    }
}

export default new ClientsService();