import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Card} from "primereact/card";
import {ConfirmDelete} from "../../core/ConfirmDelete";
import {useDeleteGenderMutation, useGenders} from "./gender.hooks";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useToast} from "../../core/toast.context";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {Button} from "primereact/button";
import {HasRole} from "../../auth/has-role";
import {TopMenu} from "../../core/TopMenu";
import {Skeleton} from "primereact/skeleton";


export function GenderList(client = null) {


    const {genders, isLoading, isFetched} = useGenders();
    const deleteMutation = useDeleteGenderMutation();

    const [gender, setGender] = useState(null);

    let navigate = useNavigate();
    let toast = useToast();

    const {t} = useTranslation();

    const [filters] = useState({
        'name': {value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const actionTemplate = (gender) => {

        return (
            <>
            {!gender.common &&
                <HasRole role="ROLE_ADMIN">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-text m-0" onClick={() => navigate(gender.id)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text m-0" onClick={() => confirmDeleteGender(gender)}/>
                </HasRole>
            }
            </>
        )
    };

    const confirmDeleteGender = (gender) => {
        setGender(gender);
    };

    const hideDeleteDialog = () => {
        setGender(null);
    };

    const deleteGender = () => {
        deleteMutation.mutateAsync(gender.id)
            .then(() => {
                toast.current.show({severity: 'success', summary: t('gender.deleted.title'), detail: t('gender.deleted.text'), life: 3000});
            })
            .catch((err) => {
                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('gender.deleted.problem') + " " + err , life: 3000});
            })
            .finally(() => {
                hideDeleteDialog();
            });
    };
    const items = Array.from({length: 10}, (v, i) => i);

    const skeletonTemplate = () => {
        return <Skeleton width={"80%"}/>;
    };

    return (
        <>
            {!client.client && <TopMenu moduleName={t('gender.title')} create={'/settings/genders/create/'} />}
            <Card className='shadow-3 mt-3' title={t('gender.list')}>
                <DataTable
                    value={isLoading || !isFetched ? items : client.client ? genders.filter((x) => x.client.id === client.client) : genders}
                    paginator
                    sortMode='multiple'
                    filterDisplay='row'
                    filters={filters}
                    dataKey='id'
                    stripedRows
                    selectionMode='single'
                    emptyMessage={t('gender.emptyList')}
                    currentPageReportTemplate={t('datatable.reportTemplate')}
                    rows={25}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    rowHover
                    responsiveLayout='scroll'
                    columnResizeMode='expand'
                    size={'small'}
                    cellSelection
                    onCellSelect={(e) => !e.rowData.common && navigate(e.rowData.id)}>
                    <Column
                        field='name'
                        header={t('common.name')}
                        sortable
                        filter
                        filterPlaceholder={t('common.name')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.name)}
                    />
                    <Column body={isLoading || !isFetched ? skeletonTemplate : actionTemplate} className='p-0' style={{minWidth: '200px'}} />
                </DataTable>
            </Card>

            <ConfirmDelete name={gender?.name} loading={deleteMutation.isLoading} visible={gender} onHide={() => setGender(null)} onConfirmed={deleteGender} />
        </>
    );
}