import { Controller } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { FormErrorMessage } from '../FormErrorMessage';
import moment from 'moment';

export function CalenderControl({ fieldName, control, label, autoFocus = false, disabled = false }) {
	return (
		<Controller
			name={fieldName}
			control={control}
			render={({ field, fieldState }) => (
				<>
					<span className='p-float-label'>
						<Calendar
							inputId={field.name}
							value={field.value? new Date(field.value) : field.value}
							onChange={field.onChange}
							dateFormat='yy-mm-dd'
							disabled={disabled}
							autoFocus={autoFocus}
							className={classNames({ 'p-invalid': fieldState.error })}
							showIcon
							showButtonBar
						/>
						<label htmlFor={field.name} className={classNames({ 'p-error': !!fieldState.error })}>
							{label}
						</label>
					</span>
					<FormErrorMessage error={fieldState.error} />
				</>
			)}
		/>
	);
}
