import {useNavigate} from "react-router-dom";
import {useToast} from "../core/toast.context";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";
import {TopMenu} from "../core/TopMenu";
import {Card} from "primereact/card";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {Button} from "primereact/button";
import {useCreateCategoryMutation} from "./categories.hooks";
import {categoryScheme} from "./categories.scheme";


export function CreateCategoryPage() {
    const navigate = useNavigate();
    let toast = useToast();

    const {control, handleSubmit} = useForm({
        defaultValues: categoryScheme().cast(),
        resolver: yupResolver(categoryScheme())
    });

    const createMutation = useCreateCategoryMutation();

    const {t} = useTranslation();

    const onSubmit = async (data) => {

        const dto = {
            ...data
        };

        try {
            await createMutation.mutateAsync(dto);
            toast.current.show({severity: 'success', summary: t('category.created.title'), detail: t('category.created.text'), life: 3000});
            navigate('/settings/categories/');
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('category.created.problem')+' '+err, life: 3000});
        }
    };

    const menu =
        [
            {label: t('common.list'), icon: 'pi pi-list', command: () => navigate('/settings/categories/')},
        ]

    return (

        <div>
            <TopMenu moduleName={t('category.title')} list={'/settings/categories'}/>

            <Card title={t('category.create')} className="shadow-3 mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                    </div>
                    <Button type="submit" label={t('common.create')} loading={createMutation.isLoading}/>
                </form>
            </Card>
        </div>
    )
}