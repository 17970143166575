import {Card} from "primereact/card";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import { Dropdown } from "primereact/dropdown";
import organisationsService from "../organisations.service";

export function ThemeEdit() {

    const {t} = useTranslation();
    
    const themes = [
        {
            "name": t('theme.bootstrap4LightBlue'),
            "value": "bootstrap4-light-blue"
        },
        {
            "name": t("theme.bootstrap4DarkBlue"),
            "value": "bootstrap4-dark-blue"
        },
        {
            "name": t("theme.bootstrap4LightPurple"),
            "value": "bootstrap4-light-purple"
        },
        {
            "name": t("theme.bootstrap4DarktPurple"),
            "value": "bootstrap4-dark-purple"
        },
        {
            "name": t("theme.lightIndigo"),
            "value": "mdc-light-indigo"
        },
        {
            "name": t("theme.lightDeeppurple"),
            "value": "mdc-light-deeppurple"
        },
        {
            "name": t("theme.darkIndigo"),
            "value": "mdc-dark-indigo"
        },
        {
            "name": t("theme.DarkDeeppurple"),
            "value": "mdc-dark-deeppurple"
        },
        {
            "name": t("theme.fluentLight"),
            "value": "fluent-light"
        },
        {
            "name": t("theme.laraLightBlue"),
            "value": "lara-light-blue"
        },
        {
            "name": t("theme.laraLightIndigo"),
            "value": "lara-light-indigo"
        },
        {
            "name": t("theme.laraLightPurple"),
            "value": "lara-light-purple"
        },
        {
            "name": t("theme.laraLightTeal"),
            "value": "lara-light-teal"
        },
        {
            "name": t("theme.laraDarkBlue"),
            "value": "lara-dark-blue"
        },
        {
            "name": t("theme.laraDarkIndigo"),
            "value": "lara-dark-indigo"
        },
        {
            "name": t("theme.laraDarkPurple"),
            "value": "lara-dark-purple"
        },
        {
            "name": t("theme.laraDarkTeal"),
            "value": "lara-dark-teal"
        },
        {
            "name": t("theme.sagaBlue"),
            "value": "saga-blue"
        },
        {
            "name": t("theme.sagaGreen"),
            "value": "saga-green"
        },
        {
            "name": t("theme.sagaOrange"),
            "value": "saga-orange"
        },
        {
            "name": t("theme.sagaPurple"),
            "value": "saga-purple"
        },
        {
            "name": t("theme.velaBlue"),
            "value": "vela-blue"
        },
        {
            "name": t("theme.velaGreen"),
            "value": "vela-green"
        },
        {
            "name": t("theme.velaOrange"),
            "value": "vela-orange"
        },
        {
            "name": t("theme.velaPurple"),
            "value": "vela-purple"
        },
        {
            "name": t("theme.aryaBlue"),
            "value": "arya-blue"
        },
        {
            "name": t("theme.aryaGreen"),
            "value": "arya-green"
        },
        {
            "name": t("theme.aryaOrange"),
            "value": "arya-orange"
        },
        {
            "name": t("theme.aryaPurple"),
            "value": "arya-purple"
        },
        {
            "name": t("theme.nova"),
            "value": "nova"
        },
        {
            "name": t("theme.novaAlt"),
            "value": "nova-alt"
        },
        {
            "name": t("theme.novaAccent"),
            "value": "nova-accent"
        },
        {
            "name": t("theme.lunaAmber"),
            "value": "luna-amber"
        },
        {
            "name": t("theme.lunaBlue"),
            "value": "luna-blue"
        },
        {
            "name": t("theme.lunaGreen"),
            "value": "luna-green"
        },
        {
            "name": t("theme.lunaPink"),
            "value": "luna-pink"
        },
        {
            "name": t("theme.rhea"),
            "value": "rhea"
        }
    ];

    const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem('selectedTheme'));

    return(
        <Card title={t("theme.title")} className="shadow-3 mt-3">
                <div className="col-4 align-items-center justify-content-center">
                    <h4>{t("theme.subtitle")}</h4>
                    <Dropdown value={selectedTheme} options={themes} optionLabel={"name"}
                                optionValue={"value"} onChange={(e) => {
                                    localStorage.setItem('selectedTheme', e.value);
                                    const evt = new Event('ReloadThemeApplication');
                                    window.dispatchEvent(evt);
                                    setSelectedTheme(e.value);
                                    organisationsService.updateTheme({
                                        theme: localStorage.getItem('selectedTheme')
                                    });
                                }}/>
                </div>
        </Card>
    )
}