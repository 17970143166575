import {InvoiceComment} from "./invoice-comment";
import {Divider} from "primereact/divider";
import {AddInvoiceCommentForm} from "./invoice-comment.form";
import {useTranslation} from "react-i18next";


export function InvoiceComments({invoice}) {

    const {t} = useTranslation();

    return (
        <>
            {invoice.comments.length === 0 && (<>
                <p className={"text-gray-500"}>{t('common.noComments')}</p>
                <Divider />
            </>)}
            {invoice.comments.length > 0 && invoice.comments.map(comment => (<InvoiceComment key={comment.id} comment={comment} />))}
            <AddInvoiceCommentForm invoice={invoice} />
        </>
    );

}
