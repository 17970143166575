import {Card} from "primereact/card";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import organisationsService from "../organisations.service";
import {useState} from "react";
import {useToast} from "../../core/toast.context";
import { Divider } from "primereact/divider";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { useCreateQuoteZipMutation } from "../../quotes/quote.hooks";
import {saveAs} from 'file-saver';
import { useCreateInvoiceZipMutation } from "../../invoices/invoice.hooks";

export function DownloadsEdit() {

    const {t} = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false);
    let toast = useToast();

    const exeDownload = async () => {
        setIsDownloading(true);
        try {
            await organisationsService.downloadExe();

            toast.current.show({
                severity: 'success',
                summary: t('downloads.title'),
                detail: t('downloads.text'),
                life: 3000
            });

        } catch (e) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: t('downloads.problem'),
                life: 3000
            });
        }
        setIsDownloading(false);
    };

    const createQuoteZipMutation = useCreateQuoteZipMutation();
    const createInvoiceZipMutation = useCreateInvoiceZipMutation();

    const createQuotesZip = async () => {
        try {
            const result = await createQuoteZipMutation.mutateAsync({
                lastSentToClient: moment(selectedDate).set({"hour": 23, "minute": 59}).zone("+00:00").format(),
            });

            
            // If result isn't an object, then we know that there were no quotes for the selected month
            if (typeof result === 'object') {

                const blob = new Blob([result.data], {type: 'application/zip'});

                const month = moment(selectedDate).set({"hour": 23, "minute": 59}).zone("+00:00").format("MMMM yy");
                saveAs(blob, `${t('quote.exportZip.folderName')}-${month}.zip`);

                toast.current.show({
                    severity: 'success',
                    summary: t('quote.exportZip.title'),
                    detail: t('quote.exportZip.text'),
                    life: 3000
                });

            } else {
                toast.current.show({
                    severity: 'error',
                    summary: t('common.oops'),
                    detail: t('quote.exportZip.empty'),
                    life: 3000
                });
            }

        } catch (err) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: `Erreur ${err}`,
                life: 3000
            });
        }
    };

    const createInvoicesZip = async () => {
        try {
            const result = await createInvoiceZipMutation.mutateAsync({
                lastSentToClient: moment(selectedDate).set({"hour": 23, "minute": 59}).zone("+00:00").format(),
            });

            
            // If result isn't an object, then we know that there were no quotes for the selected month
            if (typeof result === 'object') {

                const blob = new Blob([result.data], {type: 'application/zip'});

                const month = moment(selectedDate).set({"hour": 23, "minute": 59}).zone("+00:00").format("MMMM yy");
                saveAs(blob, `${t('invoice.exportZip.folderName')}-${month}.zip`);

                toast.current.show({
                    severity: 'success',
                    summary: t('invoice.exportZip.title'),
                    detail: t('invoice.exportZip.text'),
                    life: 3000
                });

            } else {
                toast.current.show({
                    severity: 'error',
                    summary: t('common.oops'),
                    detail: t('invoice.exportZip.empty'),
                    life: 3000
                });
            }

        } catch (err) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: `Erreur ${err}`,
                life: 3000
            });
        }
    };

    const [selectedDate, setSelectedDate] = useState();

    return(
        <Card title={t('downloads.title')} className="shadow-3 mt-3">
            <div className="field col-6">
                <Button label={t('common.openFolder')} onClick={exeDownload} className="p-button-warning" loading={isDownloading}/>
            </div>
            <div className="field col-6">
                <Button label={t('common.teamviewer')} onClick={()=> window.open("https://download.teamviewer.com/download/TeamViewerQS.exe", "_blank")} className="p-button-info"/>
            </div>

            <Divider />

            <div className="field col-6">
                <span className='p-float-label'>
                    <Calendar
                        inputId='calendar'
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.value)}
                        dateFormat='MM yy'
                        showIcon
                        showButtonBar
                        view="month"
                    />
                    <label htmlFor='calendar'>
                        {t('common.date')}
                    </label>
                </span>
            </div>
            <div className="field row-1">
                <Button type="submit" label={t('quote.exportZip.title')} onClick={async (e) => { await createQuotesZip()}} disabled={!selectedDate} />
            </div>
            <div className="field row-1">
                <Button type="submit" label={t('invoice.exportZip.title')} onClick={async (e) => { await createInvoicesZip()}} disabled={!selectedDate} />
            </div>
        </Card>
    )
}