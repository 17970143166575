import {Card} from "primereact/card";import {useTranslation} from "react-i18next";
import {useToast} from "../../core/toast.context";
import {ColorPicker} from "primereact/colorpicker";
import {useEffect, useMemo, useState} from "react";
import {Button} from "primereact/button";
import {useOptionQuote, useUpdateOptionQuoteMutation} from "./quote.hook";
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import Loader from "../../core/Loader";
import {PlaceholderQuote} from "../../core/PlaceholderQuote";
import {PlaceholderInvoice} from "../../core/PlaceholderInvoice";


export function QuoteEdit() {

    const {optionQuote, isLoading, isFetching} = useOptionQuote();

    let toast = useToast();

    const {t} = useTranslation();

    const [defaultcolor, setDefaultcolor] = useState('');
    const [section, setSection] = useState(false);
    const [post, setPost] = useState(false);
    const [notes, setNotes] = useState(false);
    const [unitPricePost, setUnitPricePost] = useState(false);
    const [quantityPost, setQuantityPost] = useState(false);
    const [unitPost, setUnitPost] = useState(false);
    const [selectedFont, setSelectedFont] = useState('');
    const [quotePayementCondition, setQuotePayementCondition] = useState('')
    const [invoicePayementCondition, setInvoicePayementCondition] = useState('')
    const [isDirty, setIsDirty] = useState(false)


    const fonts = [
        { name: 'Arial (sans-serif)', value: "font-family: Arial, sans-serif" },
        { name: 'Verdana (sans-serif)', value: "font-family: Verdana, sans-serif" },
        { name: 'Tahoma (sans-serif)', value: "font-family: Tahoma, sans-serif" },
        { name: 'Trebuchet MS (sans-serif)', value: "font-family: Trebuchet MS, sans-serif" },
        { name: 'Times New Roman (serif)', value: "font-family: Times New Roman, serif" },
        { name: 'Georgia (serif)', value: "font-family: Georgia, serif" },
        { name: 'Garamond (serif)', value: "font-family: Garamond, serif" },
        { name: 'Courier New (monospace)', value: "font-family: Courier New, monospace" },
        { name: 'Brush Script MT (cursive)', value: "font-family: Brush Script MT, cursive" }
    ];

    const onFontChange = (e) => {
        setIsDirty(true)
        setSelectedFont(e.value);
    }

    const updateMutation = useUpdateOptionQuoteMutation();
    const onSubmit = async () => {

        try {
            await updateMutation.mutateAsync({
                font: `${selectedFont}; font-size: 14px;`,
                color: defaultcolor,
                section: section? true : false,
                post: post? true : false,
                notes: notes ? true : false,
                unitPricePost: unitPricePost ? true : false,
                quantityPost: quantityPost ? true : false,
                unitPost: unitPost ? true : false,
                quotePayementCondition: quotePayementCondition,
                invoicePayementCondition: invoicePayementCondition
            });
            setIsDirty(false)
            toast.current.show({severity: 'success', summary: t('quote.optionPdf.updated.title'), detail: t('quote.optionPdf.updated.text'), life: 3000});
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('quote.optionPdf.updated.problem') + " " + err, life: 3000});
        }
    };

    useMemo(() => {
        setDefaultcolor(optionQuote?.color)
        setSection(optionQuote?.section);
        setPost(optionQuote?.post);
        setNotes(optionQuote?.notes);
        setUnitPricePost(optionQuote?.unitPricePost);
        setQuantityPost(optionQuote?.quantityPost);
        setUnitPost(optionQuote?.unitPost);
        setSelectedFont(optionQuote?.font?.split(';')[0]);
        setQuotePayementCondition(optionQuote?.quotePayementCondition);
        setInvoicePayementCondition(optionQuote?.invoicePayementCondition);
    }, [optionQuote])
    return (
        <>
            <Card className="p-card md:col-12 sm:col-12" title={t('quote.optionPdf.title')}>
                {isFetching || isLoading ? <Loader/> :
                    <div className="grid">
                        <div className="col-4 align-items-center justify-content-center">
                            <div>
                                <h4>{t("quote.optionPdf.color")}</h4>
                                <ColorPicker value={defaultcolor} onChange={(e) => {
                                    setIsDirty(true);
                                    setDefaultcolor(`#${e.value}`)
                                }}
                                             inline/>
                                <h6>{t("quote.optionPdf.hex")}</h6>
                                <input id="input" value={defaultcolor || ''}
                                       onChange={(e) => {
                                           setIsDirty(true);
                                           setDefaultcolor(e.target.value)
                                       }}/>
                            </div>
                        </div>
                        <div className="col-4 align-items-center justify-content-center">
                            <h4>{t("quote.optionPdf.option")}</h4>
                            <div className="field-checkbox">
                                <Checkbox inputId="section" checked={section}
                                          onChange={e => {
                                              setIsDirty(true);
                                              setSection(e.checked)
                                          }}/>
                                <label htmlFor="section">{t("quote.optionPdf.section")}</label>
                            </div>
                            <div className="field-checkbox">
                                <Checkbox inputId="post" checked={post}
                                          onChange={e => {
                                              setIsDirty(true);
                                              setPost(e.checked)
                                          }}/>
                                <label htmlFor="post">{t("quote.optionPdf.post")}</label>
                            </div>
                            <div className="field-checkbox">
                                <Checkbox inputId="notes" checked={notes}
                                          onChange={e => {
                                              setIsDirty(true);
                                              setNotes(e.checked)
                                          }}/>
                                <label htmlFor="notes">{t("quote.optionPdf.notes")}</label>
                            </div>
                            <div className="field-checkbox">
                                <Checkbox inputId="unitPricePost" checked={unitPricePost}
                                          onChange={e => {
                                              setIsDirty(true);
                                              setUnitPricePost(e.checked)
                                          }}/>
                                <label htmlFor="unitPricePost">{t("quote.optionPdf.postPrice")}</label>
                            </div>
                            <div className="field-checkbox">
                                <Checkbox inputId="quantityPost" checked={quantityPost}
                                          onChange={e => {
                                              setIsDirty(true);
                                              setQuantityPost(e.checked)
                                          }}/>
                                <label htmlFor="quantityPost">{t("quote.optionPdf.postAmount")}</label>
                            </div>
                            <div className="field-checkbox">
                                <Checkbox inputId="unitPost" checked={unitPost}
                                          onChange={e => {
                                              setIsDirty(true);
                                              setUnitPost(e.checked)
                                          }}/>
                                <label htmlFor="unitPost">{t("quote.optionPdf.postUnit")}</label>
                            </div>
                        </div>
                        <div className="col-4 align-items-center justify-content-center">
                            <h4>{t("quote.optionPdf.font")}</h4>
                            <Dropdown value={selectedFont} options={fonts} optionLabel={"name"}
                                      optionValue={"value"} onChange={onFontChange}/>
                        </div>
                        <div className="field col-12">
                            <h4>{t("quote.optionPdf.quotePaymentCondition")}</h4>
                            <PlaceholderQuote/>
                            <InputTextarea autoResize id="quotePayementCondition" value={quotePayementCondition}
                                           onChange={(e) => {
                                               setIsDirty(true);
                                               setQuotePayementCondition(e.target.value)
                                           }} rows={5} style={{width: '100%'}}/>
                        </div>
                        <div className="field col-12">
                            <h4>{t("quote.optionPdf.invoicePaymentCondition")}</h4>
                            <PlaceholderInvoice/>
                            <InputTextarea autoResize id="invoicePayementCondition" value={invoicePayementCondition}
                                           onChange={(e) => {
                                               setIsDirty(true);
                                               setInvoicePayementCondition(e.target.value)
                                           }} rows={5} style={{width: '100%'}}/>
                        </div>

                    <Button type="submit" label={t('common.edit')} disabled={!isDirty} onClick={onSubmit}/>
                </div>
                }
            </Card>
        </>
    );
}