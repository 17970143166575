import {Card} from "primereact/card";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useToast} from "../core/toast.context";
import {organisationScheme} from "./organisation.scheme";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCreateOrganisationMutation} from "./organisations.hooks";
import {useTranslation} from "react-i18next";
import {TopMenu} from "../core/TopMenu";
import {OrganisationForm} from "./organisation.form";
import moment from "moment";

export function CreateOrganisationsPage() {

    const navigate = useNavigate();
    let toast = useToast();

    const scheme = organisationScheme();

    const {control, handleSubmit} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const createMutation = useCreateOrganisationMutation();

    const {t} = useTranslation();

    const onSubmit = async (data) => {
        const dto = {
            ...data,
            deletedAt: data.deletedAt ? moment(data.deletedAt).set({"hour": 23, "minute": 59}).zone("+00:00").format() : null
        };

        try {
            const newOrganisation = await createMutation.mutateAsync(dto);
            toast.current.show({severity: 'success', summary: t('organisation.created.title'), detail: t('organisation.created.text'), life: 3000});
            navigate('/organisations/'+newOrganisation.id);
        } catch (err) {
            // console.dir(err);
            //
            // if(err?.response?.status === 400)
            // {
            //     if(err?.response?.data?.violations !== null) {
            //         for (let i = 0; i < err.response.data.violations.length; i++) {
            //             const violation = err.response.data.violations[i];
            //             setError(violation.propertyPath, {
            //                 type: 'server',
            //                 message: violation.title,
            //                 parameters: violation.parameters
            //             });
            //         }
            //
            //         return;
            //     }
            // }

            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('organisation.created.problem')+' '+err, life: 3000});
        }
    };

    return (

        <div>
            <TopMenu moduleName={t('organisation.title')} list={'/organisations'}/>

            <Card title={t('organisation.create')} className="shadow-3 mt-3">
                <OrganisationForm control={control} onSubmit={handleSubmit(onSubmit)} submitLabel={t('common.create')} isLoading={createMutation.isLoading} />
            </Card>
        </div>
    )
}