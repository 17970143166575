import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {ProgressBar} from "primereact/progressbar";
import {useEffect} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import {useEmail, useUpdateEmailMutation} from "./emails.hooks";
import {emailScheme} from "./emails.scheme";
import {EditorControl} from "../core/ControlledInputs/EditorControl";
import {PlaceholderQuote} from "../core/PlaceholderQuote";
import {PlaceholderInvoice} from "../core/PlaceholderInvoice";
import Loader from "../core/Loader";

export function EditEmailPage() {

    const scheme = emailScheme();

    const {control, formState: {isDirty}, handleSubmit, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const navigate = useNavigate();

    let params = useParams();

    const {t} = useTranslation();

    const {email, isLoading, isFetching} = useEmail(params.id);

    useEffect(() => {
        if(!isDirty) {
            reset(email);
        }
    }, [isDirty, reset, email]);

    const updateMutation = useUpdateEmailMutation();

    const toast = useToast();

    const onSubmit = async (data) => {
        try {
            const dto = {
                ...data
            };

            await updateMutation.mutateAsync({
                id: email.id,
                data: dto
            });

            toast.current.show({severity: 'success', summary: t('email.updated.title'), detail: t('email.updated.text'), life: 3000});
            navigate('/settings/emails');
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('email.updated.problem') + " " + err, life: 3000});
        }
    };

    return (
        <div>
            <TopMenu moduleName={t('email.title')} create={'/settings/emails/create/'} list={'/settings/emails'}/>
                <Card title={t('email.edit')} className="shadow-3 mt-3">
                    {isLoading || isFetching ? <Loader/> :
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                            <div className="field col-12">
                                <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus/>
                            </div>
                            <div className="field col-12">
                                <TextControl control={control} fieldName={'subject'} label={t('email.subject')}/>
                            </div>
                            <div className="field col-12">
                                <PlaceholderQuote/>
                                <PlaceholderInvoice/>
                            </div>
                            <div className="field col-12">
                                <EditorControl control={control} fieldName={'body'} label={t('email.body')}/>
                            </div>
                            <Button type="submit" label={t('common.edit')}
                                    loading={updateMutation.isLoading}/>
                        </form>
                    }
                </Card>
        </div>
    )
}
