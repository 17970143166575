import {Route, Routes} from "react-router-dom";
import {CreateCategoryPage} from "./categories-create.page";
import {EditCategoryPage} from "./categories-edit.page";
import {CategoryList} from "./category-list";

export function Categories() {
    return <Routes>

        <Route path=":id" element={<EditCategoryPage />} />
        <Route path="create" element={<CreateCategoryPage />} />
        <Route path="*" element={<CategoryList />} />

    </Routes>

}