import originService from "./origin.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export function useOrigins() {
    const {data: origins, isFetched, isLoading, isError} = useQuery(['origins'], originService.all, {
        initialData: []
    });

    return {
        origins,
        isLoading,
        isFetched,
        isError
    };
}

export function useOrigin(id, options = null) {
    const {data: origin, isLoading, isError} = useQuery(['origins', id], ({queryKey}) => originService.get(queryKey[1]), options);

    return {
        origin, isLoading, isError
    };
}

export function useUpdateOriginMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => originService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['origins']);
        }
    });

    return mutation;
}


export function useCreateOriginMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(originService.create, {
        onSuccess: (data) => {
            const origins = queryClient.getQueryData(['origins']) ?? [];
            queryClient.setQueryData(['origins'], [...origins, data]);
        }
    });

    return mutation;
}

export function useDeleteOriginMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(originService.delete, {
        onSuccess: (data, variables) => {
            const origins = queryClient.getQueryData(['origins']) ?? [];
            queryClient.setQueryData(['origins'], origins.filter(x => x.id !== variables))
        }
    });

    return mutation;
}