import api from "../services/api";

class QuotesService {

    all() {
        return api.get('api/quotes')
            .then(function (x) {
                return x.data;
            });
    }

    delete(id) {
        return api.delete('api/quotes/'+id);
    }

    get(id) {
        return api.get('api/quotes/'+id)
            .then(x => x.data);
    }

    update(id, data) {
        return api.post('api/quotes/'+id, JSON.stringify(data))
            .then(x => x.data);
    }

    create(data) {
        return api.post('api/quotes', JSON.stringify(data))
            .then(x => x.data);
    }

    async getPdf(id, watermark = true) {
        return api.get('api/quotes/'+id+'/pdf/'+watermark, {
            responseType: 'blob'
        })
            .then(response => {
                const blob = new Blob([response.data], {type: 'application/pdf'});

                return blob;
            })
    }

    async sendToClient(id,data,files) {
        const form = new FormData();
        form.append("request",JSON.stringify(data));
        
        if(files?.length) {
            Object.entries(files).map(([key, file]) => {
                form.append(key, file, file.name);
            })
        }

        return api.post('api/quotes/'+id+'/send-to-client', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).catch(e => {throw e});
    }

    async generatePdf(id) {
        return api.get('api/quotes/'+id+'/generate-pdf', {
            responseType: 'blob'
        })
            .then(response => {
                const blob = new Blob([response.data], {type: 'application/pdf'});

                return blob;
            })
    }

    async newVersion(id, empty) {
        return api.post('api/quotes/'+id+'/new-version', {empty})
            .then(x => x.data);
    }

    allTemplates() {
        return api.get('api/quote-templates')
            .then(function (x) {
                return x.data;
            });
    }

    createTemplate(data) {
        return api.post('api/quote-templates', JSON.stringify(data))
            .then(x => x.data);
    }

    deleteTemplate(id) {
        return api.delete('api/quote-templates/'+id);
    }

    setStatus(id, data) {
        return api.post('api/quotes/'+id+'/status', JSON.stringify(data))
    }

    getByClient(id) {
        return api.get('api/quotes/client/'+id)
            .then(x => x.data);
    }

    addDwg(id) {
        return api.post('api/quotes/'+id+'/dwg')
            .then(x => x.data);
    }

    print(id) {
        return api.post('api/quotes/'+id+'/print')
            .then(x => x.data);
    }

    addComment(id, data) {
        return api.post('api/quotes/'+id+'/comments', JSON.stringify(data))
            .then(x => x.data);
    }

    createZip(data) {
        return api.post('api/quotes/zip', JSON.stringify(data), {
            responseType: 'blob'
        })
            .then(response => {
                if (response.status === 204) {
                    return "NO RESULT";
                } else {
                    return response;
                }
            });
    }
}

export default new QuotesService();
