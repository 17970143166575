import {useState} from "react";
import {useToast} from "../../core/toast.context";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {FileUpload} from "primereact/fileupload";
import organisationsService from "../organisations.service";


export function LogosEdit() {

    const {t} = useTranslation();
    let toast = useToast();

    const uploadLogo = async (event) => {

        try {

            await organisationsService.uploadLogo(event.files[0]);

            toast.current.show({
                severity: 'success',
                summary: t('logos.uploaded.title'),
                detail: t('logos.uploaded.text'),
                life: 3000
            });
        } catch (e) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: t('logos.uploaded.problem'),
                life: 3000
            });
        }
    };

    const chooseOptions = {label: t('common.choose'), icon: 'pi pi-fw pi-plus'};
    const uploadOptions = {label: t('common.upload'), icon: 'pi pi-upload', className: 'p-button-success'};

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{
                    'fontSize': '2em',
                    borderRadius: '50%',
                    backgroundColor: 'var(--surface-b)',
                    color: 'var(--surface-d)'
                }}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">{t('logos.empty')}</span>
            </div>
        )
    }

    const [isDownloading, setIsDownloading] = useState(false);
    const download = async () => {
        setIsDownloading(true);
        try {
            await organisationsService.downloadLogo();

            toast.current.show({
                severity: 'success',
                summary: t('logos.downloaded.title'),
                detail: t('logos.downloaded.text'),
                life: 3000
            });

        } catch (e) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: t('logos.downloaded.problem'),
                life: 3000
            });
        }
        setIsDownloading(false);
    };

    const headerTemplate = (options) => {
        const {className, chooseButton, uploadButton} = options;

        return (
            <div className={className}>
                {chooseButton}
                {uploadButton}
                <Button onClick={download} label={t('common.download')} icon={'pi pi-download'} className={"p-button-info"} loading={isDownloading} />
            </div>
        );
    }

    return (
        <>
            <Card className="p-card col-12" title={t('logos.title')}>
                <FileUpload name="logo" emptyTemplate={emptyTemplate} accept=".png"
                            chooseOptions={chooseOptions} uploadOptions={uploadOptions}
                            customUpload uploadHandler={uploadLogo} headerTemplate={headerTemplate}/>
            </Card>
        </>
    );
}