export function ColorLegend({ colors }) {
    return (
        <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {colors.map(color => {
                    return (
                        <div key={color.code} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', paddingBottom: "5px"}}>
                            <div style={{ backgroundColor: color.code, width: '20px', height: '20px', borderRadius: '50%', marginRight: "8px" }}>
                            </div>
                            <div>
                                {color.label}
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    );
}
