import {Card} from "primereact/card";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCreateMaterialMutation} from "./materials.hooks";
import Decimal from "decimal.js";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {DropdownControl} from "../core/ControlledInputs/DropDownControl";
import {CurrencyControl} from "../core/ControlledInputs/CurrencyControl";
import {vats} from "../core/vats";
import {TextAreaControl} from "../core/ControlledInputs/TextAreaControl";
import {materialScheme} from "./material.scheme";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {useTranslation} from "react-i18next";
import {useUnits} from "../units/units.hooks";
import {useProviders} from "../providers/providers.hooks";
import {useCategories} from "../categories/categories.hooks";

export function CreateMaterialsPage() {

    const navigate = useNavigate();
    let toast = useToast();

    const scheme = materialScheme();

    const {units, isLoading: isLoadingUnits} = useUnits();
    const {providers, isLoading: isLoadingProviders} = useProviders();
    const {categories, isLoading: isLoadingCategories} = useCategories();

    const {control, handleSubmit} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const createMutation = useCreateMaterialMutation();

    const {t} = useTranslation();

    const onSubmit = async (data) => {

        const dto = {
            ...data,
            price: new Decimal(data.price).toFixed(2),
            tva: new Decimal(data.tva).toFixed(2)
        };

        try {
            const newMaterial = await createMutation.mutateAsync(dto);
            toast.current.show({severity: 'success', summary: t('material.created.title'), detail: t('material.created.text'), life: 3000});
            navigate('/materials/'+newMaterial.id);
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('material.created.problem')+' '+err, life: 3000});
        }
    };

    return (

        <div>
            <TopMenu moduleName={t('material.title')} list={'/materials/'}/>

            <Card title={t('material.create')} className="shadow-3 mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                    </div>

                    <div className="field col-12">
                        <DropdownControl control={control} fieldName={'unitId'} label={t('common.unit')} loading={isLoadingUnits} options={units} optionLabel={"name"} optionValue={"id"} />
                    </div>

                    <div className="field col-12">
                        <CurrencyControl control={control} fieldName={"price"} label={t('common.price')} />
                    </div>

                    <div className="field col-12">
                        <DropdownControl control={control} fieldName={"tva"} label={t('common.vat')} options={vats} optionValue={"value"} optionLabel={"name"} />
                    </div>

                    <div className="field col-12">
                        <DropdownControl control={control} fieldName={'providerId'} label={t('common.provider')} loading={isLoadingProviders} options={providers} optionLabel={"name"} optionValue={"id"} />
                    </div>

                    <div className="field col-12">
                        <DropdownControl control={control} fieldName={'categoryId'} label={t('common.section')} loading={isLoadingCategories} options={categories} optionLabel={"name"} optionValue={"id"} />
                    </div>

                    <div className="field col-12">
                        <TextAreaControl control={control} fieldName={"note"} label={t('common.note')} maxLength={1000} />
                    </div>

                    <Button type="submit" label={t('common.create')} loading={createMutation.isLoading}/>
                </form>
            </Card>
        </div>
    )
}