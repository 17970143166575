import {Route, Routes} from "react-router-dom";
import {ClientsListPage} from "./clients-list.page";
import {CreateClientPage} from "./clients-create.page";
import {EditClientPage} from "./clients-edit.page";


export function Clients() {
    return <Routes>
        <Route path="/create" element={<CreateClientPage />} />
        <Route path=":id/*" element={<EditClientPage />} />
        <Route path="*" element={<ClientsListPage />} />
    </Routes>
}