
import * as yup from 'yup';

export const emailScheme = () => yup.object({
    name: yup.string()
        .min(2)
        .max(100)
        .required()
        .default(''),
    subject: yup.string()
        .min(2)
        .max(100)
        .required()
        .default(''),
    body: yup.string()
        .min(2)
        .max(2000)
        .required()
        .default('')
});