import {action, makeObservable, observable} from "mobx";
import {v4 as uuidv4} from "uuid";

export class MultipleMaterialLine {
    id;
    description = "";
    type = 'multipleMaterial';
    parent;

    static create(parent) {
        return new MultipleMaterialLine(uuidv4(), "", parent);
    }


    constructor(id, description, parent) {
        this.id = id;
        this.description = description
        this.parent = parent

        makeObservable(this, {
            description: observable,
            removeLine: action,
            updateDescription: action,
        })
    }

    updateDescription(description, materials) {
        // Create line for each material
        // delete MultipleMaterialLine
        const elements = description.split('\n')

        elements.forEach(elem => {
            if (elem !== '') { // Avoid adding a new material when the description is only a newline char (empty string in js)
                const materialFound = materials.find(mat => mat.name === elem);
                const materialCreated = this.parent.addMaterial(materialFound ?? elem);
                materialFound && materialCreated.updateFromMaterial(materialFound);
            }
        });

        this.parent.removeMultipleMaterialLine(this);
    }

    removeLine() {
        this.parent.removeMultipleMaterialLine(this);
    }

    updateFromForm(data) {

        if (this.description !== data.description) {
            this.description = data.description
        }
    }

    get valid() {
        return true
    }

    updateFromMaterial(material) {
        this.description = material.name;
    }

    get dto() {
        return {
            id: this.id,
            type: this.type,
            description: this.description,
        }
    }

}
