import {Card} from "primereact/card";
import {useCallback, useEffect, useMemo, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {Quote} from "../models/Quote";
import {ProvideQuote, useQuoteContext} from "../quote.context";
import {QuoteInfoForm} from "../components/quote-info.form";
import {Divider} from "primereact/divider";
import {QuoteForm} from "../components/quote.form";
import moment from "moment/moment";
import {useCreateQuoteMutation, useQuoteTemplates} from "../quote.hooks";
import {observer} from "mobx-react";
import {Button} from "primereact/button";
import {useToast} from "../../core/toast.context";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {TopMenu} from "../../core/TopMenu";
import quotesService from "../quotes.service";
import {ProgressSpinner} from "primereact/progressspinner";
import {Checkbox} from "primereact/checkbox";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import quoteTemplateService from '../../settings/quote/templates/quote.template.service';


export function QuotesCreatePage() {

    const [searchParams] = useSearchParams();
    const navigate=useNavigate();
    const {pathname} = useLocation()

    const [isLoading,setIsLoading]=useState(true);
    const [visible, setVisible] = useState(true);

    const [quote,setQuote]=useState(null);
    
    const [selectedTemplate,setSelectedTemplate]=useState(null);
    const {quoteTemplates, isLoading:isLoadingTemplate} = useQuoteTemplates();

    const toast = useToast();

    const {t} = useTranslation();

    const startNewQuote = () => {
        setQuote(new Quote(uuidv4(), [], null, null, moment().toDate(), moment().add(1, 'month').toDate()));
        setIsLoading(false);
    };

    useEffect(() => {
        window.addEventListener('beforeunload',alertUser);
        if(pathname==="/quotes/create/") {
            window.history.pushState(null,document.title,window.location.href);
            window.addEventListener('popstate', popFunction);
        }     
		return () => {
			window.removeEventListener('beforeunload', alertUser);
			window.removeEventListener('popstate', popFunction);
		};
	}, []);
	const alertUser = (e) => {
		e.preventDefault();
		e.returnValue = '';
    };
    
    const popFunction = (event) => {
			event.preventDefault();
			const answer = window.confirm(t(`common.leavePage`));
			if (!answer) {
				window.history.pushState(null, document.title, window.location.href);
			} else {
				navigate('/quotes/');
			}
		};

    useEffect(() => {
        if(searchParams.has("quoteId")) {
            quotesService
                .get(searchParams.get("quoteId"))
                .then(dto => {
                    setQuote(Quote.fromDto(uuidv4(), dto.lines, null, null, moment().toDate(), moment().add(1, 'month').toDate()));
                    setIsLoading(false);
                })
                .catch(e => {
                    toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('common.notFound'), life: 3000});
                    navigate("/quotes");
                });
        } else if(!selectedTemplate&&!visible) {
            startNewQuote();
        } else if(selectedTemplate&&!visible) {
                quoteTemplateService.get(selectedTemplate?.id)
                .then(dto => {
                    setQuote(Quote.fromDto(uuidv4(), dto.lines, null, null, moment().toDate(), moment().add(1, 'month').toDate()));
                    setIsLoading(false);
                })
                .catch(e => {
                    toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('common.notFound'), life: 3000});
                    navigate("/quotes");
                });
        }
    },[searchParams,setQuote,toast,navigate,t,visible, selectedTemplate]);
    
    
		const footerContent = (
			<div>
				<Button label={t(`quote.template.continueWithout`)} icon='pi' onClick={() => {setVisible(false); setSelectedTemplate(null)}} className='p-button-text p-button-info' />
				<Button label={t(`quote.template.continue`)} icon='pi' disabled={!selectedTemplate} onClick={() => setVisible(false)} autoFocus />
			</div>
		);

    if(isLoading) {
        return (
            <Card className="ml-auto mr-auto mt-2 text-center" style={{maxWidth: '1400px'}}>
                <ProgressSpinner />
                <ConfirmDialog />
                <Dialog header={t('quote.template.choice')} visible={visible} style={{ width: '50vw', height:'400px' }} closable={false} footer={footerContent}>
                    
                <Dropdown placeholder={t('quote.template.title')}
                        options={quoteTemplates}
                        className="col-12"
                              optionLabel={'name'}
                              value={selectedTemplate}
                              disabled={isLoadingTemplate}
                              dropdownIcon={isLoadingTemplate ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'}
                              onChange={(e) => {
                                  setSelectedTemplate(e.value)
                              }
                        } />
            </Dialog>

            </Card>
        )
    }

    return (<ProvideQuote devi={quote}>
        <CreatePage quote={quote}/>
    </ProvideQuote>)
}

const CreatePage = observer(({quote}) => {

    const createMutation = useCreateQuoteMutation();

    const toast = useToast();

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [createDwg, useCreateDwg] = useState(false);

    const removeMultipleMaterialLinesFromQuote = (quote) => {
        const processLines = (lines) => {
            return lines.reduce((result, line) => {
                if (line.type === 'section') {
                    const sectionLines = {...line, lines: processLines(line.lines)};
                    result.push(sectionLines);
                } else if (line.type === 'post') {
                    const postLines = {...line, lines: line.lines.filter(postLine => postLine.type !== "multipleMaterial")};
                    result.push(postLines);
                } else if (line.type === 'material' && line.type !== 'multipleMaterial') {
                    result.push(line);
                }
    
                return result;
            }, []);
        };
    
        return {...quote, lines: processLines(quote.lines)};
    };

    const submitForm = async () => {
        const dto = quote.dto;
        dto.createBasePlan = createDwg;

        const filteredDTO = removeMultipleMaterialLinesFromQuote(dto)
        try {
            const newQuote = await createMutation.mutateAsync(filteredDTO);
            toast.current.show({
                severity: 'success',
                summary: t('quote.created.title'),
                detail: t('quote.created.text'),
                life: 3000
            });
            localStorage.removeItem("archigest_create_quote");
            navigate('/quotes/' + newQuote.id);
        } catch (err) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: t('quote.created.problem') + ' ' + err,
                life: 3000
            });
        }
    };

    const quoteModel = useQuoteContext();

    useEffect(() => {
        const interval = setInterval(() => {
            const json = JSON.stringify(quote.dto);
            localStorage.setItem("archigest_create_quote", json);
        }, 3000);

        return () => clearInterval(interval);
        }
    , [quote])

    const handleKeyDown = useCallback((event) => {
        // Check for ALT + s
        if (event.altKey && event.key === "s") {
            event.preventDefault(); // Prevent the default browser behavior
            quote.addSection();
        }

        // Check for ALT + p
        if (event.altKey && event.key === "p") {
            event.preventDefault();
            quote.addPost();
        }

        // Check for ALT + m
        if (event.altKey && event.key === "m") {
            event.preventDefault();
            quote.addMaterial();
        }

        // Check for ALT + d
        if (event.altKey && event.key === "d") {
            event.preventDefault();
            quote.addMultipleMaterial();
        }
      }, [quote]);

    // EventListener for capturing Keyboard shortcuts to perform some actionsS
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
    
        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }, [handleKeyDown]);

    return (
        <div>
            <TopMenu moduleName={t('quote.title')} list={'/quotes/'}/>

            <Card className="ml-auto mr-auto mt-2 xl:p-8 sm:p-3" style={{maxWidth: '1400px'}}>
                <QuoteInfoForm quote={quote}/>

                <Divider className={'mt-0'}/>

                <QuoteForm quote={quote}/>

                <div className="grid grid-nogutter">
                    <div className="col justify-content-center">
                        <Button type={"button"} label={t('common.create')}
                                disabled={!quote.valid || !quoteModel.isValid || createMutation.isLoading}
                                loading={createMutation.isLoading} onClick={submitForm}/>
                    </div>
                    <div className="col field-checkbox">
                        <Checkbox inputId="binary" checked={createDwg} onChange={e => useCreateDwg(e.checked)} />
                        <label htmlFor="binary">{t('quote.dwg.add')}</label>
                    </div>
                    <div className="col-8">

                    </div>
                </div>
            </Card>
        </div>
    )
});
