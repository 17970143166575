import {Route, Routes} from "react-router-dom";
import {OrganisationsListPage} from "./organisations-list.page"
import {CreateOrganisationsPage} from "./organisations-create.page"
import {EditOrganisationsPage} from "./organisations-edit.page"


export function Organisations() {
    return <Routes>

        <Route path="create" element={<CreateOrganisationsPage/>}/>
        <Route path=":id" element={<EditOrganisationsPage/>}/>
        <Route path="*" element={<OrganisationsListPage/>}/>

    </Routes>

}