import {Route, Routes} from 'react-router-dom';
import {InvoiceTemplateList} from './invoice.template-list';
import {CreateInvoiceTemplatePage} from './invoice.template-create.page';
import {EditInvoiceTemplatePage} from './invoice.template-edit.page';

export function InvoiceTemplates() {
	return (
		<Routes>
			<Route path=':id' element={<EditInvoiceTemplatePage />} />
			<Route path='/create' element={<CreateInvoiceTemplatePage />} />
			<Route path='*' element={<InvoiceTemplateList />} />
		</Routes>
	);
}
