import {Route, Routes} from "react-router-dom";
import {LogosEdit} from "./logos.edit";

export function Logo() {
    return <Routes>

        <Route path="*" element={<LogosEdit />} />

    </Routes>

}