import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FilterMatchMode} from "primereact/api";
import {Button} from "primereact/button";
import {HasRole} from "../auth/has-role";
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useDeleteSectionMutation, useSections} from "./sections.hooks";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {useTranslation} from "react-i18next";
import {ConfirmDelete} from "../core/ConfirmDelete";
import {Skeleton} from "primereact/skeleton";
import {QuoteStatus} from "../core/QuoteStatus";
import {DateTime} from "../core/DateTime";

export function SectionsListPage(client = null) {
    const [section, setSection] = useState(null);
    const {sections, isLoading, isFetched} = useSections();

    const deleteMutation = useDeleteSectionMutation();

    let navigate = useNavigate();
    let toast = useToast();

    const {t} = useTranslation();

    const [filters] = useState({
        //'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS,  }
    });

    const actionTemplate = (section) => {
        return (
            <Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text m-0" onClick={() => navigate("/sections/"+section.id)} />
                <HasRole role="ROLE_ADMIN">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text m-0" onClick={() => setSection(section)}/>
                </HasRole>
            </Fragment>
        )
    };

    const deleteSection = () => {

        deleteMutation.mutateAsync(section.id)
            .then(() => {
                toast.current.show({severity: 'success', summary: t('section.deleted.title'), detail: t('section.deleted.text'), life: 3000});
            })
            .catch((err) => {
                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('section.deleted.problem') + " " + err, life: 3000});
            })
            .finally(() => {
                setSection(null);
            })
    };

    const items = Array.from({length: 10}, (v, i) => i);

    const skeletonTemplate = () => {
        return <Skeleton width={"80%"}/>;
    };

    return (
        <div>
            {!client.client && <TopMenu moduleName={t('section.title')} create={'/sections/create/'} />}
            <Card className='shadow-3 mt-3' title={t('section.list')}>
                <DataTable
                    value={isLoading || !isFetched ? items : client.client ? sections.filter((x) => x.client.id === client.client) : sections}
                    paginator
                    sortMode='multiple'
                    filterDisplay='row'
                    filters={filters}
                    dataKey='id'
                    stripedRows
                    selectionMode='single'
                    emptyMessage={t('section.emptyList')}
                    currentPageReportTemplate={t('datatable.reportTemplate')}
                    rows={25}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    rowHover
                    responsiveLayout='scroll'
                    columnResizeMode='expand'
                    size={'small'}
                    cellSelection
                    onCellSelect={(e) => navigate('/sections/'+e.rowData.id)}>
                    <Column
                        field='name'
                        header={t('common.name')}
                        sortable
                        filter
                        filterPlaceholder={t('common.name')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.name)}
                    />
                    <Column body={isLoading || !isFetched ? skeletonTemplate : actionTemplate} className='p-0' style={{minWidth: '200px'}} />
                </DataTable>
            </Card>

            <ConfirmDelete name={section?.name} loading={deleteMutation.isLoading} visible={section} onHide={() => setSection(null)} onConfirmed={deleteSection} />
        </div>
    );
}