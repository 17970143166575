import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import authService from "../services/auth.service";
import {Dialog} from "primereact/dialog";
import {Card} from "primereact/card";
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import {Divider} from "primereact/divider";
import {CustomLink} from "../core/CustomLink";
import {Password} from "primereact/password";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";


export function ChoosePasswordPage() {
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [message, setMessage] = useState('');

    const defaultValues = {
        password: ''
    };

    const { control, setError, formState: { errors }, handleSubmit} = useForm({ defaultValues });

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            await authService.setPassword(params.token, data.password);
            setMessage(t('auth.passwordHasBeenSet'));
            setShowDialog(true);
            navigate('/login');
        }
        catch(err) {
            if(err.response.status === 400) {
                const data = err.response.data;

                let groupedErrors = {};
                for (let i = 0; i < data.violations.length; i++) {
                    let violation = data.violations[i];

                    if(!groupedErrors[violation.propertyPath]) {
                        groupedErrors[violation.propertyPath] = [];
                    }

                    groupedErrors[violation.propertyPath].push(violation.title);
                }

                if(groupedErrors.token) {
                    setMessage(t('auth.invalidLink'));
                    setShowDialog(true);
                    return;
                }

                for (let groupedErrorsKey in groupedErrors) {
                    let errors = groupedErrors[groupedErrorsKey].join(', ');
                    setError(groupedErrorsKey, {message: errors});
                }

                if(groupedErrors.token) {
                    setMessage(t('auth.invalidLink'));
                    setShowDialog(true);
                }

                return;
            }
            setMessage(t('auth.couldNotReset'));
            setShowDialog(true);
        }
        finally {
            setLoading(false);
        }
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const [showFeedBack, setShowFeedBack] = useState(false);

    return (
        <div className="h-screen flex" style={{background: '#DF8009'}}>
            <Dialog header="Password" visible={showDialog}  style={{ width: '40vw' }} onHide={() => setShowDialog(false)}>
                <p>{message}</p>
            </Dialog>

            <Card title={t('auth.choosePassword')} className="px-6 shadow-3 col-4 col-offset-4 my-auto">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                    <div className="field mt-5">
                            <span className="p-float-label">
                                <Controller name="password" control={control} rules={{ required: t('auth.passwordRequired'), minLength: {value: 8, message: t('auth.passwordLength')} }} render={({ field, fieldState }) => (
                                    <Password autoFocus id={field.name} {...field} toggleMask feedback={showFeedBack} className={classNames({ 'p-invalid': !!fieldState.error })} onFocus={() => {
                                        setShowFeedBack(true)
                                    }
                                    } />
                                )} />
                                <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Password</label>
                            </span>
                        {getFormErrorMessage('password')}
                    </div>
                    <Button type="submit" label={t('auth.setPassword')} loading={loading} />
                </form>
                <Divider />
                <div className="text-right">
                    <CustomLink to="/login" label={t('auth.backToLogin')} />
                </div>
            </Card>
        </div>
    );
}