import './App.css';
import {ProvideAuth} from "./auth/auth.context";
import {Routes, Route} from "react-router-dom";
import {RequireNoAuth} from "./auth/RequireNoAuth";
import {RequireAuth} from "./auth/RequireAuth";
import {LoginPage} from "./auth/login.page";
import {ForgotPasswordPage} from "./auth/forgot-password.page";
import {Layout} from "./Layout";
import {ChoosePasswordPage} from "./auth/choose-password.page";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import React, {useEffect, useRef, useState} from 'react';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Toast} from "primereact/toast";

//other themes
// import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
// import "primereact/resources/themes/bootstrap4-light-purple/theme.css";
// import "primereact/resources/themes/bootstrap4-dark-blue/theme.css";
// import "primereact/resources/themes/bootstrap4-dark-purple/theme.css";
//  import "primereact/resources/themes/md-light-indigo/theme.css";
//  import "primereact/resources/themes/md-light-deeppurple/theme.css";
// import "primereact/resources/themes/md-dark-indigo/theme.css";
// import "primereact/resources/themes/md-dark-deeppurple/theme.css";
// import "primereact/resources/themes/mdc-light-indigo/theme.css";
// import "primereact/resources/themes/mdc-light-deeppurple/theme.css";
// import "primereact/resources/themes/mdc-dark-indigo/theme.css";
// import "primereact/resources/themes/mdc-dark-deeppurple/theme.css";
// import "primereact/resources/themes/tailwind-light/theme.css";
// import "primereact/resources/themes/fluent-light/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-purple/theme.css";
// import "primereact/resources/themes/lara-light-teal/theme.css";
// import "primereact/resources/themes/lara-dark-blue/theme.css";
// import "primereact/resources/themes/lara-dark-indigo/theme.css";
// import "primereact/resources/themes/lara-dark-purple/theme.css";
// import "primereact/resources/themes/lara-dark-teal/theme.css";
// import "primereact/resources/themes/saga-blue/theme.css";
//import "primereact/resources/themes/saga-orange/theme.css";
// import "primereact/resources/themes/saga-purple/theme.css";
// import "primereact/resources/themes/vela-blue/theme.css";
// import "primereact/resources/themes/vela-green/theme.css";
// import "primereact/resources/themes/vela-orange/theme.css";
// import "primereact/resources/themes/vela-purple/theme.css";
// import "primereact/resources/themes/arya-blue/theme.css";
// import "primereact/resources/themes/arya-green/theme.css";
// import "primereact/resources/themes/arya-orange/theme.css";
// import "primereact/resources/themes/arya-purple/theme.css";
// import "primereact/resources/themes/nova/theme.css";
// import "primereact/resources/themes/nova-alt/theme.css";
// import "primereact/resources/themes/nova-accent/theme.css";
// import "primereact/resources/themes/luna-amber/theme.css";
// import "primereact/resources/themes/luna-blue/theme.css";
// import "primereact/resources/themes/luna-green/theme.css";
// import "primereact/resources/themes/luna-pink/theme.css";
// import "primereact/resources/themes/rhea/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";

import "primereact/resources/themes/saga-green/theme.css";          //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import "primeflex/primeflex.css"

//see https://www.primefaces.org/primereact/setup/
import PrimeReact from 'primereact/api';
import {ProvideToast} from "./core/toast.context";

PrimeReact.inputStyle = 'filled';


const queryClient = new QueryClient()


function App() {

    const toast = useRef(null);

    const [theme, setTheme] = useState(('saga-green' || localStorage.getItem('selectedTheme')));

    const changeTheme = (newTheme) => {
        // Update the theme state and re-render the component
        setTheme(newTheme);
      };
  
    // Dynamically generate the theme import statement based on the current theme
    import(`primereact/resources/themes/${localStorage.getItem(
        'selectedTheme') ? localStorage.getItem('selectedTheme') : 'saga-green'}/theme.css`
        ).then(() => {
      // Do something when the theme is dynamically imported (optional)
    });

    useEffect(() => {

        const handleEvent = () => {
            const theme = localStorage.getItem('selectedTheme');

            if (theme) {
                changeTheme(theme);
            }
        };

        window.addEventListener('ReloadThemeApplication', handleEvent);

        return () => window.removeEventListener('ReloadThemeApplication', handleEvent);
    }, []);

    return (
        <DndProvider backend={HTML5Backend}>
            <QueryClientProvider client={queryClient}>
                <Toast ref={toast}/>
                <ProvideToast toastRef={toast}>
                <ProvideAuth>
                    <Routes>
                        <Route path="/login" element={
                            <RequireNoAuth>
                                <LoginPage/>
                            </RequireNoAuth>
                        }/>
                        <Route path="/forgotpassword" element={
                            <RequireNoAuth>
                                <ForgotPasswordPage/>
                            </RequireNoAuth>
                        }/>
                        <Route path="/setpassword/:token" element={
                            <RequireNoAuth>
                                <ChoosePasswordPage/>
                            </RequireNoAuth>
                        }/>
                        <Route path="/*" element={
                            <RequireAuth>
                                <Layout/>
                            </RequireAuth>
                        }/>
                    </Routes>
                </ProvideAuth>
                </ProvideToast>
            </QueryClientProvider>
        </DndProvider>
    );
}

export default App;
