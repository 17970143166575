import {Fragment, useCallback} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {usePost, useUpdatePostMutation} from "./posts.hooks";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {DropdownControl} from "../core/ControlledInputs/DropDownControl";
import {TextAreaControl} from "../core/ControlledInputs/TextAreaControl";
import {postScheme} from "./post.scheme";
import {yupResolver} from "@hookform/resolvers/yup";
import {AddMaterialForm} from "./add-material-form";
import Decimal from "decimal.js";
import {PostLineItem} from "./post-line-item";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {useTranslation} from "react-i18next";
import {useUnits} from "../units/units.hooks";
import {useSections} from "../sections/sections.hooks";
import {TimelinePerso} from "../core/Timeline";
import Loader from "../core/Loader";

export function EditPostPage() {

    const scheme = postScheme();
    const {control, formState: {isDirty}, handleSubmit, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const toast = useToast();

    const {units, isLoading: isLoadingUnits} = useUnits();
    const {sections, isLoading: isLoadingSections} = useSections();

    const {t} = useTranslation();

    let params = useParams();

    const {post, isLoading, isFetching} = usePost(params.id, {
        onSuccess: (data) => {
            if(!isDirty) {
                reset({
                    ...data,
                    unitId : data.unit?.id,
                    sectionId : data.section?.id,
                    note: data.note ?? '',
                    lines: data.lines.map(line => { return {...line, state: line.option ? {name: 'option'} : line.discount ? {name: 'discount'} : {name: 'normal'}}})
                });
            }
        }
    });

    const updateMutation = useUpdatePostMutation();

    const navigate = useNavigate();

    const {fields, append, remove, move} = useFieldArray({name: 'lines', control, keyName: 'key'});

    const containsMaterial = useCallback((material) => {
        return fields.map(x => x.material?.id).indexOf(material.id) !== -1;
    }, [fields]);

    const addMaterial = useCallback((data) => {
        if(containsMaterial(data.material)) {
            toast.current.show({severity: 'info', summary: t('post.materialAlreadyExists'), life: 3000});
            return;
        }
        append(data);
    }, [containsMaterial, append, toast, t]);

    const onSubmit = async (data) => {

        try {

            const dto = {
                ...data,
                lines: data.lines.map(x => {
                    return {
                        id: x.id ?? null,
                        type: x.type,
                        materialId: x.material?.id,
                        amount: new Decimal(x.amount).toFixed(2),
                        option: x.state['name'] === 'option' ? true : undefined,
                        discount: x.state['name'] === 'discount' ? true : undefined,
                    };
                })
            };

            await updateMutation.mutateAsync({
                id: post.id,
                data: dto
            });

            reset(data);

            toast.current.show({ severity: 'success', summary: t('post.updated.title'), detail: t('post.updated.text'), life: 3000})
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('post.updated.problem') + " " + err, life: 3000});
        }
    };

    return (
        <div>
            <TopMenu moduleName={t('post.title')} list={'/posts/'} create={'/posts/create/'}/>

            <Card title={t('post.edit')} className="shadow-3 mt-3">

                {isFetching || isLoading ? <Loader/> :

                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">

                        <div className="field col-12">
                            <DropdownControl control={control} fieldName={'sectionId'} label={t('common.section')}
                                             loading={isLoadingSections} options={sections} optionLabel={"name"}
                                             optionValue={"id"}/>
                        </div>

                        <div className="field col-12">
                            <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus/>
                        </div>

                        <div className="field col-12">
                            <DropdownControl control={control} fieldName={'unitId'} label={t('common.unit')}
                                             loading={isLoadingUnits} options={units} optionLabel={"name"}
                                             optionValue={"id"}/>
                        </div>

                        <div className="field col-12">
                            <TextAreaControl control={control} fieldName={"note"} label={t('common.note')} maxLength={1000}/>
                        </div>

                        <div className="col-12">
                            <h3>{t('post.materialList')}</h3>

                            {fields.length === 0 && <small>{t('post.emptyMaterialList')}</small>}

                            {fields.map((line, i) => (
                                <PostLineItem key={line.key} line={line} control={control} remove={remove} index={i}
                                              moveLine={move}/>
                            ))}

                        </div>

                        <div className="col-12">
                            <h3>{t('post.addAMaterial')}</h3>
                            <AddMaterialForm onAdd={addMaterial}/>
                        </div>

                        <Button type="submit" label={t('common.edit')} disabled={!isDirty}
                                loading={updateMutation.isLoading}/>
                    </form>
                }
            </Card>
            {post?.logs &&
                <TimelinePerso logs={post?.logs}/>
            }
        </div>
    )
}
