import {Route, Routes} from "react-router-dom";
import {PostsListPage} from "./posts-list.page.js";
import {EditPostPage} from "./posts-edit.page";
import {CreatePostPage} from "./posts-create.page";

export function Posts() {
    return <Routes>

        <Route path=":id" element={<EditPostPage />} />
        <Route path="create" element={<CreatePostPage />} />
        <Route path="*" element={<PostsListPage />} />

    </Routes>

}