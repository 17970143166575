import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import categoriesService from "./categories.service";

export function useCategories() {
    const {data: categories, isLoading, isFetched, isError} = useQuery(['categories'], categoriesService.all, {
        initialData: []
    });

    return {
        categories,
        isFetched,
        isLoading,
        isError
    };
}

export function useCategory(id, options = null) {
    const {data: category, isLoading, isError} = useQuery(['categories', id], ({queryKey}) => categoriesService.get(queryKey[1]), options);

    return {
        category, isLoading, isError
    };
}

export function useUpdateCategoryMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => categoriesService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['categories']);
        }
    });

    return mutation;
}


export function useCreateCategoryMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(categoriesService.create, {
        onSuccess: (data) => {
            const categories = queryClient.getQueryData(['categories']) ?? [];
            queryClient.setQueryData(['categories'], [...categories, data]);
        }
    });

    return mutation;
}

export function useDeleteCategoryMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(categoriesService.delete, {
        onSuccess: (data, variables) => {
            const categories = queryClient.getQueryData(['categories']) ?? [];
            queryClient.setQueryData(['categories'], categories.filter(x => x.id !== variables))
        }
    });

    return mutation;
}