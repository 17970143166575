import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import organisationsService from "../../organisations/organisations.service";

export function useOptionQuote() {
    const {data: optionQuote, isLoading, isError, isFetching} = useQuery(['optionQuote'], organisationsService.getOptionQuote, {
        initialData: []
    });
    //console.log(optionQuote)

    return {
        optionQuote,
        isLoading,
        isError,
        isFetching
    };

}


export function useUpdateOptionQuoteMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => organisationsService.updateOptionQuote(params), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['optionQuote']);
        }
    });

    return mutation;
}