import {Card} from "primereact/card";
import {useCallback, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {Invoice} from "../models/Invoice";
import {ProvideInvoice, useInvoiceContext} from "../invoice.context";
import {InvoiceInfoForm} from "../components/invoice-info.form";
import {Divider} from "primereact/divider";
import {InvoiceForm} from "../components/invoice.form";
import moment from "moment/moment";
import {useCreateInvoiceMutation, useInvoiceTemplates} from "../invoice.hooks";
import {observer} from "mobx-react";
import {Button} from "primereact/button";
import {useToast} from "../../core/toast.context";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {TopMenu} from "../../core/TopMenu";
import invoicesService from "../invoices.service";
import {ProgressSpinner} from "primereact/progressspinner";
import {Checkbox} from "primereact/checkbox";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import quoteTemplateService from "../../settings/quote/templates/quote.template.service";
import {Quote} from "../../quotes/models/Quote";
import invoiceTemplateService from "../../settings/invoice/templates/invoice.template.service";


export function InvoicesCreatePage() {

    const [searchParams] = useSearchParams();
    const navigate=useNavigate();
    const {pathname} = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [visible, setVisible] = useState(true);

    const [invoice, setInvoice] = useState(null);

    const [selectedTemplate,setSelectedTemplate]=useState(null);
    const {invoiceTemplates, isLoading:isLoadingTemplate} = useInvoiceTemplates();

    const toast = useToast();

    const {t} = useTranslation();

    const startNewInvoice = () => {
        setInvoice(new Invoice(uuidv4(), [], null, null, moment().toDate(), moment().add(1, 'month').toDate()));
        setIsLoading(false);
    };

    useEffect(() => {
        window.addEventListener('beforeunload',alertUser);
        if(pathname==="/invoices/create/") {
            window.history.pushState(null,document.title,window.location.href);
            window.addEventListener('popstate', popFunction);
        }     
		return () => {
			window.removeEventListener('beforeunload', alertUser);
			window.removeEventListener('popstate', popFunction);
		};
	}, []);
	const alertUser = (e) => {
		e.preventDefault();
		e.returnValue = '';
    };

     const popFunction = (event) => {
			event.preventDefault();
			const answer = window.confirm(t(`common.leavePage`));
			if (!answer) {
				window.history.pushState(null, document.title, window.location.href);
			} else {
				navigate('/invoices/');
			}
		};

    useEffect(() => {
        if(searchParams.has("invoiceId")) {
            invoicesService
                .get(searchParams.get("invoiceId"))
                .then(dto => {
                    setInvoice(Invoice.fromDto(uuidv4(), dto.lines, null, null, moment().toDate(), moment().add(1, 'month').toDate()));
                    setIsLoading(false);
                })
                .catch(e => {
                    toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('common.notFound'), life: 3000});
                    navigate("/invoices");
                });
        } else if(!selectedTemplate&&!visible) {
            startNewInvoice();
        } else if(selectedTemplate&&!visible) {
            invoiceTemplateService.get(selectedTemplate?.id)
                .then(dto => {
                    setInvoice(Invoice.fromDto(uuidv4(), dto.lines, null, null, moment().toDate(), moment().add(1, 'month').toDate()));
                    setIsLoading(false);
                })
                .catch(e => {
                    toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('common.notFound'), life: 3000});
                    navigate("/invoices");
                });
        }

    }, [searchParams, setInvoice, toast, navigate, t, visible, selectedTemplate]);

    const footerContent = (
        <div>
            <Button label={t(`invoice.template.continueWithout`)} icon='pi' onClick={() => {setVisible(false); setSelectedTemplate(null)}} className='p-button-text p-button-info' />
            <Button label={t(`invoice.template.continue`)} icon='pi' disabled={!selectedTemplate} onClick={() => setVisible(false)} autoFocus />
        </div>
    );

    if(isLoading) {
        return (
            <Card className="ml-auto mr-auto mt-2 text-center" style={{maxWidth: '1400px'}}>
                <ProgressSpinner />
                <ConfirmDialog />
                <Dialog header={t('invoice.template.choice')} visible={visible} style={{ width: '50vw', height:'400px' }} closable={false} footer={footerContent}>

                    <Dropdown placeholder={t('invoice.template.title')}
                              options={invoiceTemplates}
                              className="col-12"
                              optionLabel={'name'}
                              value={selectedTemplate}
                              disabled={isLoadingTemplate}
                              dropdownIcon={isLoadingTemplate ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'}
                              onChange={(e) => {
                                  setSelectedTemplate(e.value)
                              }
                              } />
                </Dialog>

            </Card>
        )
    }

    return (<ProvideInvoice devi={invoice}>
        <CreatePage invoice={invoice}/>
    </ProvideInvoice>)
}

const CreatePage = observer(({invoice}) => {

    const createMutation = useCreateInvoiceMutation();

    const toast = useToast();

    const {t} = useTranslation();

    const navigate = useNavigate();

    const submitForm = async () => {
        const dto = invoice.dto;

        try {
            const newInvoice = await createMutation.mutateAsync(dto);
            toast.current.show({
                severity: 'success',
                summary: t('invoice.created.title'),
                detail: t('invoice.created.text'),
                life: 3000
            });
            localStorage.removeItem("archigest_create_invoice");
            navigate('/invoices/' + newInvoice.id);
        } catch (err) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: t('invoice.created.problem') + ' ' + err,
                life: 3000
            });
        }
    };

    const invoiceModel = useInvoiceContext();

    useEffect(() => {
        const interval = setInterval(() => {
            const json = JSON.stringify(invoice.dto);
            localStorage.setItem("archigest_create_invoice", json);
        }, 3000);

        return () => clearInterval(interval);
        }
    , [invoice])

    const handleKeyDown = useCallback((event) => {
        // Check for ALT + s
        if (event.altKey && event.key === "s") {
            event.preventDefault(); // Prevent the default browser behavior
            invoice.addSection();
        }

        // Check for ALT + p
        if (event.altKey && event.key === "p") {
            event.preventDefault();
            invoice.addPost();
        }

        // Check for ALT + m
        if (event.altKey && event.key === "m") {
            event.preventDefault();
            invoice.addMaterial();
        }

    }, [invoice]);

    // EventListener for capturing Keyboard shortcuts to perform some actionsS
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <div>
            <TopMenu moduleName={t('invoice.title')} list={'/invoices/'}/>

            <Card className="ml-auto mr-auto mt-2 xl:p-8 sm:p-3" style={{maxWidth: '1400px'}}>
                <InvoiceInfoForm invoice={invoice}  />

                <Divider className={'mt-0'}/>

                <InvoiceForm invoice={invoice}/>

                <div className="grid grid-nogutter">
                    <div className="col justify-content-center">
                        <Button type={"button"} label={t('common.create')}
                                disabled={!invoice.valid || !invoiceModel.isValid || createMutation.isLoading}
                                loading={createMutation.isLoading} onClick={submitForm}/>
                    </div>
                </div>
            </Card>
        </div>
    )
});
