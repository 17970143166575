

export function OrganisationCard({organisation}) {
    return (
        <div className={"text-left pl-3"}>
            <p>
                <strong>{organisation.name}</strong> <br/>
                {organisation.address} {organisation.nb},<br />
                {organisation.postalCode} {organisation.city}, {organisation.country}<br/>
                {organisation.tva}
            </p>
            <p>
                {organisation.phone}<br/>
                {organisation.email}<br/>
            </p>

        </div>
    )
}