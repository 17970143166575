import Decimal from "decimal.js";
import {action, computed, makeObservable, observable} from "mobx";
import {MaterialLine} from "./MaterialLine";
import {v4 as uuidv4} from "uuid";

export class PostLine
{
    id;
    type = 'post';
    description = "";
    unit = "";
    amount = new Decimal("0");
    //pricePerUnit = new Decimal("0");
    lines = []
    shouldFocus = false;
    coefficient;

    note = null;

    totalExclVAT6 = new Decimal("0");
    totalExclVAT21 = new Decimal("0");
    totalVAT6 = new Decimal("0");
    totalVAT21 = new Decimal("0");

    static create() {
        return new PostLine(uuidv4(), "", "m²", new Decimal(1), [], true, null, 1);
    }

    constructor(id, description, unit, amount, lines, shouldFocus = false, note = null, coefficient) {
        this.id = id;
        this.description = description
        this.unit = unit
        this.amount = amount
        //this.pricePerUnit = pricePerUnit
        this.lines = lines
        this.shouldFocus = shouldFocus
        this.note = note;
        this.coefficient = coefficient;

        makeObservable(this, {
            description: observable,
            unit: observable,
            coefficient: observable,
            amount: observable,
            pricePerUnit: computed,
            lines: observable,
            total: computed,
            subTotal: computed,
            vatTotal: computed,
            changeSubTotal: action,
            changeAmount: action,
            changePricePerUnit: action,
            updateFromForm: action,
            moveLine: action,
            pasteLine: action,
            removeLine: action,
            addMaterial: action,
            valid: computed,
            updateFromPost: action,
            updateDescription: action,
            updatePricePerUnit: action,
            updateAmount: action,
            updateSubTotal: action,
            updateUnit: action,
            note: observable,
            updateNote: action,
            extraCharge: computed,
            updateExtraCharge: action,
            extraChargeSubTotal: computed,
            extraChargeTotal: computed,
            credit: computed,
            updateCredit: action,
            creditSubTotal: computed,
            creditTotal: computed,
            variant: computed,
            updateVariant: action,
            variantSubTotal: computed,
            variantTotal: computed,
            updateCoefficient: action
        })
    }

    populateMultipleVAT() {
        // first reset values
        this.totalExclVAT6 = new Decimal("0");
        this.totalExclVAT21 = new Decimal("0");
        this.totalVAT6 = new Decimal("0");
        this.totalVAT21 = new Decimal("0");
        // secondly compute all vat values
        this.lines.forEach(line => {
            if (line.vat.d[0] === 6 ) {
                if (!line.credit && !line.variant) {
                    this.totalExclVAT6 = this.totalExclVAT6.add(line.subTotal, new Decimal(0));
                    this.totalVAT6 = this.totalVAT6.add(line.total, new Decimal(0));
                } else if (line.credit) {
                    this.totalExclVAT6 = this.totalExclVAT6.minus(line.creditSubTotal, new Decimal(0));
                    this.totalVAT6 = this.totalVAT6.minus(line.creditTotal, new Decimal(0));
                }
            } else if (line.vat.d[0] === 21) {
                if (!line.credit && !line.variant) {
                    this.totalExclVAT21 = this.totalExclVAT21.add(line.subTotal, new Decimal(0));
                    this.totalVAT21 = this.totalVAT21.add(line.total, new Decimal(0));
                } else if (line.credit) {
                    this.totalExclVAT21 = this.totalExclVAT21.add(line.creditSubTotal, new Decimal(0));
                    this.totalVAT21 = this.totalVAT21.add(line.creditTotal, new Decimal(0));
                }
            }
        })
    }

    get total() {
        return this.calculateSubTotal().add(this.calculateVatTotal());
    }

    calculateSubTotal() {
        return this.pricePerUnit.times(this.amount)
    }

    calculateVatTotal() {
        return this.lines.map(x => x.vatTotal).reduce((prev, current) => current.add(prev), new Decimal(0))
    }

    get subTotal() {
        return this.calculateSubTotal()
    }

    get vatTotal() {
        return this.calculateVatTotal()
    }

    get pricePerUnit() {
        return this.lines.map(x => x.subTotal.dividedBy(this.amount)).reduce((prev, current) => current.add(prev), new Decimal(0))
    }

    get extraChargeSubTotal() {
        return this.lines.filter(x => x.extraCharge).reduce((prev, current) => prev.add(current.subTotal), new Decimal(0))
    }

    get extraChargeTotal() {
        return this.extraChargeSubTotal.add(this.lines.filter(x => x.extraCharge).map(x => x.vatTotal).reduce((prev, current) => current.add(prev), new Decimal(0)));
    }

    get creditSubTotal() {
        return this.lines.filter(x => x.credit).reduce((prev, current) => prev.add(current.creditSubTotal), new Decimal(0))
    }

    get creditTotal() {
        return this.lines.filter(x => x.credit).reduce((prev, current) => prev.add(current.creditTotal), new Decimal(0));
    }

    get variantSubTotal() {
        return this.lines.filter(x => x.variant).reduce((prev, current) => prev.add(current.subTotal), new Decimal(0))
    }

    get variantTotal() {
        return this.variantSubTotal.add(this.lines.filter(x => x.variant).map(x => x.vatTotal).reduce((prev, current) => current.add(prev), new Decimal(0)));
    }

    changeSubTotal(newSubTotal) {
        const currentTotal = this.subTotal

        this.lines.forEach(x => {
            const linePriceShare = x.subTotal.dividedBy(currentTotal)
            const newLineTotal = newSubTotal.times(linePriceShare)
            x.changeSubTotal(newLineTotal)
        })
    }

    changeAmount(newAmount) {

        this.lines.forEach(x => {
            x.amount = x.amount.dividedBy(this.amount).times(newAmount);
        })

        this.amount = newAmount
    }

    changePricePerUnit(newPricePerUnit) {
        const priceShare = newPricePerUnit.dividedBy(this.pricePerUnit)

        this.lines.forEach(x => {
            x.pricePerUnit = x.pricePerUnit.times(priceShare)
        })
    }

    updateNote(value) {
        this.note = value;
    }

    updateFromForm(data) {
        const subTotal = new Decimal(data.subTotal);

        if(!this.subTotal.equals(subTotal)) {
            this.changeSubTotal(subTotal)
            return;
        }

        const amount = new Decimal(data.amount);
        if(!this.amount.equals(amount)) {
            this.changeAmount(amount);
            return;
        }

        const pricePerUnit = new Decimal(data.pricePerUnit);
        if(!this.pricePerUnit.equals(pricePerUnit)) {
            this.changePricePerUnit(pricePerUnit);
            return;
        }

        if(this.description !== data.description) {
            this.description = data.description
        }

        if(this.unit !== data.unit) {
            this.unit = data.unit;
        }

        if(this.note !== data.note) {
            this.note = data.note;
        }
    }

    updateCoefficient(coefficient) {
            const oldCoef = Number(this.coefficient) === 1 ? 1 : (this.coefficient/100)+1;
            this.coefficient = coefficient;
            const coefValue = Number(this.coefficient) === 1 ? 1 : (this.coefficient/100)+1;

            this.lines.forEach(x => {
                x.changeSubTotal(x.subTotal.div(oldCoef).times(coefValue))
            })
    }

    moveLine(lineItem, dragItem, dragIndex, hoverIndex) {
        // dragIndex & hoverIndex doesn't match the real actual position index
        const draggedItemIndex = this.lines.findIndex(line => line.id === dragItem.id);
        const hoverItemIndex = this.lines.findIndex(line => line.id === lineItem.id);

        this.lines.splice(hoverItemIndex, 0, this.lines.splice(draggedItemIndex, 1)[0]);
    }

    pasteLine(line, addAfterLine) {
        const materialToCreate = MaterialLine.create();
        materialToCreate.description = line.description;
        materialToCreate.unit = line.unit;
        materialToCreate.amount = new Decimal(line.amount);
        materialToCreate.pricePerUnit = new Decimal(line.pricePerUnit);
        materialToCreate.vat = new Decimal(line.vat);
        materialToCreate.note = line.note;
        materialToCreate.extraCharge = line.extraCharge;
        materialToCreate.credit = line.credit;

        const indexToAddAfter = this.lines.findIndex(material => material.id === addAfterLine.id);

         // Insert materialToCreate after the item found
         this.lines.splice(indexToAddAfter + 1, 0, materialToCreate);

         // If there are items after indexToAddAfter, move them after the created material
         if (indexToAddAfter < this.lines.length - 1) {
             const itemsToMove = this.lines.splice(indexToAddAfter + 2); // Get items after indexToAddAfter
             this.lines.push(...itemsToMove); // Move them after the created material
         }
    }

    removeLine(lineItem) {
        const index = this.lines.indexOf(lineItem);
        if(index !== -1) {
            this.lines.splice(index, 1)
        }
    }

    addMaterial() {
        this.lines.push(MaterialLine.create())
    }

    get valid() {

        for (let i = 0; i < this.lines.length; i++) {
            if(!this.lines[i].valid) {
                return false;
            }
        }

        return this.lines.length > 0;
    }


    updateDescription(description) {
        this.description = description
    }

    updateSubTotal(newSubTotal) {
        const subTotal = new Decimal(newSubTotal);

        if(!this.subTotal.equals(subTotal)) {
            this.changeSubTotal(subTotal);
        }
    }

    updateUnit(unit) {
        this.unit = unit;
    }

    updateAmount(value) {
        const amount = new Decimal(value);

        if(!this.amount.equals(amount)) {
            this.changeAmount(amount);
        }
    }

    updatePricePerUnit(value) {
        const pricePerUnit = new Decimal(value);

        if(!this.pricePerUnit.equals(pricePerUnit)) {
            this.changePricePerUnit(pricePerUnit);
        }
    }

    updateFromPost(post) {
        this.description = post.name;
        this.unit = post.unit.name;
        this.amount = new Decimal(1)
        this.note = post.note || null;

        const newLines = post.lines.map(line => {
            switch (line.type) {
                case 'material':
                    return MaterialLine.fromPostLine(line);
                default:
                    throw new Error("Line type not implemented for updateFromPost, "+line.type);
            }
        })

        this.lines.replace(newLines)
    }

    get dto() {
        return {
            id: this.id,
            description: this.description,
            type: this.type,
            unit: this.unit,
            amount: this.amount.toFixed(2),
            pricePerUnit: this.pricePerUnit.toFixed(2),
            subTotal: this.subTotal.toFixed(2),
            vatTotal: this.vatTotal.toFixed(2),
            total: this.total.toFixed(2),
            lines: this.lines.map(x => x.dto),
            note: this.note,
            extraCharge: this.extraCharge,
            extraChargeSubTotal: this.extraChargeSubTotal.toFixed(2),
            extraChargeTotal: this.extraChargeTotal.toFixed(2),
            credit: this.credit,
            creditSubTotal: this.creditSubTotal.toFixed(2),
            creditTotal: this.creditTotal.toFixed(2),
            variant: this.variant,
            variantSubTotal: this.variantSubTotal.toFixed(2),
            variantTotal: this.variantTotal.toFixed(2),
            coefficient: this.coefficient.toString(),
        }
    }

    get extraCharge() {
        for (let i = 0; i < this.lines.length; i++) {
            if(!this.lines[i].extraCharge) {
                return false;
            }
        }

        return this.lines.length === 0 ? false : true;
    }

    updateExtraCharge(value) {
        //this.option = value;

        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            if(line instanceof MaterialLine) {
                line.updateExtraCharge(value);
            }
        }
    }

    get credit() {
        for (let i = 0; i < this.lines.length; i++) {
            if(!this.lines[i].credit) {
                return false;
            }
        }

        return this.lines.length === 0 ? false : true;
    }

    updateCredit(value) {
        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            if(line instanceof MaterialLine) {
                line.updateCredit(value);
            }
        }
    }


    get variant() {
        for (let i = 0; i < this.lines.length; i++) {
            if(!this.lines[i].variant) {
                return false;
            }
        }

        return this.lines.length === 0 ? false : true;
    }

    updateVariant(value) {
        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            if(line instanceof MaterialLine) {
                line.updateVariant(value);
            }
        }
    }
}
