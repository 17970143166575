import {useTranslation} from "react-i18next";
import {useLicense} from "./licenses.hooks";
import {useEffect, useRef} from "react";
import moment from "moment/moment";
import {Messages} from "primereact/messages";


export default function LicenseBanner() {
    const {t} = useTranslation();

    const {licenseInformation} = useLicense();

    const messagesRef = useRef(null);

    useEffect(() => {
        if (!licenseInformation || !messagesRef.current) {
            return;
        }

        localStorage.setItem('selectedTheme', licenseInformation.theme);
        const evt = new Event('ReloadThemeApplication')
        window.dispatchEvent(evt);

        const now = moment();

        const messages = [];

        // messages.push({
        //     sticky: true,
        //     severity: getSeverity(7),
        //     summary: t('user.licenseExpiring.detail', {days: 7})
        //     //summary: t('user.licenseExpiring.summary', {context: {days: 7}}),
        //     //detail: t('user.licenseExpiring.detail', {context: {days: 7}})
        // });

        if (licenseInformation.userLicenseEndDate) {
            const userLicenseEndDate = moment(licenseInformation.userLicenseEndDate);
            const userDiff = userLicenseEndDate.diff(now, 'days');

            if (userDiff < 30) {
                messages.push({
                    sticky: true,
                    severity: getSeverity(userDiff),
                    summary: t('user.licenseExpiring.detail', {days: userDiff}),
                    // summary: t('user.licenseExpiring.summary', {days: userDiff}),
                    // detail: t('user.licenseExpiring.detail', {days: userDiff})
                });
            }
        }

        if (licenseInformation.organisationLicenseEndDate) {
            const organisationLicenseEndDate = moment(licenseInformation.organisationLicenseEndDate);
            const organisationDiff = organisationLicenseEndDate.diff(now, 'days');

            if (organisationDiff < 30) {
                messages.push({
                    sticky: true,
                    severity: getSeverity(organisationDiff),
                    summary: t('organisation.licenseExpiring.detail', {days: organisationDiff}),
                    // summary: t('organisation.licenseExpiring.summary', {days: organisationDiff}),
                    // detail: t('organisation.licenseExpiring.detail', {days: organisationDiff})
                });
            }
        }

        if (messages.length > 0) {
            messagesRef.current.replace(messages);
        }

    }, [licenseInformation, t]);


    return (
        <Messages ref={messagesRef}/>
    )
}

function getSeverity(daysLeft) {
    if (daysLeft < 7) {
        return 'error';
    }

    if (daysLeft < 14) {
        return 'warn';
    }

    return 'info';
}
