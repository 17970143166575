import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            intlCurrency: '{{value, currency}}',
            auth: {
                passwordHasBeenSet: 'Your password has been set successfully, you can now log in',
                invalidLink: "This link is no longer valid, please request a new link.",
                couldNotReset: 'Oops, we could not reset your password',
                choosePassword: 'Choose your password',
                passwordRequired: 'A password is required',
                passwordLength: 'Password must be at least 8 characters',
                backToLogin: 'Back to log in',
                setPassword: 'Set password',
                resetPasswordEmailSent: 'We have sent you an email with a reset password link',
                resetPasswordFailed: "Oops, we could not send you a reset link",
                resetPassword: 'Reset password',
                emailRequired: 'Emails is required',
                emailInvalid: 'Invalid email address. E.g. example@email.com',
                sendMeALink: 'Send me a link',
                forgotPassword: 'Forgot password?',
                login: 'Log in',
                loginError:'Something went wrong',
                logOutQuestion:"Are you sure you want to log out?",
            },
            datatable: {
                reportTemplate: 'Showing {first} to {last} of {totalRecords} entries',
            },
            common: {
                month: '["January","February","March","April","May","June","July","August","September","October","November","December"]',
                printToPdf: 'Print',
                info: "Information",
                notification: "Notifications",
                documents: 'Documents',
                openFolder: "Open folder",
                teamviewer: "Teamviewer",
                choose : 'Choose',
                upload : 'Upload',
                download : 'Download',
                oops: 'Oops',
                yes: 'Yes',
                no: 'No',
                create: 'Create',
                list: 'List',
                edit: 'Save',
                name: 'Name',
                unit: 'Unit',
                price: 'Price',
                vat: 'Vat',
                note: 'Note',
                confirm: 'Confirm',
                confirmDelete: 'Are you sure you want to delete <strong>{{name}}</strong> from the list?',
                confirmGeneratePdf: 'Are you sure you want to delete generate PDF',
                generatePdf: 'Generate PDF',
                logout: 'Log out',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
                material: 'Material',
                post: 'Post',
                section: 'Section',
                amount: 'Amount',
                state:"State",
                add: 'Add',
                gender: 'Gender',
                email: 'Email',
                phoneNumber: 'Telephone number',
                provider: 'Provider',
                category: 'Category',
                city: 'City',
                origin: 'Origin',
                status: 'Status',
                houseNumber: "Number",
                postalCode: 'Postal code',
                country: 'Country',
                address: 'Address',
                role: 'Role',
                priority: 'Priority',
                clickToEdit: "Click to edit",
                description: 'Description',
                subTotal: 'Sub Total',
                vatIncluded: 'Vat Included',
                pricePerUnit: 'Price Per Unit',
                validUntil: 'Valid until',
                tbd: 't.b.d.',
                newVersion: 'New Version',
                empty: 'Empty',
                fromCurrentVersion: 'From current',
                other: 'Other',
                duplicate: 'Duplicate',
                notFound: "Could not find the item you were looking for",
                version: 'Version',
                admin: 'Administrator',
                warning: "Warning",
                saveAsTemplate: "Save as template",
                search: "Search",
                noCategory: "No category",
                noSection: 'No section',
                confirmation: "Confirmation",
                option: "Option",
                total: 'Total',
                inOption: 'In option',
                totalWithoutOption: 'Total without option',
                discount: 'Discount',
                addNote: 'Add Note',
                comments: 'Comments',
                noComments: 'No comments',
                addComment: 'Add a comment',
                anonymous: 'Anonymous',
                send: 'Send',
                deletedAt: "Expiry Date",
                deleted: "Deleted",
                notDeleted: "Not Deleted",
                open: 'Open',
                close: 'Close',
                leavePage:'If you leave this page you will lose your data?',
                coefficient: "Coefficient",
                normal: "Normal",
                date:"Choose the date",
                problemGenerate: 'We could not generate the pdf',
                variant: "Variant",
                copy: "Copy",
                paste: "Paste"
            },
            material: {
                title: 'Materials',
                emptyList: 'No materials found',
                list: 'Material list',
                deleted: {
                    title: 'Material deleted',
                    text: 'The material has been deleted',
                    problem: 'There was a problem deleting this material'
                },
                created: {
                    title: 'Material created',
                    text: 'The material has been created',
                    problem: 'There was a problem when creating the material'
                },
                updated: {
                    title: 'Material updated',
                    text: 'The material has successfully been updated',
                    problem: 'There was a problem when updating the material'
                },
                create: 'Create a new material',
                edit: 'Edit material',
            },
            client: {
                title: 'Clients',
                emptyList: 'No clients found',
                name: 'Client name',
                number: 'Client Number',
                list: 'Clients list',
                deleted: {
                    title: 'Client deleted',
                    text: 'The client has been deleted',
                    problem: 'There was a problem deleting this client'
                },
                created: {
                    title: 'Client created',
                    text: 'The client has been created',
                    problem: 'There was a problem when creating the client'
                },
                updated: {
                    title: 'Client updated',
                    text: 'The client has successfully been updated',
                    problem: 'There was a problem when updating the client'
                },
                create: 'Create a new client',
                edit: 'Edit client',
                formattedId: 'N°',
                invoiceAddress: 'Invoice address',
                worksiteAddress: 'Worksite address',
                worksiteAddressIsInvoiceAddress: 'Invoice address is the worksite address',
                worksiteAddressIsNotTheInvoiceAddress: 'Invoice address is different than the worksite address',
                phone:{
                    problem: 'Phone number is invalid'
                },
            },
            quote: {
                title: 'Quotes',
                name: 'Quote',
                emptyList: 'No quotes found',
                number: 'Quote Number',
                list: 'Quotes list',
                multilines: "Add multilines",
                deleted: {
                    title: 'Quote deleted',
                    text: 'The quote has been deleted',
                    problem: 'There was a problem deleting this quote'
                },
                created: {
                    title: 'Quote created',
                    text: 'The quote has been created',
                    problem: 'There was a problem when creating the quote'
                },
                updated: {
                    title: 'Quote updated',
                    text: 'The quote has successfully been updated',
                    problem: 'There was a problem when updating the quote'
                },
                create: 'Create a new quote',
                edit: 'Edit quote',
                emptySectionLines: 'No lines have been added to the section yet',
                emptyPostLines: 'No lines have been added to the post yet',
                emptyQuoteLines: 'No lines have been added to the quote yet',
                confirmImportPost: 'Would you like to import {{name}}?',
                importPost: 'Import post?',
                pdf: {
                    problem: 'We could not open the pdf',
                    open: 'Open Pdf',
                    editSentQuote: "This quote has already been sent to the customer. If you modify it, the quote will go back to draft and it will no longer correspond with the one the customer received. Are you sure you want to edit it?"
                },
                sendToClient: {
                    success: 'Sent',
                    successDetail: 'The quote has been sent to the client successfully',
                    tooltip: {
                        send: 'Send to client',
                        reSend: 'Resend to client',
                    },
                    problem: 'We were not able to send the quote to the client, make sure email addresses are registered',
                    attachment: 'Attachment is too big make sure its less then 20 MB',
                    modal: {
                        header: 'Send to client'
                    },
                    subject: 'Email subject',
                    body: 'Email body',
                    to: 'To',
                    cc: 'CC',
                    bcc: 'BCC'
                },
                newVersion: {
                    error: 'We were not able to create a new version'
                },
                wasSentToClient: "This quote has already been sent to the client",
                dwg: {
                    add: 'Add a base plan',
                    added: {
                        title: 'Base plan added',
                        text: 'The base plan has been created in your drive',
                        problem: 'The base plan already exists in your drive or your organisation does not have a base plan'
                    },
                    tooltip: "Open dwg file"
                },
                print:{
                    title:"Quote printing",
                    text:"The quote has been printed successfully",
                    problem:"There was a problem printing the quote"
                },
                confirmSendToClient: "Would you like to send this quote to the client?",
                optionPdf: {
                    title: "Pdf settings",
                    hex: "Hexadecimal color",
                    color: "Color pdf",
                    option: "Pdf option",
                    section: "Show total of section",
                    post: "Show total of post",
                    notes: "Show notes",
                    postAmount:"Show post quantity",
                    postPrice:"Show post unit price",
                    postUnit:"Show post unity",
                    font: "Writing font",
                    quotePaymentCondition: "Payment conditions for quotes",
                    invoicePaymentCondition: "Payment conditions for invoices",
                    updated: {
                        title: 'Quote settings updated',
                        text: 'The quote settings has successfully been updated',
                        problem: 'There was a problem when updating the  quote settings'
                    },
                },
                lineOptionType: "Type",
                lineOptionTypeNormal: "Normal",
                lineOptionTypeOption: "Option",
                lineOptionTypeDiscount: "Discount",
                actions: "Actions",
                line: {
                    delete: "Delete"
                },
                totalWithOptionBeforeDiscount: "Total (before discount and with options)",
                total: 'Total',
                inOption: 'In option',
                discount: 'Discount',
                totalAfterDiscountWithOptions: "Total (with options)",
                totalAfterDiscountWithoutOptions: "Total (without options)",
                restore: {
                    create: {
                        message: 'Restore previous state ?',
                        header: 'Restore'
                    }
                },
                template: {
                    title: 'Quote templates',
                    list: 'Quote templates list',
                    emptyList: 'No quote templates found',
                    name: 'Quote template name',
                    create: 'Create a new quote template',
                    edit: 'Edit quote template',
                    choice: 'Choose quote template',
                    continue: 'Continue',
                    continueWithout:'Continue without template',
                    deleted: {
                        title: 'Quote template deleted',
                        text: 'The Quote template has been deleted',
                        problem: 'There was a problem deleting this template'
                    },
                    created: {
                        title: 'Quote template created',
                        text: 'The quote template has been created',
                        problem: 'There was a problem when creating the quote template'
                    },
                    updated: {
                        title: 'Quote template updated',
                        text: 'The quote template has successfully been updated',
                        problem: 'There was a problem when updating the quote template'
                    },
                },
                exportZip:{
                    title: "Export quote",
                    folderName: "Export-Quote",
                    text:"Exporting quotes successfully",
                    empty:"There are no quotes to export for this period"
                }
            },
            invoice: {
                title: 'Invoices',
                name: 'Invoice',
                emptyList: 'No invoices found',
                number: 'Invoice Number',
                list: 'Invoices list',
                deleted: {
                    title: 'Invoice deleted',
                    text: 'The invoice has been deleted',
                    problem: 'There was a problem deleting this invoice'
                },
                created: {
                    title: 'Invoice created',
                    text: 'The invoice has been created',
                    problem: 'There was a problem when creating the invoice'
                },
                updated: {
                    title: 'Invoice updated',
                    text: 'The invoice has successfully been updated',
                    problem: 'There was a problem when updating the invoice'
                },
                create: 'Create a new invoice',
                edit: 'Edit invoice',
                emptySectionLines: 'No lines have been added to the section yet',
                emptyPostLines: 'No lines have been added to the post yet',
                emptyInvoiceLines: 'No lines have been added to the invoice yet',
                confirmImportPost: 'Would you like to import {{name}}?',
                importPost: 'Import post?',
                pdf: {
                    problem: 'We could not open the pdf',
                    open: 'Open Pdf',
                    editSentInvoice: "This invoice has already been sent to the customer. If you modify it, the invoice will go back to draft and it will no longer correspond with the one the customer received. Are you sure you want to edit it?"
                },
                sendToClient: {
                    success: 'Sent',
                    successDetail: 'The invoice has been sent to the client successfully',
                    tooltip: {
                        send: 'Send to client',
                        reSend: 'Resend to client',
                    },
                    problem: 'We were not able to send the invoice to the client, make sure email addresses are registered',
                    attachment: 'Attachment is too big make sure its less then 20 MB',
                    modal: {
                        header: 'Send to client'
                    },
                    subject: 'Email subject',
                    body: 'Email body',
                    to: 'To',
                    cc: 'CC',
                    bcc: 'BCC'
                },
                newVersion: {
                    error: 'We were not able to create a new version'
                },
                wasSentToClient: "This invoice has already been sent to the client",
                print:{
                    title:"Invoice printing",
                    text:"The invoice has been printed successfully",
                    problem:"There was a problem printing the invoice"
                },
                confirmSendToClient: "Would you like to send this invoice to the client?",
                optionPdf: {
                    title: "Invoice settings",
                    hex: "Hexadecimal color",
                    color: "Color invoice pdf",
                    option: "Option invoice pdf",
                    section: "Show total of section",
                    post: "Show total of post",
                    notes: "Show notes",
                    font: "Writing font",
                },
                lineOptionType: "Type",
                lineOptionTypeNormal: "Normal",
                lineOptionTypeExtraCharge: "Extra charge",
                lineOptionTypeCredit: "Credit",
                actions: "Actions",
                line: {
                    delete: "Delete"
                },
                totalWithExtraChargeBeforeCredit: "Total (with extra charge)",
                total: 'Total',
                inExtraCharge: 'In extra charge',
                credit: 'Credited',
                variant: 'In variant',
                cocontractant: 'co-contractor', 
                totalAfterCreditWithExtraCharge: "Grand total",
                restore: {
                    create: {
                        message: 'Restore previous state ?',
                        header: 'Restore'
                    }
                },
                template: {
                    title: 'Invoice templates',
                    list: 'Invoice templates list',
                    emptyList: 'No invoice templates found',
                    name: 'Invoice template name',
                    create: 'Create a new invoice template',
                    edit: 'Edit invoice template',
                    choice: 'Choose invoice template',
                    continue: 'Continue',
                    continueWithout:'Continue without template',
                    deleted: {
                        title: 'Invoice template deleted',
                        text: 'The invoice template has been deleted',
                        problem: 'There was a problem deleting this template'
                    },
                    created: {
                        title: 'Invoice template created',
                        text: 'The invoice template has been created',
                        problem: 'There was a problem when creating the invoice template'
                    },
                    updated: {
                        title: 'Invoice template updated',
                        text: 'The invoice template has successfully been updated',
                        problem: 'There was a problem when updating the invoice template'
                    },
                },
                exportZip:{
                    title: "Export invoice",
                    folderName: "Export-Invoice",
                    text:"Exporting invoices successfully",
                    empty:"There are no invoices to export for this period"
                }
            },
            section: {
                title: 'Sections',
                emptyList: 'No sections found',
                list: 'Sections list',
                deleted: {
                    title: 'Section deleted',
                    text: 'The section has been deleted',
                    problem: 'There was a problem deleting this section'
                },
                created: {
                    title: 'Section created',
                    text: 'The section has been created',
                    problem: 'There was a problem when creating the section'
                },
                updated: {
                    title: 'Section updated',
                    text: 'The section has successfully been updated',
                    problem: 'There was a problem when updating the section'
                },
                create: 'Create a new section',
                edit: 'Edit section'
            },
            post: {
                title: 'Posts',
                emptyList: 'No posts found',
                list: 'Posts list',
                deleted: {
                    title: 'Post deleted',
                    text: 'The post has been deleted',
                    problem: 'There was a problem deleting this post'
                },
                created: {
                    title: 'Post created',
                    text: 'The post has been created',
                    problem: 'There was a problem when creating the post'
                },
                updated: {
                    title: 'Post updated',
                    text: 'The post has successfully been updated',
                    problem: 'There was a problem when updating the post'
                },
                create: 'Create a new post',
                edit: 'Edit post',
                materialAlreadyExists: 'This material is already in the post',
                materialList: 'List of materials',
                emptyMaterialList: 'No materials have been added',
                addAMaterial: 'Add a material'
            },
            user: {
                title: 'Users',
                emptyList: 'No users found',
                list: 'Users list',
                deleted: {
                    title: 'User deleted',
                    text: 'The user has been deleted',
                    problem: 'There was a problem deleting this user'
                },
                created: {
                    title: 'User created',
                    text: 'The user has been created',
                    problem: 'There was a problem when creating the user'
                },
                updated: {
                    title: 'User updated',
                    text: 'The user has successfully been updated',
                    problem: 'There was a problem when updating the user'
                },
                create: 'Create a new user',
                edit: 'Edit user',
                cantDeleteSelf: 'You can\'t delete yourself',
                role: {
                    superAdmin: 'Super Admin',
                    admin: 'Admin',
                    user: 'User'
                },
                licenseExpiring: {
                    summary: 'Your account expires soon!',
                    detail: 'Your account expires in {{days}} days',
                },
            },
            gender: {
                title: 'Genders',
                emptyList: 'No genders found',
                list: 'Genders list',
                deleted: {
                    title: 'Gender deleted',
                    text: 'The gender has been deleted',
                    problem: 'There was a problem deleting this gender'
                },
                created: {
                    title: 'Gender created',
                    text: 'The gender has been created',
                    problem: 'There was a problem when creating the gender'
                },
                updated: {
                    title: 'Gender updated',
                    text: 'The gender has successfully been updated',
                    problem: 'There was a problem when updating the gender'
                },
                create: "Create a new gender",
                edit: "Edit gender"
            },
            country: {
                title: 'Countries',
                emptyList: 'No countries found',
                list: 'Countries list',
                deleted: {
                    title: 'Country deleted',
                    text: 'The country has been deleted',
                    problem: 'There was a problem deleting this country'
                },
                created: {
                    title: 'Country created',
                    text: 'The country has been created',
                    problem: 'There was a problem when creating the country'
                },
                updated: {
                    title: 'Country updated',
                    text: 'The country has successfully been updated',
                    problem: 'There was a problem when updating the country'
                },
                create: "Create a new country",
                edit: "Edit country"
            },
            origin: {
                title: 'Origins',
                emptyList: 'No origins found',
                list: 'Origins list',
                deleted: {
                    title: 'Origin deleted',
                    text: 'The origin has been deleted',
                    problem: 'There was a problem deleting this origin'
                },
                created: {
                    title: 'Origin created',
                    text: 'The origin has been created',
                    problem: 'There was a problem when creating the origin'
                },
                updated: {
                    title: 'Origin updated',
                    text: 'The origin has successfully been updated',
                    problem: 'There was a problem when updating the origin'
                },
                create: 'Create a new origin',
                edit: 'Edit origin'
            },
            priority: {
                title: 'Priorities',
                emptyList: 'No priorities found',
                list: 'Priorities list',
                deleted: {
                    title: 'Priority deleted',
                    text: 'The priority has been deleted',
                    problem: 'There was a problem deleting this priority'
                },
                created: {
                    title: 'Priority created',
                    text: 'The priority has been created',
                    problem: 'There was a problem when creating the priority'
                },
                updated: {
                    title: 'Priority updated',
                    text: 'The priority has successfully been updated',
                    problem: 'There was a problem when updating the priority'
                },
                create: 'Create a new priority',
                edit: 'Edit priority'
            },
            unit: {
                title: 'Units',
                emptyList: 'No units found',
                list: 'Units list',
                deleted: {
                    title: 'Unit deleted',
                    text: 'The unit has been deleted',
                    problem: 'There was a problem deleting this unit'
                },
                created: {
                    title: 'Unit created',
                    text: 'The unit has been created',
                    problem: 'There was a problem when creating the unit'
                },
                updated: {
                    title: 'Unit updated',
                    text: 'The unit has successfully been updated',
                    problem: 'There was a problem when updating the unit'
                },
                create: 'Create a new unit',
                edit: 'Edit unit'
            },
            provider: {
                title: 'Providers',
                emptyList: 'No providers found',
                list: 'Providers list',
                deleted: {
                    title: 'Provider deleted',
                    text: 'The provider has been deleted',
                    problem: 'There was a problem deleting this provider'
                },
                created: {
                    title: 'Provider created',
                    text: 'The provider has been created',
                    problem: 'There was a problem when creating the provider'
                },
                updated: {
                    title: 'Provider updated',
                    text: 'The provider has successfully been updated',
                    problem: 'There was a problem when updating the provider'
                },
                create: 'Create a new provider',
                edit: 'Edit provider'
            },
            category: {
                title: 'Categories',
                emptyList: 'No categories found',
                list: 'Categories list',
                deleted: {
                    title: 'Category deleted',
                    text: 'The category has been deleted',
                    problem: 'There was a problem deleting this category'
                },
                created: {
                    title: 'Category created',
                    text: 'The category has been created',
                    problem: 'There was a problem when creating the category'
                },
                updated: {
                    title: 'Category updated',
                    text: 'The category has successfully been updated',
                    problem: 'There was a problem when updating the category'
                },
                create: 'Create a new category',
                edit: 'Edit category'
            },
            setting: {
                title: 'Settings',
                list: {
                    title: "Lists"
                }
            },
            organisation: {
                title: 'Organisations',
                emptyList: 'No organisations found',
                list: 'Organisations list',
                deleted: {
                    title: 'Organisation deleted',
                    text: 'The organisation has been deleted',
                    problem: 'There was a problem deleting this organisation'
                },
                created: {
                    title: 'Organisation created',
                    text: 'The organisation has been created',
                    problem: 'There was a problem when creating the organisation'
                },
                updated: {
                    title: 'Organisation updated',
                    text: 'The organisation has successfully been updated',
                    problem: 'There was a problem when updating the organisation'
                },
                create: 'Create a new organisation',
                edit: 'Edit organisation',
                placeholders: {
                    name: 'Placeholders',
                    fullYear: 'Full year',
                    year: 'Year',
                    month: 'Month',
                    day: 'Day',
                    uniqueId: 'Unique Id',
                    examples: 'Examples'
                },
                examples: 'Examples',
                formats: 'Number Formats',
                clientFormat: 'Client Number Format',
                quoteFormat: 'Quote Number Format',
                invoiceFormat: 'Invoice Number Format',
                bankAccountNumber: 'Bank account number',
                billingInformation: 'Billing Information',
                contactInformation: 'Contact Information',
                change: 'Change the organisation context',
                googleDriveConfiguration: {
                    title: 'Google Drive Configuration',
                    serviceAccount: 'Service Account',
                    invoiceDirectory: 'Invoice Directory',
                    invoiceDirectoryDefault: 'Invoices',
                    quoteDirectory: 'Quote Directory',
                    quoteDirectoryDefault: 'Quotes',
                    documentsDirectory: 'Documents Directory',
                    documentsDirectoryDefault: 'Documents',
                    updated: {
                        title: 'Google Drive Configuration updated',
                        text: 'The configuration has successfully been updated',
                        problem: 'There was a problem when updating the configuration'
                    },
                    serviceAccountIsRequired: 'Service Account is required',
                    serviceAccountIsActive: 'An active service account is already configured',
                    serviceAccountRequired: {
                        summary: 'Service account required',
                        detail: 'A valid service account is required'
                    }
                },
                licenseExpiring: {
                    summary: 'Your organisation expires soon!',
                    detail: 'Your organisation license expires in {{days}} days',
                }
            },
            baseFiles: {
                    title: 'Base AutoCAD plan',
                    empty: 'Drag and Drop AutoCAD .dwg file here',
                    uploaded: {
                        title: 'AutoCAD uploaded',
                        text: 'The base plan has successfully been uploaded',
                        problem: 'Please upload a valid AutoCAD file (.dwg)'
                    },
                    downloaded: {
                        title: 'AutoCAD downloaded',
                        text: 'The base plan has successfully been downloaded',
                        problem: 'There was a problem when downloading the base plan'
                    },
            },
            downloads: {
                title: 'Downloads',
                text: 'The file has successfully been uploaded',
                problem: 'There was a problem when downloading the file'
            },
            logos: {
                title: 'Logo',
                empty: 'Drag and Drop Logo .png file here',
                uploaded: {
                    title: 'Logo uploaded',
                    text: 'The logo has successfully been uploaded',
                    problem: 'Please upload a valid logo file (.png)'
                },
                downloaded: {
                    title: 'Logo downloaded',
                    text: 'The logo has successfully been downloaded',
                    problem: 'There was a problem when downloading the logo'
                }
            },
            signature: {
                title: 'Signature',
                empty: 'Drag and Drop Signature .png file here',
                uploaded: {
                    title: 'Signature uploaded',
                    text: 'The signature has successfully been uploaded',
                    problem: 'Please upload a valid signature file (.png)'
                },
                downloaded: {
                    title: 'Signature downloaded',
                    text: 'The signature has successfully been downloaded',
                    problem: 'There was a problem when downloading the signature'
                }
            },

            validations: {
                email: "Email is invalid",
                invalid: "Field is invalid",
                invalidType: "Field is required",
                required: "Field is required",
                stringMin: "Must be at least {{min}} characters",
                stringMax: "Must be at most {{max}} characters",
                number: {
                    positive: "This value must be greater than 0"
                },
                phone:"Please enter a valid phone number"
            },

            serverValidation: {
                'This email is already used': 'This email is already used'
            },

            email:{
                title: 'E-mail',
                emptyList: 'No e-mails found',
                list: 'E-mails list',
                subject: 'Subject',
                body: 'Body',
                iban: 'Iban',
                companyName: 'Company Name',
                invoiceDate: 'Invoice Date',
                quoteDate: 'Quote Date',
                placeholders: 'Placeholders',
                validityDate: 'Validity Date',
                communication: 'Structured Communication',
                open:{
                    problem: 'E-mails address is invalid'
                },
                deleted: {
                    title: 'E-mail deleted',
                    text: 'The e-mail has been deleted',
                    problem: 'There was a problem deleting this e-mail'
                },
                created: {
                    title: 'E-mail created',
                    text: 'The e-mail has been created',
                    problem: 'There was a problem when creating the e-mail'
                },
                updated: {
                    title: 'E-mail updated',
                    text: 'The e-mail has successfully been updated',
                    problem: 'There was a problem when updating the e-mail'
                },
                create: "Create a new e-mail",
                edit: "Edit e-mail",
                template: 'Email template',
                upload: "Drag and Drop files here. (.png, .jpeg, .pdf, .dwg, .docx, .xlsx)"
            },
            status:{
                draft: "Draft",
                send: "Send",
                new: "New",
                reSend: "Resend",
                oldVersion: "Modified",
                lost: "Lost",
                sign: "Sign",
                invoice: "Invoice",
                credited: "Credited",
                billed: "Billed",
                error: "Error",
                finish: "Finish",
                change:{
                    title: 'Status updated',
                    text: 'The status has been updated',
                    problem: 'There was a problem updating this status'

                }
            },
            salecondition:{
                title: 'General conditions of sale',
                empty: 'Drag and Drop general conditions of sale .pdf file here',
                uploaded: {
                    title: 'General conditions of sale uploaded',
                    text: 'The general conditions of sale has successfully been uploaded',
                    problem: 'Please upload a valid general conditions of sale file (.pdf)'
                },
                downloaded: {
                    title: 'General conditions of sale downloaded',
                    text: 'The general conditions of sale has successfully been downloaded',
                    problem: 'There was a problem when downloading the general conditions of sale'
                }
            },
            timeline:{
                client:{
                    create: "Has been created by",
                    edit: "Has been edited by",
                    delete: "Has been deleted by",
                },
                quote:{
                    create: "A quote has been created by",
                    edit: "A quote Has been edit by",
                    delete: "A quote Has been deleted by",
                },
                invoice:{
                    create: "A invoice has been created by",
                    edit: "A invoice Has been edit by",
                    delete: "A invoice Has been deleted by",
                },
                section:{
                    create: "A section has been created by",
                    edit: "A section Has been edit by",
                    delete: "A section Has been deleted by",
                },
                post:{
                    create: "A post has been created by",
                    edit: "A post Has been edit by",
                    delete: "A post Has been deleted by",
                },
                material:{
                    create: "A material has been created by",
                    edit: "A material Has been edit by",
                    delete: "A material Has been deleted by",
                }
            },
            dashboard:{
                title: "Dashboard",
                grossSales: "Gross sales",
                maxQuote: "Most expensive quote",
                grossSalesEstimate: "Gross sales estimate",
                quoteSend: "Send quote",
                quoteResend: "Resend quote",
                quoteDraft: "Draft quote",
                quoteSign: "Sign quote",
                quoteEdit: "Edit quote",
                quoteLost: "Lost quote",
                quoteInvoice: "Invoiced quote",
                invoiceSend: "Send invoice",
                invoiceResend: "Resend invoice",
                invoiceDraft: "Draft invoice",
                invoiceBilled: "Billed invoice",
                invoiceCredited: "Credited invoice",
                samePeriod: "Last year",
                averageQuote: "Average quote",
                numberOfUnpaidInvoice: "Number of unpaid invoices",
                unpaidInvoice: "Unpaid invoice",
                origin: "Client origin",
            },
            theme:{
                title: "Theme",
                subtitle: "Theme",
                bootstrap4LightBlue: "Light blue",
                bootstrap4LightPurple: "Light purple",
                bootstrap4DarkBlue: "Dark blue",
                lightIndigo: "Light Indigo",
                lightDeeppurple: "Light Deeppurple",
                darkIndigo: "darkIndigo",
                DarkDeeppurple: "DarkDeeppurple",
                fluentLight: "fluentLight",
                laraLightBlue: "laraLightBlue",
                laraLightIndigo: "laraLightIndigo",
                laraLightPurple: "laraLightPurple",
                laraLightTeal: "laraLightTeal",
                laraDarkBlue: "laraDarkBlue",
                laraDarkIndigo: "laraDarkIndigo",
                laraDarkPurple: "laraDarkPurple",
                laraDarkTeal: "laraDarkTeal",
                sagaBlue: "sagaBlue",
                sagaGreen: "sagaGreen",
                sagaOrange: "sagaOrange",
                sagaPurple: "sagaPurple",
                velaBlue: "velaBlue",
                velaGreen: "velaGreen",
                velaOrange: "velaOrange",
                velaPurple: "velaPurple",
                aryaBlue: "aryaBlue",
                aryaGreen: "aryaGreen",
                aryaOrange: "aryaOrange",
                aryaPurple: "aryaPurple",
                nova: "nova",
                novaAlt: "novaAlt",
                novaAccent: "novaAccent",
                lunaAmber: "lunaAmber",
                lunaBlue: "lunaBlue",
                lunaGreen: "lunaGreen",
                lunaPink: "lunaPink",
                rhea: "rhea",
            }
        }
    },
    fr: {
        translation: {
            intlCurrency:"{{value, currency}}",
            auth:{
                passwordHasBeenSet:"Votre mot de passe a été défini avec succès, vous pouvez maintenant vous connecter",
                invalidLink:"Ce lien n'est plus valide, veuillez demander un nouveau lien.",
                couldNotReset:"Oups, nous n'avons pas pu réinitialiser votre mot de passe",
                choosePassword:"Choisissez votre mot de passe",
                passwordRequired:"Un mot de passe est requis",
                passwordLength:"Mot de passe doit être d'au moins 8 caractères",
                backToLogin:"Retour connexion",
                setPassword:"Définir le mot de passe",
                resetPasswordEmailSent:"Nous vous avons envoyé un e-mail avec un lien pour la réinitialisation de votre compte",
                resetPasswordFailed:"Oups, nous n'avons pas su vous envoyer de lien de réinitialisation",
                resetPassword:"Réinitialiser le mot de passe",
                emailRequired:"Les e-mails sont requis",
                emailInvalid:"Adresse e-mail non valide. Par exemple example@email.com",
                sendMeALink:"Envoie moi un lien",
                forgotPassword:"Mot de passe oublié?",
                login:"Se connecter",
                loginError:"Quelque chose s'est mal passé",
                logOutQuestion:"Êtes-vous sûr de vouloir vous déconnecter ?",
            },
            datatable:{
                reportTemplate:"Affichage de {first} à {last} sur {totalRecords} entrées"
            },
            common:{
                month:"[\"Janvier\",\"Février\",\"Mars\",\"Septembre\",\"Octobre\",\"Novembre\",\"Décembre\"]",
                printToPdf: 'Imprimer',
                info:"Information",
                notification:"Notifications",
                documents:"Documents",
                openFolder:"Ouvrier dossier",
                teamviewer:"TeamViewer",
                choose:"Choisir",
                upload:"Télécharger",
                download:"Télécharger",
                oops:"Oops",
                yes:"Oui",
                no:"Non",
                create:"Créer",
                list:"Liste",
                edit:"Sauvegarder",
                name:"Nom",
                unit:"Unité",
                price:"Prix",
                vat:"Tva",
                note:"Note",
                confirm:"Confirmer",
                confirmDelete:'Êtes-vous sûr de vouloir supprimer <strong> {{name}} </strong> de la liste?',
                confirmGeneratePdf: 'Êtes-vous sûr de vouloir générer le PDF',
                generatePdf: 'Générer PDF',
                logout:"Se déconnecter",
                createdAt:"Créé le",
                updatedAt:"Mise à jour le",
                material:"Matériel",
                post:"Poste",
                section:"Section",
                amount:"Quantité",
                state:"Etat",
                add:"Ajouter",
                gender:"Genre",
                email:"E-mail",
                phoneNumber:"Numéro de téléphone",
                provider:"Fournisseur",
                category:"Catégorie",
                city:"Ville",
                origin:"Origine",
                status:"Statut",
                houseNumber:"Numéro",
                postalCode:"Code Postal",
                country:"Pays",
                address:"Adresse",
                role:"Rôle",
                priority:"Priorité",
                clickToEdit:"Cliquez pour modifier",
                description:"Description",
                subTotal:"Sous-total",
                vatIncluded:"TVA incluse",
                pricePerUnit:"Prix par unité",
                validUntil:"Valable jusque",
                tbd:"À déterminer",
                newVersion:"Nouvelle version",
                empty:"Vide",
                fromCurrentVersion:"Avec contenu",
                other:"Autre",
                duplicate:"Dupliquer",
                notFound:"Nous n'avons pas pu trouver l'article que vous recherchiez",
                version:"Version",
                admin:"Administrateur",
                warning:"Avertissement",
                saveAsTemplate:"Enregistrer en tant que modèle",
                search:"Recherche",
                noCategory:"Pas de catégorie",
                noSection:"Pas de section",
                confirmation:"Confirmation",
                option:"Option",
                total:"Total",
                inOption:"En option",
                totalWithoutOption:"Total sans option",
                discount:"Réduction",
                addNote:"Ajouter une note",
                comments:"Commentaires",
                noComments:"Sans commentaires",
                addComment:"Ajouter un commentaire",
                anonymous:"Anonyme",
                send:"Envoyer",
                deletedAt:"Date d'expiration",
                deleted:"Supprimé",
                notDeleted:"Non supprimé",
                open:"Ouvrir",
                close:"Fermer",
                leavePage:"Si vous quittez cette page, vous perdrez vos données?",
                coefficient: "Coefficient",
                normal: "Normal",
                date:"Choisiez la date",
                problemGenerate: "Il y a eu un problème lors de la génération du pdf",
                variant: "Variante",
                copy: "Copier",
                paste: "Coller"
            },
            material:{
                title:"Matériaux",
                emptyList:"Pas de matériaux trouvés",
                list:"Liste de matériel",
                deleted:{
                    title:"Matériau supprimé",
                    text:"Le matériel a été supprimé",
                    problem:"Il y a eu un problème lors de la suppression de ce matériel"
                },
                created:{
                    title:"Matériel créé",
                    text:"Le matériel a été créé",
                    problem:"Il y a eu un problème lors de la création du matériel"
                },
                updated:{
                    title:"Matériel mis à jour",
                    text:"Le matériel a été mis à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour du matériel"
                },
                create:"Créer un nouveau matériel",
                edit:"Modifier le matériel"
            },
            client:{
                title:"Clients",
                emptyList:"Aucun client trouvé",
                name:"Nom du client",
                number:"Numéro client",
                list:"Liste des clients",
                deleted:{
                    title:"Client supprimé",
                    text:"Le client a été supprimé",
                    problem:"Il y a eu un problème lors de la suppression de ce client"
                },
                created:{
                    title:"Client créé",
                    text:"Le client a été créé",
                    problem:"Il y aeu un problème lors de la création du client"
                },
                updated:{
                    title:"Client mis à jour",
                    text:"Le client a été mis à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour du client"
                },
                create:"Créer un nouveau client",
                edit:"Modifier le client",
                formattedId:"N °",
                invoiceAddress:"Adresse de facturation",
                worksiteAddress:"Adresse de chantier",
                worksiteAddressIsInvoiceAddress:"L'adresse de facturation est la même que l'adresse de chantier",
                worksiteAddressIsNotTheInvoiceAddress:"L'adresse de facturation est différente de l'adresse de chantier",
                phone:{
                    problem: 'Numéro de téléphone invalide'
                },
            },
            quote:{
                title:"Devis",
                name:"Devis",
                emptyList:"Pas de devis trouvées",
                number:"Numéro client",
                list:"Liste des devis",
                multilines: "Ajouter multiples lignes",
                deleted:{
                    title:"Devis supprimé",
                    text:"Le devis a été supprimé",
                    problem:"Il y a eu un problème lors de la suppression de ce devis"
                },
                created:{
                    title:"Devis créé",
                    text:"Le devis a été créé",
                    problem:"Il y a eu un problème lors de la création du devis"
                },
                updated:{
                    title:"Devis mise à jour",
                    text:"Le devis a été mise à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour du devis"
                },
                create:"Créer un nouveau devis",
                edit:"Modifier le devis",
                emptySectionLines:"Aucune ligne n'a encore été ajoutée à la section",
                emptyPostLines:"Aucune ligne n'a encore été ajoutée au poste",
                emptyQuoteLines:"Aucune ligne n'a encore été ajoutée à le devis",
                confirmImportPost:"Souhaitez-vous importer {{name}}?",
                importPost:"Importer le poste?",
                pdf:{
                    problem:"Nous ne pouvions pas ouvrir le PDF",
                    open:"Open PDF",
                    editSentQuote: "Ce devis a déjà été envoyé au client. Si vous le modifié, le devis repassera en brouillon et il ne correspondra plus avec celui que le client a reçu. Êtes-vous sûr de vouloir le modifier ?"
                },
                sendToClient:{
                    success:"Envoyé",
                    successDetail:"Le devis a été envoyé au client avec succès",
                    tooltip:{
                        send:"Envoyer au client",
                        reSend:"Renvoyer au client"
                    },
                    problem:"Nous n'avons pas pu envoyer le devis au client, assurez-vous que les adresses e-mail sont enregistrées",
                    attachment:"La pièce jointe est trop volumineuse assurez-vous que la taille soit de max. 20 Mo",
                    modal:{
                        header:"Envoyer au client"
                    },
                    subject:"Sujet de l'E-mail",
                    body:"Ecrivez ici",
                    to:"À",
                    cc:"Cc",
                    bcc:"Cci"
                },
                newVersion:{
                    error:"Nous n'avons pas pu créer une nouvelle version"
                },
                wasSentToClient:"Ce devis a déjà été envoyée au client",
                dwg:{
                    add:"Ajouter un plan de base",
                    added:{
                        title:"Plan de base ajouté",
                        text:"Le plan de base a été créé dans votre Drive",
                        problem:"Le plan de base existe déjà dans votre Drive ou votre organisation n'a pas de plan de base"
                    },
                    tooltip:"Fichier DWG"
                },
                print:{
                    title:"Impression devis",
                    text:"Le devis a bien été imprimé",
                    problem:"Il y a eu un problème lors de l'impression du devis"
                },
                confirmSendToClient:"Souhaitez-vous envoyer ce devis au client?",
                optionPdf:{
                    title:"Paramètres PDF",
                    hex:"Couleur hexadécimale",
                    color:"Couleur PDF",
                    option:"Option PDF",
                    section:"Afficher le total de la section",
                    post:"Afficher le total du poste",
                    notes:"Afficher les notes",
                    postAmount:"Afficher la quantité du poste",
                    postPrice:"Afficher le prix unitaire du poste",
                    postUnit:"Afficher l'unité du poste",
                    font:"Police d'écriture",
                    quotePaymentCondition:"Condition de paiement pour les devis",
                    invoicePaymentCondition:"Condition de paiement pour les factures",
                    updated:{
                        title:"Paramètres du devis mis à jour",
                        text:"Les paramètres du devis ont été mis à jour avec succès",
                        problem:"Il y a eu un problème lors de la mise à jour des paramètres du devis"
                    }
                },
                lineOptionType:"Type",
                lineOptionTypeNormal:"Normal",
                lineOptionTypeOption:"Option",
                lineOptionTypeDiscount:"Réduction",
                lineOptionTypeVariant:"Variante",
                actions:"Actions",
                line:{
                    delete:"Supprimer"
                },
                totalWithOptionBeforeDiscount:"Total (avant la réduction et avec des options)",
                total:"Total",
                inOption:"Total option",
                discount:"Total réduction ",
                variant:"Total variante",
                totalAfterDiscountWithOptions:"Total (avec options)",
                totalAfterDiscountWithoutOptions:"Total (sans options)",
                restore:{
                    create:{
                        message:"Restaurer l'état précédent?",
                        header:"Restaurer"
                    }
                },
                template:{
                    title:"Modèles de devis",
                    list:"Liste des modèles de devis",
                    emptyList:"Aucun modèle de devis trouvé",
                    name:"Nom du modèle",
                    create:"Créer un nouveau modèle de devis",
                    edit:"Modifier le modèle de devis",
                    choice:"Choisissez le modèle de devis",
                    continue:"Continuer",
                    continueWithout:"Continuez sans modèle",
                    deleted:{
                        title:"Modèle de devis supprimé",
                        text:"Le modèle de devis a été supprimé",
                        problem:"Il y a eu un problème lors de la suppression de ce modèle"
                    },
                    created:{
                        title:"Modèle de devis créé",
                        text:"Le modèle de devis a été créé",
                        problem:"Il y a eu un problème lors de la création du modèle de devis"
                    },
                    updated:{
                        title:"Modèle de devis mis à jour",
                        text:"Le modèle de devis a été mis à jour avec succès",
                        problem:"Il y a eu un problème lors de la mise à jour du modèle de devis"
                    }
                },
                exportZip:{
                    title: "Export devis",
                    folderName: "Export-Devis",
                    text:"Export des devis avec succès",
                    empty:"Il n'y a pas de devis à exporter pour cette période"
                },
            },
            invoice:{
                title:"Factures",
                name:"Facture",
                emptyList:"Pas de factures trouvées",
                number:"Numéro de facture",
                list:"Liste des factures",
                deleted:{
                    title:"Facture supprimée",
                    text:"La facture a été supprimée",
                    problem:"Il y a eu un problème lors de la suppression de cette facture"
                },
                created:{
                    title:"Facture créée",
                    text:"La facture a été créée",
                    problem:"Il y a eu un problème lors de la création de la facture"
                },
                updated:{
                    title:"Facture mise à jour",
                    text:"La facture a été mise à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour de la facture"
                },
                create:"Créer une nouvelle facture",
                edit:"Modifier la facture",
                emptySectionLines:"Aucune ligne n'a encore été ajoutée à la section",
                emptyPostLines:"Aucune ligne n'a encore été ajoutée au poste",
                emptyInvoiceLines:"Aucune ligne n'a encore été ajoutée à la facture",
                confirmImportPost:"Souhaitez-vous importer {{name}}?",
                importPost:"Importer le poste ?",
                pdf:{
                    problem:"Nous ne pouvions pas ouvrir le PDF",
                    open:"Open PDF",
                    editSentInvoice: "Cette facture a déjà été envoyée au client. Si vous la modifiée, la facture repassera en brouillon et elle ne correspondra plus avec celle que le client a reçue. Êtes-vous sûr de vouloir la modifier ?"
                },
                sendToClient:{
                    success:"Envoyé",
                    successDetail:"La facture a été envoyée avec succès au client",
                    tooltip:{
                        send:"Envoyer au client",
                        reSend:"Renvoyer au client"
                    },
                    problem:"Nous n'avons pas pu envoyer la facture au client, assurez-vous qu'il y ait bien une adresse mail renseigné",
                    attachment:"La pièce jointe est trop volumineuse assurez-vous que la taille soit de max. 20 Mo",
                    modal:{
                        header:"Envoyer au client"
                    },
                    subject:"Sujet de l'E-mail",
                    body:"Ecrivez-ici",
                    to:"À",
                    cc:"Cc",
                    bcc:"Cci"
                },
                newVersion:{
                    error:"Nous n'avons pas pu créer une nouvelle version"
                },
                wasSentToClient:"Cette facture a déjà été envoyée au client",
                print: {
                    title: "Impression facture",
                    text: "La facture a bien été imprimé",
                    problem: "Il y a eu un problème lors de l'impression de la facture",
                },
                dwg:{
                    add:"Ajouter un plan de base",
                    added:{
                        title:"Plan de base ajouté",
                        text:"Le plan de base a été créé dans votre Drive",
                        problem:"Le plan de base existe déjà dans votre Drive ou votre organisation n'a pas de plan de base"
                    }
                },
                confirmSendToClient:"Souhaitez-vous envoyer cette facture au client ?",
                optionPdf:{
                    title:"Paramètres de facture",
                    hex:"Couleur hexadécimale",
                    color:"Couleur PDF",
                    option:"Option PDF",
                    section:"Afficher le total de la section",
                    post:"Afficher le total du poste",
                    notes:"Afficher les notes",
                    font:"Police d'écriture"
                },
                lineOptionType:"Type",
                lineOptionTypeNormal:"Normal",
                lineOptionTypeExtraCharge:"Supplément",
                lineOptionTypeCredit:"Crédit",
                actions:"Actions",
                line:{
                    delete:"Supprimer"
                },
                totalWithExtraChargeBeforeCredit:"Total (avec des frais supplémentaires)",
                total:"Total",
                inExtraCharge:"En supplément",
                credit:"Crédité",
                variant: "En variante",
                cocontractant: "cocontractant",
                totalAfterCreditWithExtraCharge:"Total",
                restore:{
                    create:{
                        message:"Restaurer l'état précédent ?",
                        header:"Restaurer"
                    }
                },
                template:{
                    title:"Modèles de facture",
                    list:"Liste des modèles de facture",
                    emptyList:"Aucun modèle de facture trouvé",
                    name:"Nom du modèle",
                    create:"Créer un nouveau modèle de facture",
                    edit:"Modifier le modèle de facture",
                    choice:"Choisissez le modèle de facture",
                    continue:"Continuer",
                    continueWithout:"Continuez sans modèle",
                    deleted:{
                        title:"Modèle de facture supprimé",
                        text:"Le modèle de facture a été supprimé",
                        problem:"Il y a eu un problème lors de la suppression de ce modèle"
                    },
                    created:{
                        title:"Modèle de facture créé",
                        text:"Le modèle de facture a été créé",
                        problem:"Il y a eu un problème lors de la création du modèle de facture"
                    },
                    updated:{
                        title:"Modèle de facture mis à jour",
                        text:"Le modèle de facture a été mis à jour avec succès",
                        problem:"Il y a eu un problème lors de la mise à jour du modèle de facture"
                    }
                },
                exportZip:{
                    title: "Export factures",
                    folderName: "Export-Factures",
                    text:"Export des factures avec succès",
                    empty:"Il n'y a pas de factures à exporter pour cette période"
                }
            },
            section:{
                title:"Sections",
                emptyList:"Pas de sections trouvées",
                list:"Liste des sections",
                deleted:{
                    title:"Section supprimée",
                    text:"La section a été supprimée",
                    problem:"Il y a eu un problème lors de la suppression de cette section"
                },
                created:{
                    title:"Section créée",
                    text:"La section a été créée",
                    problem:"Il y a eu un problème lors de la création de la section"
                },
                updated:{
                    title:"Section mise à jour",
                    text:"La section a été mise à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour de la section"
                },
                create:"Créer une nouvelle section",
                edit:"Modifier la section"
            },
            post:{
                title:"Postes",
                emptyList:"Aucun article trouvé",
                list:"Liste des postes",
                deleted:{
                    title:"Poste supprimé",
                    text:"Le poste a été supprimé",
                    problem:"Il y a eu un problème lors de la suppression de ce post"
                },
                created:{
                    title:"Post créé",
                    text:"Le poste a été créé",
                    problem:"Il y a eu un problème lors de la création du post"
                },
                updated:{
                    title:"Poste à jour",
                    text:"Le poste a été mis à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour du poste"
                },
                create:"Créer un nouveau poste",
                edit:"Modifier le poste",
                materialAlreadyExists:"Ce matériel est déjà dans le poste",
                materialList:"Liste des matériaux",
                emptyMaterialList:"Aucun matériau n'a été ajouté",
                addAMaterial:"Ajouter un matériau"
            },
            user:{
                title:"Utilisateurs",
                emptyList:"Aucun utilisateur trouvé",
                list:"Liste des utilisateurs",
                deleted:{
                    title:"Utilisateur supprimé",
                    text:"L'utilisateur a été supprimé",
                    problem:"Il y a eu un problème lors de la suppression de cet utilisateur"
                },
                created:{
                    title:"Utilisateur créé",
                    text:"L'utilisateur a été créé",
                    problem:"Il y a eu un problème lors de la création de l'utilisateur"
                },
                updated:{
                    title:"Utilisateur mis à jour",
                    text:"L'utilisateur a été mis à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour de l'utilisateur"
                },
                create:"Créer un nouvel utilisateur",
                edit:"Modifier l'utilisateur",
                cantDeleteSelf:"Vous ne pouvez pas vous supprimer vous même",
                role:{
                    superAdmin:"Super Administrateur",
                    admin:"Administrateur",
                    user:"Utilisateur"
                },
                licenseExpiring:{
                    summary:"Votre compte expire bientôt!",
                    detail:"Votre compte expire dans {{days}} jours"
                }
            },
            gender:{
                title:"Genders",
                emptyList:"Pas de genres trouvés",
                list:"Liste des genres",
                deleted:{
                    title:"Genre supprimé",
                    text:"Le genre a été supprimé",
                    problem:"Il y a eu un problème lors de la suppression ce genre"
                },
                created:{
                    title:"Genre créé",
                    text:"Le genre a été créé",
                    problem:"Il y a eu un problème lors de la création du genre"
                },
                updated:{
                    title:"Genre mis à jour",
                    text:"Le genre a été mis à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour du genre"
                },
                create:"Créer un nouveau genre",
                edit:"Modifier le genre"
            },
            country:{
                title:"Pays",
                emptyList:"Pas de pays trouvés",
                list:"Liste des pays",
                deleted:{
                    title:"pays supprimé",
                    text:"Le pays a été supprimé",
                    problem:"Il y a eu un problème lors de la suppression ce pays"
                },
                created:{
                    title:"Pays créé",
                    text:"Le pays a été créé",
                    problem:"Il y a eu un problème lors de la création du pays"
                },
                updated:{
                    title:"Pays mis à jour",
                    text:"Le pays a été mis à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour du pays"
                },
                create:"Créer un nouveau pays",
                edit:"Modifier le pays"
            },
            origin:{
                title:"Origines",
                emptyList:"Aucune origine trouvée",
                list:"Liste d'origine",
                deleted:{
                    title:"Origine supprimée",
                    text:"L'origine a été supprimée",
                    problem:"Il y a eu un problème lors de la suppression de cette origine"
                },
                created:{
                    title:"Origine créée",
                    text:"L'origine a été créée",
                    problem:"Il y a eu un problème lors de la création de l'origine"
                },
                updated:{
                    title:"Origine mise à jour",
                    text:"L'origine a été mise à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour de l'origine"
                },
                create:"Créer une nouvelle origine",
                edit:"Modifier l'origine"
            },
            priority:{
                title:"Priorités",
                emptyList:"Aucune priorité trouvée",
                list:"Liste des priorités",
                deleted:{
                    title:"Priorité supprimée",
                    text:"La priorité a été supprimée",
                    problem:"Il y a eu un problème lors de la suppression de cette priorité"
                },
                created:{
                    title:"Priorité créée",
                    text:"La priorité a été créée",
                    problem:"Il y a eu un problème lors de la création de la priorité"
                },
                updated:{
                    title:"Priorité mise à jour",
                    text:"La priorité a été mise à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour de la priorité"
                },
                create:"Créer une nouvelle priorité",
                edit:"Modifier la priorité"
            },
            unit:{
                title:"Unités",
                emptyList:"Pas d'unités trouvées",
                list:"Liste des unités",
                deleted:{
                    title:"Unité supprimée",
                    text:"L'unité a été supprimée",
                    problem:"Il y a eu un problème lors de la suppression de cette unité"
                },
                created:{
                    title:"Unité créée",
                    text:"L'unité a été créée",
                    problem:"Il y a eu un problème lors de la création de l'unité"
                },
                updated:{
                    title:"Unité mise à jour",
                    text:"L'unité a été mise à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour de l'unité"
                },
                create:"Créer une nouvelle unité",
                edit:"Modifier l'unité"
            },
            provider:{
                title:"Fournisseurs",
                emptyList:"Aucun fournisseur trouvé",
                list:"Liste des fournisseurs",
                deleted:{
                    title:"Fournisseur supprimé",
                    text:"Le fournisseur a été supprimé",
                    problem:"Il y a eu un problème lors de la suppression de ce fournisseur"
                },
                created:{
                    title:"Fournisseur créé",
                    text:"Le fournisseur a été créé",
                    problem:"Il y a eu un problème lors de la création du fournisseur"
                },
                updated:{
                    title:"Fournisseur mis à jour",
                    text:"Le fournisseur a été mis à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour du fournisseur"
                },
                create:"Créer un nouveau fournisseur",
                edit:"Modifier le fournisseur"
            },
            category:{
                title:"Catégories",
                emptyList:"Pas de catégories trouvées",
                list:"Liste des catégories",
                deleted:{
                    title:"Catégorie supprimée",
                    text:"La catégorie a été supprimée",
                    problem:"Il y a eu un problème lors de la suppression de cette catégorie"
                },
                created:{
                    title:"Catégorie créée",
                    text:"La catégorie a été créée",
                    problem:"Il y a eu un problème lors de la création de la catégorie"
                },
                updated:{
                    title:"Catégorie mise à jour",
                    text:"La catégorie a été mise à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour de la catégorie"
                },
                create:"Créer une nouvelle catégorie",
                edit:"Modifier la catégorie"
            },
            setting:{
                title:"Paramètres",
                list:{
                    title:"Listes"
                }
            },
            organisation:{
                title:"Organisations",
                emptyList:"Aucune organisation trouvée",
                list:"Liste des organisations",
                deleted:{
                    title:"Organisation supprimée",
                    text:"L'organisation a été supprimée",
                    problem:"Il y a eu un problème lors de la suppression de cette organisation"
                },
                created:{
                    title:"Organisation créée",
                    text:"L'organisation a été créée",
                    problem:"Il y a eu un problème lors de la création de l'organisation"
                },
                updated:{
                    title:"Organisation mise à jour",
                    text:"L'organisation a été mise à jour avec succès",
                    problem:"Il y a eu un problème lors de la mise à jour de l'organisation"
                },
                create:"Créer une nouvelle organisation",
                edit:"Modifier l'organisation",
                placeholders:{
                    name:"Création des numéros",
                    fullYear:"Année sur 4 chiffres",
                    year:"Année sur 2 chiffres",
                    month:"Mois",
                    day:"Jour",
                    uniqueId:"Compteur du document",
                    examples:"Exemples"
                },
                examples:"Exemples",
                formats:"Formats numériques",
                clientFormat:"Format du numéro de client",
                quoteFormat:"Format du numéro de devis",
                invoiceFormat:"Format du numéro de facture",
                bankAccountNumber:"Numéro de compte bancaire",
                billingInformation:"Informations de facturation",
                contactInformation:"Contact",
                change:"Changer d'organisation",
                googleDriveConfiguration:{
                    title:"Configuration de Google Drive",
                    serviceAccount:"Compte de service",
                    invoiceDirectory:"Nom du dossier pour les factures",
                    invoiceDirectoryDefault:"Factures",
                    quoteDirectory:"Nom du dossier pour les devis",
                    quoteDirectoryDefault:"Devis",
                    documentsDirectory:"nom du dossier pour les documents",
                    documentsDirectoryDefault:"Documents",
                    updated:{
                        title:"Configuration de Google Drive",
                        text:"La configuration a été mise à jour avec succès",
                        problem:"Il y a eu un problème lors de la mise à jour de la configuration"
                    },
                    serviceAccountIsRequired:"Le compte de service est requis",
                    serviceAccountIsActive:"Un compte de service est déjà configuré",
                    serviceAccountRequired:{
                        summary:"Compte de service requis",
                        detail:"Un compte de service valide est requis"
                    }
                },
                licenseExpiring:{
                    summary:"Votre organisation expire bientôt!",
                    detail:"Votre organisation expire dans {{days}} jours"
                }
            },
            baseFiles:{
                title:"Plan de base Autocad",
                empty:"Glisser et déposer votre fichier Autocad (.dwg) ici",
                uploaded:{
                    title:"Autocad Téléchargé",
                    text:"Le plan de base a été téléchargé avec succès",
                    problem:"Veuillez télécharger un fichier Autocad valide (.dwg)"
                },
                downloaded:{
                    title:"Autocad téléchargé",
                    text:"Le plan de base a été téléchargé avec succès",
                    problem:"Il y a eu un problème lors du téléchargement du plan de base"
                }
            },
            downloads:{
                title:"Téléchargements",
                text:"Le fichier a été téléchargé avec succès",
                problem:"Il y a eu un problème lors du téléchargement du fichier"
            },
            logos:{
                title:"Logo",
                empty:"Glisser et déposer votre logo (.png) ici",
                uploaded:{
                    title:"Logo Téléchargé",
                    text:"Le logo a été téléchargé avec succès",
                    problem:"Veuillez télécharger un logo valide (.png)"
                },
                downloaded:{
                    title:"Logo téléchargé",
                    text:"Le logo a été téléchargé avec succès",
                    problem:"Il y a eu un problème lors du téléchargement du logo"
                }
            },
            signature:{
                title:"Signature",
                empty:"Glisser et déposer votre signature (.png) ici",
                uploaded:{
                    title:"Signature téléchargée",
                    text:"La signature a été téléchargée avec succès",
                    problem:"Veuillez télécharger un fichier de signature valide (.png)"
                },
                downloaded:{
                    title:"Signature téléchargée",
                    text:"La signature a été téléchargée avec succès",
                    problem:"Il y a eu un problème lors du téléchargement de la signature"
                }
            },
            validations:{
                email:"E-mail est invalide",
                invalid:"Le champ est invalide",
                invalidType:"Champ requis",
                required:"Champ requis",
                stringMin:"Doit être au moins de {{min}} caractères",
                stringMax:"Doit être maximun de {{max}} caractères",
                number:{
                    positive:"Cette valeur doit être supérieure à 0"
                },
                phone:"Veuillez entrer un numéro de téléphone valide"
            },
            serverValidation:{
                "This email is already used":"Cet e-mail est déjà pris"
            },
            email:{
                title:"E-mail",
                emptyList:"Aucun e-mail trouvé",
                list:"Liste des e-mails",
                subject:"Sujet",
                body:"Ecrivez ici",
                iban:"Iban",
                companyName:"Nom de l'entreprise",
                invoiceDate: 'Date de la facture',
                quoteDate:"Date du devis",
                placeholders:"Variables",
                validityDate:"Date de validité",
                communication: 'Communication Structurée',
                open:{
                    problem:"L'adresse e-mail n'est pas valide"
                },
                deleted:{
                    title:"E-mail supprimé",
                    text:"L'e-mail a été supprimé",
                    problem:"Il y a eu un problème lors de la suppression de cet e-mail"
                },
                created:{
                    title:"E-mail créé",
                    text:"L'e-mail a été créé",
                    problem:"Il y a eu un problème lors de la création de l'e-mail"
                },
                updated:{
                    title:"E-mail mis à jour",
                    text:"L'e-mail a été mis à jour avec succès",
                    problem:"Il y avait un problème lors de la mise à jour de l'e-mail"
                },
                create:"Créer un nouvel e-mail",
                edit:"Modifier l'E-mail",
                template:"Modèle d'E-mail",
                upload:"Glisser et déposez des fichiers ici. (.Png, .jpeg, .pdf, .dwg, .docx, .xlsx)"
            },
            status:{
                draft:"Brouillon",
                send:"Envoyé",
                new:"Nouveau",
                reSend:"Renvoyé",
                oldVersion:"Modifié",
                lost:"Perdu",
                sign:"Signé",
                invoice:"En facturation",
                credited:"Crédité",
                billed:"Payé",
                error:"Erreur",
                finish:"Cloturé",
                change:{
                    title:"Statut mis à jour",
                    text:"Le statut a été mis à jour",
                    problem:"Il y a eu un problème lors de la à jour de ce statut"
                }
            },
            salecondition:{
                title:"Conditions générales de vente",
                empty:"Glisser et déposez les conditions générales de vente (.pdf) ici",
                uploaded:{
                    title:"Conditions générales de vente téléchargées",
                    text:"Les conditions générales de vente ont été téléchargées avec succès",
                    problem:"Veuillez télécharger des générales de vente valide (.pdf)"
                },
                downloaded:{
                    title:"Conditions générales de vente téléchargées",
                    text:"Les conditions générales de vente ont été téléchargées avec succès",
                    problem:"Il y a eu un problème lors du téléchargement des conditions générales de vente"
                }
            },
            timeline:{
                client:{
                    create:"A été créé par",
                    edit:"A été modifié par",
                    delete:"A été supprimé par"
                },
                quote:{
                    create:"A été créé par",
                    edit:"A été modifié par",
                    delete:"A été supprimé par"
                },
                invoice:{
                    create:"Aété créée par",
                    edit:"A été modifiée par",
                    delete:"A été supprimée par"
                },
                section:{
                    create:"A été créée par",
                    edit:"A été modifiée par",
                    delete:"A été supprimée par"
                },
                post:{
                    create:"A été créé par",
                    edit:"A été modifié par",
                    delete:"A été supprimé par"
                },
                material:{
                    create:"A été créé par",
                    edit:"A été modifié par",
                    delete:"A été supprimé par"
                }
            },
            dashboard:{
                title:"Tableau de bord",
                grossSales:"Ventes totales",
                maxQuote:"Devis le plus chère",
                grossSalesEstimate:"Estimation des ventes",
                quoteSend:"Devis envoyés",
                quoteResend:"Devis renvoyés",
                quoteDraft:"Devis en brouillions",
                quoteSign:"Devis signés",
                quoteEdit:"Devis modifiés",
                quoteLost:"Devis perdus",
                quoteInvoice:"Devis facturés",
                invoiceSend:"Factures envoyés",
                invoiceResend:"Factures renvoyés",
                invoiceDraft:"Factures en brouillions",
                invoiceBilled:"Factures payées",
                invoiceCredited:"Factures créditées",
                samePeriod:"L'année précédente",
                averageQuote:"Prix moyen d'un devis",
                numberOfUnpaidInvoice:"Nombre de factures impayées",
                unpaidInvoice:"Facture impayée",
                origin:"Origine du client"
            },
            theme:{
                title: "Thème",
                subtitle: "Thème de l'application",
                bootstrap4LightBlue: "Bleu et blanc",
                bootstrap4DarkBlue: "Bleu et noir",
                bootstrap4LightPurple: "Mauve et blanc",
                bootstrap4DarktPurple: "Mauve et noir",
                lightIndigo: "Indigo et blanc",
                lightDeeppurple: "Indigo et blanc",
                darkIndigo: "darkIndigo",
                DarkDeeppurple: "DarkDeeppurple",
                fluentLight: "fluentLight",
                laraLightBlue: "laraLightBlue",
                laraLightIndigo: "laraLightIndigo",
                laraLightPurple: "laraLightPurple",
                laraLightTeal: "laraLightTeal",
                laraDarkBlue: "laraDarkBlue",
                laraDarkIndigo: "laraDarkIndigo",
                laraDarkPurple: "laraDarkPurple",
                laraDarkTeal: "laraDarkTeal",
                sagaBlue: "sagaBlue",
                sagaGreen: "sagaGreen",
                sagaOrange: "sagaOrange",
                sagaPurple: "sagaPurple",
                velaBlue: "velaBlue",
                velaGreen: "velaGreen",
                velaOrange: "velaOrange",
                velaPurple: "velaPurple",
                aryaBlue: "aryaBlue",
                aryaGreen: "aryaGreen",
                aryaOrange: "aryaOrange",
                aryaPurple: "aryaPurple",
                nova: "nova",
                novaAlt: "novaAlt",
                novaAccent: "novaAccent",
                lunaAmber: "lunaAmber",
                lunaBlue: "lunaBlue",
                lunaGreen: "lunaGreen",
                lunaPink: "lunaPink",
                rhea: "rhea",

            }
        }
    }
};

i18n
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        resources,
        lng: "fr",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
