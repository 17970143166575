import * as yup from "yup";

export const toolBarWidth = '150px';
export const vatWidth = '50px';
export const unitWidth = '50px';

export const LineItemTypes = {
    MATERIAL: 'mat',
    POST: 'post',
    SECTION: 'section'
};

export const materialLineSchema = () =>  yup.object({
    pricePerUnit: yup.number().required().min(0),
    description: yup.string().required(),
    amount: yup.number().required().moreThan(0),
    unit: yup.string().required(),
    vat: yup.number().required(),
    subTotal: yup.number().required().min(0),
    note: yup.string().nullable(),
    option: yup.bool()
});

export const postLineSchema = () =>  yup.object({
    pricePerUnit: yup.number().required().min(0),
    description: yup.string().required(),
    amount: yup.number().required().moreThan(0),
    unit: yup.string().required(),
    subTotal: yup.number().required().min(0),
    note: yup.string().nullable(),
    option: yup.bool()
});

export const sectionLineSchema = () =>  yup.object({
    description: yup.string().required(),
    subTotal: yup.number().required().moreThan(0),
    note: yup.string().nullable(),
    option: yup.bool()
});

export const invoiceLineSchema = () =>  yup.object({
    subTotal: yup.number().required().min(0)
});

export const invoiceCommentSchema = () => yup.object({
    message: yup.string().required().max(255)
});

export const sendInvoiceToClientSchema = () => yup.object({
    subject: yup.string().required().max(255),
    to: yup.array().of(yup.string().email()).min(1),
    cc: yup.array().of(yup.string().email()),
    bcc: yup.array().of(yup.string().email()),
    body: yup.string().required()
});
