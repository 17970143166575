import {useTranslation} from "react-i18next";
import {Menu} from "primereact/menu";
import {Button} from "primereact/button";
import {useRef} from "react";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { FormErrorMessage } from "./FormErrorMessage";

export function CoefficientInput({fieldName, control, choice, setValue}) {

    const {t} = useTranslation();

    const coefficientOptions = [
        {
            label: "+ 30%",
            value: "30",
            command:() => {
                setValue(30)
            }
        },
        {
            label: "+ 15%",
            value: "15",
            command:() => {
                setValue(15)
            }
        },
        {
            label: "+ 10%",
            value: "10",
            command:() => {
                setValue(10)
            }
        },
        {
            label: "+ 5%",
            value: "5",
            command:() => {
                setValue(5)
            }
        },
        {
            label: t("common.normal"),
            value: "1",
            command:() => {
                setValue(1)
            }
        },
    ];

    const menu = useRef(null);

    return (

        <> {
            control._formValues.coefficient === '1' ? (
                <>
                    <Menu model={coefficientOptions} popup ref={menu} id="popup_menu" />
                    <Button label={t("common.coefficient")} onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                </>
            ) : (

                <>
                    <Menu model={coefficientOptions} popup ref={menu} id="popup_menu" />
                    <Button label={"+ " + control._formValues.coefficient + "%"} className="p-button p-button-warning" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                </>
            )
        }
        </>

    )
}
