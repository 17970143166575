import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import providerService from "./providers.service";


export function useProviders() {
    const {data: providers, isFetched, isLoading, isError} = useQuery(['provider'], providerService.all, {
        initialData: []
    });

    return {
        providers,
        isLoading,
        isFetched,
        isError
    };
}

export function useProvider(id, options = null) {
    const {data: provider, isLoading, isError} = useQuery(['providers', id], ({queryKey}) => providerService.get(queryKey[1]), options);

    return {
        provider, isLoading, isError
    };
}

export function useUpdateProviderMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => providerService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['providers']);
        }
    });

    return mutation;
}


export function useCreateProviderMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(providerService.create, {
        onSuccess: (data) => {
            const providers = queryClient.getQueryData(['providers']) ?? [];
            queryClient.setQueryData(['providers'], [...providers, data]);
        }
    });

    return mutation;
}

export function useDeleteProviderMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(providerService.delete, {
        onSuccess: (data, variables) => {
            const providers = queryClient.getQueryData(['providers']) ?? [];
            queryClient.setQueryData(['providers'], providers.filter(x => x.id !== variables))
        }
    });

    return mutation;
}