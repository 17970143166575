import {Controller} from "react-hook-form";
import {InputNumber} from "primereact/inputnumber";
import {classNames} from "primereact/utils";
import {FormErrorMessage} from "../FormErrorMessage";


export function CurrencyControl({fieldName, label, control}) {
    return (
        <>
            <Controller name={fieldName} control={control}
                        render={({field, fieldState}) => (
                            <>
                                <span className="p-float-label">
                                <InputNumber mode="currency"
                                             currency="EUR"
                                             onBlur={field.onBlur}
                                             value={field.value}
                                             ref={field.ref}
                                             onValueChange={(e) => field.onChange(e.value)}
                                             className={classNames({'p-invalid': fieldState.error})}/>
                                <label htmlFor={field.name}
                                       className={classNames({'p-error': !!fieldState.error})}>{label}</label>
                            </span>
                                <FormErrorMessage error={fieldState.error}/>
                            </>
                        )}/>
        </>
    )
}