import api from "../../services/api";

class GenderService {
    all() {
        return api.get('api/genders')
            .then(function(x) {
                return x.data;
            });
    }

    delete(id) {
        return api.delete('api/genders/'+id);
    }

    get(id) {
        return api.get('api/genders/'+id)
            .then(x => x.data);
    }

    create(data) {
        return api.post('api/genders', JSON.stringify(data))
            .then(x => x.data);
    }

    update(id, data) {
        return api.post('api/genders/' + id, JSON.stringify(data))
            .then(x => x.data);
    }
}

export default new GenderService();