import api from "../services/api";

class DashboardService {

    all() {
        return api.get('api/dashboard')
            .then(function(x) {
                return x.data;
            });
    }

}

export default new DashboardService();
