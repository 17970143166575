import {useForm} from "react-hook-form";
import {InvoiceContext, useInvoiceContext} from "../invoice.context";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {PostDescriptionInput} from "./description.input";
import {CurrencyInput} from "./currency.input";
import {AmountInput} from "./amount.input";
import {UnitInput} from "./unit.input";
import {postLineSchema, toolBarWidth} from "../constants";
import {Button} from "primereact/button";
import {InvoiceMaterialLine} from "./invoice-material.line";
import {useLineDnd} from "./use-line.dnd";
import {observer} from "mobx-react";
import {Currency} from "./currency";
import {yupResolver} from "@hookform/resolvers/yup";
import postsService from "../../posts/posts.service";
import {confirmDialog} from "primereact/confirmdialog";
import {classNames} from "primereact/utils";
import {NoteInput} from "./note.input";
import {Menu} from "primereact/menu";
import { CoefficientInput } from "../../core/coefficient.input";

export const InvoicePostLine = observer(({post, index, parent, isCococontractor}) => {

    const defaultValues = {
        pricePerUnit: post.pricePerUnit.toFixed(2),
        amount: post.amount.toFixed(2),
        unit: post.unit,
        note: post.note,
        description: post.description,
        subTotal: post.subTotal.toFixed(2),
        option: post.option,
        coefficient: post.coefficient.toString(), // Needs to be a string to be the selectedValue in dropdown
    };

    const {control, handleSubmit, watch, setValue, formState} = useForm({defaultValues: defaultValues, mode: 'all', resolver: yupResolver(postLineSchema())});

    const invoiceModel = useInvoiceContext();

    useEffect(() => {
        invoiceModel.setFormState(post.id, formState.isValid);

        return () => invoiceModel.unRegisterForm(post.id);
    }, [formState.isValid, post.id, invoiceModel]);

    const onSubmit = useCallback((data) => {
        post.updateFromForm(data);
    }, [post]);

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            //console.log(name, type);
            if(type === 'change') {
                //handleSubmit(onSubmit)()
                switch (name) {
                    case 'description':
                        post.updateDescription(value.description);
                        break;
                    case 'pricePerUnit':
                        post.updatePricePerUnit(value.pricePerUnit);
                        break;
                    case 'amount':
                        post.updateAmount(value.amount);
                        break;
                    case 'subTotal':
                        post.updateSubTotal(value.subTotal);
                        break;
                    case 'unit':
                        post.updateUnit(value.unit);
                        break;
                    case 'note':
                        post.updateNote(value.note);
                        break;
                    case 'option':
                        post.updateOption(value.option);
                        break;
                    case 'coefficient':
                        post.updateCoefficient(value.coefficient);
                        break;    
                    default:
                        throw new Error("Unimplemented type "+name)
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [post, watch]);

    useEffect(() => {
        setValue('description', defaultValues.description, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.description, setValue]);

    useEffect(() => {
        setValue('option', defaultValues.option, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.option, setValue]);

    useEffect(() => {
        setValue('note', defaultValues.note, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.note, setValue]);

    useEffect(() => {
        setValue('amount', defaultValues.amount, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.amount, setValue]);

    useEffect(() => {
        setValue('unit', defaultValues.unit, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.unit, setValue]);

    useEffect(() => {
        setValue('coefficient', defaultValues.coefficient, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.coefficient, setValue]);

    useEffect(() => {
        setValue('pricePerUnit', defaultValues.pricePerUnit, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.pricePerUnit, setValue]);

    useEffect(() => {
        setValue('subTotal', defaultValues.subTotal, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.subTotal, setValue]);

    const {t} = useTranslation();

    const {ref, drag, isDragging, handlerId} = useLineDnd(post, index, parent);


    const onPostSelected = useCallback(async (postListItem) => {
        const postDetails = await postsService.get(postListItem.id);

        if(post.lines.length > 0) {
            //setPostDetails(postDetails);

            confirmDialog({
                message: t('invoice.confirmImportPost', {name: postDetails.name}),
                header: t('invoice.importPost'),
                icon: 'pi pi-exclamation-triangle',
                accept: () => post.updateFromPost(postDetails)
            })

        } else {
            post.updateFromPost(postDetails);
        }

    }, [post, t]);

    const [autoFocusNote, setAutoFocusNote] = useState(false);

    const { copiedLine, setCopiedLine } = useContext(InvoiceContext);
    const optionMenuRef = useRef(null);
    const optionMenuItems = useMemo(() => {
        const items = [
            {
                label: t('invoice.lineOptionType'),
                items: [
                    {
                        label: t('invoice.lineOptionTypeNormal'),
                        icon: !post.extraCharge && !post.credit && !post.variant ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            post.updateExtraCharge(false);
                            post.updateCredit(false);
                            post.updateVariant(false);
                        },
                    },
                    {
                        label: t('invoice.lineOptionTypeExtraCharge'),
                        icon: post.extraCharge ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            post.updateExtraCharge(true);
                            post.updateCredit(false);
                            post.updateVariant(false);
                        },
                    },
                    {
                        label: t('invoice.lineOptionTypeCredit'),
                        icon: post.credit ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            post.updateCredit(true);
                            post.updateExtraCharge(false);
                            post.updateVariant(false);
                        },
                    },
                    {
                        label: t('common.variant'),
                        icon: post.variant ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            post.updateCredit(false);
                            post.updateExtraCharge(false);
                            post.updateVariant(true);
                        },
                    }
                ]
            },
            {
                label: t('invoice.actions'),
                items: [
                    {
                        label: t('common.addNote'),
                        icon: 'pi pi-comment text-green-500',
                        command: () => {
                            if(post.note === null) {
                                setAutoFocusNote(true);
                                post.updateNote("");
                            }
                        }
                    },
                    {
                        label: t('common.material'),
                        icon: 'pi pi-plus text-blue-500',
                        command: () => {
                            post.addMaterial();
                        }
                    },
                    {
                        label: t('invoice.line.delete'),
                        icon: 'pi pi-trash text-red-500',
                        command: () => {
                            parent.removeLine(post)
                        }
                    },
                    {
                        label: t('common.copy'),
                        icon: 'pi pi-copy text-grey-500',
                        command: () => {
                            setCopiedLine(post)
                        }
                    },
                    copiedLine && ((copiedLine.type === 'post' && parent.type === 'section') || (parent.type !== 'section')) && {
                        label: t('common.paste'),
                        icon: 'pi pi-file text-grey-500',
                        command: () => {
                            const item = copiedLine;
                            setCopiedLine(null)
                            parent.pasteLine(item, post);
                        }
                    },
                ].filter(Boolean) // Remove falsy values (in case "copiedItem" doesn't exist)
            }
        ];

        return items;
    }, [t, post.extraCharge, post.credit, post.variant, parent, post, copiedLine]);

    return (
        <>

            <div className="mb-2 mt-2" ref={ref} data-hanlder-id={handlerId} style={{opacity: isDragging ? 0 : 1}}>
                <form onSubmit={handleSubmit(onSubmit)} className={classNames("grid grid-nogutter", {
            "bg-red-100": post.extraCharge,
            "bg-green-100": post.credit,
            "bg-purple-100": post.variant,
        })}>
                    <div className="col flex align-items-start flex-wrap">
                        <div className="grid grid-nogutter w-full">
                            <div className="col-fixed w-1rem pr-1 pt-2"><strong>{index}</strong></div>
                            <div className="col flex flex-wrap align-items-start">
                                <PostDescriptionInput inputClassName="p-inputtext font-bold w-full" control={control}
                                                      fieldName="description" autoFocus={post.shouldFocus} displayTemplate={(value, error) => {
                                    if (error || !value) {
                                        return (t('common.clickToEdit'))
                                    }

                                    return (<strong style={{'whiteSpace': 'pre-wrap'}}>{value}</strong>);
                                }} onPostSelected={onPostSelected} />
                            </div>
                        </div>
                    </div>
                    {post.lines.length === 0 && (
                        <div className="col-6 flex align-items-start  justify-content-center">
                            <span className={"text-red-500"}>{t('invoice.emptyPostLines')}</span>
                        </div>
                    )}
                    {post.lines.length > 0 && (
                        <>
                            <div className="col-1 flex align-items-start flex-wrap text-primary">
                                <CurrencyInput control={control} fieldName="pricePerUnit" label={t('common.price')} showLabel addStyle={"font-bold"}/>
                            </div>
                            <div className="col-1 flex align-items-start flex-wrap">
                                <AmountInput control={control} fieldName="amount" label={t('common.amount')} showLabel addStyle={"font-bold"}/>
                            </div>
                            <div className="col-1 flex align-items-start flex-wrap">
                                <UnitInput control={control} fieldName="unit" label={t('common.unit')} showLabel addStyle={"font-bold"}/>
                            </div>
                            <div className="col-1 flex align-items-start flex-wrap">
                                <CoefficientInput control={control} fieldName="coefficient" label={t('common.coefficient')} setValue={(newValue) => post.updateCoefficient(newValue)} />
                            </div>
                            <div className="col-1 flex align-items-start flex-wrap">
                                <strong className="text-primary m-1 p-2 text-sm"><Currency value={post.subTotal}></Currency></strong>
                            </div>

                        </>
                    )}
                    <div className="col-fixed flex align-items-start justify-content-end  pt-1"
                         style={{width: toolBarWidth}}
                    >
                        <Button type="button" icon={"pi pi-plus"} className={"p-button-text p-button-sm p-button-info"} onClick={(e) => {
                            post.addMaterial();
                        }}/>
                        <Button type="button" icon={"pi pi-trash"} className={"p-button-text p-button-sm p-button-danger"}
                                onClick={() => {
                                    parent.removeLine(post)
                                }}/>
                        <Button type="button" ref={drag} icon={"pi pi-bars"} className={"p-button-text p-button-sm p-button-secondary cursor-move"}></Button>
                        <Button type="button" icon={"pi pi-ellipsis-v"} className={"p-button-text p-button-sm p-button-info"} onClick={(e) => optionMenuRef.current.toggle(e)}/>
                        <Menu model={optionMenuItems} popup ref={optionMenuRef} id="popup_menu" />
                    </div>

                    {post.note !== null && (
                        <>
                            <div className="grid grid-nogutter col-12">
                                <div className={classNames('col-fixed text-right', {'w-2rem': true})}><i className={"pi pi-comment text-sm mt-3"}></i></div>
                                <div className="col flex align-items-start">
                                    <NoteInput control={control} fieldName="note" autoFocus={autoFocusNote}></NoteInput>
                                </div>
                                <div className="col-1">
                                    <Button type={"button"} icon={"pi pi-times"} className={"p-button-text p-button-sm mt-1"} onClick={() => {
                                        post.updateNote(null)
                                    }}/>
                                </div>
                                <div className="col-1"></div>
                                <div className="col-1"></div>
                                <div className="col-1"></div>
                                <div className="col-1"></div>
                                <div className="col-1"></div>
                                <div className="col-fixed flex align-items-start"
                                     style={{width: toolBarWidth}}
                                ></div>
                            </div>
                        </>
                    )}
                </form>
                {post.lines.map((line, i) => <InvoiceMaterialLine index={i} key={line.id} material={line} post={post}
                                                                parent={post} isCococontractor={isCococontractor} />)}
            </div>
        </>
    )
})
