import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useQuoteContext} from "../quote.context";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {multipleMaterialLineSchema, toolBarWidth} from "../constants";
import {observer} from "mobx-react";
import { TextAreaControl } from "../../core/ControlledInputs/TextAreaControl";
import { useMaterials } from "../../materials/materials.hooks";
import { Button } from "primereact/button";

export const QuoteMultipleMaterialLine = observer(({material, post, index, parent}) => {

    const defaultValues = {
        description: material.description ?? "",
    };

    const {control, watch, setValue, formState} = useForm({defaultValues, mode: 'onBlur', resolver: yupResolver(multipleMaterialLineSchema())});

    const {materials, isLoading} = useMaterials();

    const quoteModel = useQuoteContext();

    useEffect(() => {
        quoteModel.setFormState(material.id, formState.isValid);

        return () => quoteModel.unRegisterForm(material.id);
    }, [formState.isValid, material.id, quoteModel]);


    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if(type === 'change' && !isLoading) {
                switch (name) {
                    case 'description':
                        material.updateDescription(value.description, materials);
                        break;
                    default:
                        throw new Error("Unimplemented type "+name)
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [material, watch, isLoading]);

    useEffect(() => {
        setValue('description', defaultValues.description, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.description, setValue]);


    const {t} = useTranslation();
   
    return (
    <>

        <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextAreaControl control={control} fieldName={'description'} autoFocus={true} cols={50} />
            <Button
            type="button"
            icon={"pi pi-trash"}
            className={"p-button-text p-button-sm p-button-danger"}
            onClick={() => {
                material.removeLine();
            }}
            />
        </div>
        
    </>)
})
