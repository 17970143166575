import {Card} from 'primereact/card';
import {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Invoice} from '../../../invoices/models/Invoice';
import {ProvideInvoice, useInvoiceContext} from '../../../invoices/invoice.context';
import {InvoiceForm} from '../../../invoices/components/invoice.form';
import moment from 'moment/moment';
import {observer} from 'mobx-react';
import {Button} from 'primereact/button';
import {useToast} from '../../../core/toast.context';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {TopMenu} from '../../../core/TopMenu';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Checkbox} from 'primereact/checkbox';
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {InputText} from 'primereact/inputtext';
import {useCreateInvoiceTemplateMutation, useInvoiceTemplate, useUpdateInvoiceTemplateMutation} from './invoice.template.hooks';

export function EditInvoiceTemplatePage() {
	const [searchParams] = useSearchParams();
    
    let params = useParams();

	const [isLoading, setIsLoading] = useState(true);

	const [template, setTemplate] = useState(null);
	const [name, setName] = useState('');

	const toast = useToast();

	const { t } = useTranslation();
	
	const {pathname} = useLocation();
    const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener('beforeunload', alertUser);
		if (pathname == `/settings/invoice-templates/${params.id}`) {
			window.history.pushState(null, document.title, window.location.href);
			window.addEventListener('popstate', popFunction);
		}
		return () => {
			window.removeEventListener('beforeunload', alertUser);
			window.removeEventListener('popstate', popFunction);
		};
	}, []);
	const alertUser = (e) => {
		e.preventDefault();
		e.returnValue = '';
	};

	const popFunction = (event) => {
		event.preventDefault();
		const answer = window.confirm(t(`common.leavePage`));
		if (!answer) {
			window.history.pushState(null, document.title, window.location.href);
		} else {
			navigate('/settings/invoice-templates/');
		}
	};

	useInvoiceTemplate(params.id, {
		onSuccess: (data) => {
			if (template === null) {
                const q=Invoice.fromDto(data.id,data.lines,data.client,data.number,data.createdAt,data.validUntil,data.sentToClient,data.status);
                //console.log(q, 'q')
                setTemplate(q);
                setName(data.name)
                setIsLoading(false)
			}
		},
	});

	if (isLoading) {
		return (
			<Card className='ml-auto mr-auto mt-2 text-center' style={{maxWidth: '1400px'}}>
				<ProgressSpinner />
				<ConfirmDialog />
			</Card>
		);
	}

	return (
		<ProvideInvoice devi={template}>
			<CreatePage template={template} defaultName={name} />
		</ProvideInvoice>
	);
}

const CreatePage = observer(({template, defaultName}) => {
    const updateMutation=useUpdateInvoiceTemplateMutation();
    const [name, setName] = useState(defaultName)

	const toast = useToast();

	const {t} = useTranslation();

	const navigate = useNavigate();

	const submitForm = async () => {
		if (!name) {
			return;
		}
		const dto = {
			name: name,
            lines: template.dto.lines,
            id: template.id,
			vatTotal: template.dto.vatTotal,
			total: template.dto.total,
			subTotal: template.dto.subTotal,
		};
		try {
            const newInvoice=await updateMutation.mutateAsync({
                id: template.id,
                data:dto
            });
			toast.current.show({
				severity: 'success',
				summary: t('invoice.template.updated.title'),
				detail: t('invoice.template.updated.text'),
				life: 3000,
			});
			// navigate('/settings/invoice-templates/' + newInvoice.id);
		} catch (err) {
			toast.current.show({
				severity: 'error',
				summary: t('common.oops'),
				detail: t('invoice.template.updated.problem') + ' ' + err,
				life: 3000,
			});
		}
	};

	const invoiceModel = useInvoiceContext();

	return (
		<div>
			<TopMenu moduleName={t('invoice.template.title')} list={'/settings/invoice-templates'} create={'/settings/invoice-templates/create/'} />

			<Card className='ml-auto mr-auto mt-2 xl:px-4 xl:pt-0 sm:p-3' style={{maxWidth: '1400px'}}>
				<h2 className='pb-4' style={{marginTop:'-10px'}}>{t('invoice.template.edit')}</h2>
				<div className='col-12'>
					<span className='p-float-label px-0 w-50'>
						<InputText id='templateName' value={name} onChange={(e) => setName(e.target.value)} />
						<label htmlFor='templateName'>{t('invoice.template.name')}</label>
					</span>
					{!name && <div className='p-error pt-2 text-sm'>{t(`validations.required`)}</div>}
				</div>

				<InvoiceForm invoice={template} />

				<div className='grid grid-nogutter'>
					<div className='col justify-content-center'>
						<Button type={'button'} label={t('common.edit')} disabled={!invoiceModel.isValid || updateMutation.isLoading || !name} loading={updateMutation.isLoading} onClick={submitForm} />
					</div>
					<div className='col-8'></div>
				</div>
			</Card>
		</div>
	);
});
