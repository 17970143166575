import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {ProgressBar} from "primereact/progressbar";
import {Card} from "primereact/card";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {Button} from "primereact/button";
import {yupResolver} from "@hookform/resolvers/yup";
import {useProvider, useUpdateProviderMutation} from "./providers.hooks";
import {providerScheme} from "./providers.scheme";


export function EditProviderPage() {

    const scheme = providerScheme();
    const {control, formState: {isDirty}, handleSubmit, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const navigate = useNavigate();

    let params = useParams();

    const {t} = useTranslation();

    const {provider, isLoading} = useProvider(params.id);

    useEffect(() => {
        if(!isDirty) {
            reset(provider);
        }
    }, [isDirty, reset, provider]);

    const updateMutation = useUpdateProviderMutation();

    const toast = useToast();

    const onSubmit = async (data) => {
        try {
            const dto = {
                ...data
            };

            await updateMutation.mutateAsync({
                id: provider.id,
                data: dto
            });

            toast.current.show({severity: 'success', summary: t('provider.updated.title'), detail: t('provider.updated.text'), life: 3000});
            navigate('/settings/providers');
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('provider.updated.problem') + " " + err, life: 3000});
        }
    };

    return (
        <div>
            <TopMenu moduleName={t('provider.title')} create={'/settings/providers/create/'} list={'/settings/providers'}/>

            {isLoading && <ProgressBar mode={"indeterminate"} className={'mt-3'} style={{height: '6px'}} />}

            <Card title={t('provider.edit')} className="shadow-3 mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                    </div>
                    <Button type="submit" label={t('common.edit')} loading={updateMutation.isLoading}/>
                </form>
            </Card>

        </div>
    )
}