import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FilterMatchMode} from "primereact/api";
import {Button} from "primereact/button";
import {HasRole} from "../auth/has-role";
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useDeleteMaterialMutation, useMaterials} from "./materials.hooks";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {useTranslation} from "react-i18next";
import {Vat} from "../core/Vat";
import {Currency} from "../core/Currency";
import {ConfirmDelete} from "../core/ConfirmDelete";
import {Skeleton} from "primereact/skeleton";


export function MaterialsListPage(client = null){

    const {materials, isLoading, isFetched} = useMaterials();
    const [material, setMaterial] = useState(null);

    const deleteMutation = useDeleteMaterialMutation();

    let navigate = useNavigate();
    let toast = useToast();

    const {t} = useTranslation();

    const [filters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS, },
        'createdAt': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'tva': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'price': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'note': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'provider.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'category.name': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const actionTemplate = (material) => {
        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text m-0 inline-block" onClick={() => navigate("/materials/"+material
                    .id)} />
                <HasRole role="ROLE_ADMIN">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text m-0 inline-block" onClick={() => setMaterial(material)}/>
                </HasRole>
            </>
        )
    };

    const deleteMaterial = () => {
        deleteMutation.mutateAsync(material.id)
            .then(() => {
                toast.current.show({severity: 'success', summary: t('material.deleted.title'), detail: t('material.deleted.text'), life: 3000});
            })
            .catch(() => {
                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('material.deleted.problem'), life: 3000});
            })
            .finally(() => {
                setMaterial(null);
            });
    };

    const items = Array.from({length: 10}, (v, i) => i);

    const skeletonTemplate = () => {
        return <Skeleton width={"80%"}/>;
    };

    return (
        <div>
            {!client.client && <TopMenu moduleName={t('material.title')} create={'/materials/create/'} />}
            <Card className='shadow-3 mt-3' title={t('material.list')}>
                <DataTable
                    value={isLoading || !isFetched ? items : client.client ? materials.filter((x) => x.client.id === client.client) : materials}
                    paginator
                    sortMode='multiple'
                    filterDisplay='row'
                    filters={filters}
                    dataKey='id'
                    stripedRows
                    selectionMode='single'
                    emptyMessage={t('material.emptyList')}
                    currentPageReportTemplate={t('datatable.reportTemplate')}
                    rows={25}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    rowHover
                    responsiveLayout='scroll'
                    columnResizeMode='expand'
                    size={'small'}
                    cellSelection
                    onCellSelect={(e) => navigate('/materials/'+e.rowData.id)}>
                    <Column
                        field='name'
                        header={t('common.name')}
                        sortable
                        filter
                        filterPlaceholder={t('common.name')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.name)}
                    />
                    <Column
                        field='unit.name'
                        header={t('common.unit')}
                        sortable
                        filter
                        filterPlaceholder={t('common.unit')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.unit?.name)}
                    />
                    <Column
                        field='price'
                        header={t('common.price')}
                        sortable
                        filter
                        filterPlaceholder={t('common.price')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : <span>{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(row.price)}</span>)}
                    />
                    <Column
                        field='tva'
                        header={t('common.vat')}
                        sortable
                        filter
                        filterPlaceholder={t('common.vat')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.tva)}
                    />
                    <Column
                        field='note'
                        header={t('common.note')}
                        sortable
                        filter
                        filterPlaceholder={t('common.note')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : <div style={{ maxWidth: "150px", overflow: "auto" }}> {row.note} </div>)}
                    />
                    <Column
                        field='provider.name'
                        header={t('common.provider')}
                        sortable
                        filter
                        filterPlaceholder={t('common.provider')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.provider?.name)}
                    />
                    <Column
                        field='category.name'
                        header={t('common.category')}
                        sortable
                        filter
                        filterPlaceholder={t('common.category')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.category?.name)}
                    />
                    <Column body={isLoading || !isFetched ? skeletonTemplate : actionTemplate} className='p-0' style={{minWidth: '200px'}} />
                </DataTable>
            </Card>

            <ConfirmDelete name={material?.name} loading={deleteMutation.isLoading} visible={material} onHide={() => setMaterial(null)} onConfirmed={deleteMaterial} />

        </div>
    );
}