// import genderService from './gender.service';
import quoteTemplateService from './quote.template.service';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

export function useQuoteTemplates() {
	const {
		data: templates,
		isFetched,
		isLoading,
		isError,
	} = useQuery(['quote-templates'], quoteTemplateService.all, {
		initialData: [],
	});

	return {
		templates,
		isLoading,
		isFetched,
		isError,
	};
}

export function useQuoteTemplate(id, options = null) {
	const {data: gender, isLoading, isError} = useQuery(['quote-templates', id], ({queryKey}) => quoteTemplateService.get(queryKey[1]), options);

	return {
		gender,
		isLoading,
		isError,
	};
}

export function useUpdateQuoteTemplateMutation() {
	const queryClient = useQueryClient();

	const mutation = useMutation((params) => quoteTemplateService.update(params.id, params.data), {
		onSuccess: async (data, params) => {
			await queryClient.invalidateQueries(['quote-templates']);
		},
	});

	return mutation;
}

export function useCreateQuoteTemplateMutation() {
	const queryClient = useQueryClient();

	const mutation = useMutation(quoteTemplateService.create, {
		onSuccess: (data) => {
			const genders = queryClient.getQueryData(['']) ?? [];
			queryClient.setQueryData(['quote-templates'], [...genders, data]);
		},
	});

	return mutation;
}

export function useDeleteQuoteTemplateMutation() {
	const queryClient = useQueryClient();

	const mutation = useMutation(quoteTemplateService.delete, {
		onSuccess: (data, variables) => {
			const genders = queryClient.getQueryData(['quote-templates']) ?? [];
			queryClient.setQueryData(
				['quote-templates'],
				genders.filter((x) => x.id !== variables)
			);
		},
	});

	return mutation;
}
