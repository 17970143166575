import {useQuery} from "@tanstack/react-query";
import dashboardService from "./dashboard.service";


export function useDashboard(){
    const {data: dashboard, isFetched, isLoading, isError} = useQuery(['dashboard'], dashboardService.all, {
        initialData: []
    });

    return {
        dashboard,
        isLoading,
        isFetched,
        isError
    };
}