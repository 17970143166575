import {Card} from "primereact/card";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useToast} from "../core/toast.context";
import {clientScheme} from "./clients.scheme";
import {yupResolver} from "@hookform/resolvers/yup";
import {useOrigins} from "./origins/origin.hooks";
import {useGenders} from "./genders/gender.hooks";
import {useCreateClientMutation} from "./clients.hooks";
import {useTranslation} from "react-i18next";
import {TopMenu} from "../core/TopMenu";
import {DropdownControl} from "../core/ControlledInputs/DropDownControl";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {Divider} from "primereact/divider";
import {TextAreaControl} from "../core/ControlledInputs/TextAreaControl";
import {usePriorities} from "./priorities/priority.hooks";
import { useCountries } from "./countries/country.hooks";


export function CreateClientPage() {

    const {t} = useTranslation();

    const scheme = clientScheme(t);

    let toast = useToast();

    const [useInvoiceAddress, setUseInvoiceAddress] = useState(false);

    const {control, handleSubmit, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme),
        context: {
            useInvoiceAddress
        }
    });

    const navigate = useNavigate();

    const {origins, isLoading: isLoadingOrigins} = useOrigins();
    const {genders, isLoading: isLoadingGenders} = useGenders();
    const {priorities, isLoading: isLoadingPriorities} = usePriorities();
    const {countries, isLoading: isLoadingCountries} = useCountries();

    // Default set selected country to belgium
    useEffect(() => {
        if (countries) {
            const belgiumFound = countries.find(country => country.name === 'Belgique' || country.name === 'Belgium')
            if (belgiumFound) {
                reset({
                    countryId: belgiumFound.id,
                    invoiceCountryId: belgiumFound.id,
                })
            }
        }
    }, [countries, reset]);

    const createMutation = useCreateClientMutation();

    const onSubmit = async (data) => {
        try {

            const dto = {
                ...data,
                invoiceCountry: data.invoiceCountryId,
            };

            if(!useInvoiceAddress) {
                delete dto.invoiceAddress;
                delete dto.invoiceNb;
                delete dto.invoicePostalCode;
                delete dto.invoiceCity;
                delete dto.invoiceCountry;
            }

            const newClient = await createMutation.mutateAsync(dto);
            toast.current.show({
                severity: 'success',
                summary: t('client.created.title'),
                detail: t('client.created.text'),
                life: 3000
            });
            navigate('/clients/' + newClient.id);
        } catch (err) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: t('client.created.problem') + " " + err,
                life: 3000
            });
        }
    };

    return (

        <div>
            <TopMenu moduleName={t('client.title')} list={'/clients'}/>

            <Card title={t('client.create')} className="p-card mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">

                    <div className="field col-3">
                        <DropdownControl
                            control={control}
                            label={t('common.gender')}
                            loading={isLoadingGenders}
                            options={genders}
                            optionLabel={'name'}
                            optionValue={'id'}
                            fieldName={'genderId'}/>
                    </div>


                    <div className="field col-9">
                        <TextControl label={t('common.name')} control={control} fieldName={'name'} autoFocus/>
                    </div>

                    <div className="field col-6">
                        <TextControl control={control} fieldName={'tva'} label={t('common.vat')}/>
                    </div>

                    <div className="field col-6">
                        <DropdownControl
                            control={control}
                            label={t('common.origin')}
                            loading={isLoadingOrigins}
                            options={origins}
                            optionLabel={'name'}
                            optionValue={'id'}
                            fieldName={'originId'}/>
                    </div>

                    <div className="field col-6">
                        <DropdownControl
                            control={control}
                            label={t('common.priority')}
                            loading={isLoadingPriorities}
                            options={priorities}
                            optionLabel={'name'}
                            optionValue={'id'}
                            fieldName={'priorityId'}/>
                    </div>

                    {/*<div className="field col-6">*/}
                    {/*    <DropdownControl*/}
                    {/*        control={control}*/}
                    {/*        label={t('common.status')}*/}
                    {/*        loading={isLoadingStatuses}*/}
                    {/*        options={statuses}*/}
                    {/*        optionLabel={'name'}*/}
                    {/*        optionValue={'id'}*/}
                    {/*        fieldName={'statusId'}/>*/}
                    {/*</div>*/}

                    <div className="field col-6">
                        <TextControl label={t('common.email')} control={control} fieldName={'email1'}/>
                    </div>

                    <div className="field col-6">
                        <TextControl label={t('common.email') + " 2"} control={control} fieldName={'email2'}/>
                    </div>

                    <div className="field col-6">
                        <TextControl label={t('common.phoneNumber')} control={control} fieldName={'phone1'} />
                    </div>

                    <div className="field col-6">
                        <TextControl label={t('common.phoneNumber') + " 2"} control={control} fieldName={'phone2'} />
                    </div>

                    <Divider><strong>{t('client.worksiteAddress')}</strong></Divider>

                    <div className="field col-12">
                        <TextControl control={control} fieldName={'address'} label={t('common.address')}/>
                    </div>

                    <div className="field col-6">
                        <TextControl control={control} fieldName={'nb'} label={t('common.houseNumber')}/>
                    </div>

                    <div className="field col-6">
                        <TextControl control={control} fieldName={'postalCode'} label={t('common.postalCode')} type="number" maxLength={5} />
                    </div>

                    <div className="field col-6">
                        <TextControl control={control} fieldName={'city'} label={t('common.city')} type="text"/>
                    </div>

                    <div className="field col-6">
                        <DropdownControl
                            control={control}
                            label={t('common.country')}
                            loading={isLoadingCountries}
                            options={countries}
                            optionLabel={'name'}
                            optionValue={'id'}
                            fieldName={'countryId'}
                            />
                    </div>

                    <Divider>
                        <Button type={'button'} className="p-button-outlined" onClick={() => {

                            setUseInvoiceAddress(!useInvoiceAddress);

                        }}
                                label={!useInvoiceAddress ? t('client.worksiteAddressIsNotTheInvoiceAddress') : t('client.worksiteAddressIsInvoiceAddress')}/>
                    </Divider>


                    {useInvoiceAddress &&
                        <>
                            <div className="field col-12">
                                <TextControl control={control} fieldName={'invoiceAddress'}
                                             label={t('common.address')}/>
                            </div>

                            <div className="field col-6">
                                <TextControl control={control} fieldName={'invoiceNb'} label={t('common.houseNumber')}/>
                            </div>

                            <div className="field col-6">
                                <TextControl control={control} fieldName={'invoicePostalCode'}
                                             label={t('common.postalCode')}/>
                            </div>

                            <div className="field col-6">
                                <TextControl control={control} fieldName={'invoiceCity'} label={t('common.city')}/>
                            </div>

                            <div className="field col-6">
                                <DropdownControl
                                    control={control}
                                    label={t('common.country')}
                                    loading={isLoadingCountries}
                                    options={countries}
                                    optionLabel={'name'}
                                    optionValue={'id'}
                                    fieldName={'invoiceCountryId'}
                                />
                            </div>

                        </>}

                    <div className="field col-12">
                        <TextAreaControl control={control} fieldName={'note'} label={t('common.note')} maxLength={1000} />
                    </div>

                    <Button type="submit" label={t('common.create')}
                            loading={createMutation.isLoading}/>
                </form>
            </Card>
        </div>
    )
}