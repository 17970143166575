import {Route, Routes} from "react-router-dom";
import {SectionsListPage} from "./sections-list.page";
import {EditSectionPage} from "./sections-edit.page";
import {CreateSectionPage} from "./sections-create.page";


export function Sections() {
    return <Routes>

        <Route path=":id" element={<EditSectionPage />} />
        <Route path="create" element={<CreateSectionPage />} />
        <Route path="*" element={<SectionsListPage />} />

    </Routes>

}