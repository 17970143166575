import {useOrganisationContext} from "./organisation.context";
import {useOrganisations} from "./organisations.hooks";
import {Dropdown} from "primereact/dropdown";
import {Card} from "primereact/card";
import {useTranslation} from "react-i18next";


export function OrganisationSelector() {

    const {selectedOrganisationId, setSelectedOrganisationId} = useOrganisationContext();

    const {organisations, isLoading} = useOrganisations();

    const {t} = useTranslation();

    return (
        <Card title={t('organisation.change')} className="shadow-3 mt-3 p-fluid">
            <span className="p-float-label">
                <Dropdown id="organisationId"
                          disabled={isLoading}
                          dropdownIcon={isLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'}
                          value={selectedOrganisationId}
                          onChange={(e) => setSelectedOrganisationId(e.value)}
                          options={organisations}
                          optionLabel={'name'}
                          optionValue={'id'}
                />
                <label htmlFor={'organisationId'} >{t('organisation.title')}</label>
            </span>
        </Card>
    );
}