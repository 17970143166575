import {useAuth} from "./auth.context";
import {Navigate} from "react-router-dom";

export function RequireNoAuth({ children }) {
    let auth = useAuth();

    if (auth.user) {
        return <Navigate to="/" replace />;
    }

    return children;
}