import {Route, Routes} from "react-router-dom";
import {MaterialsListPage} from "./materials-list.page"
import {EditMaterialsPage} from "./materials-edit.page"
import {CreateMaterialsPage} from "./materials-create.page";


export function Materials() {
    return <Routes>

        <Route path=":id" element={<EditMaterialsPage />} />
        <Route path="create" element={<CreateMaterialsPage />} />
        <Route path="*" element={<MaterialsListPage />} />

    </Routes>

}