import {Card} from "primereact/card";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {useToast} from "../core/toast.context";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";
import {userScheme} from "./user.scheme";
import {useCreateUserMutation} from "./users.hooks";
import {TopMenu} from "../core/TopMenu";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {DropdownControl} from "../core/ControlledInputs/DropDownControl";
import {TextAreaControl} from "../core/ControlledInputs/TextAreaControl";
import {useAllowedRoles} from "../auth/roles.hooks";
import {HasRole} from "../auth/has-role";
import {OrganisationSelector} from "../organisations/organisation.selector";
import {useOrganisationContext} from "../organisations/organisation.context";
import { CalenderControl } from "../core/ControlledInputs/CalendarControl";
import moment from "moment";


export function CreateUserPage() {

    const {selectedOrganisationId} = useOrganisationContext();
    const navigate = useNavigate();
    let toast = useToast();

    const scheme = userScheme();

    const {control, handleSubmit} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const createMutation = useCreateUserMutation();

    const {t} = useTranslation();

    const roles = useAllowedRoles();

    const onSubmit = async (data) => {

        const dto = {
            ...data,
            deletedAt: data.deletedAt ? moment(data.deletedAt).set({"hour": 23, "minute": 59}).zone("+00:00").format() : null
        };
        try {
            const newUser = await createMutation.mutateAsync({dto: dto, organisationId: selectedOrganisationId});
            toast.current.show({severity: 'success', summary: t('user.created.title'), detail: t('user.created.text'), life: 3000});
            navigate('/users/'+newUser.id);
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('user.created.problem')+' '+err, life: 3000});
        }
    };

    return (
        <div>
            <TopMenu moduleName={t('user.title')} list={'/users'}/>

            <HasRole role={'ROLE_SUPER_ADMIN'}>
                <OrganisationSelector />
            </HasRole>

            <Card title={t('user.create')} className="shadow-3 mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'name'} label={t('common.name')} />
                    </div>
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'email'} label={t('common.email')} />
                    </div>

                    <div className="field col-6">
                        <DropdownControl control={control} fieldName={'role'} label={t('common.role')} optionValue={'value'} optionLabel={'name'} options={roles} />
                    </div>

                    <div className="field col-6">
                        <CalenderControl control={control} fieldName={'deletedAt'} label={t('common.deletedAt')} />
                    </div>

                    <div className="field col-12">
                        <TextAreaControl control={control} fieldName={'note'} label={t('common.note')} maxLength={1000} />
                    </div>

                    <Button type="submit" label={t('common.create')} loading={createMutation.isLoading}/>
                </form>
            </Card>
        </div>
    )

}