import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import { createRoot } from 'react-dom/client';
import './i18n';
import * as yup from 'yup';

yup.setLocale({
    mixed: {
        default: ({path}) => ({
            key: 'validations.invalid',
            values: { path }
        }),
        required: ({path}) => ({
            key: 'validations.required',
            values: { path }
        }),
        notType: ({type}) => ({
            key: 'validations.invalidType',
            values: { type }
        }),
    },
    string: {
        email: {
            key: 'validations.email',
        },
        min: ({ min }) => ({
            key: 'validations.stringMin',
            values: { min },
        }),
        max: ({ max }) => ({
            key: 'validations.stringMax',
            values: { max },
        }),
    },
    number: {
        positive: () => ({
            key: 'validations.number.positive'
        })
    },
    boolean: {},
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
