import api from "../services/api";

class InvoicesService {

    all() {
        return api.get('api/invoices')
            .then(function (x) {
                return x.data;
            });
    }

    delete(id) {
        return api.delete('api/invoices/'+id);
    }

    get(id) {
        return api.get('api/invoices/'+id)
            .then(x => x.data);
    }

    async newInvoice(id, empty) {
        return api.post('api/invoices/'+id+'/new-invoice', {empty})
            .then(x => x.data);
    }

    update(id, data) {
        return api.post('api/invoices/'+id, JSON.stringify(data))
            .then(x => x.data);
    }

    create(data) {
        return api.post('api/invoices', JSON.stringify(data))
            .then(x => x.data);
    }

    async getPdf(id, watermark= true) {
        return api.get('api/invoices/'+id+'/pdf/'+watermark, {
            responseType: 'blob'
        })
            .then(response => {
                const blob = new Blob([response.data], {type: 'application/pdf'});

                return blob;
            })
    }

    async sendToClient(id,data,files) {
        const form = new FormData();
        form.append("request", JSON.stringify(data));

        if(files?.length) {
            Object.entries(files).map(([key, file]) => {
                form.append(key, file,file.name);
            })
        }

        return api.post('api/invoices/'+id+'/send-to-client', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).catch(e => {throw e});

    }

    async generatePdf(id) {
        return api.get('api/invoices/'+id+'/generate-pdf', {
            responseType: 'blob'
        })
            .then(response => {
                const blob = new Blob([response.data], {type: 'application/pdf'});

                return blob;
            })
    }

    allTemplates() {
        return api.get('api/invoice-templates')
            .then(function (x) {
                return x.data;
            });
    }

    createTemplate(data) {
        return api.post('api/invoice-templates', JSON.stringify(data))
            .then(x => x.data);
    }

    deleteTemplate(id) {
        return api.delete('api/invoice-templates/'+id);
    }

    setStatus(id, data) {
        return api.post('api/invoices/'+id+'/status', JSON.stringify(data))
    }

    getByClient(id) {
        return api.get('api/invoices/client/'+id)
            .then(x => x.data);
    }

    addComment(id, data) {
        return api.post('api/invoices/'+id+'/comments', JSON.stringify(data))
            .then(x => x.data);
    }

    createZip(data) {
        return api.post('api/invoices/zip', JSON.stringify(data), {
            responseType: 'blob'
        })
            .then(response => {
                if (response.status === 204) {
                    return "NO RESULT";
                } else {
                    return response;
                }
            });
    }
}

export default new InvoicesService();
