import {createSearchParams, useNavigate} from "react-router-dom";
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Fragment, useCallback, useMemo, useRef, useState} from "react";
import {FilterMatchMode} from "primereact/api";
import {Button} from "primereact/button";
import {HasRole} from "../../auth/has-role";
import {useToast} from "../../core/toast.context";
import {
    useDeleteInvoiceMutation,
    useInvoices
} from "../invoice.hooks";
import {useTranslation} from "react-i18next";
import {ConfirmDelete} from "../../core/ConfirmDelete";
import {DateTime} from "../../core/DateTime";
import {classNames} from 'primereact/utils';
import invoicesService from "../invoices.service";
import {Menu} from "primereact/menu";
import {useAuth} from "../../auth/auth.context";
import Moment from "moment";
import {InvoiceStatus} from "../../core/InvoiceStatus";
import {Dropdown} from "primereact/dropdown";
import {useInvoiceStatuses} from "../invoice.status.hooks";
import {ConfirmDialog} from "primereact/confirmdialog";
import {TopMenu} from "../../core/TopMenu";
import {Dialog} from "primereact/dialog";
import { SendInvoiceToClientForm } from "../components/send-invoice-to-client.form";
import {Skeleton} from 'primereact/skeleton';
import {ConfirmYesNo} from "../../core/ConfirmYesNo";
import quotesService from "../../quotes/quotes.service";

export function InvoicesListPage(client = null){

    const [invoice, setInvoice] = useState(null);
    const [toSendToInvoice, setToSendToInvoice] = useState(null);
    const [generatePdf, setGeneratePdf] = useState(null);

    const {invoices, isLoading, isFetched} = useInvoices();

    const deleteMutation = useDeleteInvoiceMutation();

    let navigate = useNavigate();
    let toast = useToast();

    const {t} = useTranslation();

    const [filters] = useState({
        //'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'number': { value: null, matchMode: FilterMatchMode.CONTAINS,  },
        'client.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'subTotal': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'createdAt': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'validUntil': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'status': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'updatedAt': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const actionTemplate = (invoice) => {
        return (
            <Fragment>
                <div style={{display: 'flex', justifyContent: 'right'}}>
                    <div style={{width:'2.4rem'}}>
                        <GeneratePdfButton quote={invoice} onClick={() => setGeneratePdf(invoice)} />
                    </div>
                    <SendToClientButton invoice={invoice} onClick={() => setToSendToInvoice(invoice)} />
                    <Button icon="pi pi-folder-open" className="p-button-rounded p-button-warning p-button-text m-0" onClick={() => {window.location.href = 'deyemon://G:/Mon Drive/Deyemon Gestion/'+Moment(invoice.client.createdAt).format('Y')+"/"+invoice.client.formattedId+" - "+invoice.client.name+"/Factures";}} />
                    <HasRole role="ROLE_ADMIN">
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text m-0" onClick={() => confirmDeleteInvoice(invoice)}/>
                    </HasRole>
                    <OptionsButton invoice={invoice} />
                </div>
            </Fragment>
        )
    };

    const confirmDeleteInvoice = (invoice) => {
        setInvoice(invoice);
    };

    const hideDeleteDialog = () => {
        setInvoice(null);
    };

    const deleteInvoice = () => {

        deleteMutation.mutateAsync(invoice.id)
            .then(() => {
                toast.current.show({severity: 'success', summary: t('invoice.deleted.title'), detail: t('invoice.deleted.text'), life: 3000});
            })
            .catch(() => {
                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('invoice.deleted.problem'), life: 3000});
            })
            .finally(() => {
                hideDeleteDialog();
            })
    };

    const onRowClicked = useCallback(async (invoice) => {

        if(invoice.field === "client.name"){
            navigate('/clients/'+invoice.rowData.client.id)
        }else if(invoice.field !== "status"){
            invoice = invoice.rowData;
            navigate(`/invoices/${invoice.id}`);
        }


    }, [navigate, t, toast]);

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option.value}`}>{option.name}</span>;
    }

    const invoiceStatuses = useInvoiceStatuses();

    const invoiceStatusFilterElement = (options) => {
        return <Dropdown value={options.value} options={invoiceStatuses} optionLabel={"name"} optionValue={"value"} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder={t('common.status')} className="p-column-filter" showClear />;
    }

    const items = Array.from({length: 10}, (v, i) => i);

	const skeletonTemplate = () => {
		return <Skeleton/>;
	};

    const createPdf = useCallback(async (generatePdf) => {

        const pdfWindow = window.open();

        try {
            const blob = await invoicesService.generatePdf(generatePdf.id);
            const fileURL = URL.createObjectURL(blob);
            pdfWindow.location.href = fileURL;

        } catch(error) {
            toast.current.show({severity: 'error', summary: t('common.oops'), detail: error.response?.data?.detail || t('common.problemGenerate'), life: 3000});
        }
        finally {
            setGeneratePdf(null);
        }

    }, [navigate, t, toast]);

    return (
			<div>
				{!client.client && <TopMenu moduleName={t('invoice.title')} create={'/invoices/create/'} />}
				<Card className='shadow-3 mt-3' title={t('invoice.list')}>
					<DataTable
						value={isLoading || !isFetched ? items : client.client ? invoices.filter((x) => x.client.id === client.client) : invoices}
						paginator
						sortMode='multiple'
						filterDisplay='row'
						filters={filters}
						dataKey='id'
						stripedRows
						selectionMode='single'
						emptyMessage={t('invoice.emptyList')}
						currentPageReportTemplate={t('datatable.reportTemplate')}
						rows={25}
						rowsPerPageOptions={[10, 25, 50, 100]}
						paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
						rowHover
						responsiveLayout='scroll'
						columnResizeMode='expand'
						size={'small'}
						cellSelection
						onCellSelect={(e) => onRowClicked(e)}>
						<Column
							field='number'
							header={t('invoice.number')}
							sortable
							filter
							filterPlaceholder={t('invoice.number')}
							showFilterMenu={false}
							body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.number)}
						/>
						<Column
							field='client.name'
							header={t('client.name')}
							sortable
							filter
							filterPlaceholder={t('client.name')}
							showFilterMenu={false}
							body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.client.name)}
						/>
						<Column
							field='status'
							header={t('common.status')}
							sortable
							filter
							filterPlaceholder={t('common.status')}
							filterElement={invoiceStatusFilterElement}
							showFilterMenu={false}
							body={(row) => (isLoading || !isFetched ? <Skeleton/> : <InvoiceStatus id={row.id} status={row.status} sendAt={row.sentToClient} />)}
						/>
						<Column
							field='subTotal'
							header={t('common.subTotal')}
							sortable
							filter
							filterPlaceholder={t('common.subTotal')}
							showFilterMenu={false}
							body={(row) => (isLoading || !isFetched ? <Skeleton /> : <span>{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(row.subTotal)}</span>)}
						/>
						<Column
							field='createdAt'
							header={t('common.createdAt')}
							sortable
							filter
							filterPlaceholder={t('common.createdAt')}
							showFilterMenu={false}
							body={(row) => (isLoading || !isFetched ? <Skeleton/> : <DateTime datetime={row.createdAt} />)}
						/>
						<Column
							field='validUntil'
							header={t('common.validUntil')}
							sortable
							filter
							filterPlaceholder={t('common.validUntil')}
							showFilterMenu={false}
							body={(row) => (isLoading || !isFetched ? <Skeleton/> : <DateTime datetime={row.validUntil} />)}
						/>
						<Column
							field='updatedAt'
							header={t('common.updatedAt')}
							sortable
							filter
							filterPlaceholder={t('common.updatedAt')}
							showFilterMenu={false}
							body={(row) => (isLoading || !isFetched ? <Skeleton/> : <DateTime datetime={row.updatedAt} />)}
						/>
						<Column body={isLoading || !isFetched ? skeletonTemplate : actionTemplate} className='p-0' style={{minWidth: '200px'}} />
					</DataTable>
				</Card>

				<ConfirmDelete name={invoice?.number} loading={deleteMutation.isLoading} visible={invoice} onHide={() => setInvoice(null)} onConfirmed={deleteInvoice} />
				<ConfirmDialog />

				<Dialog header={t('invoice.sendToClient.modal.header')} visible={toSendToInvoice !== null} style={{width: '50vw'}} onHide={() => setToSendToInvoice(null)}>
					{toSendToInvoice !== null && <SendInvoiceToClientForm invoice={toSendToInvoice} onCompleted={() => setToSendToInvoice(null)} />}
				</Dialog>

                <ConfirmYesNo description='common.confirmGeneratePdf' visible={generatePdf} onHide={() => setGeneratePdf(null)} onConfirmed={() => createPdf(generatePdf)} />
			</div>
		);
}

function SendToClientButton({invoice, onClick}) {

    const {t} = useTranslation();

    return (
        <>
        
        {
            (invoice.status === 1 || invoice.status === 2 || invoice.status === 3) && (

                <Button icon={invoice.sentToClient ? "pi pi-refresh" : "pi pi-send"}
                        className={classNames("p-button-rounded p-button-text m-0", {
                            "p-button-success": !invoice.sentToClient,
                            "p-button-info": invoice.sentToClient
                        })}
                        tooltip={invoice.sentToClient ? t('invoice.sendToClient.tooltip.reSend') : t('invoice.sendToClient.tooltip.send')}
                        onClick={onClick}/>
            )
        }
        
        </>
    );
}

function GeneratePdfButton({quote, onClick}) {

    const {t} = useTranslation();

    return (
        <>

            {
                quote.status === 1 && (

                    <Button icon={"pi pi-file-pdf"}
                            className={classNames("p-button-rounded p-button-text m-0 p-button-danger")}
                            tooltip={t('common.generatePdf')}
                            onClick={onClick}/>
                )
            }

        </>
    );
}

function OptionsButton({invoice}) {

    const menu = useRef(null);

    const {t} = useTranslation();

    const toast = useToast();

    const navigate = useNavigate();

    const auth = useAuth();

    const createPdf = useCallback(async (generatePdf) => {

        const pdfWindow = window.open();

        try {
            const blob = await invoicesService.getPdf(generatePdf.id);
            //Build a URL from the file
            const fileURL = URL.createObjectURL(blob);
            pdfWindow.location.href = fileURL;

        } catch (error) {
            pdfWindow.close();
            toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('quote.pdf.problem'), life: 3000});
        }

    }, [navigate, t, toast]);

    const menuItems = useMemo(() => {
        const items = [
            {
                label: t('common.other'),
                items: [
                    {
                        label: t('common.duplicate'),
                        icon: 'pi pi-clone',
                        command: () => {
                            navigate({
                                pathname: '/invoices/create',
                                search: `?${createSearchParams({invoiceId: invoice.id})}`
                            })
                        }
                    },
                    {
                        label: t('common.printToPdf'),
                        icon: 'pi pi-print',
                        command: () => {
                            createPdf(invoice)
                        }
                    }
                ]
            }
        ];

        if(invoice.sentToClient && auth.hasRole("ROLE_ADMIN")) {
            items.push({
                label: t('common.admin'),
                items: [
                    {
                        label: t('common.edit'),
                        icon: 'pi pi-exclamation-triangle',
                        command: () => {
                            navigate('/invoices/'+invoice.id);
                        }
                    }
                ]
            })
        }

        return items;
    }, [auth, navigate, invoice.id, invoice.sentToClient, invoice.status, t, toast]);

    return (
        <>
            <Menu model={menuItems} popup ref={menu} id="popup_menu" />
            <Button className={"p-button p-button-text"} icon="pi pi-ellipsis-v" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
        </>
    );
}
