import {TextControl} from "../core/ControlledInputs/TextControl";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {TextAreaControl} from "../core/ControlledInputs/TextAreaControl";
import {Divider} from "primereact/divider";
import {useMemo} from "react";
import moment from "moment";
import {Tag} from "primereact/tag";
import { CalenderControl } from "../core/ControlledInputs/CalendarControl";
import Loader from "../core/Loader";


export function OrganisationForm({onSubmit, control, isLoading, submitLabel, disableCalender}) {

    const {t} = useTranslation();

    const now = useMemo(() => {
        return moment(new Date())
    }, []);

    return (

        <form onSubmit={onSubmit} className="p-fluid grid">
            <div className="field col-6">
                <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus/>
            </div>

            <div className="field col-6">
                <CalenderControl disabled={disableCalender} control={control} fieldName={'deletedAt'}
                                 label={t('common.deletedAt')}/>
            </div>

            <div className="field col-12">
                <TextAreaControl control={control} fieldName={'note'} label={t('common.note')} maxLength={1000} />
            </div>

            <Divider>
                <strong>{t('organisation.contactInformation')}</strong>
            </Divider>

            <div className="field col-12">
                <TextControl control={control} fieldName={'email'} label={t('common.email')}/>
            </div>

            <div className="field col-12">
                <TextControl control={control} fieldName={'phone'} label={t('common.phoneNumber')}/>
            </div>

            <Divider>
                <strong>{t('organisation.formats')}</strong>
            </Divider>

            <div className="col-6 text-sm">
                <span className={'m-1'}>{t('organisation.placeholders.name')}:</span>
                <br/>
                <Tag className={'m-1'} value={'[Y]: ' + t('organisation.placeholders.fullYear')}/>
                <Tag className={'m-1'} value={'[y]: ' + t('organisation.placeholders.year')}/>
                <Tag className={'m-1'} value={'[m]: ' + t('organisation.placeholders.month')}/>
                <Tag className={'m-1'} value={'[d]: ' + t('organisation.placeholders.day')}/>
                <Tag className={'m-1'} value={'%s ' + t('organisation.placeholders.uniqueId')}/>
            </div>

            <div className="col-6 text-sm">
                <span className={'m-1'}>{t('organisation.examples')}</span>
                <ul className="list-none m-0 p-0">
                    <li className="p-1">
                        <strong>[Y]-[m]-[d]-%s</strong> -&gt; {now.format('yyyy')}-{now.format('MM')}-{now.format('DD')}-123123
                    </li>
                    <li className="p-1">
                        <strong>[y]-[m]-[d]-%s</strong> -&gt; {now.format('YY')}-{now.format('MM')}-{now.format('DD')}-123123
                    </li>
                    <li className="p-1">
                        <strong>%s-[m][d]</strong> -&gt; 123123-{now.format('MM')}{now.format('DD')}
                    </li>
                </ul>
            </div>


            <div className="field col-12">
                <TextControl control={control} fieldName={'clientFormat'} label={t('organisation.clientFormat')}/>
            </div>

            <div className="field col-12">
                <TextControl control={control} fieldName={'quoteFormat'} label={t('organisation.quoteFormat')}/>
            </div>

            <div className="field col-12">
                <TextControl control={control} fieldName={'invoiceFormat'} label={t('organisation.invoiceFormat')}/>
            </div>

            <Divider>
                <strong>{t('organisation.billingInformation')}</strong>
            </Divider>

            <div className="field col-12">
                <TextControl control={control} fieldName={'tva'} label={t('common.vat')}/>
            </div>

            <div className="field col-12">
                <TextControl control={control} fieldName={'bank'} label={t('organisation.bankAccountNumber')}/>
            </div>

            <div className="field col-12">
                <TextControl control={control} fieldName={'address'} label={t('common.address')}/>
            </div>

            <div className="field col-6">
                <TextControl control={control} fieldName={'nb'} label={t('common.houseNumber')}/>
            </div>

            <div className="field col-6">
                <TextControl control={control} fieldName={'postalCode'} label={t('common.postalCode')}/>
            </div>

            <div className="field col-6">
                <TextControl control={control} fieldName={'city'} label={t('common.city')}/>
            </div>

            <div className="field col-6">
                <TextControl control={control} fieldName={'country'} label={t('common.country')}/>
            </div>

            <Button type="submit" label={submitLabel} loading={isLoading}/>
        </form>
    );
}