import {Controller} from "react-hook-form";

import {classNames} from "primereact/utils";
import {FormErrorMessage} from "../FormErrorMessage";
import {Editor} from "primereact/editor";

export function EditorControl({fieldName, control, label, autoFocus=false, disabled=false, header = null, style = null}) {
    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    };

    return (
        <Controller name={fieldName} control={control}
                    render={({field, fieldState, formState}) => {
                        return <>
                            <label htmlFor={field.name}
                                       className={classNames({'p-error': !!fieldState.error})}>{label}</label>
                            <Editor
                                id={field.name}
                                name={field.name}
                                value={field.value}
                                headerTemplate={header || renderHeader()}
                                onTextChange={(e) => field.onChange(e.htmlValue)}
                                style={{ height: '320px', ...style }} />
                            <FormErrorMessage error={fieldState.error}/>
                        </>
                    }}/>
    );
}
