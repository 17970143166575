import React, {createContext, useContext, useState} from "react";

const organisationContext = createContext(null);

export function ProvideOrganisation({ children }) {
    const value = useProvideOrganisation();
    return <organisationContext.Provider value={value}>{children}</organisationContext.Provider>;
}

export const useOrganisationContext = () => {
    return useContext(organisationContext);
};

function useProvideOrganisation() {

    const [selectedOrganisationId, setSelectedOrganisationId] = useState(null);

    return {
        setSelectedOrganisationId,
        selectedOrganisationId
    };
}