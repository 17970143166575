import materialsService from "./materials.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export function useMaterials() {
    const {data: materials, isFetched, isLoading, isError} = useQuery(['materials'], materialsService.all, {
        initialData: []
    });

    return {
        materials,
        isLoading,
        isFetched,
        isError
    };
}

export function useMaterial(id, options = null) {
    const {data: material, isLoading, isError, isFetching} = useQuery(['materials', id], ({queryKey}) => materialsService.get(queryKey[1]), options);

    return {
        material, isLoading, isError, isFetching
    };
}

export function useUpdateMaterialMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => materialsService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['materials']);
        }
    });

    return mutation;
}


export function useCreateMaterialMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(materialsService.create, {
        onSuccess: (data) => {
            const materials = queryClient.getQueryData(['materials']) ?? [];
            queryClient.setQueryData(['materials'], [...materials, data]);
        }
    });

    return mutation;
}

export function useDeleteMaterialMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(materialsService.delete, {
        onSuccess: (data, variables) => {
            const materials = queryClient.getQueryData(['materials']) ?? [];
            queryClient.setQueryData(['materials'], materials.filter(x => x.id !== variables))
        }
    });

    return mutation;
}