import {ClientInfo} from "./components/ClientInfo";
import {Route, Routes, useMatch, useNavigate, useParams, useResolvedPath} from "react-router-dom";
import {TopMenu} from "../core/TopMenu";
import {useTranslation} from "react-i18next";
import {ClientDocuments} from "./components/ClientDocuments";
import {QuotesListPage} from "../quotes/pages/quotes-list.page";
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";
import {ClientStatus} from "../core/ClientStatus";
import {useClient} from "./clients.hooks";
import {InvoicesListPage} from "../invoices/pages/invoices-list.page";
import Moment from "moment";



export function EditClientPage (){

    const params = useParams();
    const {id: clientId} = params;

    const { t } = useTranslation();
    
    const {client} = useClient(params.id);

    return (
        <div>
            <TopMenu moduleName={t('client.title')} menu={<EditClientMenu clientId={clientId} data={client}/>} list={'/clients'} create={'/clients/create/'}/>

            <Routes>
                <Route path="/documents" element={<ClientDocuments />} />
                <Route path="/quotes" element={<QuotesListPage client={clientId}/>} />
                <Route path="/invoices" element={<InvoicesListPage client={clientId}/>} />
                <Route path="*" element={<ClientInfo />} />
            </Routes>
        </div>
    )
}

function EditClientMenu({clientId, data}) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const infoPath = useResolvedPath('/clients/:id');
    const quotesPath = useResolvedPath('/clients/:id/quotes');
    const invoicesPath = useResolvedPath('/clients/:id/invoices');
    const documentsPath = useResolvedPath('/clients/:id/documents');

    const infoMatch = useMatch({path: infoPath.pathname, end: true});
    const quotesMatch = useMatch({path: quotesPath.pathname, end: true});
    const invoicesMatch = useMatch({path: invoicesPath.pathname, end: true});
    const documentsMatch = useMatch({ path: documentsPath.pathname, end: true });

    return (
        <>
            {data && <>
            <Button id={'info'} label={t('common.info')} icon="pi pi-database" className={classNames("p-button-plain p-button-text", {
                "p-button-raised": infoMatch})} onClick={() => navigate('/clients/'+clientId)} />

            <span className="p-buttonset">
                <Button className={classNames("p-button-plain p-button-text", {"p-button-raised": quotesMatch})} label={t('quote.title')} icon="pi pi-file"
                        onClick={() => navigate('/clients/'+clientId+'/quotes')}/>
                <Button className={classNames("p-button-plain p-button-text", {"p-button-raised": quotesMatch})} icon="pi pi-plus"
                        onClick={() => navigate(`/quotes/create?clientId=${clientId}`)}/>
            </span>

            <span className="p-buttonset">
                <Button className={classNames("p-button-plain p-button-text", {"p-button-raised": invoicesMatch})} label={t('invoice.title')} icon="pi pi-bill"
                        onClick={() => navigate('/clients/'+clientId+'/invoices')}/>
                <Button className={classNames("p-button-plain p-button-text", {"p-button-raised": invoicesMatch})} icon="pi pi-plus"
                        onClick={() => navigate(`/invoices/create?clientId=${clientId}`)}/>
            </span>

            {/*<Button label={t('common.documents')} icon="pi pi-file" className={classNames("p-button-plain p-button-text", {
                "p-button-raised": documentsMatch
            })} onClick={() => navigate('/clients/'+clientId+'/documents')} />*/}
            <Button label={t('common.openFolder')} icon="pi pi-folder-open" className={"p-button-plain p-button-text"} onClick={() =>
            {window.location.href = 'deyemon://G:/Mon Drive/Deyemon Gestion/'+Moment(data.createdAt).format('YYYY')+"/"+data.cId+" - "+data.name;}
            } />
            <ClientStatus id={clientId} status={data?.status}/>
            </>}
        </>
    );
}
