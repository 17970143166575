import {Route, Routes} from "react-router-dom";
import {QuotesListPage} from "./pages/quotes-list.page";
import {QuotesEditPage} from "./pages/quotes-edit.page";
import {QuotesCreatePage} from "./pages/quotes-create.page";
import Decimal from "decimal.js";

//TODO, where to put this ?
Decimal.set({precision: 10, rounding: 4});


export function Quotes ()
{
    return (
        <Routes>
            <Route path="*" element={<QuotesListPage />} />
            <Route path=":id" element={<QuotesEditPage />} />
            <Route path="create" element={<QuotesCreatePage />} />
        </Routes>
    )
}
