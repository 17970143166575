import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import TokenService from "../services/token.service";
import authService from "../services/auth.service";
import api from "../services/api";
import {config} from "../config";
import axios from "axios";
import {useQueryClient} from "@tanstack/react-query";

const authContext = createContext(null);

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(() => {

        const user = TokenService.getUser();

        if(user == null) return null;

        // const jwt = jwtDecode(user.accessToken);
        //
        // const expired = Date.now() >= jwt.exp * 1000;
        //
        // if(expired)

        return user;
    });

    const logIn = (email, password) => {
        return authService.login(email, password)
            .then(user => {
                setUser(user);
            }).catch((error) => {
				throw error;
			});
    };

    const queryClient = useQueryClient();

    const logOut = useCallback(() => {
        authService.logout();
        setUser(null);
        queryClient.clear();
    }, [queryClient, setUser]);

    useMemo(() => {
        api.interceptors.response.use(
            res => res,
            async (err) => {
                const originalConfig = err.config;

                if (originalConfig.url !== "/api/login" && err.response) {
                    // Access Token was expired
                    if (err.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true;
                        await lock.acquire();
                        try {

                            const currentToken = TokenService.getLocalAccessToken();

                            if(currentToken !== null && currentToken !== originalConfig.headers.Authorization.replace('Bearer ', '')) {
                                //Token was refreshed by another request
                                return api(originalConfig);
                            }

                            //We create a 'new' instance here, to prevent infinite loops
                            const apiInstance = axios.create({
                                baseURL: config.url.API_URL,
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            });
                            const rs = await apiInstance.post("/api/token/refresh", {
                                refreshToken: TokenService.getLocalRefreshToken(),
                            });
                            const { token, refreshToken } = rs.data;
                            TokenService.updateLocalAccessToken(token, refreshToken);

                            return api(originalConfig);
                        } catch (_error) {

                            //log out
                            setTimeout(() => {
                                authService.logout();
                                setUser(null);
                            }, 100);

                            return Promise.reject(_error);
                        }
                        finally {
                            lock.release();
                        }
                    }
                }
                return Promise.reject(err);
            }
        );
    }, [setUser]);

    const hasRole = (role) => {
        if(!user)
            return false;

        if(!user.roles)
            return false;

        if(user.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) return true;

        return user.roles.indexOf(role) !== -1;
    };

    return {
        user,
        logIn,
        logOut,
        hasRole
    };
}


// Create a lock object
const lock = {
  locked: false,
  queue: [],

  acquire: function () {
    return new Promise((resolve) => {
      if (!this.locked) {
        this.locked = true;
        resolve();
      } else {
        this.queue.push(resolve);
      }
    });
  },

  release: function () {
    if (this.queue.length > 0) {
      const nextResolve = this.queue.shift();
      nextResolve();
    } else {
      this.locked = false;
    }
  }
};
