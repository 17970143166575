import usersService from "./users.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export function useUsers(organisationId) {
    const key = ['users'];
    if(organisationId) {
        key.push(organisationId);
    } else {
        key.push('self');
    }
    const {data: users, isFetched, isLoading, isError} = useQuery(key, ({queryKey}) => usersService.all(queryKey[1]), {
        initialData: []
    });

    return {
        users: users?.map(item => {
            return {
                ...item,
                deletedAt: item.deletedAt ?? 'NA'
            }
        }),
        isFetched,
        isLoading,
        isError
    };
}

export function useUser(id, options = null, organisationId) {

    const key = ['users'];
    if(organisationId) {
        key.push(organisationId);
    } else {
        key.push('self');
    }

    key.push(id);

    const {data: user, isLoading, isError, isFetching} = useQuery(key, ({queryKey}) => usersService.get(queryKey[2], queryKey[1]), options);

    return {
        user, isLoading, isError, isFetching
    };
}

export function useUpdateUserMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => usersService.update(params.id, params.dto, params.organisationId), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['users']);
        }
    });

    return mutation;
}

export function useCreateUserMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation((params) => usersService.create(params.dto, params.organisationId), {
        onSuccess: (data) => {
            const users = queryClient.getQueryData(['users']) ?? [];
            queryClient.setQueryData(['users'], [...users, data]);
        }
    });

    return mutation;
}

export function useDeleteUserMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation((params) => usersService.delete(params.id, params.organisationId), {
        onSuccess: (data, variables) => {
            const users = queryClient.getQueryData(['users']) ?? [];

            const key = ['users'];
            if(variables.organisationId) {
                key.push(variables.organisationId);
            } else {
                key.push('self');
            }

            queryClient.setQueryData(key, users.filter(x => x.id !== variables.id))
        }
    });

    return mutation;
}