import {Route, Routes} from "react-router-dom";
import {SaleConditionEdit} from "./sale-condition.edit";

export function SaleCondition() {
    return <Routes>

        <Route path="*" element={<SaleConditionEdit />} />

    </Routes>

}