import {useInvoiceAddCommentMutation} from "../invoice.hooks";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {invoiceCommentSchema} from "../constants";
import {useCallback} from "react";
import {TextAreaControl} from "../../core/ControlledInputs/TextAreaControl";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";


export function AddInvoiceCommentForm({invoice}) {
    const mutation = useInvoiceAddCommentMutation();

    const defaultValues = {
        message: ""
    };

    const {control, handleSubmit, reset} = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(invoiceCommentSchema())
    });

    const onSubmit = useCallback(async (data) => {

        try {
            await mutation.mutateAsync({
                id: invoice.id,
                data: data
            });

            reset(defaultValues);
        } catch (e)
        {

        }

    }, [invoice, mutation, reset]);


    const {t} = useTranslation();

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid mt-5">
                <div className="col-12 field">
                    <TextAreaControl control={control} fieldName={'message'} rows={2} autoResize={true} label={t('common.addComment')} />
                </div>
                <div className="col-12">
                    <Button type={"submit"} label={t('common.add')} loading={mutation.isLoading} />
                </div>
            </form>
        </>
    );
}
