import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {InvoiceContext, useInvoiceContext} from "../invoice.context";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {MaterialDescriptionInput} from "./description.input";
import {CurrencyInput} from "./currency.input";
import {AmountInput} from "./amount.input";
import {UnitInput} from "./unit.input";
import {VatInput} from "./vat.input";
import {materialLineSchema, toolBarWidth} from "../constants";
import {Button} from "primereact/button";
import {useLineDnd} from "./use-line.dnd";
import {observer} from "mobx-react";
import {Currency} from "./currency";
import {NoteInput} from "./note.input";
import {classNames} from "primereact/utils";
import {Menu} from "primereact/menu";

export const InvoiceMaterialLine = observer(({material, post, index, parent, isCococontractor}) => {

    const defaultValues = {
        pricePerUnit: material.pricePerUnit.toFixed(2),
        amount: material.amount.toFixed(2),
        unit: material.unit,
        description: material.description,
        vat: material.vat.toFixed(0),
        subTotal: material.subTotal.toFixed(2),
        note: material.note,
        option: material.option
    };

    const {control, handleSubmit, watch, setValue, formState} = useForm({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(materialLineSchema())
    });

    const invoiceModel = useInvoiceContext();

    useEffect(() => {
        invoiceModel.setFormState(material.id, formState.isValid);

        return () => invoiceModel.unRegisterForm(material.id);
    }, [formState.isValid, material.id, invoiceModel]);

    const onSubmit = useCallback((data) => {

        material.updateFromForm(data);

    }, [material]);

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (type === 'change') {
                //handleSubmit(onSubmit)()
                switch (name) {
                    case 'description':
                        material.updateDescription(value.description);
                        break;
                    case 'pricePerUnit':
                        material.updatePricePerUnit(value.pricePerUnit);
                        break;
                    case 'amount':
                        material.updateAmount(value.amount);
                        break;
                    case 'subTotal':
                        material.updateSubTotal(value.subTotal);
                        break;
                    case 'unit':
                        material.updateUnit(value.unit);
                        break;
                    case 'vat':
                        material.updateVat(value.vat);
                        break;
                    case 'note':
                        material.updateNote(value.note);
                        break;
                    case 'option':
                        material.updateOption(value.option);
                        break;
                    default:
                        throw new Error("Unimplemented type " + name)
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [material, watch]);

    useEffect(() => {
        setValue('description', defaultValues.description, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.description, setValue]);

    useEffect(() => {
        setValue('option', defaultValues.option, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.option, setValue]);

    useEffect(() => {
        setValue('unit', defaultValues.unit, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.unit, setValue]);

    useEffect(() => {
        setValue('note', defaultValues.note, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.note, setValue]);

    useEffect(() => {
        setValue('amount', defaultValues.amount, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.amount, setValue]);

    useEffect(() => {
        setValue('pricePerUnit', defaultValues.pricePerUnit, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.pricePerUnit, setValue]);

    useEffect(() => {
        setValue('vat', defaultValues.vat, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.vat, setValue]);

    useEffect(() => {
        setValue('subTotal', defaultValues.subTotal, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.subTotal, setValue]);

    const {t} = useTranslation();

    const {ref, drag, isDragging, handlerId} = useLineDnd(material, index, parent);

    const onMaterialSelected = useCallback((m) => {
        material.updateFromMaterial(m);
    }, [material]);

    const [autoFocusNote, setAutoFocusNote] = useState(false);

    const { copiedLine, setCopiedLine } = useContext(InvoiceContext);

    const optionMenuRef = useRef(null);
    const optionMenuItems = useMemo(() => {
        const items = [
            {
                label: t('invoice.lineOptionType'),
                items: [
                    {
                        label: t('invoice.lineOptionTypeNormal'),
                        icon: !material.extraCharge && !material.credit && !material.variant ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            material.updateExtraCharge(false);
                            material.updateCredit(false);
                            material.updateVariant(false);
                        },
                    },
                    {
                        label: t('invoice.lineOptionTypeExtraCharge'),
                        icon: material.extraCharge ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            material.updateExtraCharge(true);
                            material.updateCredit(false);
                            material.updateVariant(false);
                        },
                    },
                    {
                        label: t('invoice.lineOptionTypeCredit'),
                        icon: material.credit ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            material.updateCredit(true);
                            material.updateExtraCharge(false);
                            material.updateVariant(false);
                        },
                    },
                    {
                        label: t("common.variant"),
                        icon: material.variant ? 'pi pi-check text-green-500' : null,
                        command: () => {
                            material.updateCredit(false);
                            material.updateExtraCharge(false);
                            material.updateVariant(true);
                        },
                    },
                ]
            },
            {
                label: t('invoice.actions'),
                items: [
                    {
                        label: t('common.addNote'),
                        icon: 'pi pi-comment text-green-500',
                        command: () => {
                            if (material.note === null) {
                                setAutoFocusNote(true);
                                material.updateNote("");
                            }
                        }
                    },
                    {
                        label: t('invoice.line.delete'),
                        icon: 'pi pi-trash text-red-500',
                        command: () => {
                            parent.removeLine(material)
                        }
                    },                    {
                        label: t("common.copy"),
                        icon: 'pi pi-copy text-grey-500',
                        command: () => {
                            setCopiedLine(material)
                        }
                    },
                    copiedLine && ((copiedLine.type === 'material' && parent.type === 'post') || (parent.type !== 'post')) && {
                        label: t("common.paste"),
                        icon: 'pi pi-file text-grey-500',
                        command: () => {
                            const item = copiedLine;
                            setCopiedLine(null)
                            parent.pasteLine(item, material);
                        }
                    },
                ].filter(Boolean) // Remove falsy values (in case "copiedItem" doesn't exist)
            }
        ];

        return items;
    }, [t, material.extraCharge, material.credit, material.variant, parent, material, copiedLine]);

    return (<>
        <form onSubmit={handleSubmit(onSubmit)} className={classNames("grid grid-nogutter", {
            "bg-red-100": material.extraCharge,
            "bg-green-100": material.credit,
            "bg-purple-100": material.variant,
        })} ref={ref}
              style={{opacity: isDragging ? 0 : 1}} data-handler-id={handlerId}>

            {!post && (
                <div className="col flex align-items-start flex-wrap">
                    <div className="grid grid-nogutter w-full">
                        <div className="col-fixed w-1rem pr-1 pt-2"><strong>{index}</strong></div>
                        <div className="col flex flex-wrap align-items-start">
                            <MaterialDescriptionInput inputClassName="p-inputtext font-bold w-full" control={control}
                                                      fieldName="description" autoFocus={material.shouldFocus}
                                                      displayTemplate={(value, error) => {
                                                          if (error || !value) {
                                                              return (t('common.clickToEdit'))
                                                          }

                                                          return (<strong
                                                              style={{'whiteSpace': 'pre-wrap'}}>{value}</strong>);
                                                      }}
                                                      onMaterialSelected={onMaterialSelected}/>
                        </div>
                    </div>
                </div>
            )}
            {post && (
                <div className="col flex align-items-start flex-wrap">
                    <div className="grid grid-nogutter w-full">
                        <div className="col-fixed w-2rem pr-1 pt-2"></div>
                        <div className="col flex flex-wrap align-items-start">
                            <MaterialDescriptionInput control={control} fieldName="description"
                                                      autoFocus={material.shouldFocus}
                                                      onMaterialSelected={onMaterialSelected}/>
                        </div>
                    </div>
                </div>
            )}

            {post ?
                <>
                    <div className="col-1 flex align-items-start flex-wrap">
                        <CurrencyInput control={control} fieldName="pricePerUnit" label={t('common.price')}/>
                    </div>
                    <div className="col-1 flex align-items-start flex-wrap">
                        <AmountInput control={control} fieldName="amount" label={t('common.amount')}/>
                    </div>
                    <div className="col-1 flex align-items-start flex-wrap">
                        <UnitInput control={control} fieldName="unit" label={t('common.unit')}/>
                    </div>
                    <div className="col-1 flex align-items-start flex-wrap">
                        {!isCococontractor && <VatInput control={control} fieldName="vat" label={t('common.vat')}/>}
                    </div>
                    <div className="col-1 flex align-items-start flex-wrap">
                        <CurrencyInput control={control} fieldName="subTotal" label={t('common.subTotal')}/>
                    </div>
                </>
                :
                <>
                    <div className="col-1 flex align-items-start flex-wrap">
                        <CurrencyInput control={control} fieldName="pricePerUnit" label={t('common.price')} addStyle={"font-bold"}/>
                    </div>
                    <div className="col-1 flex align-items-start flex-wrap">
                        <AmountInput control={control} fieldName="amount" label={t('common.amount')} addStyle={"font-bold"}/>
                    </div>
                    <div className="col-1 flex align-items-start flex-wrap">
                        <UnitInput control={control} fieldName="unit" label={t('common.unit')} addStyle={"font-bold"}/>
                    </div>
                    <div className="col-1 flex align-items-start flex-wrap">
                        {!isCococontractor && <VatInput control={control} fieldName="vat" label={t('common.vat')} addStyle={"font-bold"}/>}
                    </div>
                    <div className="col-1 flex align-items-start flex-wrap">
                        <CurrencyInput control={control} fieldName="subTotal" label={t('common.subTotal')} addStyle={"font-bold text-primary"}/>
                    </div>
                </>
            }

            <div className="col-fixed flex align-items-start justify-content-end pt-1"
                 style={{width: toolBarWidth}}
            >
                <Button type="button" icon={"pi pi-trash"} className={"p-button-text p-button-sm p-button-danger"}
                        onClick={() => {
                            parent.removeLine(material)
                        }}/>
                <Button type="button" ref={drag} icon={"pi pi-bars"}
                        className={"p-button-text p-button-sm p-button-secondary cursor-move"}></Button>
                <Button type="button" icon={"pi pi-ellipsis-v"} className={"p-button-text p-button-sm p-button-info"}
                        onClick={(e) => optionMenuRef.current.toggle(e)}/>
                <Menu model={optionMenuItems} popup ref={optionMenuRef} id="popup_menu"/>
            </div>

            {material.note !== null && (
                <>
                    <div className="grid grid-nogutter col-12">
                        <div className={classNames('col-fixed text-right', {'w-3rem': !!post, 'w-2rem': !post})}><i
                            className={"pi pi-comment text-sm mt-3"}></i></div>
                        <div className="col flex align-items-start">
                            <NoteInput control={control} fieldName="note" autoFocus={autoFocusNote}></NoteInput>
                        </div>
                        <div className="col-1">
                            <Button type={"button"} icon={"pi pi-times"} className={"p-button-text p-button-sm mt-1"}
                                    onClick={() => {
                                        material.updateNote(null)
                                    }}/>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-1"></div>
                        <div className="col-1"></div>
                        <div className="col-1"></div>
                        <div className="col-1"></div>
                        <div className="col-fixed flex align-items-start"
                             style={{width: toolBarWidth}}
                        ></div>
                    </div>
                </>
            )}

        </form>
    </>)
})
