import {useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Controller} from "react-hook-form";
import {Inplace, InplaceContent, InplaceDisplay} from "primereact/inplace";
import {classNames} from "primereact/utils";
import {FormErrorMessage} from "../../core/FormErrorMessage";
import {AutoComplete} from "primereact/autocomplete";
import {useSections} from "../../sections/sections.hooks";
import {usePosts} from "../../posts/posts.hooks";
import {useMaterials} from "../../materials/materials.hooks";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Tree} from "primereact/tree";
import {InputText} from "primereact/inputtext";

export function SectionDescriptionInput({fieldName, control, displayTemplate, inputClassName = 'p-inputtext w-full', autoFocus = false}) {

    const [active, setActive] = useState(autoFocus);

    const {t} = useTranslation();

    const {sections, isLoading} = useSections();

    const nodes = useMemo(() => {
        const nodes = [];


        for (let j = 0; j < sections.length; j++) {
            const section = sections[j];
            nodes.push({
                key: section.id,
                label: section.name,
                data: section
            })
        }

        return nodes;
    }, [sections]);

    const [suggestions, setSuggestions] = useState([]);

    const searchSections = (e) => {

        if(!e.query.trim().length) {
            setSuggestions(sections)
        } else {
            setSuggestions(sections.filter((section) => {
                return section.name.toLowerCase().includes(e.query.toLowerCase());
            }));
        }
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const showInplace = useMemo(() => {
        return active || dropdownOpen
    }, [active, dropdownOpen]);

    const [showDialog, setShowDialog] = useState(false);

    const inputRef = useRef();

    const [filterValue, setFilterValue] = useState('');
    const filterInputRef = useRef();

    const myFilterFunction = (event, options) => {
        let _filterValue = event.target.value;
        setFilterValue(_filterValue);
        options.filter(event);
    }
    const filterTemplate = (options) => {
        let {filterOptions} = options;

        return (
            <span className="p-input-icon-right w-full">
                <InputText className={"w-full"} value={filterValue} ref={filterInputRef} onChange={(e) => myFilterFunction(e, filterOptions)} />
                <i className="pi pi-search" />
            </span>
        )
    }

    return (
        <>
            <Controller name={fieldName} control={control}
                        render={({field, fieldState}) => (
                            <>
                                <Inplace className="flex-grow-1" active={showInplace} onToggle={(e) => setActive(e.value)}
                                         onFocus={(e) => setActive(true)}>
                                    <InplaceDisplay className={'p-inplace-display p-inplace-display-force-block flex-grow-1'}>
                                        {displayTemplate && displayTemplate(field.value, fieldState.error)}
                                        {!displayTemplate && <>
                                            <span style={{'whiteSpace': 'pre-wrap'}}
                                                  className={classNames({'text-500': !field.value})}>{field.value || t('common.clickToEdit')}</span>
                                        </>}
                                    </InplaceDisplay>
                                    <InplaceContent className={'p-inplace-display-force-block flex align-items-center w-full'}>
                                        <AutoComplete
                                            field={'name'}
                                            {...field}
                                            inputRef={inputRef}
                                            autoFocus
                                            className={'w-full'}
                                            inputClassName={classNames({'p-invalid': fieldState.error}, inputClassName)}
                                            onFocus={(e) => {
                                                const input = e.target;
                                                const end = input.value.length;
                                                input.setSelectionRange(0, end);
                                            }}
                                            onBlur={(e) => {
                                                if(e.relatedTarget?.id === 'section-search') {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                setActive(false);
                                                field.onBlur();
                                            }}
                                            suggestions={suggestions}
                                            completeMethod={searchSections}
                                            onSelect={(e) => {
                                                field.onChange(e.value.name);
                                            }}
                                            onShow={() => {
                                                setDropdownOpen(true)
                                            }}
                                            onHide={() => {
                                                setDropdownOpen(false)
                                            }}
                                        />

                                        <Button type={"button"} id={"section-search"} icon={"pi pi-search"} onClick={(e) => {
                                            setShowDialog(true);
                                        }} />

                                    </InplaceContent>
                                </Inplace>

                                {fieldState.error && <><div className={"break"}></div><div className="ml-auto"><FormErrorMessage error={fieldState.error} /></div></>}

                                <Dialog header={t('common.search')} visible={showDialog} style={{ width: '50vw' }} onHide={() => {
                                    setShowDialog(false);
                                    inputRef.current.focus();
                                }} onShow={() => {
                                    filterInputRef.current.focus();
                                }
                                }>
                                    <Tree loading={isLoading} filter value={nodes} selectionMode={'single'} onSelectionChange={e => {
                                        const sectionIndex = sections.map(x => x.id).indexOf(e.value);

                                        if(sectionIndex !== -1) {
                                            const sectionName = sections[sectionIndex].name;
                                            field.onChange(sectionName);
                                            //onSectionSelected(sections[sectionIndex]);
                                            setShowDialog(false);
                                            inputRef.current.focus();
                                        }
                                    }
                                    } filterTemplate={filterTemplate} />
                                </Dialog>
                            </>
                        )}/>
        </>
    )
}

export function PostDescriptionInput({fieldName, control, displayTemplate, inputClassName = 'p-inputtext w-full', autoFocus = false, onPostSelected = (post) => {}}) {

    const [active, setActive] = useState(autoFocus);

    const {t} = useTranslation();

    const {posts, isLoading} = usePosts();

    const nodes = useMemo(() => {
        const nodes = [];

        const groupedBySection = [];

        for (let i = 0; i < posts.length; i++) {
            const post = posts[i];

            let group = groupedBySection.find(x => (post.section === null && x.section === null) || (post.section?.id === x.section?.id));

            if(group === undefined) {
                group = {
                    section: post.section,
                    posts: [post]
                };
                groupedBySection.push(group);
            } else {
                group.posts.push(post);
            }
        }

        for (let i = 0; i < groupedBySection.length; i++) {
            const group = groupedBySection[i];

            const node = {
                key: group.section?.id ?? "0",
                label: group.section?.name ?? t('common.noSection'),
                children: []
            };

            for (let j = 0; j < group.posts.length; j++) {
                const post = group.posts[j];
                node.children.push({
                    key: post.id,
                    label: post.name,
                    data: post
                })
            }

            nodes.push(node);
        }

        return nodes;
    }, [t, posts]);

    const [suggestions, setSuggestions] = useState([]);

    const searchSections = (e) => {

        if(!e.query.trim().length) {
            setSuggestions(posts)
        } else {
            setSuggestions(posts.filter((post) => {
                return post.name.toLowerCase().includes(e.query.toLowerCase());
            }));
        }
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const showInplace = useMemo(() => {
        return active || dropdownOpen
    }, [active, dropdownOpen]);

    const [showDialog, setShowDialog] = useState(false);

    const inputRef = useRef();

    const [filterValue, setFilterValue] = useState('');
    const filterInputRef = useRef();

    const myFilterFunction = (event, options) => {
        let _filterValue = event.target.value;
        setFilterValue(_filterValue);
        options.filter(event);
    }
    const filterTemplate = (options) => {
        let {filterOptions} = options;

        return (
            <span className="p-input-icon-right w-full">
                <InputText className={"w-full"} value={filterValue} ref={filterInputRef} onChange={(e) => myFilterFunction(e, filterOptions)} />
                <i className="pi pi-search" />
            </span>
        )
    }

    return (
        <>
            <Controller name={fieldName} control={control}
                        render={({field, fieldState}) => (
                            <>
                                <Inplace className="flex-grow-1" active={showInplace} onToggle={(e) => setActive(e.value)}
                                         onFocus={(e) => setActive(true)}>
                                    <InplaceDisplay className={'p-inplace-display p-inplace-display-force-block flex-grow-1'}>
                                        {displayTemplate && displayTemplate(field.value, fieldState.error)}
                                        {!displayTemplate && <>
                                            <span style={{'whiteSpace': 'pre-wrap'}}
                                                  className={classNames({'text-500': !field.value})}>{field.value || t('common.clickToEdit')}</span>
                                        </>}

                                    </InplaceDisplay>
                                    <InplaceContent className={'p-inplace-display-force-block flex align-items-center w-full'}>
                                        <AutoComplete
                                            field={'name'}
                                            {...field}
                                            inputRef={inputRef}
                                            autoFocus
                                            className={'w-full'}
                                            inputClassName={classNames({'p-invalid': fieldState.error}, inputClassName)}
                                            onFocus={(e) => {
                                                const input = e.target;
                                                const end = input.value.length;
                                                input.setSelectionRange(0, end);
                                            }}
                                            onBlur={(e) => {
                                                if(e.relatedTarget?.id === 'post-search') {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                setActive(false);
                                                field.onBlur();
                                            }}
                                            suggestions={suggestions}
                                            completeMethod={searchSections}
                                            onSelect={(e) => {
                                                field.onChange(e.value.name);
                                                onPostSelected(e.value);
                                            }}
                                            onShow={() => {
                                                setDropdownOpen(true)
                                            }}
                                            onHide={() => {
                                                setDropdownOpen(false)
                                            }}
                                        />

                                        <Button type={"button"} id={"post-search"} icon={"pi pi-search"} onClick={(e) => {
                                            setShowDialog(true);
                                        }} />

                                    </InplaceContent>
                                </Inplace>

                                {fieldState.error && <><div className={"break"}></div><div className="ml-auto"><FormErrorMessage error={fieldState.error} /></div></>}

                                <Dialog header={t('common.search')} visible={showDialog} style={{ width: '50vw' }} onHide={() => {
                                    setShowDialog(false);
                                    inputRef.current.focus();
                                }} onShow={() => {
                                    filterInputRef.current.focus();
                                }
                                }>
                                    <Tree loading={isLoading} filter value={nodes} selectionMode={'single'} onSelectionChange={e => {
                                        const postIndex = posts.map(x => x.id).indexOf(e.value);

                                        if(postIndex !== -1) {
                                            const postName = posts[postIndex].name;
                                            field.onChange(postName);
                                            onPostSelected(posts[postIndex]);
                                            setShowDialog(false);
                                            inputRef.current.focus();
                                        }
                                    }
                                    } filterTemplate={filterTemplate} />
                                </Dialog>

                            </>
                        )}/>
        </>
    )
}


export function MaterialDescriptionInput({fieldName, control, displayTemplate, inputClassName = 'p-inputtext w-full', autoFocus = false, onMaterialSelected = (material) => {}}) {

    //return (<TextAreaAutoComplete />)


    const [active, setActive] = useState(autoFocus);

    const {t} = useTranslation();

    const {materials, isLoading} = useMaterials();

    const nodes = useMemo(() => {
        const nodes = [];

        const groupedByCategory = [];

        for (let i = 0; i < materials.length; i++) {
            const material = materials[i];

            let group = groupedByCategory.find(x => (material.category === null && x.category === null) || (material.category?.id === x.category?.id));

            if(group === undefined) {
                group = {
                    category: material.category,
                    materials: [material]
                };
                groupedByCategory.push(group);
            } else {
                group.materials.push(material);
            }
        }

        for (let i = 0; i < groupedByCategory.length; i++) {
            const group = groupedByCategory[i];

            const node = {
                key: group.category?.id ?? "0",
                label: group.category?.name ?? t('common.noCategory'),
                children: []
            };

            for (let j = 0; j < group.materials.length; j++) {
                const material = group.materials[j];
                node.children.push({
                    key: material.id,
                    label: material.name,
                    data: material
                })
            }

            nodes.push(node);
        }

        return nodes;
    }, [t, materials]);

    const [suggestions, setSuggestions] = useState([]);

    const searchSections = (e) => {

        if(!e.query.trim().length) {
            setSuggestions(materials)
        } else {
            setSuggestions(materials.filter((material) => {
                return material.name.toLowerCase().includes(e.query.toLowerCase());
            }));
        }
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const showInplace = useMemo(() => {
        return active || dropdownOpen
    }, [active, dropdownOpen]);

    const [showDialog, setShowDialog] = useState(false);

    const inputRef = useRef();

    const [filterValue, setFilterValue] = useState('');
    const filterInputRef = useRef();

    const myFilterFunction = (event, options) => {
        let _filterValue = event.target.value;
        setFilterValue(_filterValue);
        options.filter(event);
    }
    const filterTemplate = (options) => {
        let {filterOptions} = options;

        return (
            <span className="p-input-icon-right w-full">
                <InputText className={"w-full"} value={filterValue} ref={filterInputRef} onChange={(e) => myFilterFunction(e, filterOptions)} />
                <i className="pi pi-search" />
            </span>
        )
    }


    return (
        <>
            <Controller name={fieldName} control={control}
                        render={({field, fieldState}) => (
                            <>
                                <Inplace className="flex-grow-1" active={showInplace} onToggle={(e) => setActive(e.value)}
                                         onFocus={(e) => setActive(true)}>
                                    <InplaceDisplay className={'p-inplace-display p-inplace-display-force-block flex-grow-1'}>
                                        {displayTemplate && displayTemplate(field.value, fieldState.error)}
                                        {!displayTemplate && <>
                                            <span style={{'whiteSpace': 'pre-wrap'}}
                                                  className={classNames({'text-500': !field.value})}>{field.value || t('common.clickToEdit')}</span>
                                        </>}
                                    </InplaceDisplay>
                                    <InplaceContent className={'p-inplace-display-force-block flex align-items-center w-full'}>
                                        <AutoComplete
                                            field={'name'}
                                            {...field}
                                            inputRef={inputRef}
                                            autoFocus
                                            className={'w-full'}
                                            inputClassName={classNames({'p-invalid': fieldState.error}, inputClassName)}
                                            onFocus={(e) => {
                                                const input = e.target;
                                                const end = input.value.length;
                                                input.setSelectionRange(0, end);
                                            }}
                                            onBlur={(e) => {
                                                if(e.relatedTarget?.id === 'material-search') {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                setActive(false);
                                                field.onBlur();
                                            }}
                                            suggestions={suggestions}
                                            completeMethod={searchSections}
                                            onSelect={(e) => {
                                                field.onChange(e.value.name);
                                                onMaterialSelected(e.value);
                                            }}
                                            onShow={() => {
                                                setDropdownOpen(true)
                                            }}
                                            onHide={() => {
                                                setDropdownOpen(false)
                                            }}
                                        />

                                        <Button type={"button"} id={"material-search"} icon={"pi pi-search"} onClick={(e) => {
                                            setShowDialog(true);
                                        }} />

                                    </InplaceContent>
                                </Inplace>

                                {fieldState.error && <><div className={"break"}></div><div className="ml-auto"><FormErrorMessage error={fieldState.error} /></div></>}


                                <Dialog header={t('common.search')} visible={showDialog} style={{ width: '50vw' }} onHide={() => {
                                    setShowDialog(false);
                                    inputRef.current.focus();
                                }} onShow={() => {
                                    filterInputRef.current.focus();
                                }
                                }>
                                    <Tree loading={isLoading} filter value={nodes} selectionMode={'single'} onSelectionChange={e => {
                                        const materialIndex = materials.map(x => x.id).indexOf(e.value);

                                        if(materialIndex !== -1) {
                                            const materialName = materials[materialIndex].name;
                                            field.onChange(materialName);
                                            onMaterialSelected(materials[materialIndex]);
                                            setShowDialog(false);
                                            inputRef.current.focus();
                                        }
                                    }
                                    } filterTemplate={filterTemplate} />
                                </Dialog>
                            </>
                        )}/>
        </>
    )
}
