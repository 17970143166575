import {Route, Routes, useMatch, useNavigate, useResolvedPath} from "react-router-dom";
import {Clients} from "./clients/clients";
import {Button} from "primereact/button";
import {useAuth} from "./auth/auth.context";
import {Users} from "./users/users";
import {RequireRole} from "./auth/RequireRole";
import {HasRole} from "./auth/has-role";
import {Organisations} from "./organisations/organisations";
import {Quotes} from "./quotes/quotes";
import {Invoices} from "./invoices/invoices";
import {Sections} from "./sections/sections";
import {Posts} from "./posts/posts";
import {Materials} from "./materials/materials";
import {Settings} from "./settings/settings";
import {useTranslation} from "react-i18next";
import {classNames} from "primereact/utils";
import {Dashboard} from "./dashboard/dashboard";
import LicenseBanner from "./licenses/license-banner";
import {useState} from "react";
import {ConfirmYesNo} from "./core/ConfirmYesNo";

export function Layout() {

    const navigate = useNavigate();

    const auth = useAuth();
    const [logOut, setLogOut] = useState(null);

    const {t} = useTranslation();

    return (
        <div className="relative min-h-screen surface-200">
            <div
                className="fixed top-0 bottom-0 h-screen w-3rem max-w-3rem bg-bluegray-600 center flex flex-column align-items-center">

                <img alt="logo" src="/logo.png" className="mt-2 mb-4 w-2rem border-circle cursor-pointer"
                     onClick={() => navigate("/home")}></img>

                <MenuButton label={t('client.title')} icon="pi pi-database" to="/clients"/>

                <MenuButton label={t('quote.title')} icon="pi pi-file" to="/quotes"/>

                <MenuButton label={t('invoice.title')} icon="pi pi-money-bill" to="/invoices"/>

                <MenuButton label={t('section.title')} icon="pi pi-list" to="/sections"/>

                <MenuButton label={t('post.title')} icon="pi pi-wallet" to="/posts"/>

                <MenuButton label={t('material.title')} icon="pi pi-table" to="/materials"/>

                <HasRole role="ROLE_ADMIN">
                    <MenuButton label={t('user.title')} icon="pi pi-users" to="/users"/>
                </HasRole>

                <HasRole role="ROLE_SUPER_ADMIN">
                    <MenuButton label={t('organisation.title')} icon="pi pi-building" to="/organisations"/>
                </HasRole>


                <HasRole role="ROLE_ADMIN">
                    <MenuButton label={t('setting.title')} icon="pi pi-cog" to="/settings"/>
                </HasRole>



                <div className="mt-auto mb-3">
                    <LogoutButton label={t('common.logout')} icon="pi pi-sign-out" onClick={() => setLogOut(true)}/>
                </div>
            </div>

            <ConfirmYesNo description='auth.logOutQuestion' visible={logOut} onHide={() => setLogOut(null)} onConfirmed={() => auth.logOut()} />


            <div className={`ml-6 pr-2`}>

                <div className="pl-2">

                    <LicenseBanner />


                    <Routes>

                        <Route path="*" element={<Dashboard />} />
                        <Route path="dashboard/*" element={<Dashboard />} />
                        <Route path="clients/*" element={<Clients />} />
                        <Route path="quotes/*" element={<Quotes />} />
                        <Route path="invoices/*" element={<Invoices />} />
                        <Route path="sections/*" element={<Sections />} />
                        <Route path="posts/*" element={<Posts />} />
                        <Route path="materials/*" element={<Materials />} />

                            <Route path="users/*" element={
                                <RequireRole role="ROLE_ADMIN">
                                    <Users/>
                                </RequireRole>
                            }/>

                            <Route path="organisations/*" element={
                                <RequireRole role="ROLE_SUPER_ADMIN">
                                    <Organisations/>
                                </RequireRole>
                            }/>

                            <Route path="settings/*" element={
                                <RequireRole role="ROLE_ADMIN">
                                    <Settings/>
                                </RequireRole>
                            }/>

                    </Routes>
                </div>
            </div>
        </div>

    )
}

function MenuButton({label, icon, to}) {
    const navigate = useNavigate();
    const resolved = useResolvedPath(to);
    const match = useMatch({path: resolved.pathname, end: false});

    return (
        <Button className={classNames(
            "mt-1",
            "p-button-rounded",
            {"p-button-outlined": match})} tooltip={label} icon={icon} onClick={() => navigate(to)}/>
    )
}

function LogoutButton({label, icon, onClick}) {
    return (
        <Button className="p-button-rounded mt-1" tooltip={label} icon={icon} onClick={onClick}/>
    )
}
