import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useUnit, useUpdateUnitMutation} from "./units.hooks";
import {useEffect} from "react";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {ProgressBar} from "primereact/progressbar";
import {Card} from "primereact/card";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {Button} from "primereact/button";
import {unitScheme} from "./units.scheme";
import {yupResolver} from "@hookform/resolvers/yup";


export function EditUnitPage() {

    const scheme = unitScheme();
    const {control, formState: {isDirty}, handleSubmit, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const navigate = useNavigate();

    let params = useParams();

    const {t} = useTranslation();

    const {unit, isLoading} = useUnit(params.id);

    useEffect(() => {
        if(!isDirty) {
            reset(unit);
        }
    }, [isDirty, reset, unit]);

    const updateMutation = useUpdateUnitMutation();

    const toast = useToast();

    const onSubmit = async (data) => {
        try {
            const dto = {
                ...data
            };

            await updateMutation.mutateAsync({
                id: unit.id,
                data: dto
            });

            toast.current.show({severity: 'success', summary: t('unit.updated.title'), detail: t('unit.updated.text'), life: 3000});
            navigate('/settings/units');
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('unit.updated.problem') + " " + err, life: 3000});
        }
    };

    return (
        <div>
            <TopMenu moduleName={t('unit.title')} create={'/settings/units/create/'} list={'/settings/units'}/>

            {isLoading && <ProgressBar mode={"indeterminate"} className={'mt-3'} style={{height: '6px'}} />}

            <Card title={t('unit.edit')} className="shadow-3 mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                    </div>
                    <Button type="submit" label={t('common.edit')} loading={updateMutation.isLoading}/>
                </form>
            </Card>

        </div>
    )
}