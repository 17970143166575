import {QuoteMaterialLine} from "./quote-material.line";
import { QuoteMultipleMaterialLine } from "./quote-multiple-material-line";
import {QuotePostLine} from "./quote-post.line";
import {QuoteSectionLine} from "./quote-section.line";
import {observer} from "mobx-react";

export const QuoteLine = observer(({line, index, parent}) => {
    if (line.type === 'material') {
        return <QuoteMaterialLine index={index} material={line} parent={parent} />
    }
    else if (line.type === 'post') {
        return <QuotePostLine index={index} post={line} parent={parent}></QuotePostLine>
    }
    else if (line.type === 'section') {
        return <QuoteSectionLine index={index} section={line} parent={parent}></QuoteSectionLine>
    } else if (line.type === 'multipleMaterial') {
        return <QuoteMultipleMaterialLine index={index} material={line} parent={parent} />
    }

    throw new Error("Expected a material, post, section or discount");
})
