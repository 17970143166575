import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {materialScheme} from "./material.scheme";
import {yupResolver} from "@hookform/resolvers/yup";
import {useMaterial, useUpdateMaterialMutation} from "./materials.hooks";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {DropdownControl} from "../core/ControlledInputs/DropDownControl";
import {CurrencyControl} from "../core/ControlledInputs/CurrencyControl";
import {vats} from "../core/vats";
import {TextAreaControl} from "../core/ControlledInputs/TextAreaControl";
import Decimal from "decimal.js";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {useTranslation} from "react-i18next";
import {useUnits} from "../units/units.hooks";
import {useProviders} from "../providers/providers.hooks";
import {useCategories} from "../categories/categories.hooks";
import {TimelinePerso} from "../core/Timeline";
import {useEffect, useMemo, useState} from "react";
import Loader from "../core/Loader";

export function EditMaterialsPage() {

    const scheme = materialScheme();
    const {control, handleSubmit, formState: {isDirty}, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const params = useParams();

    const {units, isLoading: isLoadingUnits} = useUnits();
    const {providers, isLoading: isLoadingProviders} = useProviders();
    const {categories,isLoading: isLoadingCategories}=useCategories();

    const {t} = useTranslation();

    const {material, isLoading, isFetching} = useMaterial(params.id, {
        onSuccess: (data) => {
            if(!isDirty) {
                reset({
                    name: data.name,
                    unitId: data.unit?.id,
                    providerId: data.provider?.id,
                    categoryId: data.category?.id,
                    price: data.price,
                    tva: data.tva,
                    note: data.note??'',
                });
                }
            }

    });

    const updateMutation = useUpdateMaterialMutation();

    const navigate = useNavigate();
    let toast = useToast();

    const onSubmit = async (data) => {

        try {
            const dto = {
                ...data,
                price: new Decimal(data.price).toFixed(2),
                tva: new Decimal(data.tva).toFixed(2)
            };

            await updateMutation.mutateAsync({
                id: material.id,
                data: dto
            });

            reset(data);

            toast.current.show({severity: 'success', summary: t('material.updated.title'), detail: t('material.updated.text'), life: 3000});
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('material.updated.problem') + " " + err, life: 3000});
        }
    };

    return (
        <div>
            <TopMenu moduleName={t('material.title')} list={'/materials/'} create={'/materials/create/'}/>

            <Card title={t('material.edit')} className="shadow-3 mt-3">

                {isFetching || isLoading ? <Loader/> :

                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                        <div className="field col-12">
                            <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus/>
                        </div>

                        <div className="field col-12">
                            <DropdownControl control={control} fieldName={'unitId'} label={t('common.unit')}
                                             loading={isLoadingUnits} options={units} optionLabel={"name"}
                                             optionValue={"id"}/>
                        </div>

                        <div className="field col-12">
                            <CurrencyControl control={control} fieldName={"price"} label={t('common.price')}/>
                        </div>

                        <div className="field col-12">
                            <DropdownControl control={control} fieldName={"tva"} label={t('common.vat')} options={vats}
                                             optionValue={"value"} optionLabel={"name"}/>
                        </div>

                        <div className="field col-12">
                            <DropdownControl control={control} fieldName={'providerId'} label={t('common.provider')}
                                             loading={isLoadingProviders} options={providers} optionLabel={"name"}
                                             optionValue={"id"}/>
                        </div>

                        <div className="field col-12">
                            <DropdownControl control={control} fieldName={'categoryId'} label={t('common.section')}
                                             loading={isLoadingCategories} options={categories} optionLabel={"name"}
                                             optionValue={"id"}/>
                        </div>

                        <div className="field col-12">
                            <TextAreaControl control={control} fieldName={"note"} label={t('common.note')} maxLength={1000} />
                        </div>

                        <Button type="submit" label={t('common.edit')} disabled={!isDirty}
                                loading={updateMutation.isLoading}/>
                    </form>
                }
            </Card>
            {material?.logs &&
                <TimelinePerso logs={material?.logs}/>
            }
        </div>
    )
}
