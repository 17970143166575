import {observer} from "mobx-react";
import {DropdownControl} from "../../core/ControlledInputs/DropDownControl";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useClients} from "../../clients/clients.hooks";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {useCurrentOrganisation, useOrganisationLogoBase64} from "../../organisations/organisations.hooks";
import {OrganisationCard} from "../../organisations/components/OrganisationCard";
import {Divider} from "primereact/divider";
import {ClientCard} from "../../clients/components/ClientCard";
import {Date} from "../../core/Date";
import {Dropdown} from "primereact/dropdown";
import { useSearchParams } from "react-router-dom";

const quoteInfoSchema = () =>  yup.object({
    clientId: yup.string().required()
});

export const QuoteInfoForm = observer(({ quote, editable = true }) => {
    
    const [isClient, setIsClient] = useState(false);
    const [searchParams] = useSearchParams();

    const defaultValues = {
        clientId: quote.client?.id
    };

    const {control, handleSubmit, watch, setValue} = useForm({
        defaultValues: defaultValues,
        mode: 'all',
        resolver: yupResolver(quoteInfoSchema())
    });

    const {clients, isLoading: isLoadingClients} = useClients();

    const orderedClients = useMemo(() => {
        return clients.sort((a, b) =>
            a.name > b.name ? 1 : -1,
        );

    }, [clients]);

    const {t} = useTranslation();

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if(type === 'change') {
                //handleSubmit(onSubmit)()
                switch (name) {
                    case 'clientId':
                        if(value.clientId) {
                            const client = clients.find(x => x.id === value.clientId);
                            quote.setClient(client);
                        } else {
                            quote.setClient(null);
                        }
                        break;
                    default:
                        throw new Error("Unimplemented type "+name)
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [quote, watch, clients]);

    useEffect(() => {
        setValue('clientId', defaultValues.clientId, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.clientId, setValue]);

    // const onSubmit = useCallback((data) => {
    //
    //     //quote.updateInfo(data);
    //
    // }, []);

    const onSubmit = () => {};

    const {organisation, isLoading: isLoadingOrganisation} = useCurrentOrganisation();

    const {base64: organisationLogo, isLoading: isLoadingOrganisationLogo, isErrorOrganisationLogo} = useOrganisationLogoBase64();

    const [selectedClient, setSelectedClient] = useState(null);

    const selectedClientTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const clientOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.formattedId + " - " + option.name}</div>
            </div>
        );
    };

    useEffect(() => {
        if (searchParams.has('clientId')) {
            if (clients) {
                const client = clients.find((x) => x.id === searchParams.get('clientId'));
                if (client) {
                    setIsClient(true)
                    quote.setClient(client);
                    setValue('clientId', searchParams.get('clientId'), {
                        shouldDirty: false,
                        shouldValidate: true,
                        shouldTouch: false,
                    });
                }
            }
		}
	}, [clients]);

    return (
        <>
            {(editable && !isClient) &&
                <>
                    <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid p-fluid grid-nogutter">
                        <div className="col-6">
                            <DropdownControl control={control} fieldName={"clientId"} label={t('client.name')} loading={isLoadingClients} options={orderedClients} optionValue={'id'} optionLabel={'name'} filter={true}/>
                        </div>
                    </form>

                    <Divider />
                </>
            }
            {
                !isLoadingOrganisationLogo && !isErrorOrganisationLogo && organisationLogo && (
                    <div className="mb-3">
                        {/*<img src="https://www.deyemon.be/wp-content/uploads/2020/10/logo_alonge_B.png" alt="react logo" style={{ width: '300px', }}/>*/}
                        <img src={organisationLogo} alt={"organisation-logo"} style={{ maxWidth: '300px', maxHeight: '150px'}}/>
                    </div>
                )
            }
            <div className="grid p-fluid grid-nogutter">
                <div className="col">
                    {!isLoadingOrganisation && <OrganisationCard organisation={organisation} />}
                </div >
                <div className="col">

                </div>

                <div className="col">
                    {quote.client && <ClientCard clientId={quote.client.id} />}
                </div>
            </div>

            <Divider className={'m-0'} />
            <strong className={"text-primary"}>
            <div className="grid p-fluid grid-nogutter m-2 p-0 justify-content-center align-items-center">
                <div className="col-4 pl-6">{t('quote.name')}</div>
                <div className="col"><span>{t('client.number')}</span> <br/><span>{quote.client && quote.client.formattedId}</span></div>
                <div className="col"><span>{t('quote.number')}</span> {!quote.number && <span className={'font-italic text-500'}><br/>{t('common.tbd')}</span>} {quote.number && <span><br/>{quote.number}</span>}</div>
                <div className="col"><span>{t('common.createdAt')}</span> <br/> <Date datetime={quote.createdAt} /></div>
                <div className="col"><span>{t('common.validUntil')}</span> <br/><Date datetime={quote.validUntil} /></div>
            </div>
            </strong>
        </>
    )
});
