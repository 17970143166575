import {ProvideQuote, useQuoteContext} from "../quote.context";
import {QuoteForm} from "../components/quote.form";
import {QuoteInfoForm} from "../components/quote-info.form";
import {Divider} from "primereact/divider";
import {Card} from "primereact/card";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Quote} from "../models/Quote";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    useCreateQuoteTemplateMutation,
    useQuote,
    useQuoteAddDwgMutation,
    useUpdateQuoteMutation
} from "../quote.hooks";
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {observer} from "mobx-react";
import {useToast} from "../../core/toast.context";
import {TopMenu} from "../../core/TopMenu";
import {useAuth} from "../../auth/auth.context";
import {Dialog} from "primereact/dialog";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {TextControl} from "../../core/ControlledInputs/TextControl";
import Moment from "moment";
import {QuoteStatus} from "../../core/QuoteStatus";
import {QuoteComments} from "../components/quote-comments";
import {TimelinePerso} from "../../core/Timeline";
import quotesService from "../quotes.service";
import {ConfirmYesNo} from "../../core/ConfirmYesNo";
import {ColorLegend} from "../../core/color-legend";


export function QuotesEditPage() {

    let params = useParams();

    const [quote, setQuote] = useState(null);
    const createDwgMutation = useQuoteAddDwgMutation();

    const toast = useToast();

    useQuote(params.id, {
        onSuccess: (data) => {
            if(quote === null) {
                const q = Quote.fromDto(data.id, data.lines, data.client, data.number, data.createdAt, data.validUntil, data.sentToClient, data.status)
                q.comments=data.comments;
                q.logs = data.logs
                setQuote(q);
            } else {
                quote.comments = data.comments;
            }
        }
    });

    const {t} = useTranslation();

    const [ showModal, setShowModal ] = useState( false );

    const navigate = useNavigate()
    const {pathname } = useLocation()
    
    useEffect(() => {
        window.addEventListener('beforeunload',alertUser);
        if(pathname===`/quotes/${params.id}`) {
            window.history.pushState(null,document.title,window.location.href);
            window.addEventListener('popstate', popFunction);
        }     
		return () => {
			window.removeEventListener('beforeunload', alertUser);
			window.removeEventListener('popstate', popFunction);
		};
	}, []);
	const alertUser = (e) => {
		e.preventDefault();
		e.returnValue = '';
    };
    
    const popFunction = (event) => {
			event.preventDefault();
			const answer = window.confirm(t(`common.leavePage`));
			if (!answer) {
				window.history.pushState(null, document.title, window.location.href);
			} else {
				navigate('/quotes/');
			}
    };

    const createPdf = useCallback(async (generatePdf) => {

        const pdfWindow = window.open();

        try {
            const blob = await quotesService.getPdf(generatePdf.id);
            //Build a URL from the file
            const fileURL = URL.createObjectURL(blob);
            pdfWindow.location.href = fileURL;

        } catch (error) {
            pdfWindow.close();
            toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('quote.pdf.problem'), life: 3000});
        }

    }, [navigate, t, toast]);

    const colors = [
        { code: '#FFADA7', label: t('quote.lineOptionTypeOption') },
        { code: '#B2DDB4', label: t('quote.lineOptionTypeDiscount') },
        { code: '#E7CBEC', label: t('quote.lineOptionTypeVariant') },
    ];

    const menu = useMemo((status) => {

        const createDwg = async () => {
            try {
                await createDwgMutation.mutateAsync({
                    id: quote.id
                });

                toast.current.show({
                    severity: 'success',
                    summary: t('quote.dwg.added.title'),
                    detail: t('quote.dwg.added.text'),
                    life: 3000
                });

            } catch (err) {
                toast.current.show({
                    severity: 'error',
                    summary: t('common.oops'),
                    detail: t('quote.dwg.added.problem') + " " + err,
                    life: 3000
                });
            }
        };

        if(quote !== null) {
            return <>
                    <Button label={t('quote.dwg.add')} icon="pi pi-plus" className="p-button-help ml-2" loading={createDwgMutation.isLoading} onClick={() => createDwg()} />
                    <Button label={t('common.saveAsTemplate')} icon="pi pi-save" className="p-button-plain p-button-text" onClick={() => setShowModal(true)} />
                    <Button label={t('common.openFolder')} icon="pi pi-folder-open" className="p-button-plain p-button-text" onClick={() => {
                        window.location.href = 'deyemon://G:/Mon Drive/Deyemon Gestion/'+Moment(quote.client?.createdAt).format('Y')+"/"+quote.client?.formattedId+" - "+quote.client?.name+"/Devis";
                    }} />
                    <Button label={t('common.printToPdf')} icon="pi pi-print" className="p-button-plain p-button-text" onClick={() => createPdf(quote)} />
                    <QuoteStatus id={quote.id} status={quote.dto.status}/>
                    <div className="col"> <ColorLegend colors={colors} /></div>
                </>
        }
    }, [quote, t, createDwgMutation.isLoading])

    if(quote === null) {
        return (
            <div>
                <TopMenu moduleName={t('quote.title')} list={'/quotes/'} create={'/quotes/create/'}/>
                <Card className="ml-auto mr-auto text-center mt-2" style={{maxWidth: '1400px'}}>
                    <ProgressSpinner />
                </Card>
            </div>
        )
    }

    return (

        <div>
            <TopMenu moduleName={t('quote.title')} list={'/quotes/'} create={'/quotes/create/'} menu={menu}/>
            <SaveAsTemplateModal quote={quote} showModal={showModal} setShowModal={setShowModal} />
            <ProvideQuote devi={quote}>
                <EditPage quote={quote} />
            </ProvideQuote>

        </div>

    )
}

const saveAsTemplateScheme = () => yup.object({
    name: yup.string().required().default('').min(2).max(100)
});

const SaveAsTemplateModal = ({showModal, setShowModal, quote}) => {

    const {t} = useTranslation();

    const scheme = saveAsTemplateScheme();
    const {control, handleSubmit, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const mutation = useCreateQuoteTemplateMutation();

    const toast = useToast();

    const onSubmit=useCallback(async (data) => {
        const dto = {
            name: data.name,
            lines: quote.dto.lines,
            vatTotal: quote.dto.vatTotal,
            total: quote.dto.total,
            subTotal: quote.dto.subTotal
        };

        try {
            await mutation.mutateAsync(dto);
            toast.current.show({
                severity: 'success',
                summary: t('quote.template.created.title'),
                detail: t('quote.template.created.text'),
                life: 3000
            });
            //navigate('/quote-templates/' + newTemplate.id);
        } catch (err) {
            toast.current.show({
                severity: 'error',
                summary: t('common.oops'),
                detail: t('quote.template.created.problem') + ' ' + err,
                life: 3000
            });
        }

        setShowModal(false)

    }, [mutation]);

    return (
        <Dialog visible={showModal} header={t('common.saveAsTemplate')} onHide={() => setShowModal(false)}>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-3 p-fluid grid grid-no-gutter">
                <div className="field col-12">
                    <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                </div>
                <div className="col">
                    <Button type={'submit'} label={t('common.saveAsTemplate')} disabled={mutation.isLoading} loading={mutation.isLoading} />
                </div>
            </form>
        </Dialog>
    )
};

const EditPage = observer(({quote}) => {

    const {t} = useTranslation();
    const [editQuote, setEditQuote] = useState(null);

    const quoteModel = useQuoteContext();

    const updateMutation = useUpdateQuoteMutation();

    const toast = useToast();

    const removeMultipleMaterialLinesFromQuote = (quote) => {
        const processLines = (lines) => {
            return lines.reduce((result, line) => {
                if (line.type === 'section') {
                    const sectionLines = {...line, lines: processLines(line.lines)};
                    result.push(sectionLines);
                } else if (line.type === 'post') {
                    const postLines = {...line, lines: line.lines.filter(postLine => postLine.type !== "multipleMaterial")};
                    result.push(postLines);
                } else if (line.type === 'material' && line.type !== 'multipleMaterial') {
                    result.push(line);
                }
    
                return result;
            }, []);
        };
    
        return {...quote, lines: processLines(quote.lines)};
    };

    const submitForm = async (data) => {

        try
        {
            const dto = quote.dto;
            const filteredDTO = removeMultipleMaterialLinesFromQuote(quote.dto)
            
            await updateMutation.mutateAsync({
                id: quote.id,
                data: filteredDTO
            });

            toast.current.show({severity: 'success', summary: t('quote.updated.title'), detail: t('quote.updated.text'), life: 3000});
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('quote.updated.problem') + " " + err, life: 3000});
        }

        setEditQuote(null);
    };

    const auth = useAuth();

    const canEdit=!quote.sentToClient||auth.hasRole("ROLE_ADMIN");

    const handleKeyDown = useCallback((event) => {
        // Check for ALT + s
        if (event.altKey && event.key === "s") {
          event.preventDefault(); // Prevent the default browser behavior
          quote.addSection();
        }
    
        // Check for ALT + p
        if (event.altKey && event.key === "p") {
          event.preventDefault();
          quote.addPost();
        }

        // Check for ALT + m
        if (event.altKey && event.key === "m") {
            event.preventDefault();
            quote.addMaterial();
        }

        // Check for ALT + d
        if (event.altKey && event.key === "d") {
            event.preventDefault();
            quote.addMultipleMaterial();
        }

      }, [quote]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
    
        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }, [handleKeyDown]);

    return (
        <>
            <Card className="ml-auto mr-auto mt-2 xl:p-8 sm:p-3" style={{maxWidth: '1400px'}}>

                {quote.sentToClient && <QuoteWasSentToClient />}

                <QuoteInfoForm quote={quote} editable={false} />

                <Divider className={'mt-0'} />

                <QuoteForm quote={quote} />

                {quote.sentToClient && <QuoteWasSentToClient />}

                <div>
                    {(!quote.sentToClient || auth.hasRole("ROLE_ADMIN")) &&
                        <Button type={"button"} label={t('common.edit')} disabled={!quote.valid || !quoteModel.isValid || updateMutation.isLoading || !canEdit} loading={updateMutation.isLoading}
                                onClick={() => {
                            if(quote.sentToClient){
                                setEditQuote(true);
                            }else{
                                submitForm();
                        }}}/>
                    }

                    <ConfirmYesNo description='quote.pdf.editSentQuote' visible={editQuote} onHide={() => setEditQuote(null)} onConfirmed={submitForm} />
                </div>
            </Card>
            <Card className="ml-auto mr-auto mt-2 xl:p-8 sm:p-3" style={{maxWidth: '1400px'}} title={t('common.comments')}>
                <QuoteComments quote={quote} />
            </Card>
            <TimelinePerso logs={quote?.logs}/>
        </>
    );
});

function QuoteWasSentToClient() {
    const {t} = useTranslation();

    return (
        <Card title={t('common.warning')} className={"mt-4 mb-4 bg-orange-300"}>
            {t('quote.wasSentToClient')}
        </Card>
    )
}
