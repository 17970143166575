import {useNavigate} from "react-router-dom";
import {useToast} from "../core/toast.context";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useCreateProviderMutation} from "./providers.hooks";
import {useTranslation} from "react-i18next";
import {TopMenu} from "../core/TopMenu";
import {Card} from "primereact/card";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {Button} from "primereact/button";
import {providerScheme} from "./providers.scheme";


export function CreateProviderPage() {
    const navigate = useNavigate();
    let toast = useToast();

    const {control, handleSubmit} = useForm({
        defaultValues: providerScheme().cast(),
        resolver: yupResolver(providerScheme())
    });

    const createMutation = useCreateProviderMutation();

    const {t} = useTranslation();

    const onSubmit = async (data) => {

        const dto = {
            ...data
        };

        try {
            await createMutation.mutateAsync(dto);
            toast.current.show({severity: 'success', summary: t('provider.created.title'), detail: t('provider.created.text'), life: 3000});
            navigate('/settings/providers');
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('provider.created.problem')+' '+err, life: 3000});
        }
    };

    return (

        <div>
            <TopMenu moduleName={t('provider.title')} list={'/settings/providers'}/>

            <Card title={t('provider.create')} className="shadow-3 mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                    </div>
                    <Button type="submit" label={t('common.create')} loading={createMutation.isLoading}/>
                </form>
            </Card>
        </div>
    )
}