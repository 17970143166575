import {Controller} from "react-hook-form";
import {InputNumber} from "primereact/inputnumber";
import {classNames} from "primereact/utils";
import {FormErrorMessage} from "../FormErrorMessage";


export function NumericControl({minFractionDigits = 2, maxFractionDigits = 2, fieldName, control, label}) {
    return (
        <Controller name={fieldName} control={control}
                    render={({field, fieldState}) => (
                        <>
                            <span className="p-float-label">
                                <InputNumber type="decimal"
                                             minFractionDigits={2}
                                             maxFractionDigits={2}
                                             onBlur={field.onBlur}
                                             value={field.value}
                                             onValueChange={(e) => field.onChange(e.value)}
                                             className={classNames({'p-invalid': !!fieldState.error})}
                                />
                                <label htmlFor={field.name}
                                       className={classNames({'p-error': !!fieldState.error})}>{label}</label>
                            </span>
                            <FormErrorMessage error={fieldState.error}/>
                        </>
                    )}/>
    );
}