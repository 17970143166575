import {Card} from 'primereact/card';
import {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Quote} from '../../../quotes/models/Quote';
import {ProvideQuote, useQuoteContext} from '../../../quotes/quote.context';
import {QuoteForm} from '../../../quotes/components/quote.form';
import moment from 'moment/moment';
import {observer} from 'mobx-react';
import {Button} from 'primereact/button';
import {useToast} from '../../../core/toast.context';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {TopMenu} from '../../../core/TopMenu';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Checkbox} from 'primereact/checkbox';
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {InputText} from 'primereact/inputtext';
import {useCreateQuoteTemplateMutation} from './quote.template.hooks';

export function CreateQuoteTemplatePage() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);

	const [quote, setQuote] = useState(null);

	const toast = useToast();

	const {t} = useTranslation();

	const startNewQuote = () => {
		setQuote(new Quote(uuidv4(), [], null, null, moment().toDate(), moment().add(1, 'month').toDate()));
		setIsLoading(false);
	};

	useState(() => {
		startNewQuote();
	}, []);

	const {pathname} = useLocation();

	useEffect(() => {
		window.addEventListener('beforeunload', alertUser);
		if (pathname == '/settings/quote-templates/create/') {
			//console.log(pathname);
			window.history.pushState(null, document.title, window.location.href);
			window.addEventListener('popstate', popFunction);
		}
		return () => {
			window.removeEventListener('beforeunload', alertUser);
			window.removeEventListener('popstate', popFunction);
		};
	}, []);
	const alertUser = (e) => {
		e.preventDefault();
		e.returnValue = '';
	};

	const popFunction = (event) => {
		event.preventDefault();
		const answer = window.confirm(t(`common.leavePage`));
		if (!answer) {
			window.history.pushState(null, document.title, window.location.href);
		} else {
			navigate('/settings/quote-templates/');
		}
	};

	if (isLoading) {
		return (
			<Card className='ml-auto mr-auto mt-2 text-center' style={{maxWidth: '1400px'}}>
				<ProgressSpinner />
				<ConfirmDialog />
			</Card>
		);
	}

	return (
		<ProvideQuote devi={quote}>
			<CreatePage quote={quote} />
		</ProvideQuote>
	);
}

const CreatePage = observer(({quote}) => {
	const createMutation = useCreateQuoteTemplateMutation();
	const [name, setName] = useState('');

	const toast = useToast();

	const {t} = useTranslation();

	const navigate = useNavigate();

	const submitForm = async () => {
		if (!name) {
			return;
		}
		const dto = {
			name: name,
			lines: quote.dto.lines,
			vatTotal: quote.dto.vatTotal,
			total: quote.dto.total,
			subTotal: quote.dto.subTotal,
		};
		try {
			const newQuote = await createMutation.mutateAsync(dto);
			toast.current.show({
				severity: 'success',
				summary: t('quote.template.created.title'),
				detail: t('quote.template.created.text'),
				life: 3000,
			});
			navigate('/settings/quote-templates/');
		} catch (err) {
			toast.current.show({
				severity: 'error',
				summary: t('common.oops'),
				detail: t('quote.template.created.problem') + ' ' + err,
				life: 3000,
			});
		}
	};

	const quoteModel = useQuoteContext();

	return (
		<div>
			<TopMenu moduleName={t('quote.template.title')} list={'/settings/quote-templates'} />

			<Card className='ml-auto mr-auto mt-2 xl:px-4 xl:pt-0 sm:p-3' style={{maxWidth: '1400px'}}>
				<h2 className='pb-4' style={{marginTop: '-10px'}}>
					{t('quote.template.create')}
				</h2>
				<div className='col-12'>
					<span className='p-float-label'>
						<InputText id='templateName' value={name} onChange={(e) => setName(e.target.value)} />
						<label htmlFor='templateName'>{t('quote.template.name')}</label>
					</span>
					{!name && <div className='p-error pt-2 text-sm'>{t(`validations.required`)}</div>}
				</div>

				<QuoteForm quote={quote} />

				<div className='grid grid-nogutter'>
					<div className='col justify-content-center'>
						<Button type={'button'} label={t('common.create')} disabled={!quoteModel.isValid || createMutation.isLoading || !name} loading={createMutation.isLoading} onClick={submitForm} />
					</div>
					<div className='col-8'></div>
				</div>
			</Card>
		</div>
	);
});
