import {Tag} from "primereact/tag";
import {useTranslation} from "react-i18next";


export function PlaceholderInvoice(){

    const {t} = useTranslation();

    return(
        <div className="col-6 text-sm mb-5">
            <span className={'m-1'}>{t('email.placeholders')}:</span>
            <br/>
            <Tag className={'m-1'} value={'%validitydate% : ' + t('email.validityDate')} />
            <Tag className={'m-1'} value={'%invoicenumber% : ' + t('invoice.number')} />
            <Tag className={'m-1'} value={'%invoiceamount% : ' + t('common.subTotal')} />
            <Tag className={'m-1'} value={'%iban% : ' + t('email.iban')} />
            <Tag className={'m-1'} value={'%invoicedate% : ' + t('email.invoiceDate')} />
            <Tag className={'m-1'} value={'%companyname% : ' + t('email.companyName')} />
            <Tag className={'m-1'} value={'%clientname% : ' + t('client.name')} />
            <Tag className={'m-1'} value={'%gender% : ' + t('common.gender')} />
            <Tag className={'m-1'} value={'%clientnumber% : ' + t('client.number')} />
            <Tag className={'m-1'} value={'%communication% : ' + t('email.communication')} />
        </div>
    )
}