import {Route, Routes} from "react-router-dom";
import { ThemeEdit } from "./theme.edit";
import { ProvideOrganisation } from "../organisation.context";

export function Theme() {
    return (
        <ProvideOrganisation>
            <Routes>
                <Route path="*" element={<ThemeEdit />} />
            </Routes>
        </ProvideOrganisation>
    )
}