import {useAuth} from "./auth.context";


export function HasRole({role, children}) {
    const auth = useAuth();

    if(auth.hasRole(role)) {
        return children;
    }

    return null;
}