import React, {createContext, useCallback, useContext, useRef, useState} from "react";


export const QuoteContext = createContext();

export function ProvideQuote({ devi, children }) {
    const deviModel = useProvideQuoteModel();
    return <QuoteContext.Provider value={deviModel}>{children}</QuoteContext.Provider>;
}

export const useQuoteContext = () => {
    return useContext(QuoteContext);
};

// Provider hook that creates auth object and handles state
function useProvideQuoteModel() {
    const forms = useRef({});
    const [valid, setValid] = useState(false);

    const [copiedLine, setCopiedLine] = useState(null);

    const setFormState = useCallback((id, formValid) => {
        //setForms({...forms, [id]: formValid})
        forms.current[id] = formValid;

        for (let key in forms.current) {
            if(!forms.current[key]) {
                setValid(false);
                return;
            }
        }

        setValid(true);

    }, [forms, setValid])

    const unRegisterForm = useCallback((id) => {
        delete forms.current[id];

        for (let key in forms.current) {
            if(!forms.current[key]) {
                setValid(false);
                return;
            }
        }

        setValid(true);

    }, [forms, setValid])

    return {
        setFormState,
        unRegisterForm,
        isValid: valid,
        copiedLine,
        setCopiedLine,
    };
}
