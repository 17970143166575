import {InputTextarea} from "primereact/inputtextarea";
import {FormErrorMessage} from "../FormErrorMessage";
import {Controller} from "react-hook-form";
import {classNames} from "primereact/utils";
import { useEffect, useState } from "react";

export function TextAreaControl({fieldName, control, label, autoResize=true, cols, rows, autoFocus = false, placeholder = null, className = null, maxLength}) {
    const noteValue = control._defaultValues.note;

    const [counter, setCounter] = useState(noteValue ? noteValue.toString().length : 0);

    useEffect(() => { 
        noteValue && setCounter(noteValue.toString().length) 
    }, [noteValue])
    const updateCounter = (e) => {
        setCounter(e.target.value.toString().length);
    }
           return (
               <Controller name={fieldName} control={control}
                           render={({field, fieldState}) => (
                               <>
                                   <span className={classNames("p-float-label", className)}>
                                       <InputTextarea id={field.name} {...field}
                                                      className={classNames({'p-invalid': fieldState.error}, className)}
                                                      autoResize={autoResize}
                                                      cols={cols}
                                                      rows={rows}
                                                      autoFocus={autoFocus}
                                                      placeholder={placeholder}
                                                      maxLength={maxLength}
                                                      onInput={maxLength && updateCounter}             
                                       />
                                       {
                                        maxLength && <div className="w-full text-right text-xs" style={{ position: 'absolute' }}>{counter}/{maxLength}</div>
                                       }
                                       <label htmlFor={field.name}
                                              className={classNames({'p-error': fieldState.error})}>{label}</label>
                                   </span>
                                   <FormErrorMessage error={fieldState.error} />
                               </>
                           )}/>
           )
       }