// import genderService from './gender.service';
import invoiceTemplateService from './invoice.template.service';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

export function useInvoiceTemplates() {
	const {
		data: templates,
		isFetched,
		isLoading,
		isError,
	} = useQuery(['invoice-templates'], invoiceTemplateService.all, {
		initialData: [],
	});

	return {
		templates,
		isFetched,
		isLoading,
		isError,
	};
}

export function useInvoiceTemplate(id, options = null) {
	const {data: gender, isLoading, isError} = useQuery(['invoice-templates', id], ({queryKey}) => invoiceTemplateService.get(queryKey[1]), options);

	return {
		gender,
		isLoading,
		isError,
	};
}

export function useUpdateInvoiceTemplateMutation() {
	const queryClient = useQueryClient();

	const mutation = useMutation((params) => invoiceTemplateService.update(params.id, params.data), {
		onSuccess: async (data, params) => {
			await queryClient.invalidateQueries(['invoice-templates']);
		},
	});

	return mutation;
}

export function useCreateInvoiceTemplateMutation() {
	const queryClient = useQueryClient();

	const mutation = useMutation(invoiceTemplateService.create, {
		onSuccess: (data) => {
			const genders = queryClient.getQueryData(['']) ?? [];
			queryClient.setQueryData(['invoice-templates'], [...genders, data]);
		},
	});

	return mutation;
}

export function useDeleteInvoiceTemplateMutation() {
	const queryClient = useQueryClient();

	const mutation = useMutation(invoiceTemplateService.delete, {
		onSuccess: (data, variables) => {
			const genders = queryClient.getQueryData(['invoice-templates']) ?? [];
			queryClient.setQueryData(
				['invoice-templates'],
				genders.filter((x) => x.id !== variables)
			);
		},
	});

	return mutation;
}
