import {createSearchParams, useNavigate} from "react-router-dom";
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Fragment, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FilterMatchMode} from "primereact/api";
import {Button} from "primereact/button";
import {HasRole} from "../../auth/has-role";
import {useToast} from "../../core/toast.context";
import {useDeleteQuoteMutation, useQuotes} from "../quote.hooks";
import {useTranslation} from "react-i18next";
import {ConfirmDelete} from "../../core/ConfirmDelete";
import {DateTime} from "../../core/DateTime";
import {classNames} from 'primereact/utils';
import quotesService from "../quotes.service";
import {Menu} from "primereact/menu";
import {useAuth} from "../../auth/auth.context";
import Moment from "moment";
import {QuoteStatus} from "../../core/QuoteStatus";
import {Dropdown} from "primereact/dropdown";
import {useQuoteStatuses} from "../quote.status.hooks";
import {ConfirmDialog} from "primereact/confirmdialog";
import {TopMenu} from "../../core/TopMenu";
import {Dialog} from "primereact/dialog";
import {SendQuoteToClientForm} from "../components/send-quote-to-client.form";
import {Skeleton} from "primereact/skeleton";
import invoicesService from "../../invoices/invoices.service";
import { useLocation } from 'react-router-dom';
import {ConfirmYesNo} from "../../core/ConfirmYesNo";


export function QuotesListPage(client = null){

    const [quote, setQuote] = useState(null);
    const [toSendToQuote, setToSendToQuote] = useState(null);
    const [generatePdf, setGeneratePdf] = useState(null);

    const {quotes, isLoading , isFetched} = useQuotes();

    const deleteMutation = useDeleteQuoteMutation();

    const MyComponent = () => {
        const location = useLocation()

        useEffect(() => {
            // runs on location, i.e. route, change
            //console.log('handle route change here', location)
        }, [location])
    }

    let navigate = useNavigate();
    let toast = useToast();

    const {t} = useTranslation();

    const [filters] = useState({
        //'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'number': { value: null, matchMode: FilterMatchMode.CONTAINS,  },
        'client.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'subTotal': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'createdAt': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'validUntil': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'status': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'updatedAt': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const actionTemplate = (quote) => {
        return (
            <Fragment>
                <div style={{display: 'flex',justifyContent: 'right'}}>
                    <div style={{width: '2.5rem'}}>
                        <ShowDwgFileButton quote={quote}/>
                    </div>
                    <div style={{width:'2.4rem'}}>
                        <GeneratePdfButton quote={quote} onClick={() => setGeneratePdf(quote)} />
                    </div>
                    <div style={{width:'2.4rem'}}>
                        <SendToClientButton quote={quote} onClick={() => setToSendToQuote(quote)} />
                    </div>
                    <div style={{width: '2.5rem'}}>
                    <Button icon="pi pi-folder-open" className="p-button-rounded p-button-warning p-button-text m-0" tooltip={t('common.openFolder')} onClick={() => {window.location.href = 'deyemon://G:/Mon Drive/Deyemon Gestion/'+Moment(quote.client.createdAt).format('Y')+"/"+quote.client.formattedId+" - "+quote.client.name+"/Devis";}} />
                    </div>
                    <HasRole role="ROLE_ADMIN">
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text m-0" onClick={() => confirmDeleteQuote(quote)}/>
                    </HasRole>
                    <OptionsButton quote={quote} />
                </div>
            </Fragment>
        )
    };

    const confirmDeleteQuote = (quote) => {
        setQuote(quote);
    };

    const hideDeleteDialog = () => {
        setQuote(null);
    };

    const deleteQuote = () => {

        deleteMutation.mutateAsync(quote.id)
            .then(() => {
                toast.current.show({severity: 'success', summary: t('quote.deleted.title'), detail: t('quote.deleted.text'), life: 3000});
            })
            .catch(() => {
                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('quote.deleted.problem'), life: 3000});
            })
            .finally(() => {
                hideDeleteDialog();
            })
    };

    const onRowClicked = useCallback(async (quote) => {

        if(quote.field === "client.name"){
            navigate('/clients/'+quote.rowData.client.id)
        }else if(quote.field !== "status"){
            quote = quote.rowData;
            navigate(`/quotes/${quote.id}`);
        }


    }, [navigate, t, toast]);

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option.value}`}>{option.name}</span>;
    }

    const quoteStatuses = useQuoteStatuses();

    const quotesStatusFilterElement = (options) => {
        return <Dropdown value={options.value} options={quoteStatuses} optionLabel={"name"} optionValue={"value"} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder={t('common.status')} className="p-column-filter" showClear />;
    }

    const items = Array.from({length: 10}, (v, i) => i);

    const skeletonTemplate = () => {
        return <Skeleton/>;
    };

    const createPdf = useCallback(async (generatePdf) => {

        const pdfWindow = window.open();

        try {
            const blob = await quotesService.generatePdf(generatePdf.id);
            const fileURL = URL.createObjectURL(blob);
            pdfWindow.location.href = fileURL;

        } catch(error) {
            toast.current.show({severity: 'error', summary: t('common.oops'), detail: error.response?.data?.detail || t('common.problemGenerate'), life: 3000});
        }
        finally {
            setGeneratePdf(null);
        }

    }, [navigate, t, toast]);

    return (

        <div>
            {!client.client && <TopMenu moduleName={t('quote.title')} create={'/quotes/create/'} />}
            <Card className='shadow-3 mt-3' title={t('quote.list')}>
                <DataTable
                    value={isLoading || !isFetched ? items : client.client ? quotes.filter((x) => x.client.id === client.client) : quotes}
                    paginator
                    sortMode='multiple'
                    filterDisplay='row'
                    filters={filters}
                    dataKey='id'
                    stripedRows
                    selectionMode='single'
                    emptyMessage={t('quote.emptyList')}
                    currentPageReportTemplate={t('datatable.reportTemplate')}
                    rows={25}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    rowHover
                    responsiveLayout='scroll'
                    columnResizeMode='expand'
                    size={'small'}
                    cellSelection
                    onCellSelect={(e) => onRowClicked(e)}>
                    <Column
                        field='number'
                        header={t('quote.number')}
                        sortable
                        filter
                        filterPlaceholder={t('quote.number')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.number)}
                    />
                    <Column
                        field='client.name'
                        header={t('client.name')}
                        sortable
                        filter
                        filterPlaceholder={t('client.name')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : row.client.name)}
                    />
                    <Column
                        field='status'
                        header={t('common.status')}
                        sortable
                        filter
                        filterPlaceholder={t('common.status')}
                        filterElement={quotesStatusFilterElement}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : <QuoteStatus id={row.id} status={row.status} sendAt={row.sentToClient} />)}
                    />
                    <Column
                        field='subTotal'
                        header={t('common.subTotal')}
                        sortable
                        filter
                        filterPlaceholder={t('common.subTotal')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton /> : <span>{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(row.subTotal)}</span>)}
                    />
                    <Column
                        field='createdAt'
                        header={t('common.createdAt')}
                        sortable
                        filter
                        filterPlaceholder={t('common.createdAt')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : <DateTime datetime={row.createdAt} />)}
                    />
                    <Column
                        field='validUntil'
                        header={t('common.validUntil')}
                        sortable
                        filter
                        filterPlaceholder={t('common.validUntil')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : <DateTime datetime={row.validUntil} />)}
                    />
                    <Column
                        field='updatedAt'
                        header={t('common.updatedAt')}
                        sortable
                        filter
                        filterPlaceholder={t('common.updatedAt')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton/> : <DateTime datetime={row.updatedAt} />)}
                    />
                    <Column body={isLoading || !isFetched ? skeletonTemplate : actionTemplate} className='p-0' style={{minWidth: '200px'}} />
                </DataTable>
            </Card>

            <ConfirmDelete name={quote?.number} loading={deleteMutation.isLoading} visible={quote} onHide={() => setQuote(null)} onConfirmed={deleteQuote} />
            <ConfirmDialog />


            <Dialog header={t('quote.sendToClient.modal.header')} visible={toSendToQuote !== null} style={{ width: '50vw' }} onHide={() => setToSendToQuote(null)}>
                {toSendToQuote !== null && (<SendQuoteToClientForm quote={toSendToQuote} onCompleted={() => setToSendToQuote(null)} />)}
            </Dialog>

            <ConfirmYesNo description='common.confirmGeneratePdf' visible={generatePdf} onHide={() => setGeneratePdf(null)} onConfirmed={() => createPdf(generatePdf)} />

        </div>
    );
}

function SendToClientButton({quote, onClick}) {

    const {t} = useTranslation();

    return (
        <>
        
        {
            (quote.status === 1 || quote.status === 2 || quote.status === 3) && (

                <Button icon={quote.sentToClient ? "pi pi-refresh" : "pi pi-send"}
                        className={classNames("p-button-rounded p-button-text m-0", {
                            "p-button-success": !quote.sentToClient,
                            "p-button-info": quote.sentToClient
                        })}
                        tooltip={quote.sentToClient ? t('quote.sendToClient.tooltip.reSend') : t('quote.sendToClient.tooltip.send')}
                        onClick={onClick}/>
            )
        }
        
        </>
    );
}

function GeneratePdfButton({quote, onClick}) {

    const {t} = useTranslation();

    return (
        <>

            {
                quote.status === 1 && (

                    <Button icon={"pi pi-file-pdf"}
                            className={classNames("p-button-rounded p-button-text m-0 p-button-danger")}
                            tooltip={t('common.generatePdf')}
                            onClick={onClick}/>
                )
            }

        </>
    );
}

function ShowDwgFileButton({quote}) {

    const {t} = useTranslation();

    return (
        <>

            {(quote.dwgFile) && (
                <Button icon={"pi pi-file"}
                        className="p-button-rounded p-button-text m-0 p-button-success"
                        tooltip={t('quote.dwg.tooltip')}
                        onClick={() => {window.location.href = 'deyemon://G:/Mon Drive/Deyemon Gestion/'+Moment(quote.client.createdAt).format('Y')+"/"+quote.client.formattedId+" - "+quote.client.name+"/Devis/"+quote.number+".dwg";}}
                />
            )}

        </>
    );
}

function OptionsButton({quote}) {

    const menu = useRef(null);

    const {t} = useTranslation();

    const toast = useToast();

    const navigate = useNavigate();

    const auth = useAuth();

    const createPdf = useCallback(async (generatePdf) => {

        const pdfWindow = window.open();

        try {
            const blob = await quotesService.getPdf(generatePdf.id);
            //Build a URL from the file
            const fileURL = URL.createObjectURL(blob);
            pdfWindow.location.href = fileURL;

        } catch (error) {
            pdfWindow.close();
            toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('quote.pdf.problem'), life: 3000});
        }

    }, [navigate, t, toast]);

    const menuItems = useMemo(() => {
        const items = [
            {
                label: t('common.other'),
                items: [
                    {
                        label: t('common.duplicate'),
                        icon: 'pi pi-clone',
                        command: () => {
                            navigate({
                                pathname: '/quotes/create',
                                search: `?${createSearchParams({quoteId: quote.id})}`
                            })
                        }
                    },
                    {
                        label: t('common.printToPdf'),
                        icon: 'pi pi-print',
                        command: () => {
                            createPdf(quote)
                        }
                    }
                ]
            }
        ];

        if (quote.status !== 7 && quote.status !== 6) {
            items.splice(0, 0,{
                label: t('common.newVersion'),
                items: [
                    {
                        label: t('common.empty'),
                        icon: 'pi pi-stop',
                        command: async () => {
                            //toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                            try {
                                const newQuote = await quotesService.newVersion(quote.id, true);
                                navigate(`/quotes/${newQuote.id}`);
                            } catch (e) {
                                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('quote.newVersion.error'), life: 3000});
                            }
                        }
                    },
                    {
                        label: t('common.fromCurrentVersion'),
                        icon: 'pi pi-replay',
                        command: async () => {
                            //toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                            try {
                                const newQuote = await quotesService.newVersion(quote.id, false);
                                navigate(`/quotes/${newQuote.id}`);
                            } catch (e) {
                                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('quote.newVersion.error'), life: 3000});
                            }
                        }
                    }
                ]
            });
        }else{
            items.splice(0, 0,{
                label: t('invoice.name'),
                items: [
                    {
                        label: t('common.empty'),
                        icon: 'pi pi-stop',
                        command: async () => {
                            //toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                            try {
                                const newInvoice = await invoicesService.newInvoice(quote.id, true);
                                navigate(`/invoices/${newInvoice.id}`);
                            } catch (e) {
                                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('invoice.newVersion.error'), life: 3000});
                            }
                        }
                    },
                    {
                        label: t('common.fromCurrentVersion'),
                        icon: 'pi pi-replay',
                        command: async () => {
                            //toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                            try {
                                const newInvoice = await invoicesService.newInvoice(quote.id, false);
                                navigate(`/invoices/${newInvoice.id}`);
                            } catch (e) {
                                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('quote.newVersion.error'), life: 3000});
                            }
                        }
                    }
                ]
            });
        }

        return items;
    }, [auth, navigate, quote.id, quote.sentToClient, quote.status, t, toast]);

    return (
        <>
            <Menu model={menuItems} popup ref={menu} id="popup_menu" />
            <Button className={"p-button p-button-text"} icon="pi pi-ellipsis-v" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
        </>
    );
}
