import {useClient} from "../clients.hooks";
import {ProgressSpinner} from "primereact/progressspinner";


export const ClientDocuments = () => {


    return (
        <>
            <h1>Documents</h1>
        </>
    )
};