import Decimal from "decimal.js";
import {useTranslation} from "react-i18next";


export function Currency({value}) {

    const {t} = useTranslation();

    if(value === null || value === undefined) {
        return <>***</>
    }

    if(!(value instanceof Decimal)) {
        value = new Decimal(value);
    }

    return <>{t('intlCurrency', {value: value.toFixed(2), formatParams: {value: {
                currency: 'EUR',
                locale: 'fr-BE'
            }
            }})}</>
}
