import {useTranslation} from "react-i18next";
import {useMemo} from "react";


export function useInvoiceStatuses() {
    const {t} = useTranslation();

    const statuses = useMemo(() => {
        return [
            { value: 1,  name: t('status.draft') },
            { value: 2,  name: t('status.send') },
            { value: 3,  name: t('status.reSend') },
            { value: 4,  name: t('status.credited') },
            { value: 5,  name: t('status.billed') }
        ];
    }, [t]);


    return statuses;
}

export function useInvoiceStatus(value) {

    const statuses = useInvoiceStatuses();

    const status = useMemo(() => {
        return statuses.find(x => x.value === value);
    });

    return status;
}

