import {useInvoiceContext} from "../invoice.context";
import {useForm} from "react-hook-form";
import {useCallback, useEffect, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import Decimal from "decimal.js";
import {invoiceLineSchema, toolBarWidth} from "../constants";
import {InvoiceLine} from "./invoice.line";
import {Divider} from "primereact/divider";
import {CurrencyInput} from "./currency.input";
import {observer} from "mobx-react"
import {Currency} from "./currency";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button} from "primereact/button";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Menu} from "primereact/menu";
import {classNames} from "primereact/utils";

export const InvoiceForm = observer(({invoice, isInvoiceCococontractor}) => {
    const defaultValues = {
        subTotal: invoice.subTotal.toFixed(2)
    };

    const {control, handleSubmit, watch, setValue, formState} = useForm({
        defaultValues: defaultValues,
        mode: 'all',
        resolver: yupResolver(invoiceLineSchema())
    });

    const invoiceModel = useInvoiceContext();

    useEffect(() => {
        invoiceModel.setFormState(invoice.id, formState.isValid);

        return () => invoiceModel.unRegisterForm(invoice.id);
    }, [formState.isValid, invoice.id, invoiceModel]);

    const onSubmit = useCallback((data) => {
        invoice.changeSubTotal(new Decimal(data.subTotal))
    }, [invoice]);

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (type === 'change') {
                //handleSubmit(onSubmit)()
                switch (name) {
                    case 'subTotal':
                        invoice.updateSubTotal(value.subTotal);
                        break;
                    default:
                        throw new Error("Unimplemented type " + name)
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [invoice, watch]);

    useEffect(() => {
        setValue('subTotal', defaultValues.subTotal, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.subTotal, setValue]);

    const {t} = useTranslation();

    const op = useRef(null)

    const menuItems = useMemo(() => {
        const items = [
            {
                label: t('common.section'),
                //icon: 'pi pi-clone',
                command: () => {
                    invoice.addSection();
                }
            },
            {
                label: t('common.post'),
                //icon: 'pi pi-clone',
                command: () => {
                    invoice.addPost();
                }
            },
            {
                label: t('common.material'),
                //icon: 'pi pi-clone',
                command: () => {
                    invoice.addMaterial();
                }
            }
        ];

        return items;

    }, [t, invoice]);


    const hasCredit = invoice.lines.length > 0 && invoice.creditSubTotal < 0;
    const hasExtraCharge = invoice.lines.length > 0 && invoice.extraChargeSubTotal > 0;
    const hasVariant = invoice.lines.length > 0 && invoice.variantSubTotal > 0;

    let counter = 0;

    const calculateIndex = (index, line) => {
        if (index === 0) {
            if (line.type !== 'material' && line.type !== 'post') {
                counter += line.lines.length
                return index;
            }
        }
        
        if (line.type !== 'section') {
            counter += 1
        }
        ;
        const toReturn = counter;

        if (line.type !== 'material' && line.type !== 'post') {
            counter += line.lines.length
        }
        
        return toReturn;
    }

    return (
        <>
            {invoice.lines.map((line, index) => <InvoiceLine key={line.id} index={calculateIndex(index, line)} line={line}
                                                             parent={invoice} isCococontractor={isInvoiceCococontractor} />)}

            <div className={classNames("grid grid-nogutter pt-2 pb-2")}>
                <div className="col flex align-items-center flex-wrap"></div>
                <div className="col-1 flex align-items-center flex-wrap"></div>
                <div className="col-fixed align-items-center" style={{width: toolBarWidth}}></div>
                <div className="col-fixed flex align-items-center justify-content-end" style={{width: toolBarWidth}}>
                    <Button type="button" icon={"pi pi-plus"} className={"p-button-text p-button-sm p-button-info"}
                            onClick={(e) => op.current.toggle(e)}/>
                    <Menu model={menuItems} popup ref={op} id="popup_menu"/>
                </div>
            </div>

            <Divider/>

            {hasExtraCharge && (
                <>
                    <SummaryLine text={t('invoice.inExtraCharge')} subTotal={invoice.extraChargeSubTotal}
                                 total={invoice.extraChargeTotal} classes={"text-red-500"}/>
                    <Divider/>
                </>
            )}
            {hasCredit && (
                <>
                    <SummaryLine text={t('invoice.credit')} subTotal={invoice.creditSubTotal}
                                 total={invoice.creditTotal} classes={"text-primary"}/>
                    <Divider/>
                </>
            )}

            {hasVariant && (
                <>
                    <SummaryLine text={t('invoice.variant')} subTotal={invoice.variantSubTotal} total={invoice.variantTotal} classes={"text-purple-500"} />
                    <Divider/>
                </>
            )}

            { !isInvoiceCococontractor && invoice.lines.length > 0 && parseFloat(invoice.totalExclVAT6, new Decimal(0)) !== 0 &&
                <>
                    <SummaryLine text={'Total HTVA 6%'} total={invoice.totalExclVAT6} />
                    <SummaryLine text={'TVA 6%'} total={Math.abs(invoice.totalVAT6 - invoice.totalExclVAT6)} />
                    <SummaryLine text={'Total TVAC 6%'} total={invoice.totalVAT6} />
                    <Divider />
                </>
            }

            { !isInvoiceCococontractor && invoice.lines.length > 0 && parseFloat(invoice.totalExclVAT21, new Decimal(0)) !== 0 &&
                <>
                    <SummaryLine text={'Total HTVA 21%'} total={invoice.totalExclVAT21} />
                    <SummaryLine text={'TVA 21%'} total={Math.abs(invoice.totalVAT21 - invoice.totalExclVAT21)} />
                    <SummaryLine text={'Total TVAC 21%'} total={invoice.totalVAT21} />
                    <Divider />
                </>
            }

            <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid p-fluid grid-nogutter">
                <div
                    className="col flex align-items-center">{t('invoice.total')}</div>
                {invoice.lines.length === 0 && (
                    <div className="col-6 flex align-items-center justify-content-center">
                        <span className={"text-red-500"}>{t('invoice.emptyInvoiceLines')}</span>
                    </div>
                )}
                {invoice.lines.length > 0 && (
                    <>
                        {!isInvoiceCococontractor ? (
                            <>
                                <div className="col-1 flex align-items-center flex-wrap">
                                    <CurrencyInput control={control} fieldName="subTotal" label={t('common.subTotal')}/>
                                </div>
                                <div className="col-1 flex align-items-center">
                                    <strong className={"text-primary text-sm pl-2 m-1"}><Currency
                                        value={invoice.total}></Currency></strong>
                                </div>
                            </>
                        ) : (
                            <div className="col-1 flex align-items-center">
                                <strong className={"text-primary text-sm pl-2 m-1"}><Currency
                                    value={invoice.subTotal}></Currency></strong>
                            </div>
                        )}
                        
                    </>
                )}
                <div className="col-fixed flex align-items-center justify-content-end" style={{width: toolBarWidth}}></div>
            </form>

            <Divider/>

            {/* {(hasExtraCharge) && (
                <>
                    <SummaryLine text={t('invoice.totalAfterCreditWithExtraCharge')}
                                 subTotal={invoice.subTotalAfterCreditWithExtraCharge}
                                 total={invoice.totalAfterCreditWithExtraCharge}/>
                    <Divider/>
                </>
            )}

            {!hasExtraCharge && hasCredit && (
                <>
                    <SummaryLine text={t('invoice.total')} subTotal={invoice.subTotalAfterCreditWithExtraCharge}
                                 total={invoice.totalAfterCreditWithExtraCharge}/>
                    <Divider/>
                </>
            )} */}
            <ConfirmDialog/>
        </>
    );
})

function SummaryLine({text, subTotal, total, classes, prefix = null}) {

    return (
        <div className={classNames(classes, "grid grid-nogutter pt-2 pb-2")}>
            <div className="col flex align-items-center flex-wrap">{text}</div>
            {/* <div className="col-1 flex align-items-center flex-wrap"><span
                className={"pl-2 ml-1 text-sm"}>{prefix}<Currency value={subTotal}/></span></div> */}
            <div className="col-1 flex align-items-center flex-wrap"><strong
                className={"pl-2 ml-1 text-sm"}>{prefix}<Currency value={total}/></strong></div>
            <div className="col-fixed align-items-center" style={{width: toolBarWidth}}></div>
        </div>
    );
}
