import {useTranslation} from "react-i18next";
import { Chart } from 'primereact/chart';
import React, { useRef } from 'react';
import {Card} from "primereact/card";
import {TopMenu} from "../core/TopMenu";
import {TimelinePerso} from "../core/Timeline";
import {useDashboard} from "./dashboard.hooks";


export function DashboardPage() {

    const {t} = useTranslation();

    const {dashboard, isLoading, isFetched} = useDashboard();

    const lineData = {
        labels: JSON.parse(t("common.month")),
        datasets: [
            {
                label: t("invoice.title"),
                data: dashboard.invoicePerMonth,
                fill: false,
                backgroundColor: '#00bb7e',
                borderColor: '#00bb7e',
                tension: 0.4
            }
        ]
    };

    const documentStyle = getComputedStyle(document.documentElement);

    const data = {
        labels: dashboard.origin?.map(x => x.name),
        datasets: [
            {
                data: dashboard.origin?.map(x => x.occurrences),
                backgroundColor: [
                    documentStyle.getPropertyValue('--blue-500'),
                    documentStyle.getPropertyValue('--yellow-500'),
                    documentStyle.getPropertyValue('--green-500'),
                    documentStyle.getPropertyValue('--red-500'),
                    documentStyle.getPropertyValue('--purple-500'),
                    documentStyle.getPropertyValue('--orange-500')
                ],
                hoverBackgroundColor: [
                    documentStyle.getPropertyValue('--blue-400'),
                    documentStyle.getPropertyValue('--yellow-400'),
                    documentStyle.getPropertyValue('--green-400'),
                    documentStyle.getPropertyValue('--red-400'),
                    documentStyle.getPropertyValue('--purple-400'),
                    documentStyle.getPropertyValue('--orange-400')
                ]
            }
        ]
    }

    return(
        <>
            <TopMenu className="mb-3" moduleName={t('dashboard.title')} />
            <div className="grid">
                <div className="col-12 lg:col-6 xl:col-3">
                    <Card className="p-card md:col-12 sm:col-12">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">{t('dashboard.grossSales')}</span>
                                <div className="text-900 font-medium text-xl">{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(dashboard.grossSales)}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                <i className="pi pi-euro text-green-500 text-xl" />
                            </div>
                        </div>
                        <span className="text-500">{t("dashboard.samePeriod")} </span>
                        <span className="text-green-500 font-medium">{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(dashboard.grossSalesLastYear)}</span>
                    </Card>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <Card className="p-card md:col-12 sm:col-12">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">{t('dashboard.grossSalesEstimate')}</span>
                                <div className="text-900 font-medium text-xl">{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( parseFloat(dashboard.grossSales) + parseFloat(dashboard.sign))}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                <i className="pi pi-map-marker text-orange-500 text-xl" />
                            </div>
                        </div>
                        <span className="text-500">{t("dashboard.quoteSign")} </span>
                        <span className="text-green-500 font-medium">{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(dashboard.sign)}</span>
                    </Card>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <Card className="p-card md:col-12 sm:col-12">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">{t("dashboard.averageQuote")}</span>
                                <div className="text-900 font-medium text-xl">{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(dashboard.averageQuote)}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                <i className="pi pi-inbox text-cyan-500 text-xl" />
                            </div>
                        </div>
                        <span className="text-500">{t("dashboard.maxQuote")} </span>
                        <span className="text-green-500 font-medium">{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(dashboard.maxQuote)}</span>
                    </Card>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <Card className="p-card md:col-12 sm:col-12">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">{t("dashboard.unpaidInvoice")}</span>
                                <div className="text-900 font-medium text-xl">{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(dashboard.unpaidInvoice)}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                <i className="pi pi-check-square text-purple-500 text-xl" />
                            </div>
                        </div>
                        <span className="text-500">{t("dashboard.numberOfUnpaidInvoice")} </span>
                        <span className="text-green-500 font-medium">{dashboard.numberOfUnpaidInvoice}</span>
                    </Card>
                </div>

                {/*<div className="col-12 xl:col-6">
                    <Card className="p-card md:col-12 sm:col-12" title="Recent Sales">
                        <DataTable value={products} rows={5} paginator responsiveLayout="scroll">
                            <Column field="name" header="Name" sortable style={{ width: '35%' }} />
                            <Column field="price" header="Price" sortable style={{ width: '35%' }} />
                            <Column
                                header="View"
                                style={{ width: '15%' }}
                                body={() => (
                                    <>
                                        <Button icon="pi pi-search" type="button" text />
                                    </>
                                )}
                            />
                        </DataTable>
                    </Card>
                </div>*/}
                <div className="col-12 pb-0" >
                    <div className="grid" >
                        <div className="col-12 xl:col-6 h-auto">
                            <Card className="p-card md:col-12 sm:col-12 h-full" title={t("quote.title")}>

                                <ul className="list-none p-0 m-0">
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.quoteDraft")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbQuoteDraft}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-blue-600 h-full" style={{width : dashboard.nbQuoteDraftPercent + "%"}} />
                                            </div>
                                            <span className="text-blue-600 ml-3 font-medium">{dashboard.nbQuoteDraftPercent}%</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.quoteSend")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbQuoteSend}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-yellow-500 h-full" style={{width : dashboard.nbQuoteSendPercent + "%"}} />
                                            </div>
                                            <span className="text-yellow-500 ml-3 font-medium">{dashboard.nbQuoteSendPercent}%</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.quoteResend")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbQuoteResend}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-orange-500 h-full" style={{width : dashboard.nbQuoteResendPercent + "%"}} />
                                            </div>
                                            <span className="text-orange-500 ml-3 font-medium">{dashboard.nbQuoteResendPercent}%</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.quoteSign")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbQuoteSign}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-green-500 h-full" style={{width : dashboard.nbQuoteSignPercent + "%"}} />
                                            </div>
                                            <span className="text-green-500 ml-3 font-medium">{dashboard.nbQuoteSignPercent}%</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.quoteEdit")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbQuoteEdit}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-purple-500 h-full" style={{width : dashboard.nbQuoteEditPercent + "%"}} />
                                            </div>
                                            <span className="text-purple-500 ml-3 font-medium">{dashboard.nbQuoteEditPercent}%</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.quoteLost")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbQuoteLost}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-red-500 h-full" style={{width : dashboard.nbQuoteLostPercent + "%"}} />
                                            </div>
                                            <span className="text-red-500 ml-3 font-medium">{dashboard.nbQuoteLostPercent}%</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.quoteInvoice")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbQuoteInvoice}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-black-alpha-50 h-full" style={{width : dashboard.nbQuoteInvoicePercent + "%"}} />
                                            </div>
                                            <span className="text-black-alpha-50 ml-3 font-medium">{dashboard.nbQuoteInvoicePercent}%</span>
                                        </div>
                                    </li>
                                </ul>
                            </Card>
                        </div>

                        <div className="col-12 xl:col-6 h-auto">
                            <Card className="p-card md:col-12 sm:col-12 h-full" title={t("dashboard.grossSales")} style={{paddingBottom : "43px"}}>
                                <Chart type="line" data={lineData} />
                            </Card>
                        </div>
                    </div>
                </div>
                

                <div className="col-12">
                    <div className="grid">
                        <div className="col-12 xl:col-6 h-auto">
                            <Card className="p-card md:col-12 sm:col-12 h-full" title={t("invoice.title")}>

                                <ul className="list-none p-0 m-0">
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.invoiceDraft")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbInvoiceDraft}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-blue-600 h-full" style={{width : dashboard.nbInvoiceDraftPercent + "%"}} />
                                            </div>
                                            <span className="text-blue-600 ml-3 font-medium">{dashboard.nbInvoiceDraftPercent}%</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.invoiceSend")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbInvoiceSend}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-yellow-500 h-full" style={{width : dashboard.nbInvoiceSendPercent + "%"}} />
                                            </div>
                                            <span className="text-yellow-500 ml-3 font-medium">{dashboard.nbInvoiceSendPercent}%</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.invoiceResend")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbInvoiceResend}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-orange-500 h-full" style={{width : dashboard.nbInvoiceResendPercent + "%"}} />
                                            </div>
                                            <span className="text-orange-500 ml-3 font-medium">{dashboard.nbInvoiceResendPercent}%</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.invoiceBilled")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbInvoiceBilled}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-green-500 h-full" style={{width : dashboard.nbInvoiceBilledPercent + "%"}} />
                                            </div>
                                            <span className="text-green-500 ml-3 font-medium">{dashboard.nbInvoiceBilledPercent}%</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                                        <div>
                                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{t("dashboard.invoiceCredited")}</span>
                                            <div className="mt-1 text-600">{dashboard.nbInvoiceCredited}</div>
                                        </div>
                                        <div className="mt-2 md:mt-0 flex align-items-center">
                                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                                <div className="bg-red-500 h-full" style={{width : dashboard.nbInvoiceCreditedPercent + "%"}} />
                                            </div>
                                            <span className="text-red-500 ml-3 font-medium">{dashboard.nbInvoiceCreditedPercent}%</span>
                                        </div>
                                    </li>
                                </ul>
                            </Card>
                        </div>

                        <div className="col-12 xl:col-6 h-auto">
                            <Card className="p-card md:col-12 sm:col-12" title={t("dashboard.origin")}>
                                <Chart type="pie" className="w-full md:w-18.2rem align-content-center"  data={data}/>
                            </Card>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}