import * as React from "react";
import {useNavigate,} from "react-router-dom";
import {Button} from "primereact/button";

export function CustomLink({children, to, label, ...props}) {
    //let resolved = useResolvedPath(to);
    //let match = useMatch({path: resolved.pathname, end: true});
    let navigate = useNavigate();

    return (
        <Button type="button" label={label} className="p-button-link" onClick={() => navigate(to)} />
    )
}