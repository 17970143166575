import {useTranslation} from "react-i18next";
import {Controller} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import {FormErrorMessage} from "../../core/FormErrorMessage";

export function VatInput({fieldName, control, addStyle}) {

    const vatOptions = [
        {
            label: "21%",
            value: "21"
        },
        {
            label: "6%",
            value: "6"
        },
    ];

    //const [active, setActive] = useState(false);

    const {t} = useTranslation();


    return (
        <>
            <Controller name={fieldName} control={control}
                        render={({field, fieldState}) => (
                            <>
                                <Dropdown
                                    optionLabel="label"
                                    optionValue={"value"}
                                    options={vatOptions}
                                    placeholder={t('common.vat')}
                                    dropdownIcon={""}
                                    {...field}
                                    className={classNames({'p-invalid': fieldState.error}, 'p-inputtext-sm', 'w-full m-1 hover:surface-hover background-color-none border-color-none ' + addStyle)}
                                />

                                {fieldState.error && <><div className={"break"}></div><div className="ml-auto"><FormErrorMessage error={fieldState.error} /></div></>}
                            </>
                        )}/>
        </>
    )
}
