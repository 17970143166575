import {Route, Routes} from "react-router-dom";
import {DownloadsEdit} from "./downloads.edit";

export function Downloads() {
    return <Routes>

        <Route path="*" element={<DownloadsEdit />} />

    </Routes>

}