import {Tag} from "primereact/tag";
import {useTranslation} from "react-i18next";


export function PlaceholderQuote(){

    const {t} = useTranslation();

    return(
        <div className="col-6 text-sm mb-5">
            <span className={'m-1'}>{t('email.placeholders')}:</span>
            <br/>
            <Tag className={'m-1'} value={'%validitydate% : ' + t('email.validityDate')} />
            <Tag className={'m-1'} value={'%quotenumber% : ' + t('quote.number')} />
            <Tag className={'m-1'} value={'%quoteamount% : ' + t('common.subTotal')} />
            <Tag className={'m-1'} value={'%iban% : ' + t('email.iban')} />
            <Tag className={'m-1'} value={'%quotedate% : ' + t('email.quoteDate')} />
            <Tag className={'m-1'} value={'%companyname% : ' + t('email.companyName')} />
            <Tag className={'m-1'} value={'%clientname% : ' + t('client.name')} />
            <Tag className={'m-1'} value={'%gender% : ' + t('common.gender')} />
            <Tag className={'m-1'} value={'%clientnumber% : ' + t('client.number')} />
        </div>
    )
}