import {Fragment} from "react";
import {Card} from "primereact/card";
import {Timeline} from "primereact/timeline";
import {Date} from "./Date";
import 'moment/locale/fr';
import {useTranslation} from "react-i18next";

export function TimelinePerso({logs}) {

    const { t } = useTranslation();

    const customizedContent  = (item) => {
        return (
            <Fragment>
                <small>
                    {item.name === "client.create" && (<span>{t('timeline.client.create')} </span>)}
                    {item.name === "client.edit" && (<span>{t('timeline.client.edit')}  </span>)}
                    {item.name === "client.delete" && (<span>{t('timeline.client.delete')}  </span>)}
                    {item.name === "quote.create" && (<span>{t('timeline.quote.create')} </span>)}
                    {item.name === "quote.edit" && (<span>{t('timeline.quote.edit')}  </span>)}
                    {item.name === "quote.deleted" && (<span>{t('timeline.quote.delete')}  </span>)}
                    {item.name === "invoice.create" && (<span>{t('timeline.invoice.create')} </span>)}
                    {item.name === "invoice.edit" && (<span>{t('timeline.invoice.edit')}  </span>)}
                    {item.name === "invoice.deleted" && (<span>{t('timeline.invoice.delete')}  </span>)}
                    {item.name === "section.create" && (<span>{t('timeline.section.create')} </span>)}
                    {item.name === "section.edit" && (<span>{t('timeline.section.edit')}  </span>)}
                    {item.name === "section.deleted" && (<span>{t('section.quote.delete')}  </span>)}
                    {item.name === "post.create" && (<span>{t('timeline.post.create')} </span>)}
                    {item.name === "post.edit" && (<span>{t('timeline.post.edit')}  </span>)}
                    {item.name === "post.deleted" && (<span>{t('timeline.post.delete')}  </span>)}
                    {item.name === "material.create" && (<span>{t('timeline.material.create')} </span>)}
                    {item.name === "material.edit" && (<span>{t('timeline.material.edit')}  </span>)}
                    {item.name === "material.deleted" && (<span>{t('timeline.material.delete')}  </span>)}

                    <a href={"../users/" + item.user.id} style={{color: "#DF8009"}}><b>{item.user.name}</b></a></small>
                <br/>
                <small className="p-text-secondary"><Date datetime={item.createdAt} /></small>
            </Fragment>

        )
    };

    const customizedMarker = (item) => {
        return (
            <span className="custom-marker p-shadow-2" style={{
                backgroundColor: "#df8009",
                display: "flex",
                width: "2rem",
                height: "2rem",
                color: "#ffffff",
                borderRadius: "50%",
                zIndex: 1,
                alignItems: "center",
                justifyContent: "center"
            }}>
                {item.name === "client.edit" && <i className="pi pi-pencil"/>}
                {item.name === "client.create" && <i className="pi pi-plus"/>}
                {item.name === "client.deleted" && <i className="pi pi-trash"/>}
                {item.name === "quote.create" && <i className="pi pi-plus"/>}
                {item.name === "quote.edit" && <i className="pi pi-pencil"/>}
                {item.name === "quote.deleted" && <i className="pi pi-trash"/>}
                {item.name === "invoice.create" && <i className="pi pi-plus"/>}
                {item.name === "invoice.edit" && <i className="pi pi-pencil"/>}
                {item.name === "invoice.deleted" && <i className="pi pi-trash"/>}
                {item.name === "section.create" && <i className="pi pi-plus"/>}
                {item.name === "section.edit" && <i className="pi pi-pencil"/>}
                {item.name === "section.deleted" && <i className="pi pi-trash"/>}
                {item.name === "post.create" && <i className="pi pi-plus"/>}
                {item.name === "post.edit" && <i className="pi pi-pencil"/>}
                {item.name === "post.deleted" && <i className="pi pi-trash"/>}
                {item.name === "material.create" && <i className="pi pi-plus"/>}
                {item.name === "material.edit" && <i className="pi pi-pencil"/>}
                {item.name === "material.deleted" && <i className="pi pi-trash"/>}
            </span>
        );
    };

    return (
        <Fragment>
            <Card title="Timeline" className="shadow-3 mt-3">
                <div className="card">
                    <Timeline value={logs} align="alternate" marker={customizedMarker} content={customizedContent} />
                </div>
            </Card>
        </Fragment>
    )
}