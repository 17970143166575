import {Route, Routes} from "react-router-dom";
import {CreateGenderPage} from "./genders-create.page";
import {EditGenderPage} from "./genders-edit.page";
import {GenderList} from "./gender-list";


export function Genders() {
    return <Routes>
        <Route path=":id" element={<EditGenderPage />} />
        <Route path="/create" element={<CreateGenderPage />} />
        <Route path="*" element={<GenderList />} />
    </Routes>
}