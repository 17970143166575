import {useTranslation} from "react-i18next";
import {useMemo} from "react";


export function useQuoteStatuses() {
    const {t} = useTranslation();

    const statuses = useMemo(() => {
        return [
            { value: 1,  name: t('status.draft') },
            { value: 2,  name: t('status.send') },
            { value: 3,  name: t('status.reSend') },
            { value: 4,  name: t('status.oldVersion') },
            { value: 5,  name: t('status.lost') },
            { value: 6,  name: t('status.sign') },
            { value: 7,  name: t('status.invoice') },
        ];
    }, [t]);


    return statuses;
}

export function useQuoteStatus(value) {

    const statuses = useQuoteStatuses();

    const status = useMemo(() => {
        return statuses.find(x => x.value === value);
    });

    return status;
}

