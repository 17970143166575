import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {useOrigin, useUpdateOriginMutation} from "./origin.hooks";
import {useTranslation} from "react-i18next";
import {useToast} from "../../core/toast.context";
import {TopMenu} from "../../core/TopMenu";
import {TextControl} from "../../core/ControlledInputs/TextControl";
import {ProgressBar} from "primereact/progressbar";

import {useEffect} from "react";
import {originScheme} from "./origins.scheme";
import {yupResolver} from "@hookform/resolvers/yup";

export function EditOriginPage() {

    const scheme = originScheme();

    const {control, formState: {isDirty}, handleSubmit, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const navigate = useNavigate();

    let params = useParams();

    const {t} = useTranslation();

    const {origin, isLoading} = useOrigin(params.id);

    useEffect(() => {
        if(!isDirty) {
            reset(origin);
        }
    }, [isDirty, reset, origin]);

    const updateMutation = useUpdateOriginMutation();

    const toast = useToast();

    const onSubmit = async (data) => {
        try {
            const dto = {
                ...data
            };

            await updateMutation.mutateAsync({
                id: origin.id,
                data: dto
            });

            toast.current.show({severity: 'success', summary: t('origin.updated.title'), detail: t('origin.updated.text'), life: 3000});
            navigate('/settings/origins');
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('origin.updated.problem') + " " + err, life: 3000});
        }
    };

    return (
        <div>
            <TopMenu moduleName={t('origin.title')} create={'/settings/origins/create/'} list={'/settings/origins'}/>

            {isLoading && <ProgressBar mode={"indeterminate"} className={'mt-3'} style={{height: '6px'}} />}

            <Card title={t('origin.edit')} className="shadow-3 mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                    </div>
                    <Button type="submit" label={t('common.edit')} loading={updateMutation.isLoading}/>
                </form>
            </Card>

        </div>
    )
}
