import {Route, Routes} from "react-router-dom";
import {CreateOriginPage} from "./origins-create.page";
import {EditOriginPage} from "./origins-edit.page";
import {OriginList} from "./origin-list";


export function Origins() {
    return <Routes>
        <Route path=":id" element={<EditOriginPage />} />
        <Route path="/create" element={<CreateOriginPage />} />
        <Route path="*" element={<OriginList />} />
    </Routes>
}