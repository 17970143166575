import axios from "axios";
import TokenService from "./token.service";
import {config} from "../config";
const instance = axios.create({
    baseURL: config.url.API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});


instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;