import priorityService from "./priority.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export function usePriorities() {
    const {data: priorities, isFetched, isLoading, isError} = useQuery(['priorities'], priorityService.all, {
        initialData: []
    });

    return {
        priorities,
        isLoading,
        isFetched,
        isError
    };
}

export function usePriority(id, options = null) {
    const {data: priority, isLoading, isError} = useQuery(['priorities', id], ({queryKey}) => priorityService.get(queryKey[1]), options);

    return {
        priority, isLoading, isError
    };
}

export function useUpdatePriorityMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => priorityService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['priorities']);
        }
    });

    return mutation;
}


export function useCreatePriorityMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(priorityService.create, {
        onSuccess: (data) => {
            const priorities = queryClient.getQueryData(['priorities']) ?? [];
            queryClient.setQueryData(['priorities'], [...priorities, data]);
        }
    });

    return mutation;
}

export function useDeletePriorityMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(priorityService.delete, {
        onSuccess: (data, variables) => {
            const priorities = queryClient.getQueryData(['priorities']) ?? [];
            queryClient.setQueryData(['priorities'], priorities.filter(x => x.id !== variables))
        }
    });

    return mutation;
}