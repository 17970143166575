import {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {ItemTypes} from "../core/ItemTypes";
import {NumericControl} from "../core/ControlledInputs/NumericControl";
import {Button} from "primereact/button";
import { DropdownControl } from "../core/ControlledInputs/DropDownControl";
import {useTranslation} from "react-i18next";

export function PostLineItem({line, control, index, remove, moveLine}) {

    const {t} = useTranslation();
    const ref = useRef(null)
    const [, drop] = useDrop({
        accept: ItemTypes.PostLine,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveLine(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.PostLine,
        item: () => {
            return { line, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    const materialState = [
        {name: "normal"},
        {name: "option"},
        {name: "discount"},
    ];

    return (
        <div className="grid col-12" ref={ref} style={{opacity}}>
            <div className="col-12 md:col-3">
                {line.material.name}
            </div>
            <div className="field col-12 md:col-3">
                <NumericControl control={control} fieldName={`lines.${index}.amount`} label={t('common.amount')} />
            </div>
            <div className="field col-12 md:col-3">
                <DropdownControl control={control} fieldName={`lines.${index}.state`} label={t('common.state')} optionLabel={'name'} options={materialState} filter />
            </div>
            <div className="col-12 md:col-3">
                <Button type="button" icon="pi pi-trash" className="p-button-rounded p-button-text" onClick={() => remove(index)} />
            </div>
        </div>
    );
}