import postsService from "./posts.service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export function usePosts() {
    const {data: posts, isFetched, isLoading, isError} = useQuery(['posts'], postsService.all, {
        initialData: []
    });

    return {
        posts,
        isLoading,
        isFetched,
        isError
    };
}

export function usePost(id, options = null) {
    const {data: post, isLoading, isError, isFetching} = useQuery(['posts', id], ({queryKey}) => postsService.get(queryKey[1]), options);

    return {
        post, isLoading, isError, isFetching
    };
}

export function useUpdatePostMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => postsService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['posts']);
        }
    });

    return mutation;
}


export function useCreatePostMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(postsService.create, {
        onSuccess: (data) => {
            const posts = queryClient.getQueryData(['posts']) ?? [];
            queryClient.setQueryData(['posts'], [...posts, data]);
        }
    });

    return mutation;
}

export function useDeletePostMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(postsService.delete, {
        onSuccess: (data, variables) => {
            const posts = queryClient.getQueryData(['posts']) ?? [];
            queryClient.setQueryData(['posts'], posts.filter(x => x.id !== variables))
        }
    });

    return mutation;
}