import {Route, Routes} from "react-router-dom";
import {InvoicesListPage} from "./pages/invoices-list.page";
import {InvoicesEditPage} from "./pages/invoices-edit.page";
import {InvoicesCreatePage} from "./pages/invoices-create.page";
import Decimal from "decimal.js";

//TODO, where to put this ?
Decimal.set({precision: 10, rounding: 4});


export function Invoices ()
{
    return (
        <Routes>
            <Route path="*" element={<InvoicesListPage />} />
            <Route path=":id" element={<InvoicesEditPage />} />
            <Route path="create" element={<InvoicesCreatePage />} />
        </Routes>
    )
}
