import {useNavigate} from "react-router-dom";
import {Fragment, useState} from "react";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FilterMatchMode} from "primereact/api";
import {useToast} from "../core/toast.context";
import {useDeleteOrganisationMutation, useOrganisations, useCurrentOrganisation} from "./organisations.hooks";
import {useTranslation} from "react-i18next";
import {TopMenu} from "../core/TopMenu";
import {ConfirmDelete} from "../core/ConfirmDelete";
import { Dropdown } from "primereact/dropdown";
import {Skeleton} from "primereact/skeleton";
import {DateTime} from "../core/DateTime";
import organisationsService from "./organisations.service";

export function OrganisationsListPage(client = null) {

    const {organisations, isLoading, isFetched} = useOrganisations();
    const [organisation, setOrganisation] = useState(null);

    const deleteMutation = useDeleteOrganisationMutation();
    const { organisation: userCurrentOrganisation } = useCurrentOrganisation();

    let navigate = useNavigate();
    let toast = useToast();

    const {t} = useTranslation();

    const [filters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'city': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'deletedAt': { value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const actionTemplate = (organisation) => {
        const canDelete = userCurrentOrganisation?.id !== organisation?.id && organisation.deletedAt === 'NA' ;
        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text m-0 inline-block" onClick={() => navigate("/organisations/"+organisation.id)} />
                {canDelete &&  <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text m-0 inline-block" onClick={() => setOrganisation(organisation)}/>}
                {userCurrentOrganisation?.id !== organisation?.id && <Button icon="pi pi-eye" className="p-button-rounded p-button-help p-button-text m-0 inline-block" onClick={() => organisationsService.impersonate(organisation.id)} />}
            </>
        )
    };

    const deleteOrganisation = () => {
        deleteMutation.mutateAsync(organisation.id)
            .then(() => {
                toast.current.show({severity: 'success', summary: t('organisation.deleted.title'), detail: t('organisation.deleted.text'), life: 3000});
            })
            .catch(() => {
                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('organisation.deleted.problem'), life: 3000});
            })
            .finally(() => {
                setOrganisation(null);
            });
    };
    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option.value}`}>{option.name}</span>;
    }
    const deleteStatuses = [
			{ value: 'T', name: t('common.deleted') },
			{ value: 'NA', name: t('common.notDeleted') },
		];
    const deleteStatusFilterElement = (options) => {
        return <Dropdown value={options.value} options={deleteStatuses} optionLabel={"name"} optionValue={"value"} onChange={(e) => options.filterApplyCallback(e.value ? e.value : '')} itemTemplate={statusItemTemplate} placeholder={t('common.deletedAt')} className="p-column-filter" showClear />;
    }

    const items = Array.from({length: 10}, (v, i) => i);

    const skeletonTemplate = () => {
        return <Skeleton width={"80%"}/>;
    };

    return (
        <div>
            {!client.client && <TopMenu moduleName={t('organisation.title')} create={'/organisations/create/'} />}
            <Card className='shadow-3 mt-3' title={t('organisation.list')}>
                <DataTable
                    value={isLoading || !isFetched ? items : client.client ? organisations.filter((x) => x.client.id === client.client) : organisations}
                    paginator
                    sortMode='multiple'
                    filterDisplay='row'
                    filters={filters}
                    dataKey='id'
                    stripedRows
                    selectionMode='single'
                    emptyMessage={t('organisation.emptyList')}
                    currentPageReportTemplate={t('datatable.reportTemplate')}
                    rows={25}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    rowHover
                    responsiveLayout='scroll'
                    columnResizeMode='expand'
                    size={'small'}
                    cellSelection
                    onCellSelect={(e) => navigate('/organisations/'+e.rowData.id)}>
                    <Column
                        field='name'
                        header={t('common.name')}
                        sortable
                        filter
                        filterPlaceholder={t('common.name')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.name)}
                    />
                    <Column
                        field='email'
                        header={t('common.email')}
                        sortable
                        filter
                        filterPlaceholder={t('common.email')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"60%"}/> : row.email)}
                    />
                    <Column
                        field='city'
                        header={t('common.city')}
                        sortable
                        filter
                        filterPlaceholder={t('common.city')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"90%"}/> : row.city)}
                    />
                    <Column
                        field='deletedAt'
                        header={t('common.deletedAt')}
                        sortable
                        filter
                        filterPlaceholder={t('common.deletedAt')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : <span>{row.deletedAt !== 'NA' ? <DateTime datetime={row.deletedAt}/> : ' '}</span>)}
                    />
                    <Column body={isLoading || !isFetched ? skeletonTemplate : actionTemplate} className='p-0' style={{minWidth: '200px'}} />
                </DataTable>
            </Card>

            <ConfirmDelete name={organisation?.name} loading={deleteMutation.isLoading} visible={organisation} onHide={() => setOrganisation(null)} onConfirmed={deleteOrganisation} />

        </div>
    );
}