import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {useToast} from "../core/toast.context";
import {TopMenu} from "../core/TopMenu";
import {ProgressBar} from "primereact/progressbar";
import {Card} from "primereact/card";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {Button} from "primereact/button";
import {categoryScheme} from "./categories.scheme";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCategory, useUpdateCategoryMutation} from "./categories.hooks";


export function EditCategoryPage() {

    const scheme = categoryScheme();
    const {control, formState: {isDirty}, handleSubmit, reset} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const navigate = useNavigate();

    let params = useParams();

    const {t} = useTranslation();

    const {category, isLoading} = useCategory(params.id);

    useEffect(() => {
        if(!isDirty) {
            reset(category);
        }
    }, [isDirty, reset, category]);

    const updateMutation = useUpdateCategoryMutation();

    const toast = useToast();

    const onSubmit = async (data) => {
        try {
            const dto = {
                ...data
            };

            await updateMutation.mutateAsync({
                id: category.id,
                data: dto
            });

            toast.current.show({severity: 'success', summary: t('category.updated.title'), detail: t('category.updated.text'), life: 3000});
            navigate('/settings/categories');
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('category.updated.problem') + " " + err, life: 3000});
        }
    };

    return (
        <div>
            <TopMenu moduleName={t('category.title')} create={'/settings/categories/create/'} list={'/settings/categories'}/>

            {isLoading && <ProgressBar mode={"indeterminate"} className={'mt-3'} style={{height: '6px'}} />}

            <Card title={t('category.edit')} className="shadow-3 mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                    </div>
                    <Button type="submit" label={t('common.edit')} loading={updateMutation.isLoading}/>
                </form>
            </Card>

        </div>
    )
}