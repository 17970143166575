import {TopMenu} from "../core/TopMenu";
import {Card} from "primereact/card";
import {TextControl} from "../core/ControlledInputs/TextControl";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {useToast} from "../core/toast.context";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {PlaceholderQuote} from "../core/PlaceholderQuote";
import {PlaceholderInvoice} from "../core/PlaceholderInvoice";
import {useTranslation} from "react-i18next";
import {emailScheme} from "./emails.scheme";
import {useCreateEmailMutation} from "./emails.hooks";
import {EditorControl} from "../core/ControlledInputs/EditorControl";


export function CreateEmailPage(){

    const navigate = useNavigate();
    let toast = useToast();

    const scheme = emailScheme();

    const {control, handleSubmit} = useForm({
        defaultValues: scheme.cast(),
        resolver: yupResolver(scheme)
    });

    const createMutation = useCreateEmailMutation();

    const {t} = useTranslation();

    const onSubmit = async (data) => {

        const dto = {
            ...data
        };

        try {
            await createMutation.mutateAsync(dto);
            toast.current.show({severity: 'success', summary: t('email.created.title'), detail: t('email.created.text'), life: 3000});
            navigate('/settings/emails');
        } catch (err) {
            toast.current.show({ severity: 'error', summary: t('common.oops'), detail: t('email.created.problem')+' '+err, life: 3000});
        }
    };

    return (

        <div>
            <TopMenu moduleName={t('email.title')} list={'/settings/emails'}/>

            <Card title={t('email.create')} className="shadow-3 mt-3">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid">
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'name'} label={t('common.name')} autoFocus />
                    </div>
                    <div className="field col-12">
                        <TextControl control={control} fieldName={'subject'} label={t('email.subject')} />
                    </div>
                    <div className="field col-12">
                        <PlaceholderQuote/>
                        <PlaceholderInvoice/>
                    </div>
                    <div className="field col-12">
                        <EditorControl control={control} fieldName={'body'} label={t('email.body')} />
                    </div>
                    <Button type="submit" label={t('common.create')} loading={createMutation.isLoading}/>
                </form>
            </Card>
        </div>
    )
}
