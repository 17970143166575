import {Controller} from "react-hook-form";
import {classNames} from "primereact/utils";
import {InputNumber} from "primereact/inputnumber";
import {FormErrorMessage} from "../../core/FormErrorMessage";

export function CurrencyInput({fieldName, control, addStyle}) {

    return (
        <>
            <Controller name={fieldName} control={control}
                        render={({field, fieldState}) => (
                            <>
                                <InputNumber mode="currency"
                                             minFractionDigits={2}
                                             maxFractionDigits={2}
                                             currency="EUR"
                                             locale={'de-DE'}
                                             onBlur={(e) => {
                                                field.onBlur();
                                            }}
                                             value={field.value}
                                             ref={field.ref}
                                             onValueChange={(e) => field.onChange(e.value)}
                                             onClick={e => e.target.select()}
                                             className={classNames({'p-invalid': fieldState.error}, 'p-inputtext-sm', 'w-full', 'border-round-xs', 'm-1 background-color-none border-color-none')}
                                             inputClassName={classNames('w-full hover:surface-hover background-color-none border-color-none ' + addStyle)}
                                />

                                {fieldState.error && <><div className={"break"}></div><div className="ml-auto"><FormErrorMessage error={fieldState.error} /></div></>}
                            </>
                        )}/>
        </>
    )
}
