import {Route, Routes} from "react-router-dom";
import {UsersListPage} from "./users-list.page";
import {CreateUserPage} from "./users-create.page";
import {EditUserPage} from "./users-edit.page";
import {ProvideOrganisation} from "../organisations/organisation.context";


export function Users() {
    return <ProvideOrganisation>
        <Routes>
            <Route path="create" element={<CreateUserPage />} />
            <Route path=":id" element={<EditUserPage />} />
            <Route path="*" element={<UsersListPage />} />
        </Routes>
    </ProvideOrganisation>
}