import {Controller} from "react-hook-form";
import {classNames} from "primereact/utils";
import {FormErrorMessage} from "../FormErrorMessage";
import {Chips} from "primereact/chips";

export function ChipsControl({fieldName, control, label, autoFocus=false, disabled=false}) {
    return (
        <Controller name={fieldName} control={control}
                    render={({field, fieldState, formState}) => {
                        return <>
                            <span className="p-float-label">
                                <Chips id={field.name}
                                           {...field}
                                            autoFocus={autoFocus}
                                           className={classNames({'p-invalid': !!fieldState.error})} disabled={disabled}/>
                                <label htmlFor={field.name}
                                       className={classNames({'p-error': !!fieldState.error})}>{label}</label>
                            </span>
                            <FormErrorMessage error={fieldState.error}/>
                        </>
                    }}/>
    );
}
