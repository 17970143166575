import {useQuoteContext} from "../quote.context";
import {useForm} from "react-hook-form";
import {useCallback, useEffect, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import Decimal from "decimal.js";
import {quoteLineSchema, toolBarWidth} from "../constants";
import {QuoteLine} from "./quote.line";
import {Divider} from "primereact/divider";
import {CurrencyInput} from "./currency.input";
import {observer} from "mobx-react"
import {Currency} from "./currency";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button} from "primereact/button";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Menu} from "primereact/menu";
import {classNames} from "primereact/utils";

export const QuoteForm = observer(({quote}) => {

    const defaultValues = {
        subTotal: quote.subTotal.toFixed(2)
    };

    const {control, handleSubmit, watch, setValue, formState} = useForm({
        defaultValues: defaultValues,
        mode: 'all',
        resolver: yupResolver(quoteLineSchema())
    });

    const quoteModel = useQuoteContext();

    useEffect(() => {
        quoteModel.setFormState(quote.id, formState.isValid);

        return () => quoteModel.unRegisterForm(quote.id);
    }, [formState.isValid, quote.id, quoteModel]);

    const onSubmit = useCallback((data) => {
        quote.changeSubTotal(new Decimal(data.subTotal))
    }, [quote]);

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            //console.log(name, type);
            if (type === 'change') {
                //handleSubmit(onSubmit)()
                switch (name) {
                    case 'subTotal':
                        quote.updateSubTotal(value.subTotal);
                        break;
                    default:
                        throw new Error("Unimplemented type " + name)
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [quote, watch]);

    useEffect(() => {
        setValue('subTotal', defaultValues.subTotal, {
            shouldDirty: false, shouldValidate: true, shouldTouch: false
        });
    }, [defaultValues.subTotal, setValue]);

    const {t} = useTranslation();

    const op = useRef(null)

    const menuItems = useMemo(() => {
        const items = [
            {
                label: t('common.section'),
                //icon: 'pi pi-clone',
                command: () => {
                    quote.addSection();
                }
            },
            {
                label: t('common.post'),
                //icon: 'pi pi-clone',
                command: () => {
                    quote.addPost();
                }
            },
            {
                label: t('common.material'),
                //icon: 'pi pi-clone',
                command: () => {
                    quote.addMaterial();
                }
            },
            {
                label: 'Ajouter multiples lignes',
                // icon: 'pi pi-clone',
                command: () => {
                    quote.addMultipleMaterial();
                }
            },
        ];

        return items;

    }, [t, quote]);

    const hasDiscount = quote.lines.length > 0 && quote.discountSubTotal > 0;
    const hasOptions = quote.lines.length > 0 && quote.optionSubTotal > 0;
    const hasVariant = quote.lines.length > 0 && quote.variantSubTotal > 0;

    let counter = 0;

    const calculateIndex = (index, line) => {
        if (line.type === 'multipleMaterial') {
            // Do not create index for multipleMaterial line, since this line will be discarded when value entered
            return -1;
        }
        if (index === 0) {
            if (line.type !== 'material' && line.type !== 'post') {
                counter += line.lines.length
                return index;
            }
        }
        
        if (line.type !== 'section') {
            counter += 1
        }
        ;
        const toReturn = counter;

        if (line.type !== 'material' && line.type !== 'post') {
            counter += line.lines.length
        }
        
        return toReturn;
    }
    
    return (
        <>
        {/* HERE */}
            {quote.lines.map((line, index) => { return <QuoteLine key={line.id} index={calculateIndex(index, line)} line={line}
                                                         parent={quote} /> })}

            <div className={classNames("grid grid-nogutter pt-2 pb-2")}>
                <div className="col flex align-items-center flex-wrap"></div>
                <div className="col-1 flex align-items-center flex-wrap"></div>
                <div className="col-fixed align-items-center" style={{width: toolBarWidth}}></div>
                <div className="col-fixed flex align-items-center justify-content-end" style={{width: toolBarWidth}}>
                    <Button type="button" icon={"pi pi-plus"} className={"p-button-text p-button-sm p-button-info"}
                            onClick={(e) => op.current.toggle(e)}/>
                    <Menu model={menuItems} popup ref={op} id="popup_menu"/>
                </div>
            </div>

            <Divider/>


            {hasOptions && (
                <>
                    <SummaryLine text={t('quote.inOption')} subTotal={quote.optionSubTotal} total={quote.optionTotal} classes={"text-red-500"} />
                    <Divider/>
                </>
            )}
            {hasDiscount && (
                <>
                    <SummaryLine text={t('quote.discount')} subTotal={quote.discountSubTotal} total={quote.discountTotal} classes={"text-primary"} prefix={"-"} />
                    <Divider/>
                </>
            )}
            {hasVariant && (
                <>
                    <SummaryLine text={t('quote.variant')} subTotal={quote.variantSubTotal} total={quote.variantTotal} classes={"text-purple-500"} />
                    <Divider/>
                </>
            )}

            { quote.lines.length > 0 && parseFloat(quote.totalExclVAT6, new Decimal(0)) !== 0 &&
                <>
                    <SummaryLine text={'Total HTVA 6%'} total={quote.totalExclVAT6} />
                    <SummaryLine text={'TVA 6%'} total={Math.abs(quote.totalVAT6 - quote.totalExclVAT6)} />
                    <SummaryLine text={'Total TVAC 6%'} total={quote.totalVAT6} />
                    <Divider />
                </>
            }

            { quote.lines.length > 0 && parseFloat(quote.totalExclVAT21, new Decimal(0)) !== 0 &&
                <>
                    <SummaryLine text={'Total HTVA 21%'} total={quote.totalExclVAT21} />
                    <SummaryLine text={'TVA 21%'} total={Math.abs(quote.totalVAT21 - quote.totalExclVAT21)} />
                    <SummaryLine text={'Total TVAC 21%'} total={quote.totalVAT21} />
                    <Divider />
                </>
            }

            <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid p-fluid grid-nogutter">
                <div className="col flex align-items-center">{t('quote.total')}</div>
                {quote.lines.length === 0 && (
                    <div className="col-6 flex align-items-center justify-content-center">
                        <span className={"text-red-500"}>{t('quote.emptyQuoteLines')}</span>
                    </div>
                )}
                {quote.lines.length > 0 && (
                    <>
                        <div className="col-1 flex align-items-center flex-wrap">
                            <CurrencyInput control={control} fieldName="subTotal" label={t('common.subTotal')}/>
                        </div>
                        <div className="col-1 flex align-items-center">
                            <strong className={"text-primary text-sm pl-2 m-1"}><Currency value={quote.total}></Currency></strong>
                        </div>
                    </>
                )}
                <div className="col-fixed flex align-items-center justify-content-end" style={{width: toolBarWidth}}></div>
            </form>

            <Divider/>


            {/* { (hasOptions) && (
                <>
                    <SummaryLine text={t('quote.totalAfterDiscountWithOptions')} subTotal={quote.subTotalAfterDiscountWithOptions} total={quote.totalAfterDiscountWithOptions} />
                    <Divider/>
                    <SummaryLine text={t('quote.totalAfterDiscountWithoutOptions')} subTotal={quote.subTotalAfterDiscountWithoutOptions} total={quote.totalAfterDiscountWithoutOptions} />
                    <Divider/>
                </>
            )}
            {!hasOptions && hasDiscount && (
                <>
                    <SummaryLine text={t('quote.total')} subTotal={quote.subTotalAfterDiscountWithOptions} total={quote.totalAfterDiscountWithOptions} />
                    <Divider/>
                </>
            )} */}
            <ConfirmDialog/>
        </>
    );
})

function SummaryLine({text, total, classes, prefix = null}) {

    return (
        <div className={classNames(classes, "grid grid-nogutter pt-2 pb-2")}>
            <div className="col flex align-items-center flex-wrap">{text}</div>
            {/* <div className="col-1 flex align-items-center flex-wrap"><span className={"pl-2 ml-1 text-sm"}>{prefix}<Currency value={subTotal} /></span></div> */}
            <div className="col-1 flex align-items-center flex-wrap"><strong className={"pl-2 ml-1 text-sm"}>{prefix}<Currency value={total} /></strong></div>
            <div className="col-fixed align-items-center" style={{width: toolBarWidth}}></div>
        </div>
    );
}
