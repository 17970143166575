import api from "../services/api";

class EmailsService {

    all() {
        return api.get('api/emails')
            .then(function(x) {
                return x.data;
            });
    }

    create(data) {
        return api.post('api/emails', JSON.stringify(data))
            .then(x => x.data);
    }

    delete(id) {
        return api.delete('api/emails/'+id);
    }

    get(id) {
        return api.get('api/emails/'+id)
            .then(x => x.data);
    }

    update(id, data) {
        return api.post('api/emails/' + id, JSON.stringify(data))
            .then(x => x.data);
    }

}

export default new EmailsService();