import api from "../../services/api";

class OriginService {
    all() {
        return api.get('api/origins')
            .then(function(x) {
                return x.data;
            });
    }

    delete(id) {
        return api.delete('api/origins/'+id);
    }

    get(id) {
        return api.get('api/origins/'+id)
            .then(x => x.data);
    }

    create(data) {
        return api.post('api/origins', JSON.stringify(data))
            .then(x => x.data);
    }

    update(id, data) {
        return api.post('api/origins/' + id, JSON.stringify(data))
            .then(x => x.data);
    }
}

export default new OriginService();