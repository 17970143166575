import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Card} from "primereact/card";
import {ConfirmDelete} from "../../core/ConfirmDelete";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useToast} from "../../core/toast.context";
import {FilterMatchMode} from "primereact/api";
import {Button} from "primereact/button";
import {HasRole} from "../../auth/has-role";
import {TopMenu} from "../../core/TopMenu";
import {Skeleton} from "primereact/skeleton";
import { useCountries, useDeleteCountryMutation } from "./country.hooks";


export function CountryList(client = null) {


    const {countries, isLoading, isFetched} = useCountries();
    const deleteMutation = useDeleteCountryMutation();

    const [country, setCountry] = useState(null);

    let navigate = useNavigate();
    let toast = useToast();

    const {t} = useTranslation();

    const [filters] = useState({
        'name': {value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const actionTemplate = (country) => {

        return (
            <>
            {!country.common &&
                <HasRole role="ROLE_ADMIN">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-text m-0" onClick={() => navigate(country.id)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text m-0" onClick={() => confirmDeleteCountry(country)}/>
                </HasRole>
            }
            </>
        )
    };

    const confirmDeleteCountry = (country) => {
        setCountry(country);
    };

    const hideDeleteDialog = () => {
        setCountry(null);
    };

    const deleteCountry = () => {
        deleteMutation.mutateAsync(country.id)
            .then(() => {
                toast.current.show({severity: 'success', summary: t('contry.deleted.title'), detail: t('country.deleted.text'), life: 3000});
            })
            .catch((err) => {
                toast.current.show({severity: 'error', summary: t('common.oops'), detail: t('country.deleted.problem') + " " + err , life: 3000});
            })
            .finally(() => {
                hideDeleteDialog();
            });
    };
    const items = Array.from({length: 10}, (v, i) => i);

    const skeletonTemplate = () => {
        return <Skeleton width={"80%"}/>;
    };

    return (
        <>
            {!client.client && <TopMenu moduleName={t('country.title')} create={'/settings/countries/create/'} />}
            <Card className='shadow-3 mt-3' title={t('country.list')}>
                <DataTable
                    value={isLoading || !isFetched ? items : client.client ? countries.filter((x) => x.client.id === client.client) : countries}
                    paginator
                    sortMode='multiple'
                    filterDisplay='row'
                    filters={filters}
                    dataKey='id'
                    stripedRows
                    selectionMode='single'
                    emptyMessage={t('country.emptyList')}
                    currentPageReportTemplate={t('datatable.reportTemplate')}
                    rows={25}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    rowHover
                    responsiveLayout='scroll'
                    columnResizeMode='expand'
                    size={'small'}
                    cellSelection
                    onCellSelect={(e) => !e.rowData.common && navigate(e.rowData.id)}>
                    <Column
                        field='name'
                        header={t('common.name')}
                        sortable
                        filter
                        filterPlaceholder={t('common.name')}
                        showFilterMenu={false}
                        body={(row) => (isLoading || !isFetched ? <Skeleton width={"70%"}/> : row.name)}
                    />
                    <Column body={isLoading || !isFetched ? skeletonTemplate : actionTemplate} className='p-0' style={{minWidth: '200px'}} />
                </DataTable>
            </Card>

            <ConfirmDelete name={country?.name} loading={deleteMutation.isLoading} visible={country} onHide={() => setCountry(null)} onConfirmed={deleteCountry} />
        </>
    );
}