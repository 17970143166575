import api from "../services/api";
import {saveAs} from 'file-saver';
import {parse} from "content-disposition-header";

class OrganisationsService {

    all(){
        return api.get('api/organisations')
            .then(function (x) {
                return x.data
            })
    }

    create(data){
        return api.post('api/organisations', JSON.stringify(data))
            .then(x => x.data);
    }

    get(id) {
        return api.get('api/organisations/' + id)
            .then(x => x.data);
    }

    getCurrent() {
        return api.get('api/organisations/current')
            .then(x => x.data);
    }
    
    getExpiryDate(id) {
        return api.get('api/expired-date/'+ id)
            .then(x => x.data);
    }

    update(id, data) {
        return api.post('api/organisations/' + id, JSON.stringify(data))
            .then(x => x.data);
    }

    delete(id) {
        return api.delete('api/organisations/'+id);
    }

    getGoogleDriveConfiguration(id) {
        return api.get('api/organisations/' + id + '/google-drive-configuration')
            .then(x => x.data);
    }

    setGoogleDriveConfiguration(id, data) {
        return api.post('api/organisations/' + id + '/google-drive-configuration', JSON.stringify(data))
            .then(x => x.data);
    }

    async uploadBaseDwg(file) {

        const form = new FormData();
        form.append("basedwg", file, file.name);

        return api.post('api/basefiles/basedwg', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    async downloadBaseDwg() {
        return api.get('api/basefiles/basedwg', {
            responseType: 'blob'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['Content-Type'] });

                const contentDisposition = parse(response.headers["content-disposition"]);

                saveAs(blob, contentDisposition.parameters.filename);
            });
    }

    async uploadLogo(file) {

        const form = new FormData();
        form.append("logo", file, file.name);

        return api.post('api/organisations/logo', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    async downloadLogo() {
        return api.get('api/organisations/logo', {
            responseType: 'blob'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['Content-Type'] });

                const contentDisposition = parse(response.headers["content-disposition"]);

                saveAs(blob, contentDisposition.parameters.filename);
            });
    }

    async uploadSaleCondition(file) {

        const form = new FormData();
        form.append("salecondition", file, file.name);

        return api.post('api/organisations/salecondition', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    async downloadSaleCondition() {
        return api.get('api/organisations/salecondition', {
            responseType: 'blob'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['Content-Type'] });

                const contentDisposition = parse(response.headers["content-disposition"]);

                saveAs(blob, contentDisposition.parameters.filename);
            });
    }

    async getLogoBase64() {
        return api.get('api/organisations/logo', {
            responseType: 'arraybuffer'
        })
            .then((response) => {
                    let data = `data:${
                        response.headers["content-type"]
                    };base64,${arrayBufferToBase64(response.data)}`;

                    return data;
                }
            );
    }

    getOptionQuote() {
        return api.get('api/pdfoption')
            .then(function (x) {
                return x.data;
            });
    }


    updateOptionQuote(data) {
        return api.post('api/pdfoption', JSON.stringify(data))
            .then(x => x.data);
    }

    async downloadExe(){
        return api.get('api/basefiles/exefolder', {
            responseType: 'blob'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['Content-Type'] });

                const contentDisposition = parse(response.headers["content-disposition"]);

                saveAs(blob, contentDisposition.parameters.filename);
            });
    }

    async uploadSignature(file) {

        const form = new FormData();
        form.append("signature", file, file.name);

        return api.post('api/organisations/signature', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    async downloadSignature() {
        return api.get('api/organisations/signature', {
            responseType: 'blob'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['Content-Type'] });

                const contentDisposition = parse(response.headers["content-disposition"]);

                saveAs(blob, contentDisposition.parameters.filename);
            });
    }

    updateTheme(data, organisationId) {
        return api.post('api/organisations/theme', JSON.stringify(data))
            .then(x => x.data);
    }

    impersonate(id) {
        return api.get('api/organisations/impersonate/' + id)
            .then(window.location.reload());
    }
}

function arrayBufferToBase64(buffer) {
    return btoa(
        new Uint8Array(buffer)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
}

export default new OrganisationsService();