import {DateTime} from "../../core/DateTime";
import {useTranslation} from "react-i18next";

export function QuoteComment({comment}) {

    const {t} = useTranslation();

    return (
      <div className={"shadow-1 p-3 mt-2 mb-2"}>
          <div>
              {comment.user && (<strong className={"text-primary"}>{comment.user.name}</strong>)}
              {!comment.user && (<em>{t('common.anonymous')}</em>)}
          </div>
          <div className={"text-sm text-gray-500"}><DateTime datetime={comment.createdAt} /></div>
          <div className={"pre-blank mt-2"} style={{
              whiteSpace: 'pre-wrap'
          }}>{comment.message}</div>
      </div>
    );
}
