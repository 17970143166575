import {Route, Routes} from 'react-router-dom';
import {QuoteTemplateList} from './quote.template-list';
import {CreateQuoteTemplatePage} from './quote.template-create.page';
import {EditQuoteTemplatePage} from './quote.template-edit.page';

export function QuoteTemplates() {
	return (
		<Routes>
			<Route path=':id' element={<EditQuoteTemplatePage />} />
			<Route path='/create' element={<CreateQuoteTemplatePage />} />
			<Route path='*' element={<QuoteTemplateList />} />
		</Routes>
	);
}
