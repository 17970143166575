import {Route, Routes} from "react-router-dom";
import {SignatureEdit} from "./signature.edit";

export function Signature() {
    return <Routes>

        <Route path="*" element={<SignatureEdit />} />

    </Routes>

}