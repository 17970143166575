import {Card} from 'primereact/card';
import {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Invoice} from '../../../invoices/models/Invoice';
import {ProvideInvoice, useInvoiceContext} from '../../../invoices/invoice.context';
import {InvoiceForm} from '../../../invoices/components/invoice.form';
import moment from 'moment/moment';
import {observer} from 'mobx-react';
import {Button} from 'primereact/button';
import {useToast} from '../../../core/toast.context';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {TopMenu} from '../../../core/TopMenu';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Checkbox} from 'primereact/checkbox';
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {InputText} from 'primereact/inputtext';
import {useCreateInvoiceTemplateMutation} from './invoice.template.hooks';

export function CreateInvoiceTemplatePage() {
	const [searchParams] = useSearchParams();

	const [isLoading, setIsLoading] = useState(true);

	const [invoice, setInvoice] = useState(null);

	const toast = useToast();

	const { t } = useTranslation();
	
	const {pathname} = useLocation();
    const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener('beforeunload', alertUser);
		if (pathname == `/settings/invoice-templates/create/`) {
			window.history.pushState(null, document.title, window.location.href);
			window.addEventListener('popstate', popFunction);
		}
		return () => {
			window.removeEventListener('beforeunload', alertUser);
			window.removeEventListener('popstate', popFunction);
		};
	}, []);
	const alertUser = (e) => {
		e.preventDefault();
		e.returnValue = '';
	};

	const popFunction = (event) => {
		event.preventDefault();
		const answer = window.confirm(t(`common.leavePage`));
		if (!answer) {
			window.history.pushState(null, document.title, window.location.href);
		} else {
			navigate('/settings/invoice-templates/');
		}
	};

	const startNewInvoice = () => {
		setInvoice(new Invoice(uuidv4(), [], null, null, moment().toDate(), moment().add(1, 'month').toDate()));
		setIsLoading(false);
	};

    useState(() => {
        startNewInvoice();
    }, [])

	if (isLoading) {
		return (
			<Card className='ml-auto mr-auto mt-2 text-center' style={{maxWidth: '1400px'}}>
				<ProgressSpinner />
				<ConfirmDialog />
			</Card>
		);
	}

	return (
		<ProvideInvoice devi={invoice}>
			<CreatePage invoice={invoice} />
		</ProvideInvoice>
	);
}

const CreatePage = observer(({invoice}) => {
    const createMutation=useCreateInvoiceTemplateMutation();
    const [name, setName] = useState('')

	const toast = useToast();

	const {t} = useTranslation();

	const navigate = useNavigate();

	const submitForm=async () => {
		if(!name) {
			return
		}
        const dto = {
            name: name,
            lines: invoice.dto.lines,
            vatTotal: invoice.dto.vatTotal,    
			total: invoice.dto.total,
            subTotal: invoice.dto.subTotal,
		};
		try {
			const newInvoice = await createMutation.mutateAsync(dto);
			toast.current.show({
				severity: 'success',
				summary: t('invoice.template.created.title'),
				detail: t('invoice.template.created.text'),
				life: 3000,
			});
			navigate('/settings/invoice-templates/');
		} catch (err) {
			toast.current.show({
				severity: 'error',
				summary: t('common.oops'),
				detail: t('invoice.template.created.problem') + ' ' + err,
				life: 3000,
			});
		}
	};

	const invoiceModel = useInvoiceContext();

	return (
		<div>
			<TopMenu moduleName={t('invoice.template.title')} list={'/settings/invoice-templates'} />

			<Card className='ml-auto mr-auto mt-2 xl:px-4 xl:pt-0 sm:p-3' style={{maxWidth: '1400px'}}>
				<h2 className='pb-4' style={{marginTop:'-10px'}}>{t('invoice.template.create')}</h2>
				<div className='col-12'>
					<span className='p-float-label'>
						<InputText id='templateName' value={name} onChange={(e) => setName(e.target.value)} />
						<label htmlFor='templateName'>{t('invoice.template.name')}</label>
					</span>
					{!name && <div className='p-error pt-2 text-sm'>{t(`validations.required`)}</div>}
				</div>

				<InvoiceForm invoice={invoice} />

				<div className='grid grid-nogutter'>
					<div className='col justify-content-center'>
						<Button type={'button'} label={t('common.create')} disabled={!invoiceModel.isValid || createMutation.isLoading || !name} loading={createMutation.isLoading} onClick={submitForm} />
					</div>
					<div className='col-8'></div>
				</div>
			</Card>
		</div>
	);
});
