import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import emailService from "./emails.service";
import clientsService from "../clients/clients.service";
import emailsService from "./emails.service";
import genderService from "../clients/genders/gender.service";


export function useEmails(){

    const {data: emails, isFetched, isLoading, isError} = useQuery(['emails'], emailsService.all, {
        initialData: []
    });

    return {
        emails,
        isLoading,
        isFetched,
        isError
    };

}

export function useCreateEmailMutation() {

    const queryEmail = useQueryClient();

    const mutation = useMutation(emailService.create, {
        onSuccess: (data) => {
            const emails = queryEmail.getQueryData(['emails']) ?? [];
            queryEmail.setQueryData(['emails'], [...emails, data]);
        }
    });

    return mutation;
}

export function useDeleteEmailMutation() {

    const queryClient = useQueryClient();

    const mutation = useMutation(emailsService.delete, {
        onSuccess: (data, variables) => {
            const emails = queryClient.getQueryData(['emails']) ?? [];
            queryClient.setQueryData(['emails'], emails.filter(x => x.id !== variables))
        }
    });

    return mutation;
}

export function useEmail(id, options = null) {
    const {data: email, isLoading, isError, isFetching} = useQuery(['emails', id], ({queryKey}) => emailService.get(queryKey[1]), options);

    return {
        email, isLoading, isError, isFetching
    };
}

export function useUpdateEmailMutation() {
    const queryClient = useQueryClient();

    const mutation = useMutation((params) => emailService.update(params.id, params.data), {
        onSuccess: async (data, params) => {
            await queryClient.invalidateQueries(['emails']);
        }
    });

    return mutation;
}
